import { Component, OnInit } from '@angular/core';
declare const dicom: any;
declare const box: any;
declare var $: any;
 import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-dicom',
  templateUrl: './dicom.component.html',
  styleUrls: ['./dicom.component.css']
})
export class DicomComponent implements OnInit {
  public accesstoken: string;
  message: string;
  public fileID;
  public receivedImagesList: any;
  constructor(private http: HttpClient, public authService: AuthService) {
    this.fileID = this.authService.getValue();
    console.log('received file id', this.fileID._value);
  }
  ngOnInit() {
  new dicom();
  this.view();
  }
  view() {
    this.http.get(this.authService.baseURL + '/FetchAccessToken').subscribe(
    data => {
      console.log(data['accessToken']);
      this.accesstoken = data['accessToken'];
      box.dicom.createViewer(document.getElementById('dicombody'), {
        'accessToken': this.accesstoken,
        'studies' : [ {
        'fileId' : this.fileID._value
      }]
      });
    });
  }
}

