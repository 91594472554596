import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AngularFireAuth } from 'angularfire2/auth';
import {MatDialog} from '@angular/material';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import { PlatformLocation } from '@angular/common';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.css']
})
export class DoctorProfileComponent implements OnInit {

  showProfileDiv = true;
  editProfileDiv = false;
  ShowResetPasswordDiv = false;
  url = '';
  loggedInUser;
  fullName;
  userEmail;
  userName;
  wrongPassword = false;
  responseReceived;
  showMatchError = false;
  showValidationMsg = false;
  disabledVar;
  contactNo;
  profilePicUrl;
  reader;
  files;
  file;
  setToggleStatus;
  availabilityStatus: Boolean = false;
  passwordForm: FormGroup;
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  fullNameFormControl = new FormControl('', [
    Validators.required
  ]);
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  newPasswordFormControl = new FormControl('', [
 Validators.required,
 Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')
  ]);
 confirmPasswordFormControl = new FormControl('', [
   Validators.required,
   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')
 ]);
 updatedFullName;
 updatedUserName;
 updatedEmail;
  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private http: HttpClient, public authService: AuthService,
    public dialog: MatDialog , public errorDialog: MatDialog , public successDialog: MatDialog , fb: FormBuilder,
    public loader: LoadingBarService, public dialogForProfile: MatDialog, location: PlatformLocation) {
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.IsDoctorAvailableFunction());
    this.setToggleStatus = this.authService.getAvailabilityToggleStatus();
    this.disabledVar = true;
    this.passwordForm = fb.group({
      currentPassword: fb.control('', [Validators.required]),
      newPassword: fb.control('', [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')]),
        confirmPassword: fb.control('', [Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')]),
    }, {
    validator: this.matchEmail,
    });
    location.onPopState(() => {
      if (this.editProfileDiv === true) {
        window.history.forward();
        this.showProfileDiv = true;
        this.editProfileDiv = false;
        this.ShowResetPasswordDiv = false;
      }
      if (this.ShowResetPasswordDiv === true) {
        window.history.forward();
        this.showProfileDiv = true;
        this.editProfileDiv = false;
        this.ShowResetPasswordDiv = false;
      }
      });
  }

  ngOnInit() {
    this.getProfileDetails();
  }
  private matchEmail(AC: AbstractControl) {
    // tslint:disable-next-line:max-line-length
  return AC.get('newPassword').value === AC.get('confirmPassword').value ? null : AC.get('confirmPassword').setErrors( {mailmismatch: true} );
}
  viewEditprofileDiv() {
    this.editProfileDiv = true;
    this.showProfileDiv = false;
  }
  showProfileInfo() {
    this.editProfileDiv = false;
    this.showProfileDiv = true;
  }
  goBack() {
    if (this.editProfileDiv === true) {
        this.showProfileDiv = true;
        this.editProfileDiv = false;
        this.ShowResetPasswordDiv = false;
        return;
    }
    if (this.ShowResetPasswordDiv === true) {
      this.passwordForm.get('currentPassword').reset();
      this.passwordForm.get('newPassword').reset();
      this.passwordForm.get('confirmPassword').reset();
      this.showProfileDiv = true;
      this.editProfileDiv = false;
      this.ShowResetPasswordDiv = false;
      return;
    }
    if (this.showProfileDiv === true) {
      this.router.navigate(['waiting-room']);
      return;
    }
  }
  changePasswordDiv() {
    this.showProfileDiv = false;
    this.editProfileDiv = false;
    this.ShowResetPasswordDiv = true;
  }
  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.files = event.target.files;
      this.file = this.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event: any) => { // called once readAsDataURL is completed

          this.url = event.target.result;
        };
    }
  }
   /*====================== Availabilty Of Doctor ===================*/
   SetAvailibiltyOfDoctor(toggle) {
    console.log(toggle);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('userName', this.loggedInUser);
    this.http.post(this.authService.baseURL + '/GetProfileInfo' , param , {headers: headers}).subscribe(response => {
      if (response['message'] === 'success') {
        this.profilePicUrl = response['avatar'];
      } else {
        this.profilePicUrl = '';
      }
      if (toggle.checked) {
        var addRef = firebase.database().ref('doctorAvailability/' + this.loggedInUser);
        addRef.set({
         name : this.loggedInUser,
         status: 'idle',
         callId: '',
         profilePic: this.profilePicUrl
       });
     } else {
       var deleteRef = firebase.database().ref('doctorAvailability/');
       deleteRef.child(this.loggedInUser).remove();
     }
    });
  }

  /*================ get Availability of Doctor after loggedIn ============*/
  IsDoctorAvailableFunction() {
    firebase.database().ref('doctorAvailability/').once('value', snapshot => {
      if (snapshot.hasChild(this.loggedInUser)) {
        this.availabilityStatus = true;
      } else {
        this.availabilityStatus = false;
      }
      });
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('userName', this.loggedInUser);
    this.http.post(this.authService.baseURL + '/IsDoctorAvailable' , param , {headers: headers}).subscribe(response => {
        if (response['Message'] === 'Success') {
          if (response['isAvailable']) {
            this.availabilityStatus = true;
          } else {
            this.availabilityStatus = false;
          }
           firebase.database().ref('doctorAvailability/').on('value', snapshot => {
        if (snapshot.hasChild(this.loggedInUser)) {
          this.availabilityStatus = true;
        } else {
          this.availabilityStatus = false;
        }
        });
        }
    });
  }
  checkCurrentPassword(currentPassword) {
    if (currentPassword) {
    if (currentPassword.length > 0) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams()
    .set('userName', this.loggedInUser)
    .set('currentPassword', currentPassword);
    this.http.post(this.authService.baseURL + '/CheckCurrentPassword' , param , {headers: headers}).subscribe(response => {
      this.responseReceived = response['message'];
        if (response['status'] === true) {
          // this.authService.DoctorAvailableFunction(response['isAvailable']);
        } else {
          // alert('true');
          this.wrongPassword = false;
          // this.passwordFormControl.setErrors(Validators.required);
          this.passwordForm.get('currentPassword').setErrors(Validators.required);
        }
    });
  }
}
  }
  checkPassword(value) {
    alert('pattern' + value);
    this.showValidationMsg = value;
  }
  checkMatch(newPassword, confirmPassword) {
    alert('newPassword' + newPassword);
    alert('confirmPassword' + confirmPassword);
    if (newPassword === confirmPassword) {
    this.showMatchError = false;
    } else {
      this.showMatchError = true;
    }
  }

  resetPassword(newPassword, confirmPassword, currentPassword) {
    if (newPassword === confirmPassword) {
      if (currentPassword) {
        if (currentPassword.length > 0) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const param = new HttpParams()
        .set('userName', this.loggedInUser)
        .set('currentPassword', currentPassword);
        this.http.post(this.authService.baseURL + '/CheckCurrentPassword' , param , {headers: headers}).subscribe(response => {
          // this.responseReceived = response['message'];
            if (response['status'] === true) {
            this.loader.start();
            this.loader.set(10);
              const params = new HttpParams()
              .set('userName', this.loggedInUser)
              .set('password', newPassword);
              this.http.post(this.authService.baseURL + '/ChangePassword' , params , {headers: headers}).subscribe(res => {
                if (res['message'] === 'success') {
                  this.loader.complete();
                  this.opendialogForProfile('Password is changed successfully! Please login again.');
                } else {
                  this.loader.complete();
                  this.openErrorDialog('Some error occurred, please try again.');
                }
              }, err => {
                this.loader.complete();
                this.openErrorDialog('Some error occurred, please try again.');
              });
            } else {
              this.wrongPassword = false;
              this.passwordForm.get('currentPassword').setErrors(Validators.required);
            }
        });
      }
    }
      }
  }
  logout() {
    this._firebaseAuth.auth.signOut().then(res => {
      setTimeout(() => { this.router.navigate(['/']); } , 1000);
    });
  }
  getProfileDetails() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const param = new HttpParams().set('userName', this.loggedInUser);
      this.http.post(this.authService.baseURL + '/GetProfileInfo' , param , {headers: headers}).subscribe(response => {
        if (response['message'] === 'success') {
          this.userName = response['userName'];
          this.userEmail = response['Email'];
          this.contactNo = response['contactNo'];
          this.fullName = response['fullName'];
          this.profilePicUrl = response['avatar'];
          this.updatedFullName = this.fullName;
          localStorage.setItem("fullName",this.updatedFullName);
          this.updatedEmail = this.userEmail;
          this.updatedUserName = this.userName;
          if (this.profilePicUrl !== '') {
            // let reader = new FileReader();
            // reader.readAsDataURL(this.profilePicUrl);
            this.url = this.profilePicUrl;
          }
        }
    });
  }
  editProfile(fullName, userName, userEmail) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // const param = new HttpParams()
    // .set('fullName', '')
    // .set('userName', userName)
    // .set('userEmail', this.loggedInUser);
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append('userName', userName);
    formData.append('fullName', fullName);
    formData.append('userEmail', userEmail);
    if (this.url !== '') {
      formData.append('avatar', this.file);
      // this.url =  this.file;
    }
  //   this.http.post(this.authService.baseURL + '/EditProfile' , param , {headers: headers}).subscribe(response => {
  //    console.log(response);
  // });
  this.http.post(this.authService.baseURL + '/EditProfile', formData).subscribe( response => {
    console.log(response);
    if (response['Message'] === 'success')  {
      this.loader.complete();
      this.openSuccessDialog('Profile updated successfully');
        this.updatedFullName = fullName;
        localStorage.setItem("fullName",this.updatedFullName);
        this.updatedEmail = userEmail;
        this.profilePicUrl = this.url;
        // this.getProfileDetails();
        this.editProfileDiv = false;
        this.showProfileDiv = true;
  } else {
    if (response['Message'] === 'email already present') {
      this.loader.complete();
      this.openErrorDialog('Email address already exists.');
    } else {
      this.loader.complete();
      this.openErrorDialog('Problem while updating profile.');
    }
  }
}, err => {
  this.loader.complete();
  this.openErrorDialog('Problem while updating profile.');
  });
  }
  openErrorDialog(message) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : message
    });
  }

  openSuccessDialog(message) {
    const dialogRef = this.successDialog.open(SuccessDialogComponent , {
      height: '72px',
      data : message
    });
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
      height: '124px',
      data : message ,
      disableClose: true,
      hasBackdrop: true
    });
  }
}
