import { Injectable , ChangeDetectorRef} from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { MessagingService } from '../messaging.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

// Local variables
// public baseURL = 'https://sevaro-stroke.uc.r.appspot.com/';
public baseURL = 'https://telestroke-sevaro.uc.r.appspot.com/';
// public baseURL = 'https://sevaro-usa-backend.appspot.com/';
// public baseURL = 'https://stathealthmd-test.appspot.com/';
// public baseURL = 'https://stathealthmd.appspot.com/';
public headers;
public errorCode;
public errorMessage;
public userid;
public createPatientDataObj;
public fullName;
// Observables
// Login Message Display
public messageSource = new BehaviorSubject(false);
currentMessage = this.messageSource.asObservable();
// Call has ended on button
public valueStatus = new BehaviorSubject('');
currentvalueStatus = this.valueStatus.asObservable();
// Guard tells publisher to stop through me
public disconnect = new BehaviorSubject(false);
disconnectStatus = this.valueStatus.asObservable();
public logoutStatus = new BehaviorSubject(false);
logout = this.logoutStatus.asObservable();
// public sentMsgcallButtonHasEnded = new BehaviorSubject(false);
// recMsgcallButtonHasEnded = this.sentMsgcallButtonHasEnded.asObservable();
// Send Username
public valueObs = new BehaviorSubject('');
currentvalue = this.valueObs.asObservable();

public valueToggle = new BehaviorSubject(false);
currentvalueToggle = this.valueToggle.asObservable();
public valueToggleForProfile = new BehaviorSubject(false);
currentValueToggleForProfile = this.valueToggle.asObservable();
// observable for LoggedInUserId
public userUniqueIdSource = new BehaviorSubject('');
userUniqueId = this.userUniqueIdSource.asObservable();

// isDoctorAvail;
isDoctorAvail: Boolean = true;
public statusEndCall: Boolean = false;
isloading = false;
loggedUser;
public user;
constructor(private _firebaseAuth: AngularFireAuth, private http: HttpClient, private loader: LoadingBarService,
  private router: Router, private msgService: MessagingService, public errorDialog: MatDialog) {
}
// check login credentials
  login(username, password) {
    var addRef = firebase.database().ref('doctorAvailability/' + username);
            addRef.set({
             name : username,
             status: 'idle',
             callId: ''
           });
           this.isDoctorAvail = true;
          //  this.ref.detectChanges();
   this.loader.start();
   this.loader.set(10);
   const params = new HttpParams()
   .set('Type', 'TeleStrokeDoctor')
   .set('userName', username)
   .set('Password', password);
   this.headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
   // this.isloading = true; // start loader
   this.http.post(this.baseURL + '/LoginData' , params, {headers: this.headers}).subscribe(
    data => {
      console.log('this is me', data);
      if ( data['Message'] === 'Success') {
           this.userid = username;
           this.fullName= data['fullName'];
           localStorage.setItem("fullName",this.fullName);
           console.log("check the full name after login",this.fullName)
          this.userUniqueIdSource.next(data['UniqueId']);
          this.messageSource.next(true);
          // Update Device Token for Notification
          const deviceTokenParam = new HttpParams().set('userId', username).set('deviceToken', this.msgService.msgServiceToken);
          this.http.post(this.baseURL + '/UpdateDeviceToken', deviceTokenParam, { headers: this.headers}).subscribe(
            response => {
            console.log('this is messaging service data', response);
          });

          // On login success remove error
          this.loader.complete();
          this.messageSource.next(true);

          // this.isloading = false; // stop loader

          // on login successs get token from firebase and  navigate to next component
          firebase.auth().signInWithCustomToken(data['Token']).then((res) => {
            this.router.navigate(['/waiting-room']);
            this.messageSource.next(false);
          })
          .catch(function(error) {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorMessage);
              console.log(errorCode);
          });
      } else {
        // on login fail
        this.messageSource.next(false);
        // this.isloading = false;
        this.openErrorDialog();
        this.loader.complete();
      }
    },
    err => {
      this.openErrorDialog();
      this.loader.complete();
    });
  }
  getUserFromstatechange() {
    const promise = new Promise((resolve, reject) => {
      
        firebase.auth().onAuthStateChanged(function(user) {
          if(user){
          resolve(user.uid);
          }
        });
    });
    return promise;
  }
  // change availabilty of Doctor from Waiting Room
  DoctorAvailableFunction(getCurrentAvailbilty) {
    this.isDoctorAvail = getCurrentAvailbilty;
  }

  public setAvailabilityToggleStatus(value: any): void {
    this.valueToggle.next(value);
  }

  public getAvailabilityToggleStatus(): Observable<any> {
      return this.valueToggle;
  }
  public setAvailabilityToggleStatusFromProfile(value: any): void {
    this.valueToggleForProfile.next(value);
  }

  public getAvailabilityToggleStatusFromProfile(): Observable<any> {
      return this.valueToggleForProfile;
  }
  public setValue(value: any): void {
    this.valueObs.next(value);
  }

  public getValue(): Observable<any> {
      return this.valueObs;
  }

  currentUserComponent() {
    var user = firebase.auth().currentUser;
    console.log(user);
    // console.log("user",user);
    if (user != null) {
    return true;
    }
    else {
      return false;
    }
  }

  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Please Enter Valid Username and Password.'
    });
  }
  public setStatus(value: any): void {
    this.valueStatus.next(value);
  }
  public getStatus(): Observable<any> {
    return this.valueStatus;
  }

  public setDisconnectStatus(value: any): void {
    this.disconnect.next(value);
  }
  public getDisconnectStatus(): Observable<any> {
    return this.disconnect;
  }
  public setLogoutStatus(value: any): void {
    this.logoutStatus.next(value);
  }
  public getLogoutStatus(): Observable<any> {
    return this.logoutStatus;
  }
  /*public getValue(): Observable<any> {
      return this.valueObs;
  }

  currentUserComponent(){
    var user = firebase.auth().currentUser;
    console.log(user);
    //console.log("user",user);
    if (user != null) {
    return true;
    }
    else {
      return false;
    }
  }*/
  

}



