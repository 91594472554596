import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { Injectable,  } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { OpentokService } from '../opentok.service';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
 }
 @Injectable({
   providedIn: 'root',
 })
 export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  status;
  loggedInUser;
  receivedCallIdFromQuery;
  @Input() session: OT.Session;
  constructor(public authService: AuthService, private router: Router, private _firebaseAuth: AngularFireAuth) {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.receivedCallIdFromQuery =  receivedCallId[1];
  }
   canDeactivate(component: CanComponentDeactivate) {
    this.status = this.authService.getStatus();
    console.log('received', this.authService.getStatus());
    console.log(this.status._value);
    if (this.status._value === '') {
      var txt;
      var r = confirm('Are you sure you want to end call?');
      if (r === true) {
        return true;
      } else {
        return false;
      }
  } else {
    if (this.status._value === 'fromEndCallButton') {
      return true;
  } else {
    var txt;
    var r = confirm('Are you sure you want to end call?');
    if (r === true) {
      this.authService.setStatus('');
       this._firebaseAuth.auth.signOut().then(res => {
        // setTimeout(() => { this.router.navigate(['/']); } , 1000);
      });
      return true;
    } else {
      return false;
    }
  }
  }
}
}

