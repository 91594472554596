import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PlatformLocation } from '@angular/common';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import {MatDialog} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { Hemorrhage, HemorrhageType} from '../video/hemorrhage.model';
import { NonHemorrhage } from '../video/nonHemorrhage.model';
import { isNullOrUndefined } from 'util';

declare var $: any;

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.css']
})
export class WaitingRoomComponent implements OnInit {
  isHemorrhage :boolean;
  greyIconforWaitingcall = true;
  greyIconforMissedcall = true;
  greyIconforActivecall = true;
  // expanded = false;  // read more read less div

  xpandStatus = false;
  // waiting
  waitingcallmaindiv = true;
  waitingcalldetailDiv = false;

  // missed
  missedcallmaindiv = true;
  missedcalldetailDiv = false;
  endedByNurseTimeForMissedCall = false;

  // Active
  activecallmaindiv = true;
  activecalldetailDiv = false;

  // Incomplete
  incompleteMainDiv = true;
  incompleteDetailDiv = false;
  incompleteDetailEditDiv = false;

  // TPA div
  showIncompeteEditTPAdiv = false;
  showIncompeteEditNotTPAdiv = false;

  // Embolectomy div
  editDontEmbolectomyDiv = false;

  // Complete
  completeNoteMainDiv = true;
  completeNoteDetailDiv = false;
  completeDetailEditDiv = false;

  //Hemorrhage or NonHemrrhage
  hemorrhageSection:boolean;
  nonHemorrhageSection:boolean;

  // sidepanelbar
  showSidePanel = true;

  // do not give TPA options
  reasonnotTPA = new FormControl();
  // tslint:disable-next-line:max-line-length
  reasonList: string[] = ['Not a stroke', 'Complex migraine', 'History of ICH', 'Mild symptoms', 'Seizure', 'BP requires initial management',
  'Initial refusal of treatment', 'Out of window for t-PA', 'Unable to determine eligibility'];

  reasonnotEmbolectomy = new FormControl();
  reasonEmbolectomyList: string[] = ['No LVO', 'Mild symptoms', 'Not a Stroke'];
  medicationsForAntiplatelet: Boolean = false;
  medicationsForAnticoagulation: Boolean = false;
  meansOfArrivalCheck: Boolean = false;
  callerListDiv = true;
  patientDetailDiv = false;
  WaitingCall = [];
  waitingFor;
  timeToDisplay;
  retrievedData;
  patientId;
  receivedUserID;
  receivedCallID;
  public loggedInUser;
  doctorId;
  receivedDate;
  receivedMonth;
  receivedYear;
  tpaEligibiltyCheck:any;
  loggedUser:any;
  date;
  month;
  year;

//check whether the additonal workup
    isCTHChecked :boolean;
    isMRIChecked :boolean;
    isCTAChecked :boolean;
    isEEGChecked :boolean;
    isMRAChecked :boolean;
    islpChecked :boolean;
  patientSettings = {
    isPatientOnAntiplateletTherapy: '', isPatientOnAnticoagulationTherapy:'', patientAlcoholConsumption:'', cigaretteCheck:false,
    cigarettesPerDay:'', cigarettesPerDayQuantity:'', familyContactNumber:'', meansOfArrivalOptions:'',
    meansOfArrivalCustom:'', meansOfArrivalOptionsApi:'', meansOfArrivalCustomApi:'', preArrivalNotification:'',
    nurse: '', physician: '', historian: '', hospitalCampus: '',
    arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
    tpaEligibilityUnknown: false, familyAtBedside: '',
    bloodPressure: '', weight: '', oxygenSat: '',
    heartRate: '', temp: '', rr: '', bloodGlucose: '', weightUnit: '', tempUnit: '' ,
    miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
    isIntracranial: '',
    ReasonForConsult: '', /*symptoms: '', pastMedicalHistory: '', pastSurgicalHistory: '', medications: '',
    allergies: '', socialHistory: '', familyHistory: ''*/
    emergencyCall: false, callId: ''
  };
  ambulance_section: Boolean = false;
  meansOfArrivalObj:any;
  cigarette_section: Boolean = false;
  cigarette_section_Api: Boolean = false;
  reasonForConult: any = [];
  symptoms: any =  [];
  pastMedicalHistory: any = [];
  antiplateletMedicationsMenu: Boolean = false;
  anticoagulationMedicationsMenu: Boolean = false;
  medicationsForAntiplateletTherapy: any = [];
  medicationsForAnticoagulationTherapy: any = [];
  pastSurgicalHistory: any = [];
  medications: any = [];
  allergies: any = [];
  socialHistory: any = [];
  familyHistory: any = [];
  tobaccoArray: any = [];
  drugsHistory: any = [];

  // incomplete call objects for consult notes VIEW mode
  public consultNotes = {chiefComplaint: '', historyOfIllness: '', reviewOfSystems: '', vitalSigns: '', giveTPA: '',
  considerOrDoNotConsiderEmbolectomy : '', thermobectomyRecommendation: {},
  notes: '', doNotgiveTPA: '', physicalExam: '', imaging: '', assessment: '', userId: '', doctorId: '', patient: ''
  , speciality_id: '', isComplete: '', requested_call: '', timestamp: '', downloadUrl : ''};

// incomplete call objects for Retrievd consult notes
  public retrievedconsultNotes = {chiefComplaint: '', historyOfIllness: '', reviewOfSystems: '', vitalSigns: '', giveTPA: '',
  notes: '', doNotgiveTPA: '', physicalExam: '', imaging: '', assessment: '',
  considerOrDoNotConsiderEmbolectomy : '', thermobectomyRecommendation: {},
   userId: '', doctorId: '', patient: ''
  , speciality_id: '', isComplete: '', requested_call: '', timestamp: '', downloadUrl : ''};

// incomplete call objects for NIHSS VIEW mode
  public nihss = {
    levelOfConsiousness: '', responseToLocQue: '', responseToLocComm: '' , bestGaze: '', visual: '',
    facialPalsy: '', motorLeftArm: '', motorRightArm: '', motorLeftLeg: '', motorRightLeg : '',
    limbAtaxia: '', sensory: '', bestLanguage: '', dysarthria: '', extinctionAndInattention: '', totalScore: 0
  };

// incomplete call objects for Time-Points VIEW mode
  timePoints = {
    timeForConsultRequest: '', timePatientAtCtScan: '', timePatientReadyForVideo: '', timeProviderOnVideo: '', timeOfCtRead: '',
    timeOfCta: '', timeOfCtaRead: '', timeOfDecision: '', timeOfArrivalToEd: '', timeOfLastKnownNormal: ''
  };

 //For hemorrhage and NonHemorrhage
 public aspectSubganglionicNuclei = {
  frontalOperculumM1: "",
  AnteriorTemporalLobeM2: "",
  frontalOperculumM1Posterior: ""
}
public aspectSupraganglionicNuclei = {
  anteriorMCAM4: "",
  lateralMCAM5: "",
  frontalMCAM6: ""
}
public aspectBasalGanglia = {
  caudate: "",
  lentiformNucleus: "",
  insula: "",
  internalCapsule: ""
}
 ishemarrhageData:any;
 public hemorrhage = new Hemorrhage();
 public nonHemorrhage = new NonHemorrhage();
 hemorrhageTypeArr = [];
  aspectScoreDoesNotApplyArr = [];
  aspectSubganglionicArr = [];
  aspectSupraganglionicArr = [];
  aspectBasalGangliaArr = [];
  nonHemorrhageSelectTypeArr = [];
  nonHemorrhageSelectSideArr = [];
  addWorkup = {
    cth: false, cthValue: '', mriBrain: false, mriBrainValue: '', ctaHeadAndNeck: false, ctaHeadAndNeckValue: '',
    eeg: false, eegValue: '',
    mraHeadAndNeck: false, mraHeadAndNeckValue: '', lp: false, lpValue: ''
  };
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
  ];

  postLetters = {1: 'st', 2: 'nd', 3: 'rd', 4: 'th', 5: 'th', 6: 'th', 7: 'th', 8: 'th', 9: 'th', 10: 'th', 11: 'th',
  12: 'th', 13: 'th', 14: 'th', 15: 'th', 16: 'th', 17: 'th', 18: 'th', 19: 'th', 20: 'th', 21: 'st', 22: 'nd',
  23: 'rd', 24: 'th', 25: 'th', 26: 'th', 27: 'th', 28: 'th', 29: 'th', 30: 'th', 31: 'st'};

  giveTpa = {
    totalDose: '', bolusDose: '', infusionDose: '', timeToGiveTpa: '', agree: false
  };
  selectedReasonList: any = [];
  doNotGiveTpaObj = {
    reasonForNotGivingTpa: this.selectedReasonList
  };
  ctInterpretation = [];
  ctaInterpretation = [];
  considerOrDoNotConsiderEmbolectomy;
  iagreetnc: Boolean = false;
  showTPAdiv = false; // if selected Show TPA
  showDontTPAdiv = false; // if selected Dont show TPA
  showDontEmbolectomyDiv = false; // if selected Dont show embolectomy
  emboViewStatus;
  giveOrDoNotGiveTpa;
  selectedReasonEmbolectomy=[];
  showErrorForCheckbox: Boolean = false;
  embolectomyObjectForDoNotGiveTpa = {
    doNotGiveTpaSelected: this.selectedReasonEmbolectomy
  };

  todayDate = new Date();
  offset =(new Date()).getTimezoneOffset();
  iagreeDate;
  iagreeMonth;
  iagreeYear;
  iagreePostLetter;
  consultNoteDate;
  consultNoteDay;
  consultNoteMonth;
  consultNoteYear;
  consultNotePostLetter;
  patientInfo = {name : '' , dob : '' , gender : '' , mrn: '' , age : 0 };

  currentCallDetail = {
    nurse: '',
    mrn: '',
    time: '',
    callId: ''
  };

    /* Hemorrhage or NonHemorrhage */
    defaultDate: Date = new Date();
    public ctInterpretationTime = ""; 
    public ctInterpretationDate = this.defaultDate;
    public userSelectedHemorrhage: boolean = false;
    selectSideValue:any;
    hemorrhageOrNonHemorrhage:any;
    /* Hemorrhage or NonHemorrhage */ 
  
  waitingCallsList = [];
  activeCallsList = [];
  missedCallsList = [];
  incompleteCallsList = [];
  completeCallsList = [];
  isloading = false; // display loader bydefault false
  consultNoteType;
  newItemsWaitingCalls;
  newItemsMissedCalls;
  newItemsActiveCalls;
  cthValuecontrol = new FormControl('', [
  ]);
  mriBrainValuecontrol = new FormControl('', [
  ]);
  ctaHeadAndNeckValuecontrol = new FormControl('', [
  ]);
  eegValuecontrol = new FormControl('', [
  ]);
  mraHeadAndNeckValuecontrol = new FormControl('', [
  ]);
  lpValuecontrol = new FormControl('', [
  ]);
  cthcontrol = new FormControl('', [
  ]);
  cthHemorrhageControl = new FormControl('',[
  ]);
  cthHemorrhageValue = new FormControl('',[
  ]);
  mriBraincontrol = new FormControl('', [
  ]);
  ctaHeadAndNeckcontrol = new FormControl('', [
  ]);
  eegcontrol = new FormControl('', [
  ]);
  mraHeadAndNeckcontrol = new FormControl('', [
  ]);
  lpcontrol = new FormControl('', [
  ]);
  giveTpaDoNotGiveTpaFormControl = new FormControl('', [
  ]);
  considerOrDoNotConsiderEmbolectomyFormControl = new FormControl('', [
  ]);
  hemorrhageorNonHemorrhageFormControl =new FormControl('',[
  ]);
  iagreetncControl = new FormControl('', [
  ]);
  chiefComplaintControl = new FormControl('', [
  ]);
  historyOfIllnessControl = new FormControl('', [
  ]);
  ctInterpretationControl = new FormControl('', [
  ]);
  ctaInterpretationControl = new FormControl('', [
  ]);
  ctaInterpretationValue=new FormControl('',[
  ]);
  ctInterpretationValue=new FormControl('',[
  ]);
  reviewOfSystemsControl = new FormControl('', [
  ]);
  physicalExamControl = new FormControl('', [
  ]);
  asessmentControl = new FormControl('', [
  ]);
  ctaInterpretationhemorrhageControl = new FormControl('',[
  ]);
  levelOfConsciousControl = new FormControl('', [
  ]);
  responseToLocQueControl = new FormControl('', [
  ]);
  responseToLocCommControl = new FormControl('', [
  ]);
  bestGazeControl = new FormControl('', [
  ]);
  visualControl = new FormControl('', [
  ]);
  facialPalsyControl = new FormControl('', [
  ]);
  motorLeftArmControl = new FormControl('', [
  ]);
  motorRightArmControl = new FormControl('', [
  ]);
  motorLeftLegControl = new FormControl('', [
  ]);
  motorRightLegControl = new FormControl('', [
  ]);
  limbAtaxiaControl = new FormControl('', [
  ]);
  sensoryControl = new FormControl('', [
  ]);
  bestLanguageControl = new FormControl('', [
  ]);
  dysarthriaControl = new FormControl('', [
  ]);
  extinctionControl = new FormControl('', [
  ]);
  totalDoseControl = new FormControl('', [
  ]);
  bolusDoseControl = new FormControl('', [
  ]);
  infusionDoseControl = new FormControl('', [
  ]);
  timeToGiveTpaControl = new FormControl('', [
  ]);
  agreeControl = new FormControl('', [
  ]);
  timeOfArrivalToEdControl = new FormControl('', [
  ]);
  timeOfLastKnownNormalControl = new FormControl('', [
  ]);
  timeForConsultRequestControl = new FormControl('', [
  ]);
  timePatientAtCtScanControl = new FormControl('', [
  ]);
  timePatientReadyForVideoControl = new FormControl('', [
  ]);
  timeProviderOnVideoControl = new FormControl('', [
  ]);
  timeOfCtReadControl = new FormControl('', [
  ]);
  timeOfCtaControl = new FormControl('', [
  ]);
  timeOfCtaReadControl = new FormControl('', [
  ]);
  timeOfDecisionControl = new FormControl('', [
  ]);
  timeOfTpaBolusControl = new FormControl('', [
  ]);
   /* Pradeep Changes*/
   ctInterpretationTimeControl = new FormControl("", [Validators.required]);
   ctInterpretationTimeControl1 = new FormControl("", [Validators.required]);
   ctInterpretationDateFormControl = new FormControl("", [Validators.required]);
   /* Pradeep Changes*/


  constructor(private http: HttpClient, public authService: AuthService, public loader: LoadingBarService,
    location: PlatformLocation, private ref: ChangeDetectorRef, public dialogForProfile: MatDialog,
    public errorDialog: MatDialog , public successDialog: MatDialog )
    {location.onPopState(() => {
      if (this.patientDetailDiv === true) {
          window.history.forward();
          this.callerListDiv = true;
          this.patientDetailDiv = false;
      }
    });
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.IsDoctorAvailableFunction());
    // firebase
    //   .database()
    //   .ref("WaitingCalls/stroke/" + this.currentCallDetail.callId + "/status")
    //   .on("value", (Snapshot) => {
    
          
    //       this.waitingStatus = true; 
    //       this.ref.detectChanges();
    //       console.log("status check",this.waitingStatus)   
         
    //   });

    firebase.database().ref('doctorAvailability/').on('value', snapshot => {
      if (snapshot.hasChild(this.loggedInUser)) {
        // this.availabilityStatus = true;
        this.authService.isDoctorAvail = true;
        this.ref.detectChanges();

      } else {
        // this.availabilityStatus = false;
        this.authService.isDoctorAvail = false;
        this.ref.detectChanges();
      }
    });


    // Waiting Calls
    firebase.database().ref('waitingCalls/stroke').on('value', snapshot => {
      this.waitingCallsList = [];
      if (snapshot.val() !== null) {
          var respObj = snapshot.val();
          var respArr = Object.keys(respObj);
          for(var i = 0; i < respArr.length; i++) {
            respObj[respArr[i]]['callId'] = respArr[i];
            //  timer
            var startTime = respObj[respArr[i]]['timestamp'];
            var diff = Date.now() - parseInt(startTime);
            respObj[respArr[i]]['dateStr'] = this.toHHMMSS_Shorthand(diff);
            this.startTimerForCall(respArr[i], startTime);

            this.waitingCallsList.push(respObj[respArr[i]]);

          }
          
          this.waitingCallsList = this.sortByKey(this.waitingCallsList, 'timestamp');
           this.ref.detectChanges();
        }
    });

    // Active Calls
    firebase.database().ref('activeCalls/stroke').on('value', snapshot => {
      this.activeCallsList = [];
      if (snapshot.val() !== null) {
        var respObj = snapshot.val();
        var respArr = Object.keys(respObj);
        for(var i = 0; i < respArr.length; i++) {
          
          respObj[respArr[i]]['callId'] = respArr[i];
          //  timer
          // var startTime = respObj[respArr[i]]['timestamp'];
          // var diff = Date.now() - parseInt(startTime);
          // respObj[respArr[i]]['dateStr'] = this.toHHMMSS_Shorthand(diff);
          // this.startTimerForCall(respArr[i], startTime);

          if(respObj[respArr[i]]['timestamp'] && respObj[respArr[i]]['timestamp']!==undefined && respObj[respArr[i]]['timestamp']!==0){
          this.activeCallsList.push(respObj[respArr[i]]);
          }

        }
        
        this.activeCallsList = this.sortByKey(this.activeCallsList, 'timestamp');
         this.ref.detectChanges();
      }

      // if (snapshot.val() !== null) {
      //   var respObj = snapshot.val();
      //   var respArr = Object.keys(respObj);
      //   for(var i = 0; i < respArr.length; i++) {
      //     respObj[respArr[i]]['callId'] = respArr[i];
      //     this.activeCallsList.push(respObj[respArr[i]]);
      //   }
      //   this.activeCallsList = this.sortByKey(this.activeCallsList, 'timestamp');
      //    this.ref.detectChanges();
      // }
    });

    // Missed Calls
    firebase.database().ref('missedCalls/stroke').on('value', snapshot => {
      this.missedCallsList = [];
      if (snapshot.val() !== null) {
        var respObj = snapshot.val();
        var respArr = Object.keys(respObj);
        for(var i = 0; i < respArr.length; i++) {
          respObj[respArr[i]]['callId'] = respArr[i];
            var startTime = respObj[respArr[i]]['timestamp'];
           if(startTime!==0 && startTime!==null && startTime!==undefined){
            var diff = Date.now() - parseInt(startTime);
            respObj[respArr[i]]['dateStrMissedCall'] = this.toHHMMSS_Shorthand(diff);
            this.startTimerForMissedCall(respArr[i], startTime);
          this.missedCallsList.push(respObj[respArr[i]]);

          this.missedCallsList = this.sortByKeyDate (this.missedCallsList,'timestamp' );
          this.ref.detectChanges();
           }
        }
        // this.missedCallsList = this.sortByKey(this.missedCallsList, 'timestamp');
        //  this.ref.detectChanges();
      }
    });

    // Change Bell Icon Color for waitingCalls
    firebase.database().ref('waitingCalls/stroke').on('child_added', snapshot => {
      console.log('child added' , snapshot.key);
      if (!this.newItemsWaitingCalls) { return; }
      this.greyIconforWaitingcall = false;
      this.ref.detectChanges();
    });

    // Change Bell Icon Color for waitingCalls
    firebase.database().ref('missedCalls/stroke').on('child_added', snapshot => {
      console.log('child added' , snapshot.key);
      if (!this.newItemsMissedCalls) { return; }
      this.greyIconforMissedcall = false;
      this.ref.detectChanges();
    });

     // Change Bell Icon Color for waitingCalls
     firebase.database().ref('activeCalls/stroke').on('child_added', snapshot => {
      console.log('child added' , snapshot.key);
      if (!this.newItemsActiveCalls) { return; }
      this.greyIconforActivecall = false;
      this.ref.detectChanges();
    });

    firebase.database().ref('waitingCalls').once('value', () => {
      this.newItemsWaitingCalls = true;
    });

    firebase.database().ref('missedCalls').once('value', () => {
      this.newItemsMissedCalls = true;
    });

    firebase.database().ref('activeCalls').once('value', () => {
      this.newItemsActiveCalls = true;
    });

  }


  ngOnInit() {
    this.loggedUser=localStorage.getItem('fullName');
    this.iagreeDate = this.todayDate.getDate();
    // console.log('iagreeDate', this.iagreeDate);
    this.iagreePostLetter = this.postLetters[this.iagreeDate];
    this.iagreeMonth =  this.monthNames[this.todayDate.getMonth()];
    this.iagreeYear = this.todayDate.getFullYear();
    this.fetchIncompleteCalls(); // Fetch incomplete calls
    this.fetchCompleteCalls(); // Fetch lists of completed calls
  }
  // Function to show grey icon
  activateTab(elem) {
    if (elem === 'Wait') {
      this.greyIconforWaitingcall = true; // Show grey icon for waiting call
      // this.greyIconforMissedcall = false;
      // this.greyIconforActivecall = false;
    } else if (elem === 'Miss') {
      // this.greyIconforWaitingcall = false;
      this.greyIconforMissedcall = true; // Show grey icon for missed call
      // this.greyIconforActivecall = false;
    } else if (elem === 'Active') {
      // this.greyIconforWaitingcall = false;
      // this.greyIconforMissedcall = false;
      this.greyIconforActivecall = true; // Show grey icon for Active call
    } else if (elem === 'incomplete') {
      // this.greyIconforWaitingcall = false;
      // this.greyIconforMissedcall = false;
      // this.greyIconforActivecall = false;
      this.fetchIncompleteCalls();
    } else if (elem === 'complete') {
      // this.greyIconforWaitingcall = false;
      // this.greyIconforMissedcall = false;
      // this.greyIconforActivecall = false;
      this.fetchCompleteCalls();
    }
  }

  sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }

  yourOwnfunction(time, i) {
    const a = new Date(time).toString();
    const dat = new Date(a);
    const a1 = dat.getFullYear();
    const a2 = dat.getMonth() + 1;
    const a3 = dat.getDate();
    const a4 = dat.getHours();
    const a5 = dat.getUTCMinutes();
    const a6 = dat.getSeconds();
    const dateStr = a1 + '-' + a2 + '-' + a3 + ' ' + a4 + ':' + a5 + ':' + a6;

    // const dateStr = a1 + '-' + a2 + '-' + a3 + ' ' + a4 + ':' + a5;
    // const dateStr = a1 + '-' + a2 + '-' + a3 + ' ' + a4 ;
    // const timeDiff = new Date().getTime() - new Date(time).getTime();
    // if (timeDiff > 300000) {
    //    this.waitingCallsList.splice(i, 1);
    // }

    // setInterval(() => {
    //   this.ref.detectChanges();
    // }, 100);

    return dateStr;

  }

  startTimerForCall(callId, startTime) {
    var timerRef = setInterval(() => {
      var diff = Date.now() - startTime;
      var dateStr = this.toHHMMSS_Shorthand(diff);
      // console.log("Timer for call => "+callId+" is "+dateStr);
      this.updateTimerForCall(callId, dateStr);
    }, 60000);
  }

  startTimerForMissedCall(callId, startTime) {
    var timerRef = setInterval(() => {
      var diff = Date.now() - startTime;
      var dateStrMissedCall = this.toHHMMSS_Shorthand(diff);
      // console.log("Timer for call => "+callId+" is "+dateStr);
      this.updateTimerForMissedCall(callId, dateStrMissedCall);
    }, 60000);
  }

  updateTimerForCall(callId, dateStr) {
    for ( var i = 0; i < this.waitingCallsList.length; i ++) {
      if (this.waitingCallsList[i]['callId'] === callId) {
        this.waitingCallsList[i]['dateStr'] = dateStr;
      }
    }
    this.ref.detectChanges();
  }

  updateTimerForMissedCall(callId, dateStrMissedCall) {
    for ( var i = 0; i < this.missedCallsList.length; i ++) {
      if (this.missedCallsList[i]['callId'] === callId) {
        this.missedCallsList[i]['dateStrMissedCall'] = dateStrMissedCall;
      }
    }
    this.ref.detectChanges();
  }

  // getTimeDiff(startTime) {
  //   var diff = Date.now() - parseInt(startTime);
  //   return this.toHHMMSS_Shorthand(diff);
  // }

  toHHMMSS_Shorthand (millis) {
    var sec_num = (millis / 1000); // don't forget the second param
    var days    = Math.floor(sec_num / 86400);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = Math.floor(sec_num - (hours * 3600) - (minutes * 60));

    if (days > 0) {
      hours = hours - (24 * days);    // Fix 02/04/2019
        // return days + ' days ' + hours + ' hr ' + minutes + ' min ' + seconds + ' s';
        return days + ' days ' + '' + hours + ' hr ' + '' + minutes + ' min ';
    } else if (hours > 0) {
      // return hours + ' hr ' + minutes + ' min ' + seconds + ' s';
      return hours + ' hr ' + '' + minutes + ' min ';
    } else if (minutes > 0) {
      // return minutes + ' min ' + seconds + ' s';
      return '0 hr' + ' '  + minutes + ' min ';
    } else if (seconds > 0) {
      // return seconds + ' s';
      return  ' 0 hr ' + ' ' + ' 0 min ';
    } else if (seconds === 0) {
      // return this + ' ms (not enough for seconds!)';
      return  ' 0 hr ' + ' ' + ' 0 min ';
    } else {
      // return days + ' days ' + hours + ' hr ' + minutes + ' min ' + seconds + ' s';
      return days + ' days ' + '' + hours + ' hr ' + ''  + minutes + ' min ';
    }
  }

  /*===================== Cancel Call =======================*/
  CancelCallFun(callId) {
    // this.loader.start();
    // this.loader.set(10);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('callId', callId)
    .set('doctorId', this.loggedInUser);
    this.http.post(this.authService.baseURL + '/CancelCall', param, {headers: headers}).subscribe(response => {
      if (response['message'] === 'success' || response['message'] == 'successfully removed from firebase') {
        // this.isloading = false; // Stop loader
        if (this.waitingcalldetailDiv) {
          this.waitingcalldetailDiv = false;
          this.waitingcallmaindiv = true;
        }
        if (this.missedcalldetailDiv) {
          this.missedcalldetailDiv = false;
          this.missedcallmaindiv = true;
        }
        this.ref.detectChanges();
        this.loader.complete();
      }
    });
  }

  /*====================== Availabilty Of Doctor ===================*/
  SetAvailibiltyOfDoctor(toggle) {
    console.log(toggle.checked);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('userName', this.loggedInUser)
    .set('availability', toggle.checked);
    this.http.post(this.authService.baseURL + '/DoctorAvailable', param, {headers: headers}).subscribe(response => {
      if (response['message'] === 'success') {
        this.authService.DoctorAvailableFunction(toggle.checked);
      }
    });
  }

  /*================ get Availability of Doctor after loggedIn ============*/
  IsDoctorAvailableFunction() {
    console.log('userName' ,  this.loggedInUser);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('userName', this.loggedInUser);
    this.http.post(this.authService.baseURL + '/IsDoctorAvailable' , param , {headers: headers}).subscribe(response => {
      if (response['Message'] === 'Success') {
        
        if(this.authService.isDoctorAvail=== true){
          response['isAvailable']=true;
        }
        else{
          response['isAvailable']=false;
        }   
          this.authService.DoctorAvailableFunction(response['isAvailable']);
      }
  });
}

  /*================ get Patient Info ============*/
  getPatientInfo(mrn) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('mrn', mrn);
    this.http.post(this.authService.baseURL + '/GetPatientInfo' , param , {headers: headers}).subscribe(response => {
        if (response['message'] === 'success') {
        }
    });
  }

  /*================= connect missed call ==============*/
  connectMissedCall() {
      let dbRef = firebase.database().ref('missedCalls/stroke/' + this.currentCallDetail.callId + '/endedByNursetime');
      dbRef.once('value', snapshot => {
          if (snapshot.val() === null || snapshot.val() === 0) {
            this.AcceptNotification(this.currentCallDetail.callId);
          } else {
            /*====== Add callback functionality here ========*/
            this.startVideo();
          }
      });
  }

  /*============== Start Video =======================*/
  startVideo() {
    // window.open('https://stathealthmddoctor-test.appspot.com/video?callId=' + this.currentCallDetail.callId + '&initiated=true');
    if (this.missedcalldetailDiv) {
      this.missedcalldetailDiv = false;
      this.missedcallmaindiv = true;
    }
    window.open('/video?callId=' + this.currentCallDetail.callId + '&initiated=true');
  }


  /*============= Accept Call ========================*/
  AcceptNotification(callId) {
    // use call Id -  this.notificationObj.callId
    const videoCallActionsRef: firebase.database.Reference =
    firebase.database().ref('/videoCallActions/stroke/' + callId + '/actions/');
    videoCallActionsRef.push({
      action : 'accepted',
      info: 'Call is accepted by Doctor',
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: this.loggedUser
    });
    const videoCallActionStatusRef: firebase.database.Reference =
     firebase.database().ref('/videoCallActions/stroke/' + callId);
     videoCallActionStatusRef.update({
       status: 'accepted'
    });
    // this.items.splice(i, 1);
    // this.showNotificationAlert = false;
    this.ref.detectChanges();
    if (this.waitingcalldetailDiv) {
      this.waitingcalldetailDiv = false;
      this.waitingcallmaindiv = true;
    }
    if (this.missedcalldetailDiv) {
      this.missedcalldetailDiv = false;
      this.missedcallmaindiv = true;
    }
    window.open('/video?callId=' + callId + '&initiated=' + false);
 }


  CalculateAge(dob) {
    if (dob !== '' && dob !== undefined) {
      var receivedDob = dob.split('/');
      var month = receivedDob[0];
      var day = receivedDob[1];
      var year = receivedDob[2];
      var today = new Date();
      var birthday = new Date(year, month - 1, day);
      var differenceInMilisecond = today.valueOf() - birthday.valueOf();
      var year_age = Math.floor(differenceInMilisecond / 31536000000);
      var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
      return year_age;
    }
  }


  /* new ui functionality */

  /*========== show Waiting List Detail Div ==========*/
  collapseWaitingcallDiv(callId, name, mrn, dob, gender, requestedAt, nurse) {
    this.waitingcallmaindiv = false;
    this.waitingcalldetailDiv = true;
    this.patientInfo = {name : '' , dob : '' , gender : '' , mrn: '' , age : 0 };
    this.currentCallDetail = { nurse: '', mrn: '', time: '', callId: ''};
    this.patientSettings = {
      isPatientOnAntiplateletTherapy: '', isPatientOnAnticoagulationTherapy:'', patientAlcoholConsumption:'',cigaretteCheck:false,
      cigarettesPerDay:'', cigarettesPerDayQuantity:'', familyContactNumber:'', meansOfArrivalOptions:'',
      meansOfArrivalCustom:'', meansOfArrivalOptionsApi:'', meansOfArrivalCustomApi:'', preArrivalNotification:'',
      nurse: '', physician: '', historian: '', hospitalCampus: '',
      arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
      tpaEligibilityUnknown: false, familyAtBedside: '',
      bloodPressure: '', weight: '', oxygenSat: '',
      heartRate: '', temp: '', rr: '', bloodGlucose: '', weightUnit: '', tempUnit: '' ,
      miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
      isIntracranial: '', ReasonForConsult: '',  emergencyCall: false, callId: ''
    };
    this.reasonForConult = [];
    this.symptoms  =  [];
    this.pastMedicalHistory  = [];
    this.medicationsForAntiplateletTherapy = [];
    this.medicationsForAnticoagulationTherapy = [];

    this.pastSurgicalHistory = [];
    this.medications = [];
    this.allergies  = [];
    this.socialHistory = [];
    this.familyHistory = [];
    this.tobaccoArray = [];
    this.drugsHistory = [];
    this.patientSettings.callId =  callId;

    this.patientInfo.name = name;
    this.patientInfo.dob = dob;
    this.patientInfo.gender = gender;
    this.patientInfo.mrn = mrn;
    this.currentCallDetail.nurse = nurse;
    this.currentCallDetail.time = requestedAt;
    this.currentCallDetail.callId = callId;
    // this.activateListner();
    this.ref.detectChanges();
    this.patientDataRetrieve(callId);
  }

  /*============  show Waiting List Main Div ==========*/
  revertMainWaitingDiv() {
    this.waitingcalldetailDiv = false;
    this.waitingcallmaindiv = true;
    this.ref.detectChanges();
  }


  /*========== show Missed Call Detail Div ==========*/
  collapseMissedCallDiv(callId, name, mrn, dob, gender, requestedAt, nurse, endedByNurseTime) {
    this.missedcallmaindiv = false;
    this.missedcalldetailDiv = true;
    this.patientInfo = {name : '' , dob : '' , gender : '' , mrn: '' , age : 0 };
    this.currentCallDetail = { nurse: '', mrn: '', time: '', callId: ''};
    this.patientSettings = {
      isPatientOnAntiplateletTherapy: '', isPatientOnAnticoagulationTherapy:'', patientAlcoholConsumption:'',cigaretteCheck:false,
      cigarettesPerDay:'', cigarettesPerDayQuantity:'', familyContactNumber:'', meansOfArrivalOptions:'',
      meansOfArrivalCustom:'', meansOfArrivalOptionsApi:'', meansOfArrivalCustomApi:'', preArrivalNotification:'',
      nurse: '', physician: '', historian: '', hospitalCampus: '',
      arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
      tpaEligibilityUnknown: false, familyAtBedside: '',
      bloodPressure: '', weight: '', oxygenSat: '',
      heartRate: '', temp: '', rr: '', bloodGlucose: '', weightUnit: '', tempUnit: '' ,
      miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
      isIntracranial: '', ReasonForConsult: '',  emergencyCall: false, callId: ''
    };
    this.reasonForConult = [];
    this.symptoms  =  [];
    this.pastMedicalHistory  = [];
    this.medicationsForAntiplateletTherapy = [];
    this.medicationsForAnticoagulationTherapy = [];
    this.pastSurgicalHistory = [];
    this.medications = [];

    this.allergies  = [];
    this.socialHistory = [];
    this.familyHistory = [];
    this.tobaccoArray = [];
    this.drugsHistory = [];
    this.patientSettings.callId =  callId;
    this.patientInfo.name = name;
    this.patientInfo.dob = dob;
    this.patientInfo.gender = gender;
    this.patientInfo.mrn = mrn;
    this.currentCallDetail.nurse = nurse;
    this.currentCallDetail.time = requestedAt;
    this.currentCallDetail.callId = callId;
    this.endedByNurseTimeForMissedCall = endedByNurseTime;
    //this.activateListner();
    this.ref.detectChanges();
    this.patientDataRetrieve(callId);
  }

  /*============  show Missed Call List Main Div ==========*/
  revertMainMissedDiv() {
    this.missedcalldetailDiv = false;
    this.missedcallmaindiv = true;
    this.ref.detectChanges();
  }


  /*========== show Active Call Detail Div ==========*/
  collapseActiveCallDiv(callId, name, mrn, dob, gender, requestedAt, nurse) {
    this.activecallmaindiv = false;
    this.activecalldetailDiv = true;
    this.patientSettings = {
      isPatientOnAntiplateletTherapy: '', isPatientOnAnticoagulationTherapy:'', patientAlcoholConsumption:'',cigaretteCheck:false,
      cigarettesPerDay:'', cigarettesPerDayQuantity:'', familyContactNumber:'', meansOfArrivalOptions:'',
      meansOfArrivalCustom:'', meansOfArrivalOptionsApi:'', meansOfArrivalCustomApi:'', preArrivalNotification:'',
      nurse: '', physician: '', historian: '', hospitalCampus: '',
      arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
      tpaEligibilityUnknown: false, familyAtBedside: '',
      bloodPressure: '', weight: '', oxygenSat: '',
      heartRate: '', temp: '', rr: '', bloodGlucose: '', weightUnit: '', tempUnit: '' ,
      miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
      isIntracranial: '',
      ReasonForConsult: '', /*symptoms: '', pastMedicalHistory: '', pastSurgicalHistory: '', medications: '',
      allergies: '', socialHistory: '', familyHistory: ''*/
      emergencyCall: false, callId: ''
    };

    this.reasonForConult = [];
    this.symptoms  =  [];
    
    this.pastMedicalHistory  = [];
    this.medicationsForAntiplateletTherapy = [];
    this.medicationsForAnticoagulationTherapy = [];
    this.pastSurgicalHistory = [];
    this.medications = [];

    this.allergies  = [];
    this.socialHistory = [];
    this.familyHistory = [];
    this.tobaccoArray = [];
    this.drugsHistory = [];
    this.patientSettings.callId = callId;

    this.patientInfo.name = name;
    this.patientInfo.dob = dob;
    this.patientInfo.gender = gender;
    this.patientInfo.mrn = mrn;
    this.currentCallDetail.nurse = nurse;
    this.currentCallDetail.time = requestedAt;
    this.currentCallDetail.callId = callId;
    this.ref.detectChanges();
    // this.activateListner();
    this.patientDataRetrieve(callId);
  }

  /*============  show Active Call Main Div ==========*/
  revertMainActiveDiv() {
    this.activecalldetailDiv = false;
    this.activecallmaindiv = true;
    this.ref.detectChanges();
  }

  /*============== show incomplete call detail div =============*/
  showincompletedetailDiv(reviewOfSystems, notes, gender, createdAt, downloadUrl, assessment, additionalWorkup,
    doNotgiveTPA, vitalSigns, CTAInterpretation, doctorId, patient, considerOrDoNotConsiderEmbolectomy, timestamp,
    telestrokeDocumentation, mrn, giveTPA, thermobectomyRecommendation, CTInterpretation, iagreetnc, physical_exam, userId,
    imaging, otherInformation, complaint, dob, name, recommendationsNew, presenting_illness, isComplete, requested_call,
     speciality_id, type, nihss,isHemorrhage,hemorrhageNonHemorrhage) {
       console.log("Jsonformat", this.ishemarrhageData);

       this.isHemorrhage=isHemorrhage;
       this.hemorrhageTypeArr=[];
       this.aspectScoreDoesNotApplyArr=[];
       this.nonHemorrhageSelectTypeArr=[];
 
      if(this.isHemorrhage == true){
        this.hemorrhage=JSON.parse(hemorrhageNonHemorrhage);
        //this.hemorrhage.InterpretationDate = this.getStringDate(this.hemorrhage.InterpretationDate);
        this.hemorrhageSection=true;
        this.nonHemorrhageSection=false;
        this.hemorrhageOrNonHemmorhageChange("hemorrhage");
      // for (const [key, value] of Object.entries(this.hemorrhage.HemorrhageType)) {
      //   if(`${value}` === 'true' ){
      //     this.hemorrhageTypeArr.push(`${key}`);
      //   }
      // }
      if(this.hemorrhage.CTH){
        this.cthHemorrhageValue.enable();
      } else {
        this.cthHemorrhageValue.setValue('');
        this.cthHemorrhageValue.disable();
      }
      for (const [key, value] of Object.entries(this.hemorrhage.HemorrhageType)) {
        if(`${value}` === 'true' && `${key}`== "IntraparenchymalHemorrhage"){
          this.hemorrhageTypeArr.push('Intraparenchymal Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "SubarachnoidHemorrhage"){
          this.hemorrhageTypeArr.push('Subarachnoid Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
          this.hemorrhageTypeArr.push('Epidural Hemorrhage');
        }
        
        if(`${value}` === 'true' && `${key}`== "SubduralHemorrhage"){
          this.hemorrhageTypeArr.push('Subdural Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "IntraventricularHemorrhage"){
          this.hemorrhageTypeArr.push('Intraventricular Hemorrhage');
        }
      }
    }
    if(this.isHemorrhage == false){
      this.nonHemorrhage=JSON.parse(hemorrhageNonHemorrhage);
      //this.nonHemorrhage.InterpretationDate = this.getStringDate(this.nonHemorrhage.InterpretationDate);
      this.nonHemorrhageSection=true;
      this.hemorrhageSection=false;
      this.hemorrhageOrNonHemmorhageChange("nonHemorrhage");
      // for (const [key, value] of Object.entries(this.nonHemorrhage.AspectScoreDoesNotApply)) {
      //   if(`${value}` === 'true' ){
      //     this.aspectScoreDoesNotApplyArr.push(`${key}`);
      //   }
      if(this.nonHemorrhage.CTInterpretation){
        this.ctInterpretationValue.enable();
      } else {
        this.ctInterpretationValue.setValue('');
        this.ctInterpretationValue.disable();
      }

      if(this.nonHemorrhage.CtaInterpretation.CTAInterpretation){
        this.ctaInterpretationValue.enable();
      } else {
        this.ctaInterpretationValue.setValue('');
        this.ctaInterpretationValue.disable();
      }      
      if(this.nonHemorrhage.AspectScore==='aspectScoreDoesNotApply')
      {
        this.nonHemorrhage.AspectScore="Aspect Score Does Not Apply";
      }
      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectScoreDoesNotApply)) {
        if(`${value}` === 'true' && `${key}`== "SuspectPosteriorCirculationStroke"){
          this.aspectScoreDoesNotApplyArr.push('Suspect Posterior Circulation Stroke ');
        }
        if(`${value}` === 'true' && `${key}`== "MetatasticBrainDisease"){
          this.aspectScoreDoesNotApplyArr.push('Metatastic Brain Disease ');
        }
        if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
          this.aspectScoreDoesNotApplyArr.push('Epidural Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "PrimaryCNSNeoplasm"){
          this.aspectScoreDoesNotApplyArr.push('Primary CNS Neoplasm ');
        }
        if(`${value}` === 'true' && `${key}`== "Seizure"){
          this.aspectScoreDoesNotApplyArr.push('Seizure');
        }
        if(`${value}` === 'true' && `${key}`== "MigraineWithAura"){
          this.aspectScoreDoesNotApplyArr.push('Migraine With Aura');
        }
        if(`${value}` === 'true' && `${key}`== "ConversionDisorder"){
          this.aspectScoreDoesNotApplyArr.push('Conversion Disorder');
        }
        if(`${value}` === 'true' && `${key}`== "Other"){
          this.aspectScoreDoesNotApplyArr.push('Other');
        }
      }

      
     
      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectSubganglionicNuclei)) {
        if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '1' ){
            this.aspectSubganglionicNuclei.frontalOperculumM1 = "Frontal Operaculum M1";
        } if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '0' ){
          this.aspectSubganglionicNuclei.frontalOperculumM1 = "No";
      }
        if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '1' ){
          this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "Anterior TemporalLobe M2";
      }  if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '0' ){
        this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "No";
    }
    if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '1' ){
      this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "Posterior TemporalLobe M1";
  }
  if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '0' ){
    this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "No";
  }
}
        // console.log(`${key}: ${value}`);

      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectSupraganglionicNuclei)) {
        if(`${key}`== 'AnteriorMCAM4' && `${value}` === '1' ){
          this.aspectSupraganglionicNuclei.anteriorMCAM4 = "Anterior MCA M4";
      } if(`${key}`== 'AnteriorMCAM4' && `${value}` === '0' ){
        this.aspectSupraganglionicNuclei.anteriorMCAM4 = "No";
    }
      if(`${key}`== 'LateralMCAM5' && `${value}` === '1' ){
        this.aspectSupraganglionicNuclei.lateralMCAM5 = "Lateral MCA M5";
    }  if(`${key}`== 'LateralMCAM5' && `${value}` === '0' ){
      this.aspectSupraganglionicNuclei.lateralMCAM5 = "No";
  }
  if(`${key}`== 'FrontalMCAM6' && `${value}` === '1' ){
    this.aspectSupraganglionicNuclei.frontalMCAM6 = "Frontal MCA M6";
  }
  if(`${key}`== 'FrontalMCAM6' && `${value}` === '0' ){
  this.aspectSupraganglionicNuclei.frontalMCAM6 = "No";
  }
}
      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectBasalGanglia)) {
        if(`${key}`== 'Caudate' && `${value}` === '1' ){
          this.aspectBasalGanglia.caudate = "Caudate";
      } if(`${key}`== 'Caudate' && `${value}` === '0' ){
        this.aspectBasalGanglia.caudate = "No";
    }
      if(`${key}`== 'LentiformNucleus' && `${value}` === '1' ){
        this.aspectBasalGanglia.lentiformNucleus = "Lentiform Nucleus";
    }  if(`${key}`== 'LentiformNucleus' && `${value}` === '0' ){
      this.aspectBasalGanglia.lentiformNucleus = "No";
    }
      if(`${key}`== 'Insula' && `${value}` === '1' ){
    this.aspectBasalGanglia.insula = "Insula";
    }
      if(`${key}`== 'Insula' && `${value}` === '0' ){
    this.aspectBasalGanglia.insula = "No";
    }
      if(`${key}`== 'InternalCapsule' && `${value}` === '1' ){
    this.aspectBasalGanglia.internalCapsule = "Internal Capsule";
    }
      if(`${key}`== 'InternalCapsule' && `${value}` === '0' ){
    this.aspectBasalGanglia.internalCapsule = "No";
    }
      }
    
        for (const [key, value] of Object.entries(this.nonHemorrhage.CtaInterpretation.SelectType)) {
          if(`${value}` === 'true' ){
            this.nonHemorrhageSelectTypeArr.push(`${key}`);
          }
        }
        var nonHemorrhageSelectSide = [];
        this.nonHemorrhageSelectTypeArr.forEach(function (value) {
          if(value==="Occlusion"){
              nonHemorrhageSelectSide.push("Occlusion");
          }
          if(value==="Dissection"){
            nonHemorrhageSelectSide.push("Dissection");
          }
           if(value==="CervicalIcaMildStenosis"){
            nonHemorrhageSelectSide.push("Cervical ICA Mild Stenosis");
             }
           if(value==="CervicalIcaModerateStenosis"){
            nonHemorrhageSelectSide.push("Cervical ICA Moderate Stenosis");      
           }
          if(value==="CervicalIcaSevereStenosis"){
           nonHemorrhageSelectSide.push("Cervical ICA Severe Stenosis");
            }
  
          if(value==="IntracranialIca"){
          nonHemorrhageSelectSide.push("Intracranial ICA");    }
     
  
         if(value==="CervicalVertebralOcclusion"){
          nonHemorrhageSelectSide.push("Cervical Vertebral Artery Occlusion");    }
  
          if(value==="CervicalVertebralArteryStenosis"){
          nonHemorrhageSelectSide.push("Cervical Vertebral Artery Stenosis");    }
  
          if(value==="CervicalVertebralArteryDissection"){
           nonHemorrhageSelectSide.push("Cervical Vertebral Artery Dissection");    }
  
            if(value==="CervicalIntracranialArteryStenosis"){
            nonHemorrhageSelectSide.push("Cervical Intracranial Artery Stenosis");    }
  
            if(value==="PcaStenosis"){
            nonHemorrhageSelectSide.push("PCA Stenosis");    }
  
            if(value==="PcaOcclusion"){
             nonHemorrhageSelectSide.push("PCA Occlusion");    }
  
             if(value==="BasiliarArteryStenosis"){
              nonHemorrhageSelectSide.push("Basiliar Artery Stenosis");    }
  
               if(value==="DiffuseIntracranialAtherosclerosis"){
              nonHemorrhageSelectSide.push("Diffuse Intracranial Athero Sclerosis");    }
            
         
      });
      this.nonHemorrhageSelectTypeArr = nonHemorrhageSelectSide;
    }  
   
      if (doctorId === this.loggedInUser) {

      // clear previous data
      this.nihss = {
        levelOfConsiousness: '', responseToLocQue: '', responseToLocComm: '' , bestGaze: '', visual: '',
        facialPalsy: '', motorLeftArm: '', motorRightArm: '', motorLeftLeg: '', motorRightLeg : '',
        limbAtaxia: '', sensory: '', bestLanguage: '', dysarthria: '', extinctionAndInattention: '', totalScore: 0
      };
      this.consultNotes = {chiefComplaint: '', historyOfIllness: '', reviewOfSystems: '', vitalSigns: '', giveTPA: '',
          considerOrDoNotConsiderEmbolectomy : '', thermobectomyRecommendation: {},
          notes: '', doNotgiveTPA: '', physicalExam: '', imaging: '', assessment: '', userId: '', doctorId: '', patient: ''
          , speciality_id: '', isComplete: '', requested_call: '', timestamp: '', downloadUrl : ''};

      this.timePoints = {
        timeForConsultRequest: '', timePatientAtCtScan: '', timePatientReadyForVideo: '', timeProviderOnVideo: '', timeOfCtRead: '',
        timeOfCta: '', timeOfCtaRead: '', timeOfDecision: '', timeOfArrivalToEd: '', timeOfLastKnownNormal: ''
      };

      this.isHemorrhage=isHemorrhage;
      // this.hemorrhageNonHemorrhage={"CTInterpretation":'',"CTInterpretationValue":'',"InterpretationTime":"","InterpretationDate":"","AspectScore":"",AspectSubganglionicNuclei:"",
      // AspectSupraganglionicNuclei:"",AspectBasalGanglia:"",TotalAspectScore:"",AspectScoreDoesNotApply:"",ctaInterpretation:"" };


      this.addWorkup = {
        cth: false, cthValue: '', mriBrain: false, mriBrainValue: '', ctaHeadAndNeck: false, ctaHeadAndNeckValue: '',
        eeg: false, eegValue: '',
        mraHeadAndNeck: false, mraHeadAndNeckValue: '', lp: false, lpValue: ''
      };
      this.selectedReasonList = [];
      this.giveTpa = {
        totalDose: '', bolusDose: '', infusionDose: '', timeToGiveTpa: '', agree: false
      };
      this.doNotGiveTpaObj = {
        reasonForNotGivingTpa: this.selectedReasonList
      };

      if (type === 'incomplete') {
        this.incompleteDetailDiv = true;
        this.incompleteMainDiv = false;
        this.showSidePanel = false;
        this.consultNoteType = 'incomplete';
      }
      if (type === 'complete') {
        // this.completeNoteMainDiv = false;
        // this.completeNoteDetailDiv = true;
        // this.showSidePanel = false;
        this.incompleteDetailDiv = true;
        this.completeNoteMainDiv = false;
        this.showSidePanel = false;
        this.consultNoteType = 'complete';
      }
      this.patientInfo = {name : '' , dob : '' , gender : '' , mrn: '' , age : 0 };

      this.patientInfo.name = name;
      this.patientInfo.mrn = mrn;
      this.patientInfo.dob = dob;
      this.patientInfo.gender = gender;

      this.consultNotes.chiefComplaint = complaint;
      this.retrievedconsultNotes.chiefComplaint = complaint;
      this.consultNotes.historyOfIllness = presenting_illness;
      this.retrievedconsultNotes.historyOfIllness = presenting_illness;
      this.consultNotes.reviewOfSystems = reviewOfSystems;
      this.retrievedconsultNotes.reviewOfSystems = reviewOfSystems;
      this.consultNotes.vitalSigns = vitalSigns;
      this.retrievedconsultNotes.vitalSigns = vitalSigns;
      this.consultNotes.giveTPA = giveTPA;
      this.consultNotes.notes = notes;
      this.retrievedconsultNotes.notes = notes;
      this.consultNotes.doNotgiveTPA = doNotgiveTPA;

      if (iagreetnc === 'true') {
        this.iagreetnc = true;
      } else {
        this.iagreetnc = false;
      }

      if (doNotgiveTPA === true) {
          this.giveOrDoNotGiveTpa = 'Do Not Give TPA';
          this.doNotGiveTpaObj = recommendationsNew;
          this.showDontTPAdiv = true;
          this.showTPAdiv = false;
      } else {
        this.giveOrDoNotGiveTpa = 'Give TPA';
        this.giveTpa = recommendationsNew;
        this.showTPAdiv = true;
        this.showDontTPAdiv = false;
        if (recommendationsNew.agree === true) {
           this.showErrorForCheckbox = false;
        } else {
          this.showErrorForCheckbox = true;
        }
      }
      this.consultNotes.imaging = imaging;
      this.retrievedconsultNotes.imaging = imaging;
      this.consultNotes.assessment = assessment;
      this.retrievedconsultNotes.assessment = assessment;
      this.consultNotes.physicalExam = physical_exam;
      this.retrievedconsultNotes.physicalExam = physical_exam;
      var considerOrDoNotConsiderEmbolectomyStatusReceived;
      if ( considerOrDoNotConsiderEmbolectomy === 'true') {
        this.considerOrDoNotConsiderEmbolectomy = 'Consider Embolectomy';
        this.showDontEmbolectomyDiv = false;
        this.emboViewStatus = true;
      } else {
        this.considerOrDoNotConsiderEmbolectomy = 'Do Not Consider Embolectomy';
        this.embolectomyObjectForDoNotGiveTpa = thermobectomyRecommendation;
        this.showDontEmbolectomyDiv = true;
        this.emboViewStatus = false;
      }
      this.timePoints = telestrokeDocumentation;
      this.addWorkup = additionalWorkup;
      this.ctInterpretation = CTInterpretation;
      this.ctaInterpretation = CTAInterpretation;
      this.consultNotes.userId = userId;
      this.retrievedconsultNotes.userId = userId;
      this.consultNotes.doctorId = doctorId;
      this.retrievedconsultNotes.doctorId = doctorId;
      this.consultNotes.patient = patient;
      this.retrievedconsultNotes.patient = patient;
      this.consultNotes.speciality_id = speciality_id;
      this.retrievedconsultNotes.speciality_id = speciality_id;
      this.consultNotes.isComplete = isComplete;
      this.retrievedconsultNotes.isComplete = isComplete;
      this.consultNotes.requested_call = requested_call;
      this.retrievedconsultNotes.requested_call = requested_call;
      this.consultNotes.timestamp = timestamp;
      this.retrievedconsultNotes.timestamp = timestamp;
       // to get date time year
      this.consultNoteDate = new Date(this.consultNotes.timestamp);
      this.consultNoteDay = this.consultNoteDate.getDate();
      this.consultNotePostLetter = this.postLetters[this.consultNoteDay];
      this.consultNoteMonth =  this.monthNames[this.consultNoteDate.getMonth()];
      this.consultNoteYear = this.consultNoteDate.getFullYear();
      this.consultNotes.downloadUrl = downloadUrl;
      this.retrievedconsultNotes.downloadUrl = downloadUrl;
      
      if (!this.addWorkup.cth) {
        this.cthValuecontrol.setValue('');
        this.cthValuecontrol.disable();
      }
      else if(this.addWorkup.cth){
        this.cthValuecontrol.enable();
      }
      if (!this.addWorkup.mriBrain) {
        this.mriBrainValuecontrol.setValue('');
        this.mriBrainValuecontrol.disable();
      }
      else if(this.addWorkup.mriBrain){
        this.mriBrainValuecontrol.enable();
      }
      if (!this.addWorkup.ctaHeadAndNeck) {
        this.ctaHeadAndNeckValuecontrol.disable();
      }
      else if(this.addWorkup.ctaHeadAndNeck){
        this.ctaHeadAndNeckValuecontrol.enable();
      }
      if (!this.addWorkup.eeg) {
        this.eegValuecontrol.setValue('');
        this.eegValuecontrol.disable();
      }
      else if(this.addWorkup.eeg){
        this.eegValuecontrol.enable();
      }
      if (!this.addWorkup.mraHeadAndNeck) {
        this.mraHeadAndNeckValuecontrol.setValue('');
        this.mraHeadAndNeckValuecontrol.disable();
      }
      else if(this.addWorkup.mraHeadAndNeck){
        this.mraHeadAndNeckValuecontrol.enable();
      }
      if (!this.addWorkup.lp) {
        this.lpValuecontrol.setValue('');
        this.lpValuecontrol.disable();
      }
      else if(this.addWorkup.lp){
        this.lpValuecontrol.enable();
      }
      if (nihss !== null) {
        this.nihss.levelOfConsiousness = nihss.levelOfConsiousness;
        this.nihss.responseToLocQue = nihss.responseToLocQue;
        this.nihss.responseToLocComm = nihss.responseToLocComm;
        this.nihss.bestGaze = nihss.bestGaze;
        this.nihss.visual = nihss.visual;
        this.nihss.facialPalsy = nihss.facialPalsy;
        this.nihss.motorLeftArm = nihss.motorLeftArm;
        this.nihss.motorRightArm = nihss.motorRightArm;
        this.nihss.motorLeftLeg = nihss.motorLeftLeg;
        this.nihss.motorRightLeg = nihss.motorRightLeg;
        this.nihss.limbAtaxia = nihss.limbAtaxia;
        this.nihss.sensory = nihss.sensory;
        this.nihss.bestLanguage = nihss.bestLanguage;
        this.nihss.dysarthria = nihss.dysarthria;
        this.nihss.extinctionAndInattention = nihss.extinctionAndInattention;
        this.nihss.totalScore = nihss.totalScore;
      }
  

        // this.hemorrhageNonHemorrhage.CTInterpretation=hemorrhageNonHemorrhage.CTInterpretation;
        // this.hemorrhageNonHemorrhage.CTInterpretationValue=hemorrhageNonHemorrhage.CTInterpretationValue;
        // this.hemorrhageNonHemorrhage.InterpretationTime=hemorrhageNonHemorrhage.InterpretationTime;
        // this.hemorrhageNonHemorrhage.InterpretationDate=hemorrhageNonHemorrhage.InterpretationDate;
        // this.hemorrhageNonHemorrhage.AspectScore=hemorrhageNonHemorrhage.AspectScore;
        // console.log("1",this.hemorrhageNonHemorrhage.CTInterpretation)
        // console.log("2", this.hemorrhageNonHemorrhage.CTInterpretationValue+this.hemorrhageNonHemorrhage.InterpretationTime+this.hemorrhageNonHemorrhage.InterpretationDate)
       
        this.patientDataRetrieve(requested_call);

    }
  }

  patientDataRetrieve(callId) {
  
    const params = new HttpParams().set('callId', callId);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    this.http.post(this.authService.baseURL + '/GetVideoTokenDetailsTest', params , {headers: headers}).subscribe(
      res => {
        console.log(res);
        if (res['Message'] === 'Success') {
          this.retrievedData = res['data'];
          console.log("check the type", this.retrievedData.tpaEligibilityUnknown)
          this.patientId = this.retrievedData['patientId']; //
          this.receivedUserID = this.retrievedData['userId']; // null
          this.receivedCallID = this.retrievedData['callId']; //
         // this.patientSettings.nurse = this.retrievedData.nurse;
         console.log("value of fullname",this.retrievedData.fullName)
          this.patientSettings.nurse = this.retrievedData.fullName;  
          this.patientSettings.physician = this.retrievedData.physician;
          this.patientSettings.historian = this.retrievedData.historian;
          this.patientSettings.hospitalCampus = this.retrievedData.hospitalCampus;
          this.patientSettings.arrivalToEdDate = this.retrievedData.arrivalToEdDate;
          this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
          this.patientSettings.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
          this.patientSettings.bloodPressure = this.retrievedData.bloodPressure;
         // this.patientSettings.tpaEligibilityUnknown=this.retrievedData.tpaEligibilityUnknown;

          // this.patientSettings.weight = this.retrievedData.weight;
          if (this.retrievedData.weightUnit === 'kg') {
            this.patientSettings.weightUnit = 'kg';
            this.patientSettings.weight = this.retrievedData.weight;
          } else {
            this.patientSettings.weightUnit = 'lbs';
            this.patientSettings.weight = this.retrievedData.weight;
          }
          if (this.retrievedData.tempUnit === 'celsius') {
            this.patientSettings.tempUnit = 'celsius';
            this.patientSettings.temp = this.retrievedData.temp;

          } else {
            this.patientSettings.tempUnit = 'fahrenheit';
            this.patientSettings.temp = this.retrievedData.temp;
          }
          this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
          this.patientSettings.heartRate = this.retrievedData.heartRate;
          this.patientSettings.rr = this.retrievedData.rr;
          this.patientSettings.bloodGlucose = this.retrievedData.bloodGlucose;
          // this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;
          //this.patientSettings.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
          this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
          this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
          this.patientSettings.isPatientOnAntiplateletTherapy = this.retrievedData.isPatientOnAntiplatelet;
          this.patientSettings.patientAlcoholConsumption = this.retrievedData.doesPatientConsumeAlcohol;
          this.patientSettings.cigarettesPerDay = this.retrievedData.cigaretteCount;
          
          if(this.patientSettings.cigarettesPerDay == " "){
            this.cigarette_section_Api = false;
          }
          if(this.patientSettings.cigarettesPerDay !== " "){
            this.cigarette_section_Api = true;
          }
          if(this.patientSettings.isPatientOnAntiplateletTherapy == "Yes"){
            this.antiplateletMedicationsMenu = true;
          } else { this.antiplateletMedicationsMenu = false; }
          this.patientSettings.isPatientOnAnticoagulationTherapy = this.retrievedData.wasPatientOnAnticoagulation;
          if(this.patientSettings.isPatientOnAnticoagulationTherapy == "Yes"){
            this.anticoagulationMedicationsMenu = true;
          } else { this.anticoagulationMedicationsMenu = false; }
          
          if(this.retrievedData.meansOfArrival!==null && this.retrievedData.meansOfArrival!=="undefined" && this.retrievedData.meansOfArrival!== ""){
          
            this.meansOfArrivalObj = JSON.parse(this.retrievedData.meansOfArrival);
          }
          
          if(this.meansOfArrivalObj!== undefined && this.meansOfArrivalObj!=="" && this.meansOfArrivalObj!==null){
          if(this.meansOfArrivalObj["privateVehicle"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptionsApi = this.meansOfArrivalObj["privateVehicle"];
          }
          if(this.meansOfArrivalObj["inHouseAttack"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptionsApi = this.meansOfArrivalObj["inHouseAttack"];
          }
          if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == ""){
            this.ambulance_section = true;
            this.patientSettings.meansOfArrivalOptionsApi = "Ambulance";
            this.patientSettings.meansOfArrivalCustomApi = this.meansOfArrivalObj["Ambulance"];
          }
        }
          this.patientSettings.familyContactNumber = this.retrievedData.familyContactNumber;
          this.patientSettings.preArrivalNotification = this.retrievedData.preArrivalNotification;
          this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
          this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
          this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
          this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
          this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
          //this.patientSettings.ReasonForConsult = this.retrievedData.reason;
          this.tpaEligibiltyCheck=this.retrievedData.tpaEligibilityUnknown;
          if (this.retrievedData.reason !== '' && this.retrievedData.reason !== undefined && this.retrievedData.reason !== null) {
            
            this.reasonForConult = JSON.parse(this.retrievedData.reason);
          } else {
            //this.patientSettings.ReasonForConsult = '';
            this.reasonForConult = [];
          }
          if (this.retrievedData.symptoms !== '' && this.retrievedData.symptoms !== null && this.retrievedData.symptoms !== undefined) {
            this.symptoms = JSON.parse(this.retrievedData.symptoms);
          } else {
            this.symptoms = [];
          }
          if ( this.retrievedData.medicationForAntiplateletTherapy !== null && this.retrievedData.medicationForAntiplateletTherapy !== '') {
            this.medicationsForAntiplateletTherapy = JSON.parse(this.retrievedData.medicationForAntiplateletTherapy);
            if(this.medicationsForAntiplateletTherapy.length !==0 && this.patientSettings.isPatientOnAntiplateletTherapy == "Yes"){
            this.medicationsForAntiplatelet = true;
            }
          } else {
            this.medicationsForAntiplateletTherapy = [];
            this.antiplateletMedicationsMenu = false;
          }
          if ( this.retrievedData.medicationForAnticoagulationTherapy !== null && this.retrievedData.medicationForAnticoagulationTherapy !== '') {
            this.medicationsForAnticoagulationTherapy = JSON.parse(this.retrievedData.medicationForAnticoagulationTherapy);
            // this.anticoagulationMedicationsMenu = true;
          } else {
            this.medicationsForAnticoagulationTherapy = [];
            // this.anticoagulationMedicationsMenu = false;
          }
          if ( this.retrievedData.medicalHistory !== null && this.retrievedData.medicalHistory !== '') {
            this.pastMedicalHistory = JSON.parse(this.retrievedData.medicalHistory);
          } else {
            this.pastMedicalHistory = [];
          }
          if ( this.retrievedData.pastSurgicalHistory !== null && this.retrievedData.surgicalHistory !== '' ) {
            this.pastSurgicalHistory = JSON.parse(this.retrievedData.surgicalHistory);
          } else {
            this.pastSurgicalHistory = [];
          }
          if (this.retrievedData.currentMedications !== '' && this.retrievedData.currentMedications !== null && this.retrievedData.currentMedications !== undefined) {
            this.medications = JSON.parse(this.retrievedData.currentMedications);
          } else {
            this.medications = [];
          }
          if (this.retrievedData.allergies !== '' && this.retrievedData.allergies !== null && this.retrievedData.allergies !== undefined) {
            this.allergies = JSON.parse(this.retrievedData.allergies);
          } else {
            this.allergies = [];
          }
          if (this.retrievedData.socialHistory !== '' && this.retrievedData.socialHistory !== null && this.retrievedData.socialHistory !== undefined) {
            this.tobaccoArray = JSON.parse(this.retrievedData.socialHistory);
          } else {
            this.tobaccoArray = [];
          }
          if (this.retrievedData.familyHistory !== '' && this.retrievedData.familyHistory !== null && this.retrievedData.familyHistory !== undefined) {
            this.familyHistory = JSON.parse(this.retrievedData.familyHistory);
          } else {
            this.familyHistory = [];
          }

          if (this.retrievedData.patientDrugsConsumption !== '' && this.retrievedData.patientDrugsConsumption !== null && this.retrievedData.patientDrugsConsumption !== undefined) {
            this.drugsHistory = JSON.parse(this.retrievedData.patientDrugsConsumption);
          } else {
            this.drugsHistory = [];
          }

          this.activateListner();
        } else {
          this.patientSettings = {
            isPatientOnAntiplateletTherapy: '', isPatientOnAnticoagulationTherapy:'', patientAlcoholConsumption:'',cigaretteCheck:false,
            cigarettesPerDay:'', cigarettesPerDayQuantity:'', familyContactNumber:'', meansOfArrivalOptions:'',
            meansOfArrivalCustom:'', meansOfArrivalOptionsApi:'', meansOfArrivalCustomApi:'', preArrivalNotification:'',
            nurse: '', physician: '', historian: '', hospitalCampus: '',
            arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
            tpaEligibilityUnknown: false, familyAtBedside: '',
            bloodPressure: '', weight: '', oxygenSat: '',
            heartRate: '', temp: '', rr: '', bloodGlucose: '', weightUnit: '', tempUnit: '' ,
            miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
            isIntracranial: '',
            ReasonForConsult: '', /*symptoms: '', pastMedicalHistory: '', pastSurgicalHistory: '', medications: '',
            allergies: '', socialHistory: '', familyHistory: ''*/
            emergencyCall: false, callId: ''
          };
          // this.pastMedicalHistory = [];
          
          this.pastSurgicalHistory = [];
           this.socialHistory = [];
           this.tobaccoArray = [];
         // this.socialHistory = '';
         this.medicationsForAntiplateletTherapy = [];
         this.medicationsForAnticoagulationTherapy = [];
         this.pastMedicalHistory=[];
           this.familyHistory = [];
           this.drugsHistory = [];
           this.tobaccoArray = [];
         // this.familyHistory = '';
        }
      });
  }

  /*============== show incomplete call main div =============*/
  showincompletedMainDiv() {
    this.incompleteDetailDiv = false;
    this.incompleteMainDiv = true;
    this.showSidePanel = true;
    setTimeout(this.showIncompleteTab, 10);
  }
  /*================== show incomplete call view div ===============*/
  gotoincompletedviewDiv() {
    this.incompleteDetailDiv = true;
    this.incompleteDetailEditDiv = false;
    this.showSidePanel = false;
  }
  /*================== show incomplete call Edit div ===============*/
  gotoincompletedEditDiv() {
    this.incompleteDetailEditDiv = true;
    this.incompleteDetailDiv = false;
    this.showSidePanel = false;
  }
 /*============== show complete notes main div =============*/
  showcompleteNoteMainDiv() {
    this.completeNoteMainDiv = true;
    // this.completeNoteDetailDiv = false;
    this.incompleteDetailDiv = false;
    this.incompleteMainDiv = true;
    this.showSidePanel = true;
    setTimeout(this.showcompleteTab, 10);
  }
 /*============== show complete notes details div =============*/
 showcompleteNoteDetailDiv() {
  this.completeNoteMainDiv = false;
  this.completeNoteDetailDiv = true;
  this.showSidePanel = false;
 }
 /*================== show complete call view div ===============*/
 gotocompletedViewDiv() {
  this.completeDetailEditDiv = false;
  this.completeNoteDetailDiv = true;
  this.showSidePanel = false;
 }
 /*================== show complete call Edit div ===============*/
 gotocompletedEditDiv() {
  this.completeDetailEditDiv = true;
  this.completeNoteDetailDiv = false;
  this.showSidePanel = false;
}
 /*==================== Show TPA divs accordingly =========================*/
 changeTPARadio(value) {
  if (value === 'Give TPA') {
    this.showIncompeteEditTPAdiv = true;
    this.showIncompeteEditNotTPAdiv = false;
  } else {
    this.showIncompeteEditNotTPAdiv = true;
    this.showIncompeteEditTPAdiv = false;
  }
 }
 /*================== show do not consider embolectomy div ====================*/
 embolectomyChange(value) {
  if (value === 'Consider Embolectomy') {
    this.editDontEmbolectomyDiv = false;
  } else {
    this.editDontEmbolectomyDiv = true;
  }
 }

 /*============== Reverse Date ======================*/
  reverseDate(date) {
  const dateArr = date.split('/');
  const modifiedDate = dateArr[0] + '/' + dateArr[1] + '/' + dateArr[2];
  // const d = new date(modifiedDate);
  // const dateConverted = d.toUTCString();
  // return dateConverted;
  var convertedDate = new Date(modifiedDate);
  date = moment(convertedDate);
  return date;
  }

/* ============== Fetch incomplete calls Webservice GET ============== */
fetchIncompleteCalls() {
  this.incompleteCallsList = [];
  this.http.get(this.authService.baseURL + '/GetIncompleteConsultNotes').subscribe(
    resp => {
      if (resp['Message'] === 'success') {
        this.incompleteCallsList = resp['data'];
        // console.log('incomplete data', this.incompleteCallsList);
        this.incompleteCallsList = this.sortByKeyDate(resp['data'], 'createdAt');
      }
    });
}

sortByKeyDate(array, key) {
  return array.sort(function(a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return ((x > y) ? -1 : ((x < y) ? 1 : 0));
  });
}

/* =============== Fetch Complete call Webservice GET ============== */
fetchCompleteCalls() {
  this.completeCallsList = [];
  this.http.get(this.authService.baseURL + '/GetCompleteConsultNote').subscribe(
    resp => {
    
      if (resp['Message'] === 'success') {
        this.completeCallsList = resp['data'];
        this.completeCallsList = this.sortByKeyDate(resp['data'], 'createdAt');
      }
    });
}

  activateListner() {
      /*============== listner for physician ============*/
      firebase.database().ref('intakeData/stroke/' +  this.patientSettings.callId + '/physician').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.physician = Snapshot.val();
          console.log('Physician : ' + this.patientSettings.physician);
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Nurse ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/fullName').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.nurse = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Nurse ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/reason').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.reasonForConult = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Historian ================*/
      console.log('patientID', this.patientSettings.callId );
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/historian').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historian = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Hospital Campus ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/hospitalCampus').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.hospitalCampus = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Blood Pressure ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/bloodPressure').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.bloodPressure = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Pulse/ heartRate ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/heartRate').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.heartRate = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Oxygen Saturation ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/oxygenSat').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.oxygenSat = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for RR ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/rr').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.rr = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Weight Unit ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/weightUnit').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.weightUnit = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Temp Unit ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/tempUnit').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.tempUnit = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Weight ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/weight').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.weight = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Temp ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/temp').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.temp = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Symptoms ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/symptoms').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.symptoms = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for last known normal date ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/lastKnownNormalDate').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalDate = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for last known normal time ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/lastKnownNormalTime').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalTime = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Arrival To ED Date ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/arrivalToEdDate').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.arrivalToEdDate = (Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for TPA Eligibility Unkown ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/tpaEligibilityUnknown').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          if (Snapshot.val()  === true || Snapshot.val()  === 'true') {
            this.patientSettings.tpaEligibilityUnknown = true;
            // this.statusLastKnownNormal = true;
            this.timePoints.timeOfLastKnownNormal = 'Unknown';
            // this.patientSettings.lastKnownNormalDate = this.defaultDate;
            // this.patientSettings.lastKnownNormalTime = '';
          } else {
            this.patientSettings.tpaEligibilityUnknown = false;
            // this.statusLastKnownNormal = false;
            // this.timePoints.timeOfLastKnownNormal = '';
            // this.patientSettings.lastKnownNormalDate = null;
          }
          this.ref.detectChanges();
        }
      });

      /*============== listner for Arrival To Time  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/arrivalToEdTime').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.arrivalToEdTime = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for LastKnownNormalDate ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/lastKnownNormalDate').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalDate = Snapshot.val();
          this.ref.detectChanges();
        }
      });

      /*============== listner for LastKnownNormalTime  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/lastKnownNormalTime').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalTime = Snapshot.val();
          this.ref.detectChanges();
        }
      });

       /*============== listner for Patient On Antiplatelet Therapy  ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/patientOnAntiplatelet').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.isPatientOnAntiplateletTherapy = Snapshot.val();
          if(this.patientSettings.isPatientOnAntiplateletTherapy == "Yes"){
            this.medicationsForAntiplatelet = true;
          } else { this.medicationsForAntiplatelet = false; }
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Patient's Family Contact Number  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/familyContactNumber').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.familyContactNumber = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

       /*============== listner for means of arrival check ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/meansOfArrivalChecked').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.meansOfArrivalCheck = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Patient's Pre Arrival Notification  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/preArrival').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.preArrivalNotification = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Patient's Means Of Arrival  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/meansOfArrival').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.meansOfArrivalObj = JSON.parse(Snapshot.val());
          if(this.meansOfArrivalObj["privateVehicle"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["privateVehicle"];
          }
          if(this.meansOfArrivalObj["inHouseAttack"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["inHouseAttack"];
          }
          if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == "" && this.meansOfArrivalCheck == true){
            this.ambulance_section = true;
            this.patientSettings.meansOfArrivalOptions = "Ambulance";
            this.patientSettings.meansOfArrivalCustom = this.meansOfArrivalObj["Ambulance"];
          }
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

       /*============== listner for Does Patient Consume Alcohol  ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/alcoholConsumption').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.patientAlcoholConsumption = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

       /*============== listner for Does Patient Smoking Cigarettes Per Day  ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/cigarettesPerDay').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.cigarettesPerDay = Snapshot.val();
          if(this.patientSettings.cigarettesPerDay!== ""){
            this.cigarette_section = true;
          }
          if(this.patientSettings.cigarettesPerDay === ""){
            this.cigarette_section = false;
          }
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

       /*============== listner for Does Patient Smoking Cigarettes Per Day Quantity  ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/cigarettesPerDayQuantity').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.cigarettesPerDayQuantity = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });
        /*============== listner to check whether cigarette Check or not  ================*/
        firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/cigaretteCheck').on('value', Snapshot => {
           this.patientSettings.cigaretteCheck = Snapshot.val();
            // ref.detectChanges();
            this.ref.detectChanges();
        });

       /*============== listner for Patient On Anticoagulation Therapy  ================*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/patientOnAnticoagulation').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.isPatientOnAnticoagulationTherapy = Snapshot.val();
          if(this.patientSettings.isPatientOnAnticoagulationTherapy == "Yes"){
            this.medicationsForAnticoagulation = true;
          } else { this.medicationsForAnticoagulation = false; }
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for MIIn Previous 3 Weeks  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/miInPrevious3Weeks').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.miInPrevious3Weeks = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for Stroke  InPrevious3  Months  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/strokeInPrevious3Months').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.strokeInPrevious3Months = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for history OF Ich  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/isICHHistory').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historyOfIch = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for tookCEPX ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/tookCEPX').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.tookCEPX = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for tookCEPX ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/isNeoplasm').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historyOfNeoplasm = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== listner for bloodGlucose  ================*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/bloodGlucose').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientSettings.bloodGlucose = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for current medication ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/currentMedications').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          
          this.medications = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Allergies ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/allergies').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.allergies =  JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Past Medical History  ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/medicalHistory').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
            this.pastMedicalHistory = JSON.parse(Snapshot.val());
            this.ref.detectChanges();
        }
      });
 
       /*============== Listner for Anti Platelet Medications  ============*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/medicationsForAntiplateletTherapy').on('value', Snapshot => {
        if (Snapshot.val() !== null && Snapshot.val()!== "" && Snapshot.val()!== undefined) {
          
            this.medicationsForAntiplateletTherapy = JSON.parse(Snapshot.val());
            if(this.medicationsForAntiplateletTherapy.length !== 0 && this.patientSettings.isPatientOnAntiplateletTherapy == "Yes"){
            this.medicationsForAntiplatelet = true;
            }
            else {
              this.medicationsForAntiplatelet = false;
              }
            
            this.ref.detectChanges();
        }
      });

       /*============== Listner for  Anti Coagulation Medications  ============*/
       firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId + '/medicationsForAnticoagulationTherapy').on('value', Snapshot => {
        if (Snapshot.val() !== null && Snapshot.val()!== "" && Snapshot.val()!== undefined) {
    
            this.medicationsForAnticoagulationTherapy = JSON.parse(Snapshot.val());
            if(this.medicationsForAnticoagulationTherapy.length!==0 && this.patientSettings.isPatientOnAnticoagulationTherapy == "Yes"){
            this.medicationsForAnticoagulation = true;
            }
           else {
              this.medicationsForAnticoagulation = false;
              }
            this.ref.detectChanges();
        }
      });
      /*============== Listner for  Surgical History ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/surgicalHistory').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.pastSurgicalHistory = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Social History ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/tobacco').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.tobaccoArray = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Family History ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/familyHistory').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.familyHistory = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for Patient's Drug Consumption ============*/
      firebase.database().ref('intakeData/stroke/' + this.patientSettings.callId  + '/drugs').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.drugsHistory = JSON.parse(Snapshot.val());
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Patient Name ============*/
      firebase.database().ref('patientInfo/stroke/' + this.patientSettings.callId  + '/name').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientInfo.name = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Patient Gender ============*/
      firebase.database().ref('patientInfo/stroke/' + this.patientSettings.callId  + '/gender').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientInfo.gender = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

      /*============== Listner for  Patient DOB ============*/
      firebase.database().ref('patientInfo/stroke/' + this.patientSettings.callId  + '/dob').on('value', Snapshot => {
        if (Snapshot.val() !== null) {
          this.patientInfo.dob = Snapshot.val();
          // ref.detectChanges();
          this.ref.detectChanges();
        }
      });

  }
  // Function to show incomplete accordian TAB [Maulik]
  showIncompleteTab() {
    $('.nav-tabs a[href="#incomplete"]').tab('show');
  }
   // Function to show Complete accordian TAB [Maulik]
  showcompleteTab() {
    $('.nav-tabs a[href="#complete"]').tab('show');
  }
  /*=============== Calculate nihss Points ==========*/
  calculateTotal(event, selectedValue) {

    this.nihss.totalScore = 0;
    if (this.nihss.levelOfConsiousness === '4') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocQue === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocComm === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestGaze === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.visual === '4') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.facialPalsy === '4') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftArm === '5') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightArm === '5') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftLeg === '5') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightLeg === '5') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.limbAtaxia === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.sensory === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestLanguage === '4') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.dysarthria === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.extinctionAndInattention === '3') {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    this.nihss.totalScore = this.nihss.totalScore += Number(this.nihss.levelOfConsiousness) + Number(this.nihss.responseToLocQue) +
    Number(this.nihss.responseToLocComm) + Number(this.nihss.bestGaze) + Number(this.nihss.visual) +
    Number(this.nihss.facialPalsy) + Number(this.nihss.motorLeftArm) + Number(this.nihss.motorRightArm) +
    Number(this.nihss.motorLeftLeg) + Number(this.nihss.motorRightLeg) + Number(this.nihss.limbAtaxia) + Number(this.nihss.sensory) +
    Number(this.nihss.bestLanguage) + Number(this.nihss.dysarthria) + Number(this.nihss.extinctionAndInattention);
  }

  /*============ show hide  tpa Recommendations =======*/
  recommendationChangeEvent(value) {
    if ( value === 'Give TPA') {
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      // this.reasonnotTPA.setValue('');
    }
    if (value === 'Do Not Give TPA') {
      this.showDontTPAdiv = true;
      this.showTPAdiv = false;
    }
    this.ref.detectChanges();
  }

  /*============ show hide Embolectomy Recommendations =======*/
  emborecommendationChange(value) {
    if (value === 'Consider Embolectomy') {
      this.showDontEmbolectomyDiv = false;
    }
    if (value === 'Do Not Consider Embolectomy') {
      this.showDontEmbolectomyDiv = true;
    }
  }

  /*============== Open Teleconsult Dialog ==================*/
  openDialog() {
    this.opendialogForProfile('teleConsent');
  }

  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
      height: '480px',
      data : message
    });
  }

  markConsultNoteFieldsAsPristine() {
    this.cthcontrol.markAsPristine();
    this.mriBraincontrol.markAsPristine();
    this.ctaHeadAndNeckcontrol.markAsPristine();
    this.eegcontrol.markAsPristine();
    this.mraHeadAndNeckcontrol.markAsPristine();
    this.lpcontrol.markAsPristine();
    this.giveTpaDoNotGiveTpaFormControl.markAsPristine();
    this.considerOrDoNotConsiderEmbolectomyFormControl.markAsPristine();
    this.iagreetncControl.markAsPristine();
    this.chiefComplaintControl.markAsPristine();
    this.historyOfIllnessControl.markAsPristine();
    this.ctInterpretationControl.markAsPristine();
    this.ctaInterpretationControl.markAsPristine();
    this.reviewOfSystemsControl.markAsPristine();
    this.physicalExamControl.markAsPristine();
    this.asessmentControl.markAsPristine();
    this.ctaInterpretationhemorrhageControl.markAsPristine();
    this.levelOfConsciousControl.markAsPristine();
    this.responseToLocQueControl.markAsPristine();
    this.responseToLocCommControl.markAsPristine();
    this.bestGazeControl.markAsPristine();
    this.visualControl.markAsPristine();
    this.facialPalsyControl.markAsPristine();
    this.motorLeftArmControl.markAsPristine();
    this.motorRightArmControl.markAsPristine();
    this.motorLeftLegControl.markAsPristine();
    this.motorRightLegControl.markAsPristine();
    this.limbAtaxiaControl.markAsPristine();
    this.sensoryControl.markAsPristine();
    this.bestLanguageControl.markAsPristine();
    this.dysarthriaControl.markAsPristine();
    this.extinctionControl.markAsPristine();
    this.totalDoseControl.markAsPristine();
    this.bolusDoseControl.markAsPristine();
    this.infusionDoseControl.markAsPristine();
    this.timeToGiveTpaControl.markAsPristine();
    this.agreeControl.markAsPristine();
    this.timeOfArrivalToEdControl.markAsPristine();
    this.timeOfLastKnownNormalControl.markAsPristine();
    this.timeForConsultRequestControl.markAsPristine();
    this.timePatientAtCtScanControl.markAsPristine();
    this.timePatientReadyForVideoControl.markAsPristine();
    this.timeProviderOnVideoControl.markAsPristine();
    this.timeOfCtReadControl.markAsPristine();
    this.timeOfCtaControl.markAsPristine();
    this.timeOfCtaReadControl.markAsPristine();
    this.timeOfDecisionControl.markAsPristine();
    this.timeOfTpaBolusControl.markAsPristine();
    this.reasonnotEmbolectomy.markAsPristine();
    this.reasonnotTPA.markAsPristine();
    this.cthHemorrhageControl.markAsPristine();
  //  this.ctInterpretationDateFormControl.markAsPristine();
  }

  validateCheckbox(e) {
    if (this.giveOrDoNotGiveTpa === 'Give TPA') {
      if (!this.giveTpa.agree) {
      this.showErrorForCheckbox = true;
      }
    } else {
      this.showErrorForCheckbox = false;
    }
  }
  removeOrSetError(e) {
    if (e.checked) {
      this.showErrorForCheckbox = false;
    } else {
      this.showErrorForCheckbox = true;
    }
  }

  /*================ Save Consult Note =======================*/
  saveNotes() {
    if (this.showErrorForCheckbox) {
      // this.inputEl.focus();
      // var elmnt = document.getElementById('editTPARecomDiv');
      // elmnt.scrollIntoView();
      if (this.xpandStatus === false) {
        this.xpandStatus = true;
      }
      this.giveOrDoNotGiveTpa = 'Give TPA';
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox('');
    } else {
      this.markConsultNoteFieldsAsPristine();
      var giveOrDoNotGiveTpaObject;
      var giveTpaStatus;
      var doNotGiveTpaStatus;
      var considerOrDoNotConsiderEmbolectomyStatus;
      var considerOrDoNotConsiderEmbolectomyObject;
      if (this.giveOrDoNotGiveTpa === 'Give TPA') {
        var doNotGiveTPAOBj = {};
        giveOrDoNotGiveTpaObject = this.giveTpa;
        giveTpaStatus = true;
        doNotGiveTpaStatus = false;
      } else {
      // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj['doNotGiveTpaSelectedList'] = this.doNotGiveTpaObj['doNotGiveTpaSelectedList'];
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
      // } else {
      //   doNotGiveTpaStatus = false;
      //   giveTpaStatus = false;
      //   giveOrDoNotGiveTpaObject = {};
      // }
    }
    if (this.considerOrDoNotConsiderEmbolectomy === 'Consider Embolectomy') {
      var  considerOrNotEmbolectomyObj = {};
      considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
      considerOrDoNotConsiderEmbolectomyStatus = true;
    } else {
      // if (this.considerOrDoNotConsiderEmbolectomy === 'Do not consider Embolectomy') {
        var  considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject['considerOrNotSelectedList'] = this.embolectomyObjectForDoNotGiveTpa['considerOrNotSelectedList'];
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
      // } else {
      //   considerOrDoNotConsiderEmbolectomyStatus = '';
      //   considerOrDoNotConsiderEmbolectomyObject = {};
      // }
    }
    this.loader.start();
    this.loader.set(10);
    const params = new HttpParams()
      .set('complaint', this.consultNotes.chiefComplaint) // new
      .set('presenting_illness', this.consultNotes.historyOfIllness) // new
      .set('reviewOfSystems', this.consultNotes.reviewOfSystems)// new
      .set('vitalSigns', this.consultNotes.vitalSigns)// new
      .set('giveTPA', giveTpaStatus)// new
      .set('notes', this.consultNotes.notes)// new
      .set('doNotgiveTPA', doNotGiveTpaStatus)// new
      .set('nihss', JSON.stringify(this.nihss))// new
      .set('imaging', this.consultNotes.imaging)// new
      .set('assessment', this.consultNotes.assessment)
      .set('physical_exam', this.consultNotes.physicalExam)
      .set('recommendations', JSON.stringify(giveOrDoNotGiveTpaObject))
      .set('userId', this.receivedUserID)
      .set('doctorId', this.loggedInUser)
      .set('patient', this.patientInfo.mrn)
      .set('speciality_id', '123')
      .set('isComplete', 'false')
      .set('requested_call', this.receivedCallID)
      .set('considerOrDoNotConsiderEmbolectomy', considerOrDoNotConsiderEmbolectomyStatus)
      .set('thermobectomyRecommendation', JSON.stringify(considerOrDoNotConsiderEmbolectomyObject))
      .set('telestrokeDocumentation', JSON.stringify(this.timePoints))
      .set('additionalWorkup', JSON.stringify(this.addWorkup))
      .set('CTInterpretation', JSON.stringify(this.ctInterpretation))
      .set('CTAInterpretation', JSON.stringify(this.ctaInterpretation))
      .set("IsHemorrhage", String(this.isHemorrhage)) /*Pradeep Changes*/
      .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
      .set("hemorrhage", JSON.stringify(this.hemorrhage)) 
      .set("offset", JSON.stringify(this.offset))/*Pradeep Changes*/
      .set("fullName",this.loggedUser)
      .set('iagreetnc', String(this.iagreetnc));
      // this.showLoader = true; // show loader
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        this.http.post(this.authService.baseURL + '/ConsultNoteDetails', params, {headers: headers}).subscribe(
        data => {
            console.log('save notes', data);
            // this.showLoader = false; // hide loader
            if (data['Message'] === 'Success') {

                this.loader.complete();
                this.openSuccessDialog('Consult note saved successfully.');
                // this.gotoincompletedviewDiv();
                this.fetchIncompleteCalls();
                this.fetchCompleteCalls();
                if (this.consultNoteType === 'incomplete') {
                  this.incompleteDetailDiv = false;
                  this.incompleteMainDiv = true;
                  this.showSidePanel = true;
                  this.incompleteDetailEditDiv = false;
                  setTimeout(this.showIncompleteTab, 10);
                }
                if (this.consultNoteType === 'complete') {
                  this.incompleteDetailDiv = false;
                  this.completeNoteMainDiv = true;
                  this.showSidePanel = true;
                  this.incompleteDetailEditDiv = false;
                  setTimeout(this.showcompleteTab, 10);
                }
                // this.getConsultNotesFromDoctor(this.receivedCallID);
                // this.showPatientInfoDiv = true;
                // this.editPatientInfoDiv = false;
              // this.retrievedconsultNotes = this.consultNotes;
            } else {
              this.loader.complete();
              this.openErrorDialog('Some error occurred, please try again.');
            }
        },
        err => {
            this.loader.complete();
            this.openErrorDialog('Some error occurred, please try again.');
        });
      }
  }

  getStringDate(date:Date):String{
    let d = new Date(date);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    if (String(this.date).length === 1) {
      this.date = "0" + this.date;
    }
    if (String(this.month).length === 1) {
      this.month = "0" + this.month;
    }
    var stringDate  =this.month + "/" + this.date + "/" + this.year;
    return stringDate;
  }

// pradeep changes
  calculateAspectScoreTotal() {
    this.nonHemorrhage.TotalAspectScore = 0;
    this.nonHemorrhage.TotalAspectScore = this.nonHemorrhage.TotalAspectScore +=
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Caudate) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Caudate) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) ? 0 : this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Insula) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Insula) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ? 0 : this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ;
  }

  selectSideChange(selectType: any){

    if(selectType==='Left'){
     this.nonHemorrhage.CtaInterpretation.SelectSide="Left";
    }

    if(selectType ==='Right'){
      this.nonHemorrhage.CtaInterpretation.SelectSide="Right";
     }

     if(selectType==='Unknown'){
      this.nonHemorrhage.CtaInterpretation.SelectSide="Unknown";
     }

     if(selectType==='aspectdoesnotApply'){
      this.nonHemorrhage.CtaInterpretation.SelectSide="Aspect Score doesnot Apply";
     }

  }
  hemorrhageOrNonHemmorhageChange(value){
    
    if(value == 'hemorrhage'){
      this.hemorrhageSection = true;
      this.nonHemorrhageSection = false;
      this.isHemorrhage=true;
      this.hemorrhageOrNonHemorrhage="hemorrhage";
    }
    if(value == 'nonHemorrhage'){
      // this.nonHemorrhage.ctaInterpretation.SelectSide="Left";
      this.nonHemorrhageSection = true;
      this.hemorrhageSection = false;
      this.isHemorrhage=false;
      this.hemorrhageOrNonHemorrhage="nonHemorrhage"
    }
  }
  updateSelectType(selectType: String, event : any){
    if(selectType ==='Occlusion') {   
      this.nonHemorrhage.CtaInterpretation.SelectType.Occlusion= !event.source.value;
    }
    if(selectType ==='Dissection') {
      this.nonHemorrhage.CtaInterpretation.SelectType.Dissection = !event.source.value
    }
    if(selectType ==='CervicalIcaMildStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaMildStenosis = !event.source.value;
    }
    if(selectType ==='CervicalIcaModerateStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaModerateStenosis = !event.source.value;
    }
    if(selectType ==='CervicalIcaSevereStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaSevereStenosis = !event.source.value;
    }
    if(selectType ==='IntracranialIca') {   
      this.nonHemorrhage.CtaInterpretation.SelectType.IntracranialIca = !event.source.value;
    }
    if(selectType ==='CervicalVertebralOcclusion') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralOcclusion  = !event.source.value
    }
    if(selectType ==='CervicalVertebralArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryStenosis = !event.source.value;
    }
    if(selectType ==='CervicalVertebralArteryDissection') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryDissection = !event.source.value;
    }
    if(selectType ==='PcaOcclusion') {
      this.nonHemorrhage.CtaInterpretation.SelectType.PcaOcclusion  = !event.source.value;
    }
    if(selectType ==='PcaStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.PcaStenosis  = !event.source.value;
    }
    if(selectType ==='CervicalIntracranialArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIntracranialArteryStenosis = !event.source.value;
    }
    if(selectType ==='BasiliarArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.BasiliarArteryStenosis = !event.source.value;
    }
    if(selectType ==='DiffuseIntracranialAtherosclerosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.DiffuseIntracranialAtherosclerosis  = !event.source.value;
    }
  }
  /*========= Sign and Release Consult Note  ==================*/
  signAndRelease() {
    if (this.showErrorForCheckbox) {
      // this.inputEl.focus();
      // var elmnt = document.getElementById("editTPARecomDiv");
      // elmnt.scrollIntoView();
      this.xpandStatus = true;
      this.giveOrDoNotGiveTpa = 'Give TPA';
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox('');
  } else {
    this.markConsultNoteFieldsAsPristine();
    var giveOrDoNotGiveTpaObject;
    var giveTpaStatus;
    var doNotGiveTpaStatus;
    var considerOrDoNotConsiderEmbolectomyStatus;
    var considerOrDoNotConsiderEmbolectomyObject;
    if (this.giveOrDoNotGiveTpa === 'Give TPA') {
      var doNotGiveTPAOBj = {};
      giveOrDoNotGiveTpaObject = this.giveTpa;
      giveTpaStatus = true;
      doNotGiveTpaStatus = false;
    } else {
      // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj['doNotGiveTpaSelectedList'] = this.doNotGiveTpaObj['doNotGiveTpaSelectedList'];
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
      // } else {
      //   doNotGiveTpaStatus = false;
      //   giveTpaStatus = false;
      //   giveOrDoNotGiveTpaObject = {};
      // }
    }
    if (this.considerOrDoNotConsiderEmbolectomy === 'Consider Embolectomy') {
      var  considerOrNotEmbolectomyObj = {};
      considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
      considerOrDoNotConsiderEmbolectomyStatus = true;
    } else {
      // if (this.considerOrDoNotConsiderEmbolectomy === 'Do Not Consider Embolectomy') {
        var  considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject['considerOrNotSelectedList'] = this.embolectomyObjectForDoNotGiveTpa['considerOrNotSelectedList'];
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
      // } else {
      //   considerOrDoNotConsiderEmbolectomyStatus = '';
      //   considerOrDoNotConsiderEmbolectomyObject = {};
      // }
    } 
  this.loader.start();
  this.loader.set(10);
  const params = new HttpParams()
  .set('complaint', this.consultNotes.chiefComplaint) // new
      .set('presenting_illness', this.consultNotes.historyOfIllness) // new
      .set('reviewOfSystems', this.consultNotes.reviewOfSystems)// new
      .set('vitalSigns', this.consultNotes.vitalSigns)// new
      .set('giveTPA', giveTpaStatus)// new
      .set('notes', this.consultNotes.notes)// new
      .set('doNotgiveTPA', doNotGiveTpaStatus)// new
      .set('nihss', JSON.stringify(this.nihss))// new
      .set('imaging', this.consultNotes.imaging)// new
      .set('assessment', this.consultNotes.assessment)
      .set('physical_exam', this.consultNotes.physicalExam)
      .set('recommendations', JSON.stringify(giveOrDoNotGiveTpaObject))
      .set('userId', this.receivedUserID)
      .set('doctorId', this.loggedInUser)
      .set('patient', this.patientInfo.mrn)
      .set('speciality_id', '123')
      .set('isComplete', 'true')
      .set('requested_call', this.receivedCallID)
      .set('considerOrDoNotConsiderEmbolectomy', considerOrDoNotConsiderEmbolectomyStatus)
      .set('thermobectomyRecommendation', JSON.stringify(considerOrDoNotConsiderEmbolectomyObject))
      .set('telestrokeDocumentation', JSON.stringify(this.timePoints))
      .set('additionalWorkup', JSON.stringify(this.addWorkup))
      .set('CTInterpretation', JSON.stringify(this.ctInterpretation))
      .set('CTAInterpretation', JSON.stringify(this.ctaInterpretation))
      .set("IsHemorrhage", String(this.isHemorrhage)) /*Pradeep Changes*/
      .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
      .set("hemorrhage", JSON.stringify(this.hemorrhage)) 
      .set("offset", JSON.stringify(this.offset))/*Pradeep Changes*/
      .set("fullName",this.loggedUser)
      .set('iagreetnc', String(this.iagreetnc));
      // this.showLoader = true; // show loader

    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.authService.baseURL + '/ConsultNoteDetails', params, {headers: headers}).subscribe(
      data => {
          console.log('sign and release data', data);
          // this.showLoader = false; // hide loader
          if (data['Message'] === 'Success') {

            this.loader.complete();
            this.openSuccessDialog('Consult note signed & released successfully.');
            this.fetchIncompleteCalls();
            this.fetchCompleteCalls();
            if (this.consultNoteType === 'incomplete') {
              this.incompleteDetailDiv = false;
              this.incompleteMainDiv = true;
              this.showSidePanel = true;
              this.incompleteDetailEditDiv = false;
              setTimeout(this.showIncompleteTab, 10);
            }
            if (this.consultNoteType === 'complete') {
              this.incompleteDetailDiv = false;
              this.completeNoteMainDiv = true;
              this.showSidePanel = true;
              this.incompleteDetailEditDiv = false;
              setTimeout(this.showcompleteTab, 10);
            }
            // this.getConsultNotesFromDoctor(this.receivedCallID);
            // this.showPatientInfoDiv = true;
            // this.editPatientInfoDiv = false;

          } else {

            this.loader.complete();
            this.openErrorDialog('Some error occurred, please try again.');
          }
      },
      err => {

        this.loader.complete();
        this.openErrorDialog('Some error occurred, please try again.');

      });
    }
  }

  openErrorDialog(msg) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : msg
    });
    setTimeout(() => dialogRef.close(), 1000);
  }

  openSuccessDialog(msg) {
    const dialogRef = this.successDialog.open(SuccessDialogComponent , {
      height: '72px',
      data : msg
    });
    setTimeout(() => dialogRef.close(), 1000);
  }
  // Read more and Read less functionality ( Currently Commented)
  // toggleReadmore() {
  //   this.expanded = !this.expanded;
  // }

  /*============== Disable/enable additional workup text field ===============*/
  // Handle checked/unchecked trigger of CTH
  editTriggerCTHfield(event) {
    
    this.isCTHChecked = event.checked;
     if (this.isCTHChecked) {
     this.cthValuecontrol.enable();
     this.addWorkup.cth=true;
     } else {
      this.cthValuecontrol.setValue('');
      this.cthValuecontrol.disable();
     }
  }
  // Handle checked/unchecked trigger of MRI checkbox
  editTriggerMRIfield(event) {
    this.isMRIChecked = event.checked;
    if (this.isMRIChecked) {
      this.mriBrainValuecontrol.enable();
      this.addWorkup.mriBrain = true;
    } else {
      this.mriBrainValuecontrol.setValue('');
      this.mriBrainValuecontrol.disable();
    }
  }
  // Handle checked/unchecked trigger of CTA checkbox
  editTriggerCTAfield(event) {
    this.isCTAChecked = event.checked;
    if (this.isCTAChecked) {
     // this.editAddionalWorkupForm.controls['ctaHeadAndNeckValuecontrol'].enable();
      this.ctaHeadAndNeckValuecontrol.enable();
      this.addWorkup.ctaHeadAndNeck=true;
    } else {
      this.ctaHeadAndNeckValuecontrol.setValue('');
      this.ctaHeadAndNeckValuecontrol.disable();
    }
  }
  // Handle checked/unchecked trigger of EEG checkbox
  editTriggerEEGfield(event) {
    this.isEEGChecked = event.checked;
    if (this.isEEGChecked) {
      this.eegValuecontrol.enable();
      this.addWorkup.eeg=true;
    } else {
      this.eegValuecontrol.setValue('');
      this.eegValuecontrol.disable();
    }
  }

  //Handle checked/unckecked trigger of CT interpretation
  editTriggerCTfield(event) {
    if (event.checked) {
      this.ctInterpretationValue.enable();
      this.nonHemorrhage.CTInterpretation=true;
    } else {
      this.ctInterpretationValue.setValue('');
      this.ctInterpretationValue.disable();
      this.nonHemorrhage.CTInterpretation=false;
    }
  }

  //Handle checked/unckecked trigger of CTA interpretation
  editTriggerCTANonHemorrhagefield(event) {
    if (event.checked) {
      this.ctaInterpretationValue.enable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation=true;
    } else {
      this.ctaInterpretationValue.setValue('');
      this.ctaInterpretationValue.disable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation=false;
    }
  }



  //Handle checked/unchecked trigger for CTH Hemorrhage Checkbox
  editTriggerCTHhemorrhagefield(event) {
    if (event.checked) {
      this.cthHemorrhageValue.enable();
      this.hemorrhage.CTH=true;
    } else {
      this.cthHemorrhageValue.setValue('');
      this.cthHemorrhageValue.disable();
      this.hemorrhage.CTH=false;
    }
  }
  // Handle checked/unchecked trigger for MRA checkbox
  editTriggerMRAfield(event) {
    this.isMRAChecked = event.checked;
    if (this.isMRAChecked) {
      this.mraHeadAndNeckValuecontrol.enable();
      this.addWorkup.mraHeadAndNeck= true;
    } else {
      this.mraHeadAndNeckValuecontrol.setValue('');
      this.mraHeadAndNeckValuecontrol.disable();
    }
  }
  triggerlpfield(event) {
  this.islpChecked = event.checked;
    if (this.islpChecked) {
      this.lpValuecontrol.enable();
      this.addWorkup.lp=true;
    } else {
      this.lpValuecontrol.setValue('');
      this.lpValuecontrol.disable();
    }
   }


   updateHemorrhageType(event : any, hemmorhageType: String) {
    console.log('event.source.checked',event.source.checked);
    if(hemmorhageType === 'IntraparenchymalHemorrhage') {   
      this.hemorrhage.HemorrhageType.IntraparenchymalHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'SubarachnoidHemorrhage') {
      this.hemorrhage.HemorrhageType.SubarachnoidHemorrhage = event.source.checked
    }
    if(hemmorhageType === 'EpiduralHemorrhage') {
      this.hemorrhage.HemorrhageType.EpiduralHemorrhage = !!event.source.checked;
    }
    if(hemmorhageType === 'SubduralHemorrhage') {
      this.hemorrhage.HemorrhageType.SubduralHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'IntraventricularHemorrhage') {
      this.hemorrhage.HemorrhageType.IntraventricularHemorrhage = event.source.checked;
    }
  }

  updateAspectScoreDoesNotApply(aspectScoreDoesNotApply: String, event : any){
    if(aspectScoreDoesNotApply ==='SuspectPosteriorCirculationStroke') {   
      this.nonHemorrhage.AspectScoreDoesNotApply.SuspectPosteriorCirculationStroke = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MetatasticBrainDisease') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MetatasticBrainDisease = event.checked;
    }
    if(aspectScoreDoesNotApply ==='PrimaryCNSNeoplasm') {
      this.nonHemorrhage.AspectScoreDoesNotApply.PrimaryCNSNeoplasm = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Seizure') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Seizure = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MigraineWithAura') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MigraineWithAura = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ConversionDisorder') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ConversionDisorder = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ToxicMetabolicEncephalopathy') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ToxicMetabolicEncephalopathy = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Other') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Other = event.checked;
    }
  }

}
