import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChildren,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { AuthService } from "../services/auth.service";
import { DicomComponent } from "../dicom/dicom.component";
import { MatDialog } from "@angular/material";
import { MatRadioChange } from "@angular/material";
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { PlatformLocation } from "@angular/common";
import * as firebase from "firebase/app";
import { $ } from "protractor";
import { DialogForProfileComponent } from "../dialog-for-profile/dialog-for-profile.component";
import { Hemorrhage, HemorrhageType} from '../video/hemorrhage.model';
import { NonHemorrhage } from '../video/nonHemorrhage.model';
import { isNullOrUndefined } from 'util';
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-patient-records",
  templateUrl: "./patient-records.component.html",
  styleUrls: ["./patient-records.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PatientRecordsComponent implements OnInit {
 
  @ViewChildren("input") vc;
  today = new Date();
  offset =(new Date()).getTimezoneOffset();
  xpandStatus = false;
  @ViewChild("ref") inputEl: any;
  // Funtionality implementation for new fields
  emboViewStatus;
  selectedReasonEmbolectomy=[];
  giveTpa = {
    totalDose: "",
    bolusDose: "",
    infusionDose: "",
    timeToGiveTpa: "",
    agree: false,
  };
  selectedReasonList: any = [];
  doNotGiveTpaObj = {
    reasonForNotGivingTpa: this.selectedReasonList,
  };

  embolectomyObjectForDoNotGiveTpa = {
    doNotGiveTpaSelected: this.selectedReasonEmbolectomy,
  };
  timePoints = {
    timeForConsultRequest: "",
    timePatientAtCtScan: "",
    timePatientReadyForVideo: "",
    timeProviderOnVideo: "",
    timeOfCtRead: "",
    timeOfCta: "",
    timeOfCtaRead: "",
    timeOfDecision: "",
    timeOfArrivalToEd: "",
    timeOfLastKnownNormal: "",
  };
  addWorkup = {
    cth: false,
    cthValue: "",
    mriBrain: false,
    mriBrainValue: "",
    ctaHeadAndNeck: false,
    ctaHeadAndNeckValue: "",
    eeg: false,
    eegValue: "",
    mraHeadAndNeck: false,
    mraHeadAndNeckValue: "",
    lp: false,
    lpValue: "",
  };
  ctInterpretation = "";
  ctaInterpretation = "";
  giveOrDoNotGiveTpa;
  considerOrDoNotConsiderEmbolectomy;
  iagreetnc: Boolean = false;
  todayDate = new Date();
  iagreeDate;
  iagreeMonth;
  iagreeYear;
  iagreePostLetter;
  consultNoteDate;
  consultNoteDay;
  consultNoteMonth;
  consultNoteYear;
  consultNotePostLetter;
  showErrorForCheckbox: Boolean = false;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  postLetters = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
    11: "th",
    12: "th",
    13: "th",
    14: "th",
    15: "th",
    16: "th",
    17: "th",
    18: "th",
    19: "th",
    20: "th",
    21: "st",
    22: "nd",
    23: "rd",
    24: "th",
    25: "th",
    26: "th",
    27: "th",
    28: "th",
    29: "th",
    30: "th",
    31: "st",
  };
  public nihss = {
    levelOfConsiousness: "",
    responseToLocQue: "",
    responseToLocComm: "",
    bestGaze: "",
    visual: "",
    facialPalsy: "",
    motorLeftArm: "",
    motorRightArm: "",
    motorLeftLeg: "",
    motorRightLeg: "",
    limbAtaxia: "",
    sensory: "",
    bestLanguage: "",
    dysarthria: "",
    extinctionAndInattention: "",
    totalScore: 0,
  };


  //New addition for the hemmorage and nonHemmorage
    //Hemorrhage or NonHemrrhage
  hemorrhageSection:boolean;
  nonHemorrhageSection:boolean;
  public aspectSubganglionicNuclei = {
    frontalOperculumM1: "",
    AnteriorTemporalLobeM2: "",
    frontalOperculumM1Posterior: ""
  }
  public aspectSupraganglionicNuclei = {
    anteriorMCAM4: "",
    lateralMCAM5: "",
    frontalMCAM6: ""
  }
  public aspectBasalGanglia = {
    caudate: "",
    lentiformNucleus: "",
    insula: "",
    internalCapsule: ""
  }
  isHemorrhage :boolean;
  ishemarrhageData :any;
  hemorrhageTypeArr = [];
  aspectScoreDoesNotApplyArr = [];
  aspectSubganglionicArr = [];
  aspectSupraganglionicArr = [];
  aspectBasalGangliaArr = [];
  nonHemorrhageSelectTypeArr = [];
  nonHemorrhageSelectSideArr = [];
  /*Hemorrhage or NonHemorrhage */
  hemorrhageData:any;
  nonHemorrhageData:any;
  defaultDate: Date = new Date();
  public ctInterpretationTime = ""; 
  public ctInterpretationDate = this.defaultDate;
  public userSelectedHemorrhage: boolean = false;
  public hemorrhage = new Hemorrhage();
  public nonHemorrhage = new NonHemorrhage();
  selectSideValue:any;
  hemorrhageOrNonHemorrhage:any;
  /* Hemorrhage or NonHemorrhage */ 
  // New field addition in consult note
  showTPAdiv = false; // if selected Show TPA
  showDontTPAdiv = false; // if selected Dont show TPA
  showDontEmbolectomyDiv = false; // if selected Dont show embolectomy

  // do not give TPA options
  reasonnotTPA = new FormControl();
  // tslint:disable-next-line:max-line-length
  reasonList: string[] = [
    "Not a stroke",
    "Complex migraine",
    "History of ICH",
    "Mild symptoms",
    "Seizure",
    "BP requires initial management",
    "Initial refusal of treatment",
    "Out of window for tPA",
    "Unable to determine eligibility",
  ];

  reasonnotEmbolectomy = new FormControl();
  reasonEmbolectomyList: string[] = ["No LVO", "Mild symptoms", "Not a Stroke"];

  hideUpperUI = true; // Hide upper [Previous UI]
  showPatientResultDiv = true;
  showPatientRecordDiv = false;
  showPatientGeneralInfo = true;
  showPatientDetailedDiv = false;

  showPatientInfoDiv = true;
  editPatientInfoDiv = false;

  patientSearchDiv = true;
  patientDetailsDiv = false;
  patientGeneralInfoDiv = true;
  patientAllInfoDiv = false;
  meansOfArrivalObj;
  ambulance_section:Boolean = false;
  cigarette_section:Boolean = false;
  antiPlateletMedications_section = false;
  antiCoagulationMedication_section = false;
  searchType = "name";
  searchValue: any = "";
  PatientDOB: any = "";
  isValidSearch: boolean;
  public resultList: string[] = [];
  public resultMaleList: string[] = [];
  public resultFemaleList: string[] = [];
  dobstring;
  dobArrangedString;
  convertedDob;
  showConsultNotes: boolean = false;
  showSearchResult = false;
  showMalePatientList = false;
  showFemalePatientList = false;
  showDataNotFound;
  searchCategory;
  date;
  month;
  year;
  completeDate;
  age;
  receivedSpecificConsultNotes;
  showResults;
  tpaEligibiltyCheck:any;
  loggedUser:any;
  // tslint:disable-next-line:member-ordering
  firstNameFormControl = new FormControl("", []);
  nameFormControl = new FormControl("", [
    // Validators.required
  ]);
  patientObj = { name: "", mrn: "", gender: "", dob: "" };
  public receivedPreviousRecord;
  public receivedPreviousFiles;
  public receivedPreviousConsultNotes;
  files: FileList;
  resultDiv = false;
  private reader = new FileReader();
  dateFormControl = new FormControl("", [Validators.required]);
  // old consult notes
  // consultNoteData = {
  //   assessment: '', comments: '', complaint: '', doctor_id: '', isComplete: ''
  //   , labs: '', patient: '', physical_exam: '', presenting_illness: '', requested_call: '' ,
  //   speciality_id: '', timestamp: ''};
  public consultNotes = {
    chiefComplaint: "",
    historyOfIllness: "",
    reviewOfSystems: "",
    vitalSigns: "",
    giveTPA: "",
    considerOrDoNotConsiderEmbolectomy: "",
    thermobectomyRecommendation: {},
    notes: "",
    doNotgiveTPA: "",
    physicalExam: "",
    imaging: "",
    assessment: "",
    userId: "",
    doctorId: "",
    patient: "",
    speciality_id: "",
    isComplete: "",
    requested_call: "",
    timestamp: "",
    downloadUrl: "",
  };
  public retrievedconsultNotes = {
    chiefComplaint: "",
    historyOfIllness: "",
    reviewOfSystems: "",
    vitalSigns: "",
    giveTPA: "",
    notes: "",
    doNotgiveTPA: "",
    physicalExam: "",
    imaging: "",
    assessment: "",
    considerOrDoNotConsiderEmbolectomy: "",
    thermobectomyRecommendation: {},
    userId: "",
    doctorId: "",
    patient: "",
    speciality_id: "",
    isComplete: "",
    requested_call: "",
    timestamp: "",
    downloadUrl: "",
  };
  // data display and prefill blocks
  getdata;
  patientId;
  receivedUserID;
  receivedCallID;
  respData;
  callId;
  retrievedData;
  reasonArray;
  symptomsRequestArray;
  medicalArray;
  surgicalArray;
  medicationArray;
  allergiesRequestArray;
  familyArray;
  socialArray;
  retrieveArrayForReasonForConsult;
  receivedPatientId;
  loggedInUser;
  showingResultForDOB;
  receivedDate;
  receivedMonth;
  receivedYear;
  patientSettingsRetrieve = {
    nurse: "",
    physician: "",
    historian: "",
    hospitalCampus: "",
    familyContactNumber:"",
    preArrivalNotification:"",
    meansOfArrivalOptionsApi:"",
    meansOfArrivalCustomApi:"",
    isPatientOnAntiplateletTherapy:"",
    isPatientOnAnticoagulationTherapy:"",
    cigarettesPerDay:"",
    cigarettesPerDayQuantity:"",
    alcoholConsumption:"",
    arrivalToEdDate: "",
    arrivalToEdTime: "",
    lastKnownNormalDate: "",
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    weight: "",
    oxygenSat: "",
    heartRate: "",
    temp: "",
    rr: "",
    bloodGlucose: "",
    weightUnit: "",
    tempUnit: "",
    medicationsCustom: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
    ReasonForConsult: "",
    symptoms: "",
    pastMedicalHistory: "",
    pastSurgicalHistory: "",
    medications: "",
    allergies: "",
    socialHistory: "",
    familyHistory: "",
  };
  public patientSettings = {
    nurse: "",
    physician: "",
    historian: "",
    hospitalCampus: "",
    familyContactNumber:"",
    preArrivalNotification:"",
    meansOfArrivalOptionsApi:"",
    meansOfArrivalCustomApi:"",
    isPatientOnAntiplateletTherapy:"",
    isPatientOnAnticoagulationTherapy:"",
    arrivalToEdDate: "",
    arrivalToEdTime: "",
    lastKnownNormalDate: "",
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    weight: "",
    oxygenSat: "",
    heartRate: "",
    temp: "",
    medicationsCustom: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
  };
  // Object of checkboxes and other for reason for consult
  public reasonForConsult = {
    cardiacArrest: "",
    possibleStroke: "",
    other: "",
  };
  // Object of checkboxes and other for reason for consult
  public symptoms = {
    armOrLegWeakness: "",
    confusion: "",
    dizziness: "",
    facialDroop: "",
    numbnessOrTingling: "",
    balanceProblems: "",
    headache: "",
    speechDifficulties: "",
    visionChanges: "",
    rightSidedFlaccidWeakness: "",
    leftSidedFlaccidWeakness: "",
    other: "",
  };
  // Object of checkboxes for past medical history
  public pastMedicalHistory = {
    unknown: "",
    none: "",
    htn: "",
    hld: "",
    atrialFibrillation: "",
    giBleed: "",
    congestiveHeartFailure: "",
    mi: "",
    dm: "",
    priorStroke: "",
    dementia: "",
    brainTumor: "",
    seizure: "",
    migraine: "",
    cancer: "",
    other: "",
  };
  // Object of checkboxes for past medical history
  public pastSurgicalHistory = { surgicalHistory: "", other: "" };
  // Object of checkboxes for medications
  public medications = { medicationsCustom: "", medicationsOptions: "" };
  // Object of checkboxes for allergies
  public allergies = { allergiesCustom: "", allergiesOptions: "" };
  // Object of checkboxes for social history
  public socialHistory = {
    none: "",
    unknown: "",
    alcohol: "",
    drugs: "",
    tobacco: "",
  };
  // Object of checkboxes for family history
  public familyHistory = {
    none: "",
    unknown: "",
    mi: "",
    stroke: "",
    cancer: "",
    seizure: "",
    htn: "",
  };
  // Form details to be sent as array
  // array for reason for consult(checkbox and other)
  reasonForConsultArray: any[] = [];
  // array checkboxes for symptoms(checkbox and other)
  symptomsArray: any[] = [];
  // array of checkboxes for past medical history
  pastMedicalHistoryArray: any[] = [];
  // array of checkboxes for past surgical history
  pastSurgicalHistoryArray: any[] = [];
  // array of checkboxes for allergies
  allergiesArray: any[] = [];
  // array of checkboxes for medications
  medicationsArray: any[] = [];
  // array of checkboxes for family history
  familyHistoryArray: any[] = [];
  // array of checkboxes for antiPlatelet Therapy Medications
  medicationsForAntiplateletTherapy: any = [];
  // array of checkboxes for antiCoagulation Therapy Medications
  medicationsForAnticoagulationTherapy: any = [];
  // array of checkboxes for social history
  socialHistoryArray: any[] = [];
  // array of checkboxes for drugs consumption
  drugsHistory: any[] = [];

  // loader
  showLoader = false;
  patientInfo: any;
  editAddionalWorkupForm: FormGroup;

  cthValuecontrol = new FormControl("", []);
  mriBrainValuecontrol = new FormControl("", []);
  ctaHeadAndNeckValuecontrol = new FormControl("", []);
  eegValuecontrol = new FormControl("", []);
  mraHeadAndNeckValuecontrol = new FormControl("", []);
  lpValuecontrol = new FormControl("", []);
  cthcontrol = new FormControl("", []);
  mriBraincontrol = new FormControl("", []);
  ctaHeadAndNeckcontrol = new FormControl("", []);
  eegcontrol = new FormControl("", []);
  mraHeadAndNeckcontrol = new FormControl("", []);
  lpcontrol = new FormControl("", []);
  giveTpaDoNotGiveTpaFormControl = new FormControl("", []);
  considerOrDoNotConsiderEmbolectomyFormControl = new FormControl("", []);
  iagreetncControl = new FormControl("", []);
  chiefComplaintControl = new FormControl("", []);
  historyOfIllnessControl = new FormControl("", []);
  ctInterpretationControl = new FormControl("", []);
  ctaInterpretationControl = new FormControl("", []);
  reviewOfSystemsControl = new FormControl("", []);
  physicalExamControl = new FormControl("", []);
  asessmentControl = new FormControl("", []);
  levelOfConsciousControl = new FormControl("", []);
  responseToLocQueControl = new FormControl("", []);
  responseToLocCommControl = new FormControl("", []);
  bestGazeControl = new FormControl("", []);
  visualControl = new FormControl("", []);
  facialPalsyControl = new FormControl("", []);
  motorLeftArmControl = new FormControl("", []);
  motorRightArmControl = new FormControl("", []);
  motorLeftLegControl = new FormControl("", []);
  motorRightLegControl = new FormControl("", []);
  limbAtaxiaControl = new FormControl("", []);
  sensoryControl = new FormControl("", []);
  bestLanguageControl = new FormControl("", []);
  dysarthriaControl = new FormControl("", []);
  extinctionControl = new FormControl("", []);
  totalDoseControl = new FormControl("", []);
  bolusDoseControl = new FormControl("", []);
  infusionDoseControl = new FormControl("", []);
  timeToGiveTpaControl = new FormControl("", []);
  agreeControl = new FormControl("", []);
  timeOfArrivalToEdControl = new FormControl("", []);
  timeOfLastKnownNormalControl = new FormControl("", []);
  timeForConsultRequestControl = new FormControl("", []);
  timePatientAtCtScanControl = new FormControl("", []);
  timePatientReadyForVideoControl = new FormControl("", []);
  timeProviderOnVideoControl = new FormControl("", []);
  timeOfCtReadControl = new FormControl("", []);
  timeOfCtaControl = new FormControl("", []);
  timeOfCtaReadControl = new FormControl("", []);
  timeOfDecisionControl = new FormControl("", []);
  timeOfTpaBolusControl = new FormControl("", []);
  /*new for HemorrhageorNonHemorrhage */
  cthHemorrhageControl = new FormControl('',[]);   
  cthHemorrhageValue = new FormControl('',[]);   
  hemorrhageorNonHemorrhageFormControl =new FormControl('',[]);
  ctaInterpretationValue=new FormControl('',[]);
  ctInterpretationValue=new FormControl('',[]);
  ctaInterpretationhemorrhageControl = new FormControl('',[]);
   ctInterpretationTimeControl = new FormControl("", [Validators.required]);
   ctInterpretationDateFormControl = new FormControl("", [Validators.required]);
   /*new for HemorrhageorNonHemorrhage */

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public dialog: MatDialog,
    public errorDialog: MatDialog,
    public successDialog: MatDialog,
    public ref: ChangeDetectorRef,
    public loader: LoadingBarService,
    location: PlatformLocation,
    public dialogForProfile: MatDialog
  ) {
    this.authService
      .getUserFromstatechange()
      .then((val) => (this.loggedInUser = val));
    this.iagreeDate = this.todayDate.getDate();
    this.iagreePostLetter = this.postLetters[this.iagreeDate];
    this.iagreeMonth = this.monthNames[this.todayDate.getMonth()];
    this.iagreeYear = this.todayDate.getFullYear();
    location.onPopState(() => {
      if (this.showPatientRecordDiv === true) {
        window.history.forward();
        this.showPatientRecordDiv = false;
        this.showPatientResultDiv = true;
        this.showPatientDetailedDiv = false;
        this.patientGeneralInfoDiv = true;
      }
    });

    firebase
      .database()
      .ref("doctorAvailability/")
      .on("value", (snapshot) => {
        if (snapshot.hasChild(this.loggedInUser)) {
          // this.availabilityStatus = true;
          this.authService.isDoctorAvail = true;
          this.ref.detectChanges();
        } else {
          // this.availabilityStatus = false;
          this.authService.isDoctorAvail = false;
          this.ref.detectChanges();
        }
      });
  }

  ngOnInit() {
    this.loggedUser=localStorage.getItem("fullName");
  }

  sortByKeyDate(array, key) {
    return array.sort(function (a, b) {
      var x = new Date(a[key]).getTime();
      var y = new Date(b[key]).getTime();
      return x > y ? -1 : x < y ? 1 : 0;
    });
  }

  showSelectedRecordDetails(name, mrn, gender, dob) {
    // mrn = this.receivedPatientData.mrn;
    this.patientObj.name = name;
    this.patientObj.mrn = mrn;
    this.patientObj.gender = gender;
    this.patientObj.dob = dob;
    var receivedDateDetails = this.patientObj.dob.split("/");
    this.receivedDate = receivedDateDetails[1];
    this.receivedMonth = receivedDateDetails[0];
    this.receivedYear = receivedDateDetails[2];
    this.age = this.CalculateAge(
      this.receivedDate,
      this.receivedMonth,
      this.receivedYear
    );
    /*this.patientSearchDiv = false;
    this.patientDetailsDiv = true;*/
    this.showPatientResultDiv = false;
    this.showPatientRecordDiv = true;
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const param = new HttpParams().set("patientId", this.patientObj.mrn);
    this.loader.start();
    this.loader.set(10);
    /*=============== Get Previous Files ======================*/

    this.http
      .post(this.authService.baseURL + "/GetPreviousFiles", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            this.receivedPreviousFiles = response["data"];
          } else {
            this.receivedPreviousFiles = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );

    /*=============== Get Previous Consult Notes ======================*/

    this.http
      .post(this.authService.baseURL + "/GetPreviousConsultNotes", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            this.loader.complete();
            console.log("hey");
            this.receivedPreviousConsultNotes = response["data"];
            this.receivedPreviousConsultNotes = this.sortByKeyDate(
              this.receivedPreviousConsultNotes,
              "timestamp"
            );
            console.log(this.receivedPreviousConsultNotes);
            if (
              this.receivedPreviousConsultNotes.doctorId === this.loggedInUser
            ) {
              console.log(
                "hello im doctor",
                this.receivedPreviousConsultNotes.doctorID
              );
              this.showConsultNotes = true;
            }
          } else {
            this.loader.complete();
            this.receivedPreviousConsultNotes = [];
          }
        },
        (err) => {
          this.loader.complete();
          console.log(err);
        }
      );
  }
  /*=============== open Diacom files in viewer =====================*/
  viewImage(fileid, filetype, fileurl) {
    if (filetype === "Normal") {
      console.log("normal", fileurl);
      window.open(fileurl, "_blank");
    } else {
      console.log("fileid to be given to auth SErvice", fileid);
      this.authService.setValue(fileid);
      const dialogRef = this.dialog.open(DicomComponent, {
        height: "400px",
        width: "900px",
      });
    }
  }

  /* ================ Calculate Age ==================================*/
  CalculateAge(receivedDate, receivedMonth, receivedYear) {
    // if (this.patientObj.dob) {
    //   const today = new Date();
    //   const birthDate = new Date(receivedYear, receivedMonth, receivedDate);
    //   let age = today.getFullYear() - birthDate.getFullYear();
    //   const m = today.getMonth() - birthDate.getMonth();
    //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age--;
    //   }
    //   if (age < 0) {
    //     age = 0;
    //   }
    //   return age;
    // }
    var yearThen = receivedYear;
    var monthThen = receivedMonth;
    var dayThen = receivedDate;
    var today = new Date();
    var birthday = new Date(yearThen, monthThen - 1, dayThen);
    var differenceInMilisecond = today.valueOf() - birthday.valueOf();
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
    return year_age;
  }

  /*=================== Selected Consult Note Details ========================*/
  consultNoteDetails(
    complaint,
    presenting_illness,
    reviewOfSystems,
    vitalSigns,
    giveTPA,
    notes,
    doNotgiveTPA,
    nihss,
    imaging,
    assessment,
    physical_exam,
    recommendationsNew,
    thermobectomyRecommendation,
    userId,
    doctorId,
    patient,
    speciality_id,
    isComplete,
    requested_call,
    timestamp,
    considerOrDoNotConsiderEmbolectomy,
    downloadUrl,
    telestrokeDocumentation,
    additionalWorkup,
    CTInterpretation,
    CTAInterpretation,
    iagreetnc,
    IsHemorrhage,
    hemorrhageOrNonHemorrhage
  ) {
    this.consultNotes.chiefComplaint = complaint;
    this.retrievedconsultNotes.chiefComplaint = complaint;
    this.consultNotes.historyOfIllness = presenting_illness;
    this.retrievedconsultNotes.historyOfIllness = presenting_illness;
    this.consultNotes.reviewOfSystems = reviewOfSystems;
    this.retrievedconsultNotes.reviewOfSystems = reviewOfSystems;
    this.consultNotes.vitalSigns = vitalSigns;
    this.retrievedconsultNotes.vitalSigns = vitalSigns;
    this.consultNotes.giveTPA = giveTPA;
    this.consultNotes.notes = notes;
    this.retrievedconsultNotes.notes = notes;
    this.consultNotes.doNotgiveTPA = doNotgiveTPA;
    this.nihss.levelOfConsiousness = nihss.levelOfConsiousness;
    this.nihss.responseToLocQue = nihss.responseToLocQue;
    this.nihss.responseToLocComm = nihss.responseToLocComm;
    this.nihss.bestGaze = nihss.bestGaze;
    this.nihss.visual = nihss.visual;
    this.nihss.facialPalsy = nihss.facialPalsy;
    this.nihss.motorLeftArm = nihss.motorLeftArm;
    this.nihss.motorRightArm = nihss.motorRightArm;
    this.nihss.motorLeftLeg = nihss.motorLeftLeg;
    this.nihss.motorRightLeg = nihss.motorRightLeg;
    this.nihss.limbAtaxia = nihss.limbAtaxia;
    this.nihss.sensory = nihss.sensory;
    this.nihss.bestLanguage = nihss.bestLanguage;
    this.nihss.dysarthria = nihss.dysarthria;
    this.nihss.extinctionAndInattention = nihss.extinctionAndInattention;
    this.nihss.totalScore = nihss.totalScore;
    if (iagreetnc === "true") {
      this.iagreetnc = true;
    } else {
      this.iagreetnc = false;
    }
    // this.nihss = nihss;
    // if (doNotgiveTPA === true) {
    //   this.giveOrDoNotGiveTpa = 'Do Not Give TPA';
    //   // alert('recommendations' + recommendationsNew);
    //   this.doNotGiveTpaObj = recommendationsNew;
    //   this.showDontTPAdiv = true;
    // } else {
    //   this.giveOrDoNotGiveTpa = 'Give TPA';
    //   this.giveTpa = recommendationsNew;
    //   this.showTPAdiv = true;
    // }
    // if (doNotgiveTPA === false && giveTPA === false) {
    //   alert('both false');
    //     // this.giveTpaDoNotGiveTpaFormControl.reset();
    //     this.doNotGiveTpaObj['doNotGiveTpaSelectedList'] = JSON.stringify({});
    //     this.giveOrDoNotGiveTpa = '';
    // } else {
    if (doNotgiveTPA === true) {
      this.giveOrDoNotGiveTpa = "Do Not Give TPA";
      this.doNotGiveTpaObj = recommendationsNew;
      this.showDontTPAdiv = true;
      this.showTPAdiv = false;
    } else {
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.giveTpa = recommendationsNew;
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      if (recommendationsNew.agree === true) {
        this.showErrorForCheckbox = false;
      } else {
        this.showErrorForCheckbox = true;
      }
    }
    // }
    this.consultNotes.imaging = imaging;
    this.retrievedconsultNotes.imaging = imaging;
    this.consultNotes.assessment = assessment;
    this.retrievedconsultNotes.assessment = assessment;
    this.consultNotes.physicalExam = physical_exam;
    this.retrievedconsultNotes.physicalExam = physical_exam;
    // this.consultNotes.recommendationsNew = recommendationsNew;
    // this.retrievedconsultNotes.recommendationsNew = recommendationsNew;
    // this.considerOrDoNotConsiderEmbolectomy = considerOrDoNotConsiderEmbolectomy;
    var considerOrDoNotConsiderEmbolectomyStatusReceived;
    // alert(considerOrDoNotConsiderEmbolectomyStatusReceived);
    if (considerOrDoNotConsiderEmbolectomy === "true") {
      this.considerOrDoNotConsiderEmbolectomy = "Consider Embolectomy";
      this.showDontEmbolectomyDiv = false;
      this.emboViewStatus = true;
    } else {
      // if (considerOrDoNotConsiderEmbolectomy === 'false') {
      this.considerOrDoNotConsiderEmbolectomy = "Do Not Consider Embolectomy";
      this.embolectomyObjectForDoNotGiveTpa = thermobectomyRecommendation;
      this.showDontEmbolectomyDiv = true;
      this.emboViewStatus = false;
      // } else {
      //   alert('embo both false');
      //   // this.considerOrDoNotConsiderEmbolectomyFormControl.setValue('');
      //   this.considerOrDoNotConsiderEmbolectomy = '';
      //   this.emboViewStatus = '';
      //   this.embolectomyObjectForDoNotGiveTpa['considerOrNotSelectedList'] = JSON.stringify({});
      // }
    }
    // this.retrievedcoconsiderOrDoNotConsiderEmbolectomy = considerOrDoNotConsiderEmbolectomy;
    // this.retrievedconsultNotes.thermobectomyRecommendation = thermobectomyRecommendation;
    this.timePoints = telestrokeDocumentation;
    this.addWorkup = additionalWorkup;
    this.ctInterpretation = CTInterpretation;
    this.ctaInterpretation = CTAInterpretation;
    this.consultNotes.userId = userId;
    this.retrievedconsultNotes.userId = userId;
    this.consultNotes.doctorId = doctorId;
    this.retrievedconsultNotes.doctorId = doctorId;
    this.consultNotes.patient = patient;
    this.retrievedconsultNotes.patient = patient;
    this.consultNotes.speciality_id = speciality_id;
    this.retrievedconsultNotes.speciality_id = speciality_id;
    this.consultNotes.isComplete = isComplete;
    this.retrievedconsultNotes.isComplete = isComplete;
    this.consultNotes.requested_call = requested_call;
    this.retrievedconsultNotes.requested_call = requested_call;
    this.consultNotes.timestamp = timestamp;

    //to get date time year
    this.consultNoteDate = new Date(this.consultNotes.timestamp);
    this.consultNoteDay = this.consultNoteDate.getDate();
    this.consultNotePostLetter = this.postLetters[this.consultNoteDay];
    this.consultNoteMonth = this.monthNames[this.consultNoteDate.getMonth()];
    this.consultNoteYear = this.consultNoteDate.getFullYear();
    this.retrievedconsultNotes.timestamp = timestamp;
    this.consultNotes.downloadUrl = downloadUrl;
    this.retrievedconsultNotes.downloadUrl = downloadUrl;
    /*this.patientGeneralInfoDiv = false;
    this.patientAllInfoDiv = true;*/
    this.patientGeneralInfoDiv = false;
    this.showPatientDetailedDiv = true;
  
/* new additon for hemorrhage or NonHemorrage*/
       this.hemorrhageTypeArr=[];
       this.aspectScoreDoesNotApplyArr=[];
       this.nonHemorrhageSelectTypeArr=[];
       this.isHemorrhage=IsHemorrhage;
      this.ishemarrhageData=JSON.parse(hemorrhageOrNonHemorrhage);
      if(this.isHemorrhage == true){
        this.hemorrhage=JSON.parse(hemorrhageOrNonHemorrhage);        
        this.hemorrhageSection=true;
        this.nonHemorrhageSection=false;
       this.hemorrhageOrNonHemmorhageChange("hemorrhage");
      // for (const [key, value] of Object.entries(this.hemorrhage.HemorrhageType)) {
      //   if(`${value}` === 'true' ){
      //     this.hemorrhageTypeArr.push(`${key}`);
      //   }
      // }
        for (const [key, value] of Object.entries(this.ishemarrhageData.HemorrhageType)) {
        if(`${value}` === 'true' && `${key}`== "IntraparenchymalHemorrhage"){
          this.hemorrhageTypeArr.push('Intraparenchymal Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "SubarachnoidHemorrhage"){
          this.hemorrhageTypeArr.push('Subarachnoid Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
          this.hemorrhageTypeArr.push('Epidural Hemorrhage');
        }
        
        if(`${value}` === 'true' && `${key}`== "SubduralHemorrhage"){
          this.hemorrhageTypeArr.push('Subdural Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "IntraventricularHemorrhage"){
          this.hemorrhageTypeArr.push('Intraventricular Hemorrhage');
        }
      }
    }
    if(this.isHemorrhage == false){
      this.nonHemorrhage=JSON.parse(hemorrhageOrNonHemorrhage);
      this.nonHemorrhageSection=true;
      this.hemorrhageSection=false;
      this.hemorrhageOrNonHemmorhageChange("nonHemorrhage");
      // for (const [key, value] of Object.entries(this.nonHemorrhage.AspectScoreDoesNotApply)) {
      //   if(`${value}` === 'true' ){
      //     this.aspectScoreDoesNotApplyArr.push(`${key}`);
      //   }
      // }
      if(this.nonHemorrhage.AspectScore==='aspectScoreDoesNotApply')
      {
        this.nonHemorrhage.AspectScore="Aspect Score Does Not Apply";
      }
      for (const [key, value] of Object.entries(this.ishemarrhageData.AspectScoreDoesNotApply)) {
        if(`${value}` === 'true' && `${key}`== "SuspectPosteriorCirculationStroke"){
          this.aspectScoreDoesNotApplyArr.push('Suspect Posterior Circulation Stroke ');
        }
        if(`${value}` === 'true' && `${key}`== "MetatasticBrainDisease"){
          this.aspectScoreDoesNotApplyArr.push('Metatastic Brain Disease ');
        }
        if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
          this.aspectScoreDoesNotApplyArr.push('Epidural Hemorrhage');
        }
        if(`${value}` === 'true' && `${key}`== "PrimaryCNSNeoplasm"){
          this.aspectScoreDoesNotApplyArr.push('Primary CNS Neoplasm ');
        }
        if(`${value}` === 'true' && `${key}`== "Seizure"){
          this.aspectScoreDoesNotApplyArr.push('Seizure');
        }
        if(`${value}` === 'true' && `${key}`== "MigraineWithAura"){
          this.aspectScoreDoesNotApplyArr.push('Migraine With Aura');
        }
        if(`${value}` === 'true' && `${key}`== "ConversionDisorder"){
          this.aspectScoreDoesNotApplyArr.push('Conversion Disorder');
        }
        if(`${value}` === 'true' && `${key}`== "Other"){
          this.aspectScoreDoesNotApplyArr.push('Other');
        }
      }

      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectSubganglionicNuclei)) {
        if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '1' ){
            this.aspectSubganglionicNuclei.frontalOperculumM1 = "Frontal Operaculum M1";
        } if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '0' ){
          this.aspectSubganglionicNuclei.frontalOperculumM1 = "No";
      }
        if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '1' ){
          this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "Anterior TemporalLobe M2";
      }  if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '0' ){
        this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "No";
    }
    if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '1' ){
      this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "Posterior TemporalLobe M1";
  }
  if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '0' ){
    this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "No";
  }
        // console.log(`${key}: ${value}`);
      }
      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectSupraganglionicNuclei)) {
        if(`${key}`== 'AnteriorMCAM4' && `${value}` === '1' ){
          this.aspectSupraganglionicNuclei.anteriorMCAM4 = "Anterior MCA M4";
      } if(`${key}`== 'AnteriorMCAM4' && `${value}` === '0' ){
        this.aspectSupraganglionicNuclei.anteriorMCAM4 = "No";
    }
      if(`${key}`== 'LateralMCAM5' && `${value}` === '1' ){
        this.aspectSupraganglionicNuclei.lateralMCAM5 = "Lateral MCA M5";
    }  if(`${key}`== 'LateralMCAM5' && `${value}` === '0' ){
      this.aspectSupraganglionicNuclei.lateralMCAM5 = "No";
  }
  if(`${key}`== 'FrontalMCAM6' && `${value}` === '1' ){
    this.aspectSupraganglionicNuclei.frontalMCAM6 = "Frontal MCA M6";
  }
  if(`${key}`== 'FrontalMCAM6' && `${value}` === '0' ){
  this.aspectSupraganglionicNuclei.frontalMCAM6 = "No";
  }
      }
      for (const [key, value] of Object.entries(this.nonHemorrhage.AspectBasalGanglia)) {
        if(`${key}`== 'Caudate' && `${value}` === '1' ){
          this.aspectBasalGanglia.caudate = "Caudate";
      } if(`${key}`== 'Caudate' && `${value}` === '0' ){
        this.aspectBasalGanglia.caudate = "No";
    }
      if(`${key}`== 'LentiformNucleus' && `${value}` === '1' ){
        this.aspectBasalGanglia.lentiformNucleus = "Lentiform Nucleus";
    }  if(`${key}`== 'LentiformNucleus' && `${value}` === '0' ){
      this.aspectBasalGanglia.lentiformNucleus = "No";
    }
      if(`${key}`== 'Insula' && `${value}` === '1' ){
    this.aspectBasalGanglia.insula = "Insula";
    }
      if(`${key}`== 'Insula' && `${value}` === '0' ){
    this.aspectBasalGanglia.insula = "No";
    }
      if(`${key}`== 'InternalCapsule' && `${value}` === '1' ){
    this.aspectBasalGanglia.internalCapsule = "Internal Capsule";
    }
      if(`${key}`== 'InternalCapsule' && `${value}` === '0' ){
    this.aspectBasalGanglia.internalCapsule = "No";
    }
      }
    
        for (const [key, value] of Object.entries(this.nonHemorrhage.CtaInterpretation.SelectType)) {
          if(`${value}` === 'true' ){
             this.nonHemorrhageSelectTypeArr.push(`${key}`);
          }
        }
    }
    
var nonHemorrhageSelectSide = [];
this.nonHemorrhageSelectTypeArr.forEach(function (value) {
  if(value==="Occlusion"){
      nonHemorrhageSelectSide.push("Occlusion");
  }
  if(value==="Dissection"){
    nonHemorrhageSelectSide.push("Dissection");
  }
   if(value==="CervicalIcaMildStenosis"){
    nonHemorrhageSelectSide.push("Cervical ICA Mild Stenosis");
     }
   if(value==="CervicalIcaModerateStenosis"){
    nonHemorrhageSelectSide.push("Cervical ICA Moderate Stenosis");      
   }
  if(value==="CervicalIcaSevereStenosis"){
   nonHemorrhageSelectSide.push("Cervical ICA Severe Stenosis");
    }

  if(value==="IntracranialIca"){
  nonHemorrhageSelectSide.push("Intracranial ICA");    }


 if(value==="CervicalVertebralOcclusion"){
  nonHemorrhageSelectSide.push("Cervical Vertebral Artery Occlusion");    }

  if(value==="CervicalVertebralArteryStenosis"){
  nonHemorrhageSelectSide.push("Cervical Vertebral Artery Stenosis");    }

  if(value==="CervicalVertebralArteryDissection"){
   nonHemorrhageSelectSide.push("Cervical Vertebral Artery Dissection");    }

    if(value==="CervicalIntracranialArteryStenosis"){
    nonHemorrhageSelectSide.push("Cervical Intracranial Artery Stenosis");    }

    if(value==="PcaStenosis"){
    nonHemorrhageSelectSide.push("PCA Stenosis");    }

    if(value==="PcaOcclusion"){
     nonHemorrhageSelectSide.push("PCA Occlusion");    }

     if(value==="BasiliarArteryStenosis"){
      nonHemorrhageSelectSide.push("Basiliar Artery Stenosis");    }

       if(value==="DiffuseIntracranialAtherosclerosis"){
      nonHemorrhageSelectSide.push("Diffuse Intracranial Athero Sclerosis");    }
    
 
});
this.nonHemorrhageSelectTypeArr = nonHemorrhageSelectSide;
  //   for (const [key, value] of Object.entries(this.ishemarrhageData.CtaInterpretation.SelectType)) {
  //     if(`${value}` === 'true' ){
  //       this.nonHemorrhageSelectTypeArr.push(`${key}`);
  //     }
  // }


    

  }
  consultNoteDetailsOld(
    complaint,
    presenting_illness,
    reviewOfSystems,
    vitalSigns,
    giveTPA,
    notes,
    doNotgiveTPA,
    imaging,
    assessment,
    physical_exam,
    userId,
    doctorId,
    patient,
    speciality_id,
    isComplete,
    requested_call,
    timestamp,
    considerOrDoNotConsiderEmbolectomy,
    downloadUrl
  ) {
    this.consultNotes.chiefComplaint = complaint;
    this.retrievedconsultNotes.chiefComplaint = complaint;
    this.consultNotes.historyOfIllness = presenting_illness;
    this.retrievedconsultNotes.historyOfIllness = presenting_illness;
    this.consultNotes.reviewOfSystems = reviewOfSystems;
    this.retrievedconsultNotes.reviewOfSystems = reviewOfSystems;
    this.consultNotes.vitalSigns = vitalSigns;
    this.retrievedconsultNotes.vitalSigns = vitalSigns;
    this.consultNotes.giveTPA = giveTPA;
    this.consultNotes.notes = notes;
    this.retrievedconsultNotes.notes = notes;
    this.consultNotes.doNotgiveTPA = doNotgiveTPA;
    this.consultNotes.imaging = imaging;
    this.retrievedconsultNotes.imaging = imaging;
    this.consultNotes.assessment = assessment;
    this.retrievedconsultNotes.assessment = assessment;
    this.consultNotes.physicalExam = physical_exam;
    this.retrievedconsultNotes.physicalExam = physical_exam;
    // this.consultNotes.recommendationsNew = recommendationsNew;
    // this.retrievedconsultNotes.recommendationsNew = recommendationsNew;
    // this.considerOrDoNotConsiderEmbolectomy = considerOrDoNotConsiderEmbolectomy;
    var considerOrDoNotConsiderEmbolectomyStatusReceived;
    // alert(considerOrDoNotConsiderEmbolectomyStatusReceived);
    if (considerOrDoNotConsiderEmbolectomy === "true") {
      this.considerOrDoNotConsiderEmbolectomy = "Consider Embolectomy";
      this.showDontEmbolectomyDiv = false;
      this.emboViewStatus = true;
    } else {
      this.considerOrDoNotConsiderEmbolectomy = "Do Not Consider Embolectomy";
      this.showDontEmbolectomyDiv = true;
      this.emboViewStatus = false;
    }
    this.consultNotes.userId = userId;
    this.retrievedconsultNotes.userId = userId;
    this.consultNotes.doctorId = doctorId;
    this.retrievedconsultNotes.doctorId = doctorId;
    this.consultNotes.patient = patient;
    this.retrievedconsultNotes.patient = patient;
    this.consultNotes.speciality_id = speciality_id;
    this.retrievedconsultNotes.speciality_id = speciality_id;
    this.consultNotes.isComplete = isComplete;
    this.retrievedconsultNotes.isComplete = isComplete;
    this.consultNotes.requested_call = requested_call;
    this.retrievedconsultNotes.requested_call = requested_call;
    this.consultNotes.timestamp = timestamp;
    this.retrievedconsultNotes.timestamp = timestamp;
    this.consultNotes.downloadUrl = downloadUrl;
    this.retrievedconsultNotes.downloadUrl = downloadUrl;
    this.patientGeneralInfoDiv = false;
    this.showPatientDetailedDiv = true;
  }
  getFormDetails(callId) {
    this.hideUpperUI = false;
    const params = new HttpParams().set("callId", callId);
    var headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    this.http
      .post(this.authService.baseURL + "/GetVideoTokenDetailsTest", params, {
        headers: headers,
      })
      .subscribe((data) => {
        if (data["Message"] === "Success") {
          this.getConsultNotesFromDoctor(callId);
          console.log("this is query params data", data);
          this.getdata = data["data"];
          var sid = this.getdata["videoSessionId"];
          console.log(sid);
          var token = this.getdata["videoToken"];
          this.patientId = this.getdata["patientId"]; //
          this.receivedUserID = this.getdata["userId"]; // null
          this.receivedCallID = this.getdata["callId"]; //
          // this.authService.endCallId = this.getdata['callId'];
          console.log("received user id", this.receivedUserID);
          // Form details
          this.retrievedData = data["data"];
          //this.patientSettingsRetrieve.nurse = this.retrievedData.nurse;
          this.patientSettingsRetrieve.nurse = this.retrievedData.fullName;  
          this.patientSettingsRetrieve.physician = this.retrievedData.physician;
          console.log(this.retrievedData.physician);
          console.log("physician", this.patientSettingsRetrieve.physician);
          this.patientSettingsRetrieve.historian = this.retrievedData.historian;
          this.patientSettingsRetrieve.hospitalCampus = this.retrievedData.hospitalCampus;
          this.patientSettingsRetrieve.arrivalToEdDate = this.retrievedData.arrivalToEdDate;
          this.patientSettingsRetrieve.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
          if (this.retrievedData.lastKnownNormalDate === "NaN/NaN/NaN") {
            this.patientSettingsRetrieve.lastKnownNormalDate = "";
          } else {
            this.patientSettingsRetrieve.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          }
          // this.patientSettingsRetrieve.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          this.patientSettingsRetrieve.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
          this.patientSettingsRetrieve.bloodPressure = this.retrievedData.bloodPressure;
          this.patientSettingsRetrieve.isPatientOnAntiplateletTherapy = this.retrievedData.isPatientOnAntiplatelet;
          this.patientSettingsRetrieve.isPatientOnAnticoagulationTherapy = this.retrievedData.wasPatientOnAnticoagulation;
          this.patientSettings.familyContactNumber = this.retrievedData.familyContactNumber;
          this.patientSettings.preArrivalNotification = this.retrievedData.preArrivalNotification;
          
          if(this.retrievedData.meansOfArrival!==null && this.retrievedData.meansOfArrival!==undefined && this.retrievedData.meansOfArrival!== ""){
          
            this.meansOfArrivalObj = JSON.parse(this.retrievedData.meansOfArrival);
          }
          
          if(this.meansOfArrivalObj!== undefined && this.meansOfArrivalObj!=="" && this.meansOfArrivalObj!==null){
          if(this.meansOfArrivalObj["privateVehicle"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptionsApi = this.meansOfArrivalObj["privateVehicle"];
          }
          if(this.meansOfArrivalObj["inHouseAttack"] !== "")
          {
            this.ambulance_section = false;
            this.patientSettings.meansOfArrivalOptionsApi = this.meansOfArrivalObj["inHouseAttack"];
          }
          if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == ""){
            this.ambulance_section = true;
            this.patientSettings.meansOfArrivalOptionsApi = "Ambulance";
            this.patientSettings.meansOfArrivalCustomApi = this.meansOfArrivalObj["Ambulance"];
          }
        }
        // if (this.retrievedData.familyHistory !== '' && this.retrievedData.familyHistory !== null && this.retrievedData.familyHistory !== undefined) {
        //   this.familyHistory = JSON.parse(this.retrievedData.familyHistory);
        // } else {
        //   this.familyHistory = [];
        // }
          // this.patientSettingsRetrieve.weight = this.retrievedData.weight;
          this.patientSettingsRetrieve.oxygenSat = this.retrievedData.oxygenSat;
          this.patientSettingsRetrieve.heartRate = this.retrievedData.heartRate;
          // this.patientSettingsRetrieve.temp = this.retrievedData.temp;
          this.patientSettingsRetrieve.rr = this.retrievedData.rr;
          this.patientSettingsRetrieve.bloodGlucose = this.retrievedData.bloodGlucose;
          this.patientSettingsRetrieve.alcoholConsumption = this.retrievedData.doesPatientConsumeAlcohol;
          this.patientSettingsRetrieve.cigarettesPerDay = this.retrievedData.cigaretteCount;
          if(this.patientSettingsRetrieve.cigarettesPerDay !==" "){
            this.cigarette_section = true;
          }
          if(this.patientSettingsRetrieve.cigarettesPerDay == " "){
            this.cigarette_section = false;
          }
        
          if ( this.retrievedData.medicationForAntiplateletTherapy !== null && this.retrievedData.medicationForAntiplateletTherapy !== '') {
            this.medicationsForAntiplateletTherapy = JSON.parse(this.retrievedData.medicationForAntiplateletTherapy);
            if( this.medicationsForAntiplateletTherapy.length !== 0 && this.patientSettingsRetrieve.isPatientOnAntiplateletTherapy == "Yes"){
            this.antiPlateletMedications_section = true;
            }
            if(this.patientSettingsRetrieve.isPatientOnAntiplateletTherapy == "No"){
              this.antiPlateletMedications_section = false;
              }
              if(this.patientSettingsRetrieve.isPatientOnAntiplateletTherapy == "Unknown"){
                this.antiPlateletMedications_section = false;
                }
          } else {
            this.medicationsForAntiplateletTherapy = [];
          }
          if ( this.retrievedData.medicationForAnticoagulationTherapy !== null && this.retrievedData.medicationForAnticoagulationTherapy !== '') {
            this.medicationsForAnticoagulationTherapy = JSON.parse(this.retrievedData.medicationForAnticoagulationTherapy);
            if(this.medicationsForAnticoagulationTherapy.length !== 0 && this.patientSettingsRetrieve.isPatientOnAnticoagulationTherapy == "Yes"){
              this.antiCoagulationMedication_section = true;
            }
            if(this.patientSettingsRetrieve.isPatientOnAnticoagulationTherapy == "No"){
              this.antiCoagulationMedication_section = false;
            }
            if(this.patientSettingsRetrieve.isPatientOnAnticoagulationTherapy == "Unknown"){
              this.antiCoagulationMedication_section = false;
            }
          } else {
            this.medicationsForAnticoagulationTherapy = [];
          }
          // this.patientSettingsRetrieve.weightUnit = this.retrievedData.weightUnit;
          // this.patientSettingsRetrieve.tempUnit = this.retrievedData.tempUnit;
          this.tpaEligibiltyCheck=this.retrievedData.tpaEligibilityUnknown;
          if (this.retrievedData.weightUnit === "kg") {
            this.patientSettingsRetrieve.weightUnit = "kg";
            this.patientSettingsRetrieve.weight = this.retrievedData.weight;

            // var retrivedWeightConverted = (
            //   Number(this.retrievedData.weight) / 2.2046
            // ).toFixed(2);
            // this.patientSettingsRetrieve.weight = String(
            //   retrivedWeightConverted
            // );
          } else {
            this.patientSettingsRetrieve.weightUnit = "lbs";
            this.patientSettingsRetrieve.weight = this.retrievedData.weight;
          }
          if (this.retrievedData.tempUnit === "celsius") {
            this.patientSettingsRetrieve.tempUnit = "celsius";
            this.patientSettingsRetrieve.temp = this.retrievedData.temp;
            // var retrivedTemp = (Number(this.retrievedData.temp) - 32) / 1.8;
            // var retrivedTempConverted = retrivedTemp.toFixed(2);
            // // alert(retrivedTempConverted);
            // this.patientSettingsRetrieve.temp = String(retrivedTempConverted);
          } else {
            this.patientSettingsRetrieve.tempUnit = "fahrenheit";
            this.patientSettingsRetrieve.temp = this.retrievedData.temp;
          }
          this.patientSettingsRetrieve.medicationsCustom = this.retrievedData.medicationsCustom;
          this.patientSettingsRetrieve.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
          this.patientSettingsRetrieve.familyAtBedside = this.retrievedData.isFamilyBedside;
          this.patientSettingsRetrieve.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
          this.patientSettingsRetrieve.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
          this.patientSettingsRetrieve.historyOfIch = this.retrievedData.isICHHistory;
          this.patientSettingsRetrieve.tookCEPX = this.retrievedData.tookCEPX;
          this.patientSettingsRetrieve.historyOfNeoplasm = this.retrievedData.isNeoplasm;
          this.patientSettingsRetrieve.isIntracranial = this.retrievedData.isIntracranial;
          this.patientSettingsRetrieve.ReasonForConsult = JSON.parse(
            this.retrievedData.reason
          );
          this.reasonArray = JSON.parse(this.retrievedData.reason);
          console.log("reason", this.reasonArray);
          this.patientSettingsRetrieve.symptoms = JSON.parse(
            this.retrievedData.symptoms
          );
          this.symptomsRequestArray = JSON.parse(this.retrievedData.symptoms);
          this.patientSettingsRetrieve.pastMedicalHistory = JSON.parse(
            this.retrievedData.medicalHistory
          );
          this.drugsHistory = JSON.parse(this.retrievedData.patientDrugsConsumption);
          
          this.medicalArray = JSON.parse(this.retrievedData.medicalHistory);
          this.patientSettingsRetrieve.pastSurgicalHistory = JSON.parse(
            this.retrievedData.surgicalHistory
          );
          this.surgicalArray = JSON.parse(this.retrievedData.surgicalHistory);
          this.patientSettingsRetrieve.medications = JSON.parse(
            this.retrievedData.currentMedications
          );
          this.medicationArray = JSON.parse(
            this.retrievedData.currentMedications
          );
          this.patientSettingsRetrieve.allergies = JSON.parse(
            this.retrievedData.allergies
          );
          this.allergiesRequestArray = JSON.parse(this.retrievedData.allergies);
          this.patientSettingsRetrieve.familyHistory = JSON.parse(
            this.retrievedData.familyHistory
          );
          this.familyArray = JSON.parse(this.retrievedData.familyHistory);
          this.patientSettingsRetrieve.socialHistory = JSON.parse(
            this.retrievedData.socialHistory
          );
          this.socialArray = JSON.parse(this.retrievedData.socialHistory);
          // prefill form data
          this.retrievedData = data["data"];
          this.patientSettings.physician = this.retrievedData.physician;
          this.patientSettings.historian = this.retrievedData.historian;
          this.patientSettings.hospitalCampus = this.retrievedData.hospitalCampus;
          this.patientSettings.arrivalToEdDate = this.retrievedData.arrivalToEdDate;
          this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
          if (this.retrievedData.lastKnownNormalDate === "NaN/NaN/NaN") {
            this.patientSettings.lastKnownNormalDate = "";
          } else {
            this.patientSettings.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          }
          // this.patientSettings.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
          this.patientSettings.bloodPressure = this.retrievedData.bloodPressure;
          this.patientSettings.isPatientOnAntiplateletTherapy = this.retrievedData.isPatientOnAntiplatelet;
          this.patientSettings.isPatientOnAnticoagulationTherapy = this.retrievedData.wasPatientOnAnticoagulation;
          this.patientSettings.weight = this.retrievedData.weight;
          this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
          this.patientSettings.heartRate = this.retrievedData.heartRate;
          this.patientSettings.temp = this.retrievedData.temp;
          this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;
          this.patientSettings.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
          this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
          this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
          this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
          this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
          this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
          this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
          this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
          this.retrieveArrayForReasonForConsult = JSON.parse(
            this.retrievedData["reason"]
          );
          if (this.retrievedData["reason"]!== "" && this.retrievedData["reason"]!== undefined && this.retrievedData["reason"]!== null) {
            for (let reason of this.retrieveArrayForReasonForConsult) {
              if (reason === "Cardiac Arrest") {
                this.reasonForConsult.cardiacArrest = "true";
              }
              if (reason === "Possible Stroke") {
                this.reasonForConsult.possibleStroke = "true";
              }
              if (reason !== "Cardiac Arrest" && reason !== "Possible Stroke") {
                this.reasonForConsult.other = reason;
              }
            }
          }
          if (JSON.parse(this.retrievedData["symptoms"]) !== "") {
            for (let symptom of JSON.parse(this.retrievedData.symptoms)) {
              console.log(symptom);
              if (symptom === "Arm or leg weakness") {
                this.symptoms.armOrLegWeakness = "true";
              }
              if (symptom === "Confusion") {
                this.symptoms.confusion = "true";
              }
              if (symptom === "Dizziness") {
                this.symptoms.dizziness = "true";
              }
              if (symptom === "Facial Droop") {
                this.symptoms.facialDroop = "true";
              }
              if (symptom === "Numbness/Tingling") {
                this.symptoms.numbnessOrTingling = "true";
              }
              if (symptom === "Balance Problems") {
                this.symptoms.balanceProblems = "true";
              }
              if (symptom === "Headache") {
                this.symptoms.headache = "true";
              }
              if (symptom === "Speech Difficulties") {
                this.symptoms.speechDifficulties = "true";
              }
              if (symptom === "Vision Changes") {
                this.symptoms.visionChanges = "true";
              }
              if (symptom === "Right sided Flaccid Weakness") {
                this.symptoms.rightSidedFlaccidWeakness = "true";
              }
              if (symptom === "Left sided Flaccid Weakness") {
                this.symptoms.leftSidedFlaccidWeakness = "true";
              }
              if (
                symptom !== "Arm or leg weakness" &&
                symptom !== "Confusion" &&
                symptom !== "Dizziness" &&
                symptom !== "Facial Droop" &&
                symptom !== "Numbness/Tingling" &&
                symptom !== "Balance Problems" &&
                symptom !== "Headache" &&
                symptom !== "Speech Difficulties" &&
                symptom !== "Vision Changes" &&
                symptom !== "Right sided Flaccid Weakness" &&
                symptom !== "Left sided Flaccid Weakness"
              ) {
                this.symptoms.other = symptom;
              }
            }
          }
          if (JSON.parse(this.retrievedData["medicalHistory"]) !== "") {
            for (let pastMedicalHistoryItem of JSON.parse(
              this.retrievedData.medicalHistory
            )) {
              if (pastMedicalHistoryItem === "Unknown") {
                this.pastMedicalHistory.unknown = "true";
              }
              if (pastMedicalHistoryItem === "None") {
                this.pastMedicalHistory.none = "true";
              }
              if (pastMedicalHistoryItem === "HTN") {
                this.pastMedicalHistory.htn = "true";
              }
              if (pastMedicalHistoryItem === "HLD") {
                this.pastMedicalHistory.hld = "true";
              }
              if (pastMedicalHistoryItem === "Atrial Fibrillation") {
                this.pastMedicalHistory.atrialFibrillation = "true";
              }
              if (pastMedicalHistoryItem === "GI Bleed") {
                this.pastMedicalHistory.giBleed = "true";
              }
              // if (pastMedicalHistoryItem === "Cerebral Aneurysm") {
              //   this.pastMedicalHistory.cerebralAneurysm = "true";
              // }
              if (pastMedicalHistoryItem === "Brain Tumor") {
                this.pastMedicalHistory.brainTumor = "true";
              }
              if (pastMedicalHistoryItem === "MI") {
                this.pastMedicalHistory.mi = "true";
              }
              if (pastMedicalHistoryItem === "DM") {
                this.pastMedicalHistory.dm = "true";
              }
              if (pastMedicalHistoryItem === "Prior Stroke") {
                this.pastMedicalHistory.priorStroke = "true";
              }
              if (pastMedicalHistoryItem === "Dementia") {
                this.pastMedicalHistory.dementia = "true";
              }
              // if (pastMedicalHistoryItem === "CKD") {
              //   this.pastMedicalHistory.ckd = "true";
              // }
              if (pastMedicalHistoryItem === "Congestive Heart Failure") {
                this.pastMedicalHistory.congestiveHeartFailure = "true";
              }
              if (pastMedicalHistoryItem === "Seizure") {
                this.pastMedicalHistory.seizure = "true";
              }
              if (pastMedicalHistoryItem === "Migraine") {
                this.pastMedicalHistory.migraine = "true";
              }
              if (pastMedicalHistoryItem === "Cancer") {
                this.pastMedicalHistory.cancer = "true";
              }
              if (
                pastMedicalHistoryItem !== "Unknown" &&
                pastMedicalHistoryItem !== "None" &&
                pastMedicalHistoryItem !== "HTN" &&
                pastMedicalHistoryItem !== "HLD" &&
                pastMedicalHistoryItem !== "Atrial Fibrillation" &&
                pastMedicalHistoryItem !== "GI Bleed" &&
                pastMedicalHistoryItem !== "Congestive Heart Failure" &&
                pastMedicalHistoryItem !== "MI" &&
                pastMedicalHistoryItem !== "DM" &&
                pastMedicalHistoryItem !== "Prior Stroke" &&
                pastMedicalHistoryItem !== "Dementia" &&
                pastMedicalHistoryItem !== "Brain Tumor" &&
                pastMedicalHistoryItem !== "Seizure" &&
                pastMedicalHistoryItem !== "Migraine" &&
                pastMedicalHistoryItem !== "Cancer"
              ) {
                this.pastMedicalHistory.other = pastMedicalHistoryItem;
              }
            }
          }
          if (JSON.parse(this.retrievedData["surgicalHistory"]) !== "") {
            for (let pastSurgicalHistoryItem of JSON.parse(
              this.retrievedData.surgicalHistory
            )) {
              if (pastSurgicalHistoryItem === "Unknown") {
                this.pastSurgicalHistory.surgicalHistory = "Unknown";
              }
              if (pastSurgicalHistoryItem === "None") {
                this.pastSurgicalHistory.surgicalHistory = "None";
              }
              if (
                pastSurgicalHistoryItem !== "Unknown" &&
                pastSurgicalHistoryItem !== "None"
              ) {
                this.pastSurgicalHistory.other = pastSurgicalHistoryItem;
              }
            }
          }
          if (JSON.parse(this.retrievedData["currentMedications"]) !== "") {
            for (let currentMedicationsItem of JSON.parse(
              this.retrievedData.currentMedications
            )) {
              if (currentMedicationsItem === "Unknown") {
                this.medications.medicationsOptions = "Unknown";
              }
              if (currentMedicationsItem === "NKDA") {
                this.medications.medicationsOptions = "NKDA";
              }
              if (currentMedicationsItem === "Yes") {
                this.medications.medicationsOptions = "Yes";
              }
              if (
                currentMedicationsItem !== "Unknown" &&
                currentMedicationsItem !== "NKDA" &&
                currentMedicationsItem !== "Yes"
              ) {
                this.medications.medicationsCustom = currentMedicationsItem;
              }
            }
          }
          if (JSON.parse(this.retrievedData["allergies"]) !== "") {
            for (let allergiesItem of JSON.parse(
              this.retrievedData.allergies
            )) {
              if (allergiesItem === "Unknown") {
                this.allergies.allergiesOptions = "Unknown";
              }
              if (allergiesItem === "NKDA") {
                this.allergies.allergiesOptions = "NKDA";
              }
              if (allergiesItem === "Yes") {
                this.allergies.allergiesOptions = "Yes";
              }
              if (
                allergiesItem !== "Unknown" &&
                allergiesItem !== "NKDA" &&
                allergiesItem !== "Yes"
              ) {
                this.allergies.allergiesCustom = allergiesItem;
              }
            }
          }
          if (JSON.parse(this.retrievedData["familyHistory"]) !== "") {
            for (let familyHistoryItem of JSON.parse(
              this.retrievedData.familyHistory
            )) {
              console.log("family", familyHistoryItem);
              if (familyHistoryItem === "Unknown") {
                this.familyHistory.unknown = "true";
              }
              if (familyHistoryItem === "None") {
                this.familyHistory.none = "true";
              }
              if (familyHistoryItem === "MI") {
                this.familyHistory.mi = "true";
              }
              if (familyHistoryItem === "Stroke") {
                this.familyHistory.stroke = "true";
              }
              if (familyHistoryItem === "Cancer") {
                this.familyHistory.cancer = "true";
              }
              if (familyHistoryItem === "Seizure") {
                this.familyHistory.seizure = "true";
              }
              if (familyHistoryItem === "HTN") {
                this.familyHistory.htn = "true";
              }
            }
          }
          if (JSON.parse(this.retrievedData["socialHistory"]) !== "") {
            for (let socialHistoryItem of JSON.parse(
              this.retrievedData.socialHistory
            )) {
              console.log("social", socialHistoryItem);
              if (socialHistoryItem === "Unknown") {
                this.socialHistory.unknown = "true";
              }
              if (socialHistoryItem === "None") {
                this.socialHistory.none = "true";
              }
              if (socialHistoryItem === "Alcohol") {
                this.socialHistory.alcohol = "true";
              }
              if (socialHistoryItem === "Drugs") {
                this.socialHistory.drugs = "true";
              }
              if (socialHistoryItem === "Tobacco") {
                this.socialHistory.tobacco = "true";
              }
            }
          }
        }
      });
  }

  gobacktoGeneralInfo() {
    this.patientGeneralInfoDiv = true;
    this.showPatientDetailedDiv = false;
  }

  focusOnSearch() {
    this.PatientDOB = "";
    this.dateFormControl.setValue("");
    this.showingResultForDOB = false;
    this.dateFormControl.clearValidators();
    this.dateFormControl.updateValueAndValidity();
  }
  focusOnDOB() {
    this.dateFormControl.setValidators(Validators.required);
    this.dateFormControl.updateValueAndValidity();
    this.searchValue = "";
    this.showingResultForDOB = true;
  }
  // setValidators() {
  //   if (this.PatientDOB !== '') {
  //     // this.dateFormControl.setValidators(Validators.required);
  //     // this.dateFormControl.updateValueAndValidity();
  //     this.dateFormControl.clearValidators();
  //   this.dateFormControl.updateValueAndValidity();
  //   } else {
  //      this.dateFormControl.setValidators(Validators.required);
  //     this.dateFormControl.updateValueAndValidity();
  //   }
  // }
  clearValidators() {
    this.dateFormControl.clearValidators();
    this.dateFormControl.updateValueAndValidity();
  }
  SearchPatients() {
    this.showDataNotFound = false;
    this.isValidSearch = false;
    if (this.showingResultForDOB) {
      this.searchCategory = "dob";
    } else {
      if (isNaN(this.searchValue)) {
        this.searchCategory = "name";
      } else {
        this.searchCategory = "mrn";
      }
    }
    this.resultDiv = true;
    // this.isValidSearch = false;
    let params;
    this.resultList = [];
    this.showSearchResult = false;
    if (this.searchCategory === "name" && this.searchValue !== "") {
      this.isValidSearch = true;
      this.completeDate = "";
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("name", this.searchValue)
        .set("hospital_id", "")
        .set("type", "TeleStrokeDoctor");
    }

    if (this.searchCategory === "mrn" && this.searchValue !== "") {
      this.isValidSearch = true;
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("mrn", this.searchValue)
        .set("hospital_id", "")
        .set("type", "TeleStrokeDoctor");
    } else if (this.searchCategory === "dob" && !this.dateFormControl.invalid) {
      // if (this.PatientDOB.length === 10 && this.firstNameFormControl.valid) {
      let d = new Date(this.PatientDOB);
      console.log(d.getDate());
      console.log(d.getMonth() + 1);
      console.log(d.getFullYear());
      this.date = d.getDate();
      this.month = d.getMonth() + 1;
      this.year = d.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      this.completeDate = this.month + "/" + this.date + "/" + this.year;
      // Check for Valid date
      if (!isNaN(this.date) && this.completeDate !== "01/01/1970") {
        this.isValidSearch = true;
      }
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("dob", this.completeDate)
        .set("hospital_id", "")
        .set("type", "TeleStrokeDoctor");

      // }
    }
    // this.loader.set(60);
    if (this.isValidSearch) {
      this.showResults = this.searchValue;
      this.loader.start();
      this.loader.set(10);
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/PatientSearch", params, {
          headers: headers,
        })
        .subscribe(
          (response) => {
            if (response["Message"] === "Success") {
              this.loader.complete();
              this.showSearchResult = true;
              this.showResults = this.searchValue;
              console.log(response["Data"]);
              this.resultList = response["Data"];
              // this.resultMaleList = response['Data'].filter(user => user.gender === 'Male'); // store only male data
              // this.resultFemaleList = response['Data'].filter(user => user.gender === 'Female'); // store only Female data
            } else {
              this.loader.complete();
              this.showDataNotFound = true;
            }
          },
          (err) => {
            this.loader.complete();
            console.log(err);
          }
        );
    }
  }

  gotoPatientResultDiv() {
    this.showPatientRecordDiv = false;
    this.showPatientResultDiv = true;
    this.showPatientDetailedDiv = false;
    this.patientGeneralInfoDiv = true;
    // this.markConsultNoteFieldsAsPristine();
  }
  displayDetailDiv() {
    this.showPatientGeneralInfo = false;
    this.showPatientDetailedDiv = true;
  }
  gobacktoGeneralInfoUI(name, mrn, gender, dob) {
    this.hideUpperUI = true;
    this.showPatientDetailedDiv = false;
    this.patientGeneralInfoDiv = true;
    this.editPatientInfoDiv = false;
    this.showPatientInfoDiv = true;
    this.showSelectedRecordDetails(name, mrn, gender, dob);
  }
  switchinfoDiv() {
    this.showPatientInfoDiv = false;
    this.editPatientInfoDiv = true;
    this.setDisabledOnInit();
  }
  showinfoListDiv() {
    this.editPatientInfoDiv = false;
    this.showPatientInfoDiv = true;
    // this.markConsultNoteFieldsAsPristine();
  }
  markConsultNoteFieldsAsPristine() {
    this.cthcontrol.markAsPristine();
    this.mriBraincontrol.markAsPristine();
    this.ctaHeadAndNeckcontrol.markAsPristine();
    this.eegcontrol.markAsPristine();
    this.mraHeadAndNeckcontrol.markAsPristine();
    this.lpcontrol.markAsPristine();
    this.giveTpaDoNotGiveTpaFormControl.markAsPristine();
    this.considerOrDoNotConsiderEmbolectomyFormControl.markAsPristine();
    this.iagreetncControl.markAsPristine();
    this.chiefComplaintControl.markAsPristine();
    this.historyOfIllnessControl.markAsPristine();
    this.ctInterpretationControl.markAsPristine();
    this.ctaInterpretationControl.markAsPristine();
    this.reviewOfSystemsControl.markAsPristine();
    this.physicalExamControl.markAsPristine();
    this.asessmentControl.markAsPristine();
    this.levelOfConsciousControl.markAsPristine();
    this.responseToLocQueControl.markAsPristine();
    this.responseToLocCommControl.markAsPristine();
    this.bestGazeControl.markAsPristine();
    this.visualControl.markAsPristine();
    this.facialPalsyControl.markAsPristine();
    this.motorLeftArmControl.markAsPristine();
    this.motorRightArmControl.markAsPristine();
    this.motorLeftLegControl.markAsPristine();
    this.motorRightLegControl.markAsPristine();
    this.limbAtaxiaControl.markAsPristine();
    this.sensoryControl.markAsPristine();
    this.bestLanguageControl.markAsPristine();
    this.dysarthriaControl.markAsPristine();
    this.extinctionControl.markAsPristine();
    this.totalDoseControl.markAsPristine();
    this.bolusDoseControl.markAsPristine();
    this.infusionDoseControl.markAsPristine();
    this.timeToGiveTpaControl.markAsPristine();
    this.agreeControl.markAsPristine();
    this.timeOfArrivalToEdControl.markAsPristine();
    this.timeOfLastKnownNormalControl.markAsPristine();
    this.timeForConsultRequestControl.markAsPristine();
    this.timePatientAtCtScanControl.markAsPristine();
    this.timePatientReadyForVideoControl.markAsPristine();
    this.timeProviderOnVideoControl.markAsPristine();
    this.timeOfCtReadControl.markAsPristine();
    this.timeOfCtaControl.markAsPristine();
    this.timeOfCtaReadControl.markAsPristine();
    this.timeOfDecisionControl.markAsPristine();
    this.timeOfTpaBolusControl.markAsPristine();
    this.reasonnotEmbolectomy.markAsPristine();
    this.reasonnotTPA.markAsPristine();
    this.ctaInterpretationhemorrhageControl.markAsPristine();
    this.cthHemorrhageControl.markAsPristine();
  }
  radioChange(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === "male") {
      this.showSearchResult = false;
      this.showMalePatientList = true;
      this.showFemalePatientList = false;
    }
    if (event.value === "female") {
      this.showSearchResult = false;
      this.showMalePatientList = false;
      this.showFemalePatientList = true;
    }
    if (event.value === "all") {
      this.showMalePatientList = false;
      this.showFemalePatientList = false;
      this.showSearchResult = true;
    }
  }

  saveNotes() {
    if (this.showErrorForCheckbox) {
      this.inputEl.focus();
      var elmnt = document.getElementById("editTPARecomDiv");
      elmnt.scrollIntoView();
      if (this.xpandStatus === false) {
        this.xpandStatus = true;
      }
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox("");
    } else {
      this.markConsultNoteFieldsAsPristine();
      var giveOrDoNotGiveTpaObject;
      var giveTpaStatus;
      var doNotGiveTpaStatus;
      var considerOrDoNotConsiderEmbolectomyStatus;
      var considerOrDoNotConsiderEmbolectomyObject;
      if (this.giveOrDoNotGiveTpa === "Give TPA") {
        var doNotGiveTPAOBj = {};
        giveOrDoNotGiveTpaObject = this.giveTpa;
        giveTpaStatus = true;
        doNotGiveTpaStatus = false;
      } else {
        // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj["doNotGiveTpaSelectedList"] = this.doNotGiveTpaObj[
          "doNotGiveTpaSelectedList"
        ];
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
        // } else {
        //   doNotGiveTpaStatus = false;
        //   giveTpaStatus = false;
        //   giveOrDoNotGiveTpaObject = {};
        // }
      }
      if (this.considerOrDoNotConsiderEmbolectomy === "Consider Embolectomy") {
        var considerOrNotEmbolectomyObj = {};
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
        considerOrDoNotConsiderEmbolectomyStatus = true;
      } else {
        // if (this.considerOrDoNotConsiderEmbolectomy === 'Do not consider Embolectomy') {
        var considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject[
          "considerOrNotSelectedList"
        ] = this.embolectomyObjectForDoNotGiveTpa["considerOrNotSelectedList"];
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
        // } else {
        //   considerOrDoNotConsiderEmbolectomyStatus = '';
        //   considerOrDoNotConsiderEmbolectomyObject = {};
        // }
      }
      this.loader.start();
      this.loader.set(10);
      const params = new HttpParams()
        .set("complaint", this.consultNotes.chiefComplaint) // new
        .set("presenting_illness", this.consultNotes.historyOfIllness) // new
        .set("reviewOfSystems", this.consultNotes.reviewOfSystems) // new
        .set("vitalSigns", this.consultNotes.vitalSigns) // new
        .set("giveTPA", giveTpaStatus) // new
        .set("notes", this.consultNotes.notes) // new
        .set("doNotgiveTPA", doNotGiveTpaStatus) // new
        .set("nihss", JSON.stringify(this.nihss)) // new
        .set("imaging", this.consultNotes.imaging) // new
        .set("assessment", this.consultNotes.assessment)
        .set("physical_exam", this.consultNotes.physicalExam)
        .set("recommendations", JSON.stringify(giveOrDoNotGiveTpaObject))
        .set("userId", this.receivedUserID)
        .set("doctorId", this.loggedInUser)
        .set("patient", this.patientObj.mrn)
        .set("speciality_id", "123")
        .set("isComplete", "false")
        .set("requested_call", this.receivedCallID)
        .set(
          "considerOrDoNotConsiderEmbolectomy",
          considerOrDoNotConsiderEmbolectomyStatus
        )
        .set(
          "thermobectomyRecommendation",
          JSON.stringify(considerOrDoNotConsiderEmbolectomyObject)
        )
        .set("telestrokeDocumentation", JSON.stringify(this.timePoints))
        .set("additionalWorkup", JSON.stringify(this.addWorkup))
        .set("CTInterpretation", this.ctInterpretation)
        .set("CTAInterpretation", this.ctaInterpretation)
        .set("IsHemorrhage", String(this.isHemorrhage)) /*Pradeep Changes*/
        .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
        .set("hemorrhage", JSON.stringify(this.hemorrhage))
        .set("offset", JSON.stringify(this.offset)) /*Pradeep Changes*/
        .set("fullName",this.loggedUser)
        .set("iagreetnc", String(this.iagreetnc));
      // this.showLoader = true; // show loader
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/ConsultNoteDetails", params, {
          headers: headers,
        })
        .subscribe(
          (data) => {
            console.log("save notes", data);
            // this.showLoader = false; // hide loader
            if (data["Message"] === "Success") {
              this.loader.complete();
              this.openSuccessDialog("Consult note saved successfully.");
              this.getConsultNotesFromDoctor(this.receivedCallID);
              this.showPatientInfoDiv = true;
              this.editPatientInfoDiv = false;
              // this.retrievedconsultNotes = this.consultNotes;
            } else {
              this.loader.complete();
              this.openErrorDialog("Some error occurred, please try again.");
            }
          },
          (err) => {
            this.loader.complete();
            this.openErrorDialog("Some error occurred, please try again.");
          }
        );
    }
  }
// pradeep changes
calculateAspectScoreTotal() {
  this.nonHemorrhage.TotalAspectScore = 0;
  this.nonHemorrhage.TotalAspectScore = this.nonHemorrhage.TotalAspectScore +=
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Caudate) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Caudate) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) ? 0 : this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Insula) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Insula) +
    Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ? 0 : this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ;
}

selectSideChange(selectType: any){

  if(selectType==='Left'){
   this.nonHemorrhage.CtaInterpretation.SelectSide="Left";
  }

  if(selectType ==='Right'){
    this.nonHemorrhage.CtaInterpretation.SelectSide="Right";
   }

   if(selectType==='Unknown'){
    this.nonHemorrhage.CtaInterpretation.SelectSide="Unknown";
   }

   if(selectType==='aspectdoesnotApply'){
    this.nonHemorrhage.CtaInterpretation.SelectSide="Aspect Score doesnot Apply";
   }

}
hemorrhageOrNonHemmorhageChange(value){
  
  if(value == 'hemorrhage'){
    this.hemorrhageSection = true;
    this.nonHemorrhageSection = false;
    this.isHemorrhage=true;
    this.hemorrhageOrNonHemorrhage="hemorrhage";
  }
  if(value == 'nonHemorrhage'){
    // this.nonHemorrhage.ctaInterpretation.SelectSide="Left";
    this.nonHemorrhageSection = true;
    this.hemorrhageSection = false;
    this.isHemorrhage=false;
    this.hemorrhageOrNonHemorrhage="nonHemorrhage"
  }
}
updateSelectType(selectType: String, event : any){
  if(selectType ==='Occlusion') {   
    this.nonHemorrhage.CtaInterpretation.SelectType.Occlusion= !event.source.value;
  }
  if(selectType ==='Dissection') {
    this.nonHemorrhage.CtaInterpretation.SelectType.Dissection = !event.source.value
  }
  if(selectType ==='CervicalIcaMildStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaMildStenosis = !event.source.value;
  }
  if(selectType ==='CervicalIcaModerateStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaModerateStenosis = !event.source.value;
  }
  if(selectType ==='CervicalIcaSevereStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaSevereStenosis = !event.source.value;
  }
  if(selectType ==='IntracranialIca') {   
    this.nonHemorrhage.CtaInterpretation.SelectType.IntracranialIca = !event.source.value;
  }
  if(selectType ==='CervicalVertebralOcclusion') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralOcclusion  = !event.source.value
  }
  if(selectType ==='CervicalVertebralArteryStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryStenosis = !event.source.value;
  }
  if(selectType ==='CervicalVertebralArteryDissection') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryDissection = !event.source.value;
  }
  if(selectType ==='PcaOcclusion') {
    this.nonHemorrhage.CtaInterpretation.SelectType.PcaOcclusion  = !event.source.value;
  }
  if(selectType ==='PcaStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.PcaStenosis  = !event.source.value;
  }
  if(selectType ==='CervicalIntracranialArteryStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIntracranialArteryStenosis = !event.source.value;
  }
  if(selectType ==='BasiliarArteryStenosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.BasiliarArteryStenosis = !event.source.value;
  }
  if(selectType ==='DiffuseIntracranialAtherosclerosis') {
    this.nonHemorrhage.CtaInterpretation.SelectType.DiffuseIntracranialAtherosclerosis  = !event.source.value;
  }
}
  signAndRelease() {
    if (this.showErrorForCheckbox) {
      this.inputEl.focus();
      var elmnt = document.getElementById("editTPARecomDiv");
      elmnt.scrollIntoView();
      this.xpandStatus = true;
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox("");
    } else {
      this.markConsultNoteFieldsAsPristine();
      var giveOrDoNotGiveTpaObject;
      var giveTpaStatus;
      var doNotGiveTpaStatus;
      var considerOrDoNotConsiderEmbolectomyStatus;
      var considerOrDoNotConsiderEmbolectomyObject;
      if (this.giveOrDoNotGiveTpa === "Give TPA") {
        var doNotGiveTPAOBj = {};
        giveOrDoNotGiveTpaObject = this.giveTpa;
        giveTpaStatus = true;
        doNotGiveTpaStatus = false;
      } else {
        // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj["doNotGiveTpaSelectedList"] = this.doNotGiveTpaObj[
          "doNotGiveTpaSelectedList"
        ];
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
        // } else {
        //   doNotGiveTpaStatus = false;
        //   giveTpaStatus = false;
        //   giveOrDoNotGiveTpaObject = {};
        // }
      }
      if (this.considerOrDoNotConsiderEmbolectomy === "Consider Embolectomy") {
        var considerOrNotEmbolectomyObj = {};
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
        considerOrDoNotConsiderEmbolectomyStatus = true;
      } else {
        // if (this.considerOrDoNotConsiderEmbolectomy === 'Do Not Consider Embolectomy') {
        var considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject[
          "considerOrNotSelectedList"
        ] = this.embolectomyObjectForDoNotGiveTpa["considerOrNotSelectedList"];
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
        // } else {
        //   considerOrDoNotConsiderEmbolectomyStatus = '';
        //   considerOrDoNotConsiderEmbolectomyObject = {};
        // }
      }
      this.loader.start();
      this.loader.set(10);
      const params = new HttpParams()
        .set("complaint", this.consultNotes.chiefComplaint) // new
        .set("presenting_illness", this.consultNotes.historyOfIllness) // new
        .set("reviewOfSystems", this.consultNotes.reviewOfSystems) // new
        .set("vitalSigns", this.consultNotes.vitalSigns) // new
        .set("giveTPA", giveTpaStatus) // new
        .set("notes", this.consultNotes.notes) // new
        .set("doNotgiveTPA", doNotGiveTpaStatus) // new
        .set("nihss", JSON.stringify(this.nihss)) // new
        .set("imaging", this.consultNotes.imaging) // new
        .set("assessment", this.consultNotes.assessment)
        .set("physical_exam", this.consultNotes.physicalExam)
        .set("recommendations", JSON.stringify(giveOrDoNotGiveTpaObject))
        .set("userId", this.receivedUserID)
        .set("doctorId", this.loggedInUser)
        .set("patient", this.patientObj.mrn)
        .set("speciality_id", "123")
        .set("isComplete", "true")
        .set("requested_call", this.receivedCallID)
        .set(
          "considerOrDoNotConsiderEmbolectomy",
          considerOrDoNotConsiderEmbolectomyStatus
        )
        .set(
          "thermobectomyRecommendation",
          JSON.stringify(considerOrDoNotConsiderEmbolectomyObject)
        )
        .set("telestrokeDocumentation", JSON.stringify(this.timePoints))
        .set("additionalWorkup", JSON.stringify(this.addWorkup))
        .set("CTInterpretation", this.ctInterpretation)
        .set("CTAInterpretation", this.ctaInterpretation)
        .set("IsHemorrhage", String(this.isHemorrhage)) /*Pradeep Changes*/
        .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
        .set("hemorrhage", JSON.stringify(this.hemorrhage))
        .set("offset", JSON.stringify(this.offset)) /*Pradeep Changes*/
        .set("fullName",this.loggedUser)
        .set("iagreetnc", String(this.iagreetnc));
      // this.showLoader = true; // show loader

      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/ConsultNoteDetails", params, {
          headers: headers,
        })
        .subscribe(
          (data) => {
            console.log("sign and release data", data);
            // this.showLoader = false; // hide loader
            if (data["Message"] === "Success") {
              this.loader.complete();
              this.openSuccessDialog(
                "Consult note signed & released successfully."
              );
              this.getConsultNotesFromDoctor(this.receivedCallID);
              this.showPatientInfoDiv = true;
              this.editPatientInfoDiv = false;
            } else {
              this.loader.complete();
              this.openErrorDialog("Some error occurred, please try again.");
            }
          },
          (err) => {
            this.loader.complete();
            this.openErrorDialog("Some error occurred, please try again.");
          }
        );
    }
  }

  setFormDetails() {
    // add array methods ----IMPORTANT
    // this.createSymptomsArray();
    // this.createPastMedicalHistoryArray();
    // this.createPastSurgicalHistoryArray();
    // this.createAllergiesArray();
    // this.createMedicationsArray();
    // this.createFamilHistoryArray();
    // this.createSocialHistoryArray();
    // this.createReasonForConsultArray();
    var headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams()
      .set("nurse", this.patientSettings.nurse) //
      .set("physician", this.patientSettings.physician) //
      .set("historian", this.patientSettings.historian) //
      .set("hospitalCampus", this.patientSettings.hospitalCampus)
      .set("reason", JSON.stringify(this.reasonForConsultArray)) // array
      .set("symptoms", JSON.stringify(this.symptomsArray)) // array //
      .set("bloodPressure", this.patientSettings.bloodPressure) //
      .set("weight", this.patientSettings.weight) //
      .set("oxygenSat", this.patientSettings.oxygenSat) //
      .set("heartRate", this.patientSettings.heartRate) //
      .set("temp", this.patientSettings.temp) //
      .set("arrivalToEdDate", this.patientSettings.arrivalToEdDate)
      .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
      .set("lastKnownNormalDate", this.patientSettings.lastKnownNormalDate)
      .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
      .set(
        "tpaEligibilityUnknown",
        String(this.patientSettings.tpaEligibilityUnknown)
      )
      .set("isFamilyBedside", this.patientSettings.familyAtBedside) // yes no not boolean
      .set("miInPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
      .set(
        "strokeInPrevious3Months",
        this.patientSettings.strokeInPrevious3Months
      )
      .set("isICHHistory", this.patientSettings.historyOfIch) //
      .set("tookCEPX", this.patientSettings.tookCEPX)
      .set("isNeoplasm", this.patientSettings.historyOfNeoplasm) //
      .set("isIntracranial", this.patientSettings.isIntracranial)
      .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray)) // array
      .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray)) // array
      .set("allergies", JSON.stringify(this.allergiesArray)) // array
      .set("currentMedications", JSON.stringify(this.medicationsArray)) // array
      .set("socialHistory", JSON.stringify(this.socialHistoryArray)) // array
      .set("familyHistory", JSON.stringify(this.familyHistoryArray)) // array
      .set("videoToken", "sdfwef")
      .set("videoSessionId", "fef")
      .set("patientId", this.patientObj.mrn)
      .set("userId", this.receivedUserID);
    this.http
      .post(this.authService.baseURL + "/PlaceCallRequest", params, {
        headers: headers,
      })
      .subscribe((data) => {
        console.log("register", data);
        alert("Changes Saved");
      });
  }

  openErrorDialog(msg) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      height: "72px",
      data: msg,
    });
    setTimeout(() => dialogRef.close(), 1000);
  }

  openSuccessDialog(msg) {
    const dialogRef = this.successDialog.open(SuccessDialogComponent, {
      height: "72px",
      data: msg,
    });
    setTimeout(() => dialogRef.close(), 1000);
  }
  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.focusOnDOB();
  }

  getConsultNotesFromDoctor(callId) {
    var headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const param = new HttpParams().set("callId", callId);
    this.http
      .post(this.authService.baseURL + "/GetConsultNoteForDoctor", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            // this.editPatientInfoDiv = false;
            // this.showPatientInfoDiv = true;
            console.log("");
            this.receivedSpecificConsultNotes = response["data"];
            console.log(this.receivedSpecificConsultNotes);
            // this.receivedSpecificConsultNotes = this.receivedSpecificConsultNotes[0];
            console.log(this.receivedSpecificConsultNotes[0]);
            console.log(this.receivedSpecificConsultNotes[0].doctorId);
            console.log(this.receivedSpecificConsultNotes.doctorId);
            // if (this.receivedSpecificConsultNotes[0].doctorId === this.loggedInUser) {
            console.log(
              "hello im doctor",
              this.receivedSpecificConsultNotes[0].doctorId
            );
            this.showConsultNotes = true;
            this.editPatientInfoDiv = false;
            this.showPatientInfoDiv = true;
            if (
              this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "recommendationsNew"
              ) &&
              this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "thermobectomyRecommendation"
              ) &&
              this.receivedSpecificConsultNotes[0].hasOwnProperty("nihss") &&
              this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "telestrokeDocumentation"
              ) &&
              this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "additionalWorkup"
              )&&this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "IsHemorrhage"
              )&&this.receivedSpecificConsultNotes[0].hasOwnProperty(
                "hemorrhageOrNonHemorrhage"
              )
            ) {
              // alert('not null' + this.receivedSpecificConsultNotes[0].recommendationsNew);
              this.consultNoteDetails(
                this.receivedSpecificConsultNotes[0].complaint,
                this.receivedSpecificConsultNotes[0].presenting_illness,
                this.receivedSpecificConsultNotes[0].reviewOfSystems,
                this.receivedSpecificConsultNotes[0].vitalSigns,
                this.receivedSpecificConsultNotes[0].giveTPA,
                this.receivedSpecificConsultNotes[0].notes,
                this.receivedSpecificConsultNotes[0].doNotgiveTPA,
                this.receivedSpecificConsultNotes[0].nihss,
                this.receivedSpecificConsultNotes[0].imaging,
                this.receivedSpecificConsultNotes[0].assessment,
                this.receivedSpecificConsultNotes[0].physical_exam,
                this.receivedSpecificConsultNotes[0].recommendationsNew,
                this.receivedSpecificConsultNotes[0]
                  .thermobectomyRecommendation,
                this.receivedSpecificConsultNotes[0].userId,
                this.receivedSpecificConsultNotes[0].doctorId,
                this.receivedSpecificConsultNotes[0].patient,
                this.receivedSpecificConsultNotes[0].speciality_id,
                this.receivedSpecificConsultNotes[0].isComplete,
                this.receivedSpecificConsultNotes[0].requested_call,
                this.receivedSpecificConsultNotes[0].timestamp,
                this.receivedSpecificConsultNotes[0]
                  .considerOrDoNotConsiderEmbolectomy,
                this.receivedSpecificConsultNotes[0].downloadUrl,
                this.receivedSpecificConsultNotes[0].telestrokeDocumentation,
                this.receivedSpecificConsultNotes[0].additionalWorkup,
                this.receivedSpecificConsultNotes[0].CTInterpretation,
                this.receivedSpecificConsultNotes[0].CTAInterpretation,
                this.receivedSpecificConsultNotes[0].iagreetnc,
                this.receivedSpecificConsultNotes[0].IsHemorrhage,
                this.receivedSpecificConsultNotes[0].hemorrhageOrNonHemorrhage
              );
            } else {
              // alert('null');
              this.consultNoteDetailsOld(
                this.receivedSpecificConsultNotes[0].complaint,
                this.receivedSpecificConsultNotes[0].presenting_illness,
                this.receivedSpecificConsultNotes[0].reviewOfSystems,
                this.receivedSpecificConsultNotes[0].vitalSigns,
                this.receivedSpecificConsultNotes[0].giveTPA,
                this.receivedSpecificConsultNotes[0].notes,
                this.receivedSpecificConsultNotes[0].doNotgiveTPA,
                this.receivedSpecificConsultNotes[0].imaging,
                this.receivedSpecificConsultNotes[0].assessment,
                this.receivedSpecificConsultNotes[0].physical_exam,
                this.receivedSpecificConsultNotes[0].userId,
                this.receivedSpecificConsultNotes[0].doctorId,
                this.receivedSpecificConsultNotes[0].patient,
                this.receivedSpecificConsultNotes[0].speciality_id,
                this.receivedSpecificConsultNotes[0].isComplete,
                this.receivedSpecificConsultNotes[0].requested_call,
                this.receivedSpecificConsultNotes[0].timestamp,
                this.receivedSpecificConsultNotes[0]
                  .considerOrDoNotConsiderEmbolectomy,
                this.receivedSpecificConsultNotes[0].downloadUrl
              );
            }
            // }
          } else {
            this.receivedSpecificConsultNotes = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
//function to get stringDate 
  getStringDate(date:Date):String{
    let d = new Date(date);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    if (String(this.date).length === 1) {
      this.date = "0" + this.date;
    }
    if (String(this.month).length === 1) {
      this.month = "0" + this.month;
    }
    var stringDate  =this.month + "/" + this.date + "/" + this.year;
    return stringDate;
  }
  // ***************************** New field addition functionalities **********************

  recommendationChangeEvent(value) {
    if (value === "Give TPA") {
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      // this.reasonnotTPA.setValue('');
    }
    if (value === "Do Not Give TPA") {
      this.showDontTPAdiv = true;
      this.showTPAdiv = false;
    }
  }
  emborecommendationChange(value) {
    if (value === "Consider Embolectomy") {
      this.showDontEmbolectomyDiv = false;
    }
    if (value === "Do Not Consider Embolectomy") {
      this.showDontEmbolectomyDiv = true;
    }
  }
  calculateTotal(event, selectedValue) {
    // if (String(selectedValue).charAt(0) === 'C') {
    //   this.nihss.totalScore += Number(String(selectedValue).charAt(1)) - 1;
    // } else {
    //   this.nihss.totalScore += Number(selectedValue);
    // }
    this.nihss.totalScore = 0;
    if (this.nihss.levelOfConsiousness === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocQue === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocComm === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestGaze === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.visual === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.facialPalsy === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftArm === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightArm === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftLeg === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightLeg === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.limbAtaxia === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.sensory === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestLanguage === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.dysarthria === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.extinctionAndInattention === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    this.nihss.totalScore = this.nihss.totalScore +=
      Number(this.nihss.levelOfConsiousness) +
      Number(this.nihss.responseToLocQue) +
      Number(this.nihss.responseToLocComm) +
      Number(this.nihss.bestGaze) +
      Number(this.nihss.visual) +
      Number(this.nihss.facialPalsy) +
      Number(this.nihss.motorLeftArm) +
      Number(this.nihss.motorRightArm) +
      Number(this.nihss.motorLeftLeg) +
      Number(this.nihss.motorRightLeg) +
      Number(this.nihss.limbAtaxia) +
      Number(this.nihss.sensory) +
      Number(this.nihss.bestLanguage) +
      Number(this.nihss.dysarthria) +
      Number(this.nihss.extinctionAndInattention);
  }

  // Handle checked/unchecked trigger of CTH
  editTriggerCTHfield(event) {
    let isCTHChecked = event.checked;

    if (isCTHChecked) {
      this.cthValuecontrol.enable();
      this.addWorkup.cth=true;
    } else {
      this.cthValuecontrol.setValue("");
      this.cthValuecontrol.disable();
    }
  }
  // Handle checked/unchecked trigger of MRI checkbox
  editTriggerMRIfield(event) {
    let isMRIChecked = event.checked;
    if (isMRIChecked) {
      this.mriBrainValuecontrol.enable();
      this.addWorkup.mriBrain=true;
    } else {
      this.mriBrainValuecontrol.setValue("");
      this.mriBrainValuecontrol.disable();
    }
  }
  // Handle checked/unchecked trigger of CTA checkbox
  editTriggerCTAfield(event) {
    let isCTAChecked = event.checked;
    if (isCTAChecked) {
      // this.editAddionalWorkupForm.controls['ctaHeadAndNeckValuecontrol'].enable();
      this.ctaHeadAndNeckValuecontrol.enable();
      this.addWorkup.ctaHeadAndNeck=true;
    } else {
      this.ctaHeadAndNeckValuecontrol.setValue("");
      this.ctaHeadAndNeckValuecontrol.disable();
    }
  }
  //Handle checked/unckecked trigger of CT interpretation
  editTriggerCTfield(event) {
    if (event.checked) {
      this.ctInterpretationValue.enable();
      this.nonHemorrhage.CTInterpretation=true;
    } else {
      this.ctInterpretationValue.setValue('');
      this.ctInterpretationValue.disable();
      this.nonHemorrhage.CTInterpretation=false;
    }
  }

  //Handle checked/unckecked trigger of CTA interpretation
  editTriggerCTANonHemorrhagefield(event) {
    if (event.checked) {
      this.ctaInterpretationValue.enable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation=true;
    } else {
      this.ctaInterpretationValue.setValue('');
      this.ctaInterpretationValue.disable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation=false;
    }
  }



  //Handle checked/unchecked trigger for CTH Hemorrhage Checkbox
  editTriggerCTHhemorrhagefield(event) {
    if (event.checked) {
      this.cthHemorrhageValue.enable();
      this.hemorrhage.CTH=true;
    } else {
      this.cthHemorrhageValue.setValue('');
      this.cthHemorrhageValue.disable();
      this.hemorrhage.CTH=false;
    }
  }

  // Handle checked/unchecked trigger of EEG checkbox
  editTriggerEEGfield(event) {
    let isEEGChecked = event.checked;
    if (isEEGChecked) {
      this.eegValuecontrol.enable();
      this.addWorkup.eeg=true;
    } else {
      this.eegValuecontrol.setValue("");
      this.eegValuecontrol.disable();
    }
  }
  // Handle checked/unchecked trigger for MRA checkbox
  editTriggerMRAfield(event) {
    let isMRAChecked = event.checked;
    if (isMRAChecked) {
      this.mraHeadAndNeckValuecontrol.enable();
      this.addWorkup.mraHeadAndNeck=true;
    } else {
      this.mraHeadAndNeckValuecontrol.setValue("");
      this.mraHeadAndNeckValuecontrol.disable();
    }
  }
  triggerlpfield(event) {
    let islpChecked = event.checked;
    if (islpChecked) {
      this.lpValuecontrol.enable();
      this.addWorkup.lp=true;
    } else {
      this.lpValuecontrol.setValue("");
      this.lpValuecontrol.disable();
    }
  }
  setDisabledOnInit() {
    if (!this.addWorkup.cth) {
      this.cthValuecontrol.setValue("");
      this.cthValuecontrol.disable();
    }
    if (!this.addWorkup.mriBrain) {
      this.mriBrainValuecontrol.setValue("");
      this.mriBrainValuecontrol.disable();
    }
    if (!this.addWorkup.ctaHeadAndNeck) {
      this.ctaHeadAndNeckValuecontrol.disable();
    }
    if (!this.addWorkup.eeg) {
      this.eegValuecontrol.setValue("");
      this.eegValuecontrol.disable();
    }
    if (!this.addWorkup.mraHeadAndNeck) {
      this.mraHeadAndNeckValuecontrol.setValue("");
      this.mraHeadAndNeckValuecontrol.disable();
    }
    if (!this.addWorkup.lp) {
      this.lpValuecontrol.setValue("");
      this.lpValuecontrol.disable();
    }
  }
  openDialog() {
    this.opendialogForProfile("teleConsent");
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent, {
      height: "480px",
      data: message,
    });
  }
  validateCheckbox(e) {
    if (this.giveOrDoNotGiveTpa === "Give TPA") {
      if (!this.giveTpa.agree) {
        this.showErrorForCheckbox = true;
      }
    } else {
      this.showErrorForCheckbox = false;
    }
  }
  removeOrSetError(e) {
    if (e.checked) {
      this.showErrorForCheckbox = false;
    } else {
      this.showErrorForCheckbox = true;
    }
  }
  updateHemorrhageType(event : any, hemmorhageType: String) {
    console.log('event.source.checked',event.source.checked);
    if(hemmorhageType === 'IntraparenchymalHemorrhage') {   
      this.hemorrhage.HemorrhageType.IntraparenchymalHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'SubarachnoidHemorrhage') {
      this.hemorrhage.HemorrhageType.SubarachnoidHemorrhage = event.source.checked
    }
    if(hemmorhageType === 'EpiduralHemorrhage') {
      this.hemorrhage.HemorrhageType.EpiduralHemorrhage = !!event.source.checked;
    }
    if(hemmorhageType === 'SubduralHemorrhage') {
      this.hemorrhage.HemorrhageType.SubduralHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'IntraventricularHemorrhage') {
      this.hemorrhage.HemorrhageType.IntraventricularHemorrhage = event.source.checked;
    }
  }

  updateAspectScoreDoesNotApply(aspectScoreDoesNotApply: String, event : any){
    if(aspectScoreDoesNotApply ==='SuspectPosteriorCirculationStroke') {   
      this.nonHemorrhage.AspectScoreDoesNotApply.SuspectPosteriorCirculationStroke = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MetatasticBrainDisease') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MetatasticBrainDisease = event.checked;
    }
    if(aspectScoreDoesNotApply ==='PrimaryCNSNeoplasm') {
      this.nonHemorrhage.AspectScoreDoesNotApply.PrimaryCNSNeoplasm = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Seizure') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Seizure = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MigraineWithAura') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MigraineWithAura = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ConversionDisorder') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ConversionDisorder = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ToxicMetabolicEncephalopathy') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ToxicMetabolicEncephalopathy = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Other') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Other = event.checked;
    }
  }
}
