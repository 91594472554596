import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as OT from '@opentok/client';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})

export class SubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;
 subscriber;
  constructor(public authService: AuthService) { 
      console.log("subscriber disconnected",this.subscriber);
  }

ngAfterViewInit() {
    this.subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, {}, (err) => {
     if (err) {
        alert("subscriber disconected"+err.message);
        //this.authService.setFalse();
     }
      else{

        //this.authService.setTrue();
      }
    });
  }
}
