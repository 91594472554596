import { Component, OnInit , ChangeDetectorRef, ViewChildren, ANALYZE_FOR_ENTRY_COMPONENTS} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
// import { truncate } from 'fs';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // firebase variables
  // items: Observable<any[]>;
items: any = [];
callDetails: any = [];
public loggedInUser;
public loggedUser;
public newItems;
notificationObj = {callId : '' , nurseName: ''};
showNotificationAlert = false;
disableNotificationAlert: Boolean = true;
status;
availabilityStatus: Boolean = false;
setToggleStatus;
temp;
profilePicUrl;
receivedCallIdFromQuery;
callIdForVideoAction;
emergencyCallList: any = [];
onlyinitiatedList: any = [];
public fullName;

  constructor(public authService: AuthService, private _firebaseAuth: AngularFireAuth,
    private router: Router, private ref: ChangeDetectorRef,  private route: ActivatedRoute,
    private http: HttpClient, public openDialogForEndCall: MatDialog, public db: AngularFireDatabase) {
    // function to get Current Logged In User
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.IsDoctorAvailableFunction());
   // this.authService.getUserFromstateschange().then((val) => this.loggedUser = val)
    // this.authService.isDoctorAvail = this.authService.getAvailabilityToggleStatusFromProfile();

    // Active Calls
    firebase.database().ref('emergencyCall/stroke').on('value', snapshot => {
      this.emergencyCallList = [];
      if (snapshot.val() !== null) {
        this.showNotificationAlert = false;
        var respObj = snapshot.val();
        console.log('Values from notification icons are :' + snapshot.val());
        var respArr = Object.keys(respObj);
        for (var i = 0; i < respArr.length; i++) {
          if (respObj[respArr[i]].status === 'initiated') {
            respObj[respArr[i]]['callId'] = respArr[i];
            this.emergencyCallList.push(respObj[respArr[i]]);
          }
        }
        this.emergencyCallList = this.sortByKey(this.emergencyCallList, 'timestamp');
        if (this.emergencyCallList.length !== 0) {
          this.showNotificationAlert = true;
        }
        console.log('Emergency call list arr is : ' + this.emergencyCallList.length);
        this.ref.detectChanges();
      }
    });


    }
  ngOnInit() {
   this.loggedUser=localStorage.getItem("fullName");
  }

  sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
  }
  logout() {
    if (this.route.snapshot.url[0].path.toString() === 'video') {
      this.authService.setStatus('fromLogout');
      this.router.navigate(['/']);
      // var addRef = firebase.database().ref('doctorAvailability/' + this.loggedInUser);
      //       addRef.set({
      //        name : this.loggedInUser,
      //        status: 'idle',
      //        callId: '',
      //        profilePic: this.profilePicUrl
      //      });
      //      this.authService.isDoctorAvail = true;
      //      this.ref.detectChanges();
      } else {

      this.authService.setStatus('');
      this._firebaseAuth.auth.signOut().then(res => {
        setTimeout(() => { this.router.navigate(['/']); } , 1000);
      });
      }
      // var addRef = firebase.database().ref('doctorAvailability/' + this.loggedInUser);
      //       addRef.set({
      //        name : this.loggedInUser,
      //        status: 'idle',
      //        callId: '',
      //        profilePic: this.profilePicUrl
      //      });
      //      this.authService.isDoctorAvail = true;
      //      this.ref.detectChanges();
  }

  /*=========== Reject Emergency Call ===========*/

  RejectNotification(callId) {
    const emergencyCallStatus = firebase.database().ref('emergencyCall/stroke/' + callId);
    emergencyCallStatus.update({
      status: 'rejected'
    });
  }


  /*============== Accept Emergency Call =============*/
  AcceptNotification(callId) {
    const emergencyCallStatus = firebase.database().ref('emergencyCall/stroke/' + callId);
    emergencyCallStatus.update({
      status: 'active'
    });
    const videoCallActionsRef: firebase.database.Reference =
    firebase.database().ref('/videoCallActions/stroke/' + callId + '/actions/');
    videoCallActionsRef.push({
       action : 'accepted',
       info: 'Call is accepted by Doctor',
       timestamp: firebase.database.ServerValue.TIMESTAMP,
       user: this.loggedUser
    });
    const videoCallActionStatusRef: firebase.database.Reference =
      firebase.database().ref('/videoCallActions/stroke/' + callId);
      videoCallActionStatusRef.update({
        status: 'accepted'
    });
    window.open('/emergency-call?callId=' + callId + '&initiated=' + false);
  }


  // Doctor Availability Toggle
  SetAvailibiltyOfDoctor(toggle) {
    console.log(toggle);
      const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const param = new HttpParams().set('userName', this.loggedInUser);
        this.http.post(this.authService.baseURL + '/GetProfileInfo' , param , {headers: headers}).subscribe(response => {
          if (response['message'] === 'success') {
            this.profilePicUrl = response['avatar'];
          } else {
            this.profilePicUrl = '';
          }
          if(toggle.checked) {
            var addRef = firebase.database().ref('doctorAvailability/' + this.loggedInUser);
            addRef.set({
             name : this.loggedInUser,
             status: 'idle',
             callId: '',
             profilePic: this.profilePicUrl
           });
           this.authService.isDoctorAvail = true;
           this.ref.detectChanges();
          } else {
           var deleteRef = firebase.database().ref('doctorAvailability/');
           deleteRef.child(this.loggedInUser).remove();
           this.authService.isDoctorAvail = false;
           this.ref.detectChanges();
          }
      });
  }

  /*================ get Availability of Doctor after loggedIn ============*/
  IsDoctorAvailableFunction() {
    firebase.database().ref('doctorAvailability/').once('value', snapshot => {
      if (snapshot.hasChild(this.loggedInUser)) {
        // this.availabilityStatus = true;
        this.authService.isDoctorAvail = true;
        this.ref.detectChanges();

      } else {
        // this.availabilityStatus = false;
        this.authService.isDoctorAvail = false;
        this.ref.detectChanges();
      }
      });
  }

}
