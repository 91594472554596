import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from './video/video.component';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PatientRecordsComponent } from './patient-records/patient-records.component';
import { AuthGuard } from './services/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { SigninGuard } from './services/signin.guard';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { CallfromnotificationComponent} from './callfromnotification/callfromnotification.component'
import { CanDeactivateGuard } from './services/can-deactivate.guard';
const routes: Routes = [
  { path: '', component: SignUpComponent , canActivate: [SigninGuard]},
  { path: 'video', component: VideoComponent, canDeactivate: [CanDeactivateGuard]},
  { path: 'patient-records', component: PatientRecordsComponent, canActivate: [AuthGuard]},
  { path: 'waiting-room', component: WaitingRoomComponent, canActivate: [AuthGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'doctor-profile', component: DoctorProfileComponent, canActivate: [AuthGuard]},
  { path: 'emergency-call', component: CallfromnotificationComponent, canDeactivate: [CanDeactivateGuard]}

];
/*const routes: Routes = [
  { path: '', component: SignUpComponent , canActivate: [SigninGuard]},
  { path: 'video', component: VideoComponent},
  { path: 'patient-records', component: PatientRecordsComponent},
  { path: 'waiting-room', component: WaitingRoomComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'doctor-profile', component: DoctorProfileComponent}

];*/
@NgModule({
  exports: [ RouterModule ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule { }
