export class Hemorrhage{
    CTH = false;
    CTHValue = '';
    InterpretationTime = '';
    InterpretationDate: any = new Date();
    IsHemorrhage= false
    HemorrhageType = new HemorrhageType();
    CTAInterpretationValue = '';
}

export class HemorrhageType  {
    IntraparenchymalHemorrhage = false;
    SubarachnoidHemorrhage = false;
    EpiduralHemorrhage = true;
    SubduralHemorrhage = false;
    IntraventricularHemorrhage = false;
}