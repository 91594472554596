export class NonHemorrhage {
    CTInterpretation = true;
    CTInterpretationValue = '';
    InterpretationTime = '';
    InterpretationDate: any = new Date();
    IsNonHemorrhage = true;
    AspectScore = 'AspectScoreDoesNotApply';
    AspectSubganglionicNuclei = new AspectSubganglionicNuclei();
    AspectSupraganglionicNuclei = new AspectSupraganglionicNuclei();
    AspectBasalGanglia = new AspectBasalGanglia();
    TotalAspectScore = 0;
    AspectScoreDoesNotApply = new AspectScoreDoesNotApply();
    CtaInterpretation = new CtaInterpretation();
}


export class AspectSubganglionicNuclei{
    FrontalOperaculumM1 = '';
    AnteriorTemporalLobeM2 = '';
    PosteriorTemporalLobeM1 = '';
}

export class AspectSupraganglionicNuclei{
    AnteriorMCAM4 = '';
    LateralMCAM5 = '';
    FrontalMCAM6 = '';
}

export class AspectBasalGanglia{
    Caudate = '';
    LentiformNucleus = '';
    Insula = '';
    InternalCapsule = '';
}

export class AspectScoreDoesNotApply{
    SuspectPosteriorCirculationStroke = true;
    MetatasticBrainDisease = true;
    PrimaryCNSNeoplasm = true;
    Seizure = true;
    MigraineWithAura = true;
    ConversionDisorder = true;
    ToxicMetabolicEncephalopathy = true;
    Other = true;
}

export class CtaInterpretation{
    CTAInterpretation = true;
    CTAInterpretationValue ='';
    SelectSide = SelectSide.Right.toString();
    SelectOrgan = SelectOrgan.M2.toString();
    SelectType = new SelectType();
}

export class SelectType {
    Occlusion = false;
    Dissection = false;
    CervicalIcaMildStenosis = false;
    CervicalIcaModerateStenosis = false;
    CervicalIcaSevereStenosis = false;
    IntracranialIca = false;
    CervicalVertebralOcclusion = false;
    CervicalVertebralArteryStenosis = false;
    CervicalVertebralArteryDissection = false;
    CervicalIntracranialArteryStenosis = false;
    PcaStenosis = false;
    PcaOcclusion = false;
    BasiliarArteryStenosis = false;
    DiffuseIntracranialAtherosclerosis = false;

}

export enum SelectSide{
    Left = <any>'Left',
    Right = <any>'Right',
    None = <any>'None'
}

export enum SelectOrgan {
    M1 =<any>'M1',
    M2 =<any>'M2',
    M3 =<any>'M3',
    M4 =<any>'M4'
}