import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { FormsModule , ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { LoadingBarModule } from '@ngx-loading-bar/core';


import { AmazingTimePickerModule } from 'amazing-time-picker-angular6';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
// import { LoadingBarModule } from '@ngx-loading-bar/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';


// Components
import { SignUpComponent } from './sign-up/sign-up.component';
import { VideoComponent } from './video/video.component';
import { AppRoutingModule } from './/app-routing.module';
import { HeaderComponent } from './header/header.component';
import { DicomComponent } from './dicom/dicom.component';
import { PublisherComponent } from './publisher/publisher.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { PatientRecordsComponent } from './patient-records/patient-records.component';
import { MessagingService } from './messaging.service';
import { AuthGuard } from './services/auth.guard';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { SigninGuard } from './services/signin.guard';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DoctorProfileComponent } from './doctor-profile/doctor-profile.component';
import { DialogForProfileComponent } from './dialog-for-profile/dialog-for-profile.component';

// Angular material components
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatFormFieldModule} from '@angular/material';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

// firebase imports
import { AngularFireModule } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { CallfromnotificationComponent } from './callfromnotification/callfromnotification.component';

@NgModule({
  declarations: [
    AppComponent,
    VideoComponent,
    SignUpComponent,
    HeaderComponent,
    DicomComponent,
    SubscriberComponent,
    PublisherComponent,
    PatientRecordsComponent,
    WaitingRoomComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    DoctorProfileComponent,
    DialogForProfileComponent,
    CallfromnotificationComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatTabsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpModule,
    HttpClientModule,
    MatIconModule,
    MatCheckboxModule,
    MatListModule,
    MatChipsModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatExpansionModule,
    AmazingTimePickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatSnackBarModule,
    CountdownTimerModule.forRoot(),
    LoadingBarModule.forRoot(),
    NgxPasswordToggleModule,
    MatButtonToggleModule
  ],
  providers: [MessagingService, AuthGuard, SigninGuard],
  bootstrap: [AppComponent],
  entryComponents: [DicomComponent , ErrorDialogComponent , SuccessDialogComponent, DialogForProfileComponent]
})
export class AppModule { }
