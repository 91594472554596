import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-dialog-for-profile',
  templateUrl: './dialog-for-profile.component.html',
  styleUrls: ['./dialog-for-profile.component.css']
})
export class DialogForProfileComponent implements OnInit {
  closeDialog: Boolean = true;
  receivedCallIdFromQuery;
  loggedInUser;
  nurseName;
  loggedUser //new
  constructor( public dialogRef: MatDialogRef<DialogForProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _firebaseAuth: AngularFireAuth, private router: Router,
    private http: HttpClient, public authService: AuthService) {
      this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
      if (this.data === 'callEndedByNurse') {
        const url = window.location.search;
        const urlparam = url.split('&');
        const receivedCallId = (urlparam[0]).split('=');
        const callId = receivedCallId[1];
        firebase.database().ref('videoCallActions/stroke/' + callId ).once('value', snapshot => {
          var data = snapshot.val()['actions'];
          console.log('actionsObj', data);
          var actionsKey = Object.keys(snapshot.val()['actions']);
          console.log('actionskeys', data);
            for (let j = 0; j < actionsKey.length; j++) {
              if (data[actionsKey[j]].action === 'initiated') {
                //this.nurseName = data[actionsKey[j]].user;
                this.nurseName = data[actionsKey[j]].fullName;
                console.log('NurseName from endedbynurse popuo', this.nurseName);
              }
              // console.log('data' , data[actionsKey[j]] );
            }
        });
      }
     }

  ngOnInit() {
    this.loggedUser=localStorage.getItem("fullName");
  }

  logout() {
    this._firebaseAuth.auth.signOut().then(res => {
      setTimeout(() => { this.router.navigate(['/']); } , 200);
      this.dialogRef.close();
    });
  }
  closeDialogOnOk() {
    this.dialogRef.close();
  }
  closeCall(originatedFrom) {
    // 1.Hit end call webservice 2.Inform previous tab about session end 3.Delete node from videoCallActions
    if (this.data === 'fromEndCallButton' || this.data === 'callEndedByNurse') {
      if (window.location.pathname === '/emergency-call') {
          this.authService.setStatus('fromEndCallButton');
          const url = window.location.search;
          const urlparam = url.split('&');
          const receivedCallId = (urlparam[0]).split('=');
          this.receivedCallIdFromQuery =  receivedCallId[1];
          const params = new HttpParams()
          .set('userId', this.loggedInUser)
          .set('callId', this.receivedCallIdFromQuery)
          .set('type', 'TeleStrokeDoctor');
          const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            this.http.post(this.authService.baseURL + '/EndEmergencyCall', params, {headers: headers}).subscribe(
            data => {
              console.log('end call successfull', data);
              if (data['Message'] === 'Success') {
                const videoCallActionsRef: firebase.database.Reference =
                firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery + '/actions/');
                  videoCallActionsRef.push({
                    action : 'endedByDoctor',
                    info: 'Call is ended by Doctor',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    user: this.loggedUser
                  });
                  const videoCallActionStatusRef: firebase.database.Reference =
                  firebase.database().ref('videoCallActions/stroke/' + this.receivedCallIdFromQuery);
                  videoCallActionStatusRef.update({
                    status: 'endedByDoctor'
                  });
                // this.router.navigate(['/waiting-room']);
                  window.close();
              } else {
                alert('problem while ending call');
                window.close();
              }
          });

      } else {

          this.authService.setStatus('fromEndCallButton');
          const url = window.location.search;
          const urlparam = url.split('&');
          const receivedCallId = (urlparam[0]).split('=');
          this.receivedCallIdFromQuery =  receivedCallId[1];
          const params = new HttpParams()
          .set('userId', this.loggedInUser)
          .set('callId', this.receivedCallIdFromQuery)
          .set('type', 'TeleStrokeDoctor');
          const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            this.http.post(this.authService.baseURL + '/EndCall', params, {headers: headers}).subscribe(
            data => {
              console.log('end call successfull', data);
              if (data['Message'] === 'Success') {
                const videoCallActionsRef: firebase.database.Reference =
                firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery + '/actions/');
                  videoCallActionsRef.push({
                    action : 'endedByDoctor',
                    info: 'Call is ended by Doctor',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    user: this.loggedUser
                  });
                  const videoCallActionStatusRef: firebase.database.Reference =
                  firebase.database().ref('videoCallActions/stroke/' + this.receivedCallIdFromQuery);
                  videoCallActionStatusRef.update({
                    status: 'endedByDoctor'
                  });
                // this.router.navigate(['/waiting-room']);
                  window.close();
              } else {
                alert('problem while ending call');
                window.close();
              }
          });
      }
    }
  }
}
