import { Injectable } from '@angular/core';
import * as OT from '@opentok/client';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
// import config from '../config';

@Injectable({
  providedIn: 'root'
})
export class OpentokService {
  public config =  {
    // Set this to the base URL of your sample server, such as 'https://your-app-name.herokuapp.com'.
    // Do not include the trailing slash. See the README for more information:
    // SAMPLE_SERVER_BASE_URL: 'https://nodejs-sample-test.appspot.com/ot/session',
    SAMPLE_SERVER_BASE_URL: 'https://python-app-dot-sevaro-stroke.uc.r.appspot.com/ot/session',
    // OR, if you have not set up a web server that runs the learning-opentok-php code,
    // set these values to OpenTok API key, a valid session ID, and a token for the session.
    // For test purposes, you can obtain these from https://tokbox.com/account.
    //API_KEY: '46140572',
    API_KEY: '47067324',
    SESSION_ID: '',
    TOKEN: ''
  };

  session: OT.Session;
  token: string;

  constructor(private http: HttpClient) {
  }


    getSessionData() {
      //oldUrl = https://python-app-dot-sevaro-stroke.uc.r.appspot.com/ot/session  
        console.log(this.config);
        this.http.get('https://opentok-dot-telestroke-sevaro.uc.r.appspot.com/ot/session').subscribe(data => {
          console.log(data);
          this.config.SESSION_ID = data['sessionId'];
          this.config.TOKEN = data['token'];

          console.log(this.config);

        },
        err => {

            console.log('Error occured');
        });
    }

    getOT() {
      return OT
    }

    initSession() {
      // alert("Init session...");
      if (this.config.API_KEY  && this.config.TOKEN  && this.config.SESSION_ID ) {

        alert("If");
        console.log(this.config);
        this.session = this.getOT().initSession(this.config.API_KEY, this.config.SESSION_ID);
        this.token = this.config.TOKEN;
        return Promise.resolve(this.session);

      } 
      else {
        // alert("Else");
        return fetch(this.config.SAMPLE_SERVER_BASE_URL)
          .then((data) => data.json())
          .then((json) => {
            console.log("Session Id ==> "+json.sessionId+"\n Token ==> "+json.token);
            this.session = this.getOT().initSession(this.config.API_KEY, json.sessionId);
            this.token = json.token;
            this.config.SESSION_ID = json.sessionId;
            this.config.TOKEN = json.token;
            this.session["token"] = json.token;
            return Promise.resolve(this.session);
            // return this.session;
          });
      }
    }


    joinSession(sid, token) {
      // alert("Join session...");
          this.config.SESSION_ID = sid;
          this.config.TOKEN = token;
          console.log(this.config);
          this.session = this.getOT().initSession(this.config.API_KEY, sid);
          this.token = token;

          return Promise.resolve(this.session);

    }

    connect() {
      // alert("Connect function...");
      return new Promise((resolve, reject) => {
        this.session.connect(this.config.TOKEN, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(this.session);
          }
        });
      });
    }
  }
