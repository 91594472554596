
import { Component, ElementRef, AfterViewInit, ViewChild, Input, ChangeDetectorRef} from '@angular/core';
import { OpentokService } from '../opentok.service';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import {MatDialog} from '@angular/material';

declare var $: any;
declare const Split: any;
const publish = () => {

};

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css']
})

export class PublisherComponent implements AfterViewInit {
show:  Boolean = false;
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;
  publisher: OT.Publisher;
  publishing: Boolean;
  videoPublishing: Boolean; //  new
  AudioPublishing: Boolean; // new
  showFirstIcon: Boolean = true;
  showSecondIcon: Boolean = false;
  showAudioPauseIcon: Boolean = true;
  showAudioStartIcon: Boolean = false;
  callId;
  loggedInUser;
  status;
  changeDetectorRef: ChangeDetectorRef;
  showHide: Boolean = false;
  hideVisibility: Boolean = true;
  minimizeStatus: Boolean = true;
  hideVisibiltyWhenMinimized;
  splitInstance;
  constructor(private opentokService: OpentokService, public authService: AuthService,
    private http: HttpClient, private router: Router, private ref: ChangeDetectorRef, public dialogForEndCall: MatDialog,
    public dialogForProfile: MatDialog) {
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    this.publishing = false;
    this.videoPublishing = false; // new
    this.AudioPublishing = false; // new
    this.status = this.authService.getDisconnectStatus();
    if (this.status === true) {
       this.changeDetectorRef = ref;
      this.session.disconnect();
      this.show = false;
    }
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {insertMode: 'append'});
    this.show = true;
    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }
    $( function() {
      // $( '#publisher' ).draggable({ containment: '#subscriber' });
      $( '#draggableContainer' ).draggable({ containment: '#subscriber' });
    });
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishing = true;
        this.videoPublishing = true; // new
        this.AudioPublishing = true; // new
      }
    });
  }

  stopVideo() {

    if (this.videoPublishing === true) {

      this.publisher.publishVideo(false);
      this.videoPublishing = false;
      this.showSecondIcon = true;
      this.showFirstIcon = false;
    }
    else {

        this.publisher.publishVideo(true);
        this.videoPublishing = true;
        this.showFirstIcon = true;
        this.showSecondIcon = false;
    }
  }

  stopAudio() {

    if (this.AudioPublishing === true)  {

      this.publisher.publishAudio(false);
      this.AudioPublishing = false;
        this.showAudioPauseIcon = false;
      this.showAudioStartIcon = true;
    }
    else {

      this.publisher.publishAudio(true);
      this.AudioPublishing = true;
      this.showAudioPauseIcon = true;
      this.showAudioStartIcon = false;
    }
  }

  sessionDisconnect() {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent, {
      height: '125px',
      data: 'fromEndCallButton'
    });
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }
  showHidePublisher() {
    if (this.showHide === true) {
      this.showHide = false;
      this.hideVisibility = false;
    } else {
      this.hideVisibility = true;
      this.showHide = true;
    }
}
// minimize() {
//   var el = document.getElementById('iconDiv');
//   if (this.showHide === false) {
//   el.style.bottom = '0';
//   el.style.position = 'fixed';
//   this.showHide = true;
//   this.minimizeStatus = false;
//   this.hideVisibiltyWhenMinimized = true;
//   } else {
//     this.showHide = false;
//     el.style.position = 'relative';
//     this.hideVisibility = true;
//     this.minimizeStatus = true;
//     this.hideVisibiltyWhenMinimized = false;
//   }
// }
minimize() {
  var el = document.getElementById('iconDiv');
  if (this.minimizeStatus === true) {
  el.style.bottom = '0';
  el.style.position = 'fixed';
  this.showHide = true;
  this.minimizeStatus = false;
  this.hideVisibiltyWhenMinimized = true;
  } else {
    this.showHide = false;
    el.style.position = 'relative';
    this.hideVisibility = true;
    this.minimizeStatus = true;
    this.hideVisibiltyWhenMinimized = false;
  }
}
}

