import {
  Component,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  AfterViewInit,
  AfterViewChecked,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { MatDialog } from "@angular/material";
import { MatTabChangeEvent } from "@angular/material";
import { DicomComponent } from ".././dicom/dicom.component";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as moment from "moment";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSnackBar } from "@angular/material";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { filter } from "rxjs/operators";
import { DialogForProfileComponent } from "../dialog-for-profile/dialog-for-profile.component";
import * as firebase from "firebase/app";
import { AngularFireDatabase } from "@angular/fire/database";
// import {default as _rollupMoment} from 'moment';
// const moment = _rollupMoment || _moment;
declare const Test: any;
declare const Test2: any;
declare var $: any;
declare const Split: any;
import { OpentokService } from "../opentok.service";
import * as OT from "@opentok/client";
// import '../../assets/resize.js';
import "../../assets/split.js";
import { Observable } from "rxjs";
import { truncate } from "fs";
import { isNullOrUndefined } from "util";
import { Hemorrhage, HemorrhageType} from './hemorrhage.model';
import { NonHemorrhage } from './nonHemorrhage.model';
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.css"],
  providers: [
    OpentokService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class VideoComponent implements OnInit, AfterViewInit, AfterViewChecked {
  // firebase implementation
  meansOfArrivalChecked:Boolean = false;
  cigaretteCheck: Boolean= false;
  antiPlateletMenu: Boolean = false;
  antiCoagulationMenu: Boolean = false;
  cigaretteSection: Boolean = false;
  ambulanceSection: Boolean = false;
  meansOfArrivalObj;
  detailsReceived: Observable<any[]>;
  showBtnsOnDataUpdate: Boolean = true;
  items: Observable<any[]>;
  // angular opentok
  // validate give tpa checkbox
  xpandStatus = false;
  @ViewChild("ref") inputEl: any;
  showErrorForCheckbox: Boolean = false;
  showTPAdiv = false; // show UI when I clicked on give TPA radio
  showDontTPAdiv = true; // show UI when I clicked on Do not give TPA radio button
  giveOrDoNotGiveTpa = "Do Not Give TPA";
  considerOrDoNotConsiderEmbolectomy = "Do Not Consider Embolectomy";
  selectedReasonEmbolectomy=[];
  showDontEmbolectomyDiv = true;
  statusLastKnownNormal: Boolean = true;
  // do not give TPA options
  reasonnotTPA = new FormControl();
  // tslint:disable-next-line:max-line-length
  reasonList: string[] = [
    "Not a stroke",
    "Complex migraine",
    "History of ICH",
    "Mild symptoms",
    "Seizure",
    "BP requires initial management",
    "Initial refusal of treatment",
    "Out of window for t-PA",
    "Unable to determine eligibility",
  ];
  giveTpa = {
    totalDose: "",
    bolusDose: "",
    infusionDose: "",
    timeToGiveTpa: "",
    agree: false,
  };
  timePoints = {
    timeForConsultRequest: "",
    timePatientAtCtScan: "",
    timePatientReadyForVideo: "",
    timeProviderOnVideo: "",
    timeOfCtRead: "",
    timeOfCta: "",
    timeOfCtaRead: "",
    timeOfDecision: "",
    timeOfArrivalToEd: "",
    timeOfLastKnownNormal: "",
  };
  addWorkup = {
    cth: false,
    cthValue: "",
    mriBrain: false,
    mriBrainValue: "",
    ctaHeadAndNeck: false,
    ctaHeadAndNeckValue: "",
    eeg: false,
    eegValue: "",
    mraHeadAndNeck: false,
    mraHeadAndNeckValue: "",
    lp: false,
    lpValue: "",
  };
  iagreetnc: Boolean = false;
  ctInterpretation ="";
  ctaInterpretation = "";
  hideA2D: Boolean = true;
  consultNoteDefaultUnknown;
  today = new Date();
  iagreeDate;
  iagreeMonth;
  iagreeYear;
  iagreePostLetter;
  // doNotGiveTpa = {
  //   reasonForNotGivingTpa: this.selectedReasonList
  // };
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  postLetters = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
    11: "th",
    12: "th",
    13: "th",
    14: "th",
    15: "th",
    16: "th",
    17: "th",
    18: "th",
    19: "th",
    20: "th",
    21: "st",
    22: "nd",
    23: "rd",
    24: "th",
    25: "th",
    26: "th",
    27: "th",
    28: "th",
    29: "th",
    30: "th",
    31: "st",
  };
  selectedReasonList;
  embolectomyObjectForDoNotGiveTpa = {
    doNotGiveTpaSelected: this.selectedReasonEmbolectomy,
  };
  // Do not give Embolectomy options
  reasonnotEmbolectomy = new FormControl();
  reasonEmbolectomyList: string[] = ["No LVO", "Mild symptoms", "Not a Stroke"];
  todayDate = new Date();
  momentDate = moment(new Date());
  defaultDate: Date = new Date();
  totalCigaretteConsumption;
  offset =(new Date()).getTimezoneOffset();
  title = "Angular Basic Video Chat";
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  patientForm: FormGroup;
  settingsForm: FormGroup;
  loggedInUser;
  loggedUser;
  patientId: string;
  receivedUserID;
  receivedCallID;
  respData;
  callId;
  receivedImagesList;
  isCallInitiated;
  retrievedData;
  reasonArray;
  symptomsRequestArray;
  medicalArray;
  surgicalArray;
  medicationArray;
  allergiesRequestArray;
  familyArray;
  socialArray;
  retrieveArrayForReasonForConsult;
  receivedPatientId;
  // showEditIcon: Boolean = true;
  showEditIcon: Boolean = false;
  receivedDate;
  receivedMonth;
  receivedYear;
  date;
  month;
  year;
  remainingheightForNormal;
  showBtns: Boolean = true;
  public imagesList: string[] = [];
  splitInstance;
  firstTimeValue;
  expandCollapseDivStatus: Boolean = true; // expand stands for true and collapse stands for false
  sizeOfDiv1;
  sizeOfDiv2;
  sizeOfDiv3;
  allSizes;
  remainingSize;
  isCTHDisabled = true;

  // whether the subcheckbox of pastMedicalHistroy/familyhistory/symptmos check
  // ambulanceSection: Boolean = false;
  isNoneCheck: boolean = false;
  isAlcoholCheck: boolean = false;
  isTobaccoCheck: boolean = false;
  isDrugCheck: boolean = false;
  isStrokeCheck: boolean = false;
  isCancerCheck: boolean = false;
  isMiCheck: boolean = false;
  isUnknownCheck: boolean = false;
  isSeizureCheck: boolean = false;
  isFamilyNoneCheck: boolean = false;
  isMedicalUnknownCheck : boolean = false;
  isMedicalCancerCheck : boolean = false;
  isMedicalSeizureCheck : boolean = false;
  isMedicalMiCheck : boolean = false;
  isMedicalhtnCheck : boolean = false;
  isMedicalDmCheck : boolean = false;
  isMedicalCkdCheck : boolean = false;
  isMedicalPriorStrokeCheck : boolean = false;
  isMedicalgiBleedCheck : boolean = false;
  isMedicalDementiaCheck : boolean = false;
  isMedicalCerebralAneurysmCheck : boolean = false;
  isMedicalMigraineCheck : boolean = false;
  isMedicalHldCheck : boolean = false;
  isMedicalAtrialFibrillationCheck : boolean = false;
  isMedicaNoneCheck : boolean = false; 
  isMedicalBrainTumorCheck:boolean=false;   //new
  isMedicalCongestiveHeartFailureCheck:boolean=false;   //new
  isAspirinCheck: boolean = false;
  isClopidogrelCheck: boolean = false;
  isAspirinAndClopidogrelCheck: boolean = false;
  isTicagrelorCheck: boolean = false;
  isUnableToDetermineCheck: boolean = false;
  isAntiPlateletTherapyMedicationsNoneCheck: boolean = false;
  isAntiPlateletTherapyMedicationsUnknownCheck: boolean = false;
  isWarprinCheck: boolean = false;
  isApixabanCheck: boolean = false;
  isDabigatranCheck: boolean = false;
  isRivaroxabanCheck: boolean = false;
  isEnoxaparinCheck: boolean = false;
  isHeparinCheck: boolean = false;
  isAntiCoagulationMedicationUnknownCheck: boolean = false;
  isTobaccoNoneCheck: boolean = false;
  isTobaccoChewingCheck: boolean = false;
  isTobaccoVapepenCheck: boolean = false;
  isTobaccoUnknownCheck: boolean = false;
  isTobaccoCigarettesCheck: boolean = false;
  isDrugsNoneCheck: boolean = false;
  isDrugsCocaineCheck: boolean = false;
  isDrugsLsdCheck: boolean = false;
  isDrugsMarijuanaCheck: boolean = false;
  isDrugsHeroineCheck: boolean = false;
  isDrugsMethamphetamineCheck: boolean = false;
  isDrugsUnknownCheck: boolean = false;
  expandCollapseDivStatusForGUtter2: Boolean = true;
  public addition = 0;
  firstTimeClick: Boolean = false;
  /* Pradeep Changes */
  public ctInterpretationTime = ""; 
  public ctInterpretationDate = this.defaultDate;
  public userSelectedHemorrhage: boolean = false;
  public hemorrhage = new Hemorrhage();
  public nonHemorrhage = new NonHemorrhage();
  /* Pradeep Changes */ 

  // validations
  arrivalToEdDateFormControl = new FormControl("", [Validators.required]);
  lastKnownNormalDateFormControl = new FormControl("", [Validators.required]);
  weightControl = new FormControl("", [Validators.required]);
  bloodPressureControl = new FormControl("", [Validators.required]);
  heartRateControl = new FormControl("", [Validators.required]);
  oxygenSaturationControl = new FormControl("", [Validators.required]);
  tempControl = new FormControl("", [Validators.required]);
  arrivalToEdTimeControl = new FormControl("", [Validators.required]);
  lastKnownNormalTimeControl = new FormControl("", [Validators.required]);
  rrControl = new FormControl("", [Validators.required]);
  bloodGlucoseControl = new FormControl("", [Validators.required]);
  tempUnitFormControl = new FormControl("", [Validators.required]);
  weightUnitFormControl = new FormControl("", [Validators.required]);
  meansOfArrivalControl = new FormControl("", [Validators.required]);
  preArrivalControl = new FormControl("", [Validators.required]);
  meansOfArrivalCustomControl = new FormControl("", [Validators.required]);
  patientOnAntiplateletControl = new FormControl("", [Validators.required]);
  patientAlcoholConsumption = new FormControl("", [Validators.required]);
  patientOnAnticoagulationControl = new FormControl("", [Validators.required]);
  cigarettesPerDayControl = new FormControl("", [Validators.required]);
  cigarettesPerDayRadioControl = new FormControl("", [Validators.required]);
  medicationsOptionsControl = new FormControl("", [Validators.required]);
  allergiesOptionsControl = new FormControl("", [Validators.required]);
  surgicalHistoryControl = new FormControl("", [Validators.required]);
  /* Pradeep Changes*/
  ctInterpretationTimeControl = new FormControl("", [Validators.required]);
  ctInterpretationTimeControl1 = new FormControl("", [Validators.required]);
  ctInterpretationDateFormControl = new FormControl("", [Validators.required]);
  ctInterpretationDateFormControl1 = new FormControl("", [Validators.required]);
  /* Pradeep Changes*/
  // tslint:disable-next-line:max-line-length
  physicalvalue =
    "Neurological Exam: \n Mental status: Awake, Alert and Oriented to name, date,location and birthdate. Following commands. Cranial Nerves: Visual fields: Full to confrontation bilaterally Eye motility: Full motility both eyes all directions Facial sensation: No sensory loss to light touch in face Facial motor: No facial droop or facial asymmetry Tongue: No tongue deviation Motor: Full strength in both UE and LE B/L. Sensation: No sensory loss to light touch in face, arms or legs B/L Coordination: Finger to nose: Intact Heel to shin: Intact Gait: Not tested";

  public userSettings = {
    chiefComplaint: "",
    historyOfIllness: "",
    reviewOfSystems: "",
    vitalSigns: "",
    giveTPA: "",
    notes: "",
    doNotgiveTPA: "",
    // tslint:disable-next-line:max-line-length
    physicalExam:
      "Neurological Exam: \n Mental status: Awake, Alert and Oriented to name, date,location and birthdate. Following commands. \n\n Cranial Nerves: \n Visual fields: Full to confrontation bilaterally \n Eye motility: Full motility both eyes all directions \n Facial sensation: No sensory loss to light touch in face \n Facial motor: No facial droop or facial asymmetry  \n Tongue: No tongue deviation \n Motor: Full strength in both UE and LE B/L. \n\n Sensation: No sensory loss to light touch in face, arms or legs B/L  \n\n Coordination: \n Finger to nose: Intact \n Heel to shin: Intact \n Gait: Not tested",
    imaging: "",
    assessment: "",
    recommendations: "",
  };
  patientSettingsRetrieve = {
    nurse: "",
    physician: "",
    familyContactNumber: "",
    meansOfArrivalOption:"",
    meansOfArrivalCustom:"",
    historian: "",
    hospitalCampus: "",
    setLastKnownNormal: "false",
    arrivalToEdDate: "",
    arrivalToEdTime: "",
    lastKnownNormalDate: "",
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    weight: "",
    oxygenSat: "",
    heartRate: "",
    temp: "",
    rr: "",
    bloodGlucose: "",
    nurseFullName: "",
    weightUnit: "",
    tempUnit: "",
    medicationsCustom: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
    ReasonForConsult: "",
    symptoms: "",
    pastMedicalHistory: "",
    pastSurgicalHistory: "",
    medications: "",
    allergies: "",
    socialHistory: "",
    familyHistory: "",
  };

  public patientSettings = {
    meansOfArrival: "",
    preArrival: "",
    patientOnAntiplatelet: "",
    patientOnAnticoagulation: "",
    patientAlcoholConsumption: "",
    cigarettesPerDayConsumption: "",
    cigarettesPerDay: "",
    cigarettesPerDayQuantity: "",
    familyContactNumber:"",
    meansOfArrivalOption:"",
    meansOfArrivalCustom:"",
    nurse: "",
    physician: "",
    historian: "",
    hospitalCampus: "",
    setLastKnownNormal: "false",
    arrivalToEdDate: this.defaultDate,
    arrivalToEdTime: "",
    lastKnownNormalDate: this.defaultDate,
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    weight: "",
    applyheightoxygenSat: "",
    heartRate: "",
    temp: "",
    rr: "",
    bloodGlucose: "",
    nurseFullName: "",
    weightUnit: "",
    tempUnit: "",
    medicationsCustom: "",
    oxygenSat: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
  };
  public nihss = {
    levelOfConsiousness: '0',
    responseToLocQue: "",
    responseToLocComm: "",
    bestGaze: "",
    visual: "",
    facialPalsy: "",
    motorLeftArm: "",
    motorRightArm: "",
    motorLeftLeg: "",
    motorRightLeg: "",
    limbAtaxia: "",
    sensory: "",
    bestLanguage: "",
    dysarthria: "",
    extinctionAndInattention: "",
    totalScore: 0,
  };
  video = { token: "", sessionId: "" };

  patientInfo = { name: "", dob: "", gender: "", mrn: "", age: 0 };

  // Object of checkboxes and other for reason for consult
  public reasonForConsult = {
    cardiacArrest: "",
    possibleStroke: "",
    other: "",
  };
  // Object of checkboxes and other for reason for consult
  public symptoms = {
    rightSidedWeakness: "",
    leftSidedWeakness: "",
    rightSidedNumbness: "",
    leftSidedNumbness: "",
    leftGazePreference: "",
    rightGazePreference: "",
    leftFacialDroop: "",
    rightFacialDroop: "",
    aphasia: "",
    slurredSpeech: "",
    blurryVision: "",
    doubleVision: "",
    peripheralVisionLoss: "",
    alteredMentalStatus: "",
    headache: "",
    dizziness: "",
    unsteadyGait: "",
    vertigo: "",
    visionLoss: "",
    seizure: "",
    other: "",
  };

  // Object of checkboxes for anti platelet therapy
  public antiPlateletTherapyMedications = {
    aspirin: false,
    clopidogrel: false,
    aspirinAndClopidogrel: false,
    ticagrelor: false,
    unableToDetermine: false,
    none: false,
    unknown: false,
    other: ""
  }

  // Object of checkboxes for Anti Coagulation Therapy Medications
  public antiCoagulationTherapyMedications = {
    warfarin: false,
    apixaban: false,
    dabigatran: false,
    rivaroxaban: false,
    enoxaparin: false,
    heparin: false,
    unknown: false,
    other: ""
  }

  // Object of checkboxes for past medical history
  public pastMedicalHistory = {
    unknown: false,
    none: false,
    htn: false,
    hld: false,
    atrialFibrillation: false,
    giBleed: false,
    congestiveHeartFailure: false,
    mi: false,
    dm: false,
    priorStroke: false,
    dementia: false,
    ckd: false, 
    cerebralAneurysm: false,
    brainTumor: false,
    seizure: false,
    migraine: false,
    cancer: false,
    other:""
  };
  // Object of checkboxes for past medical history
  public pastSurgicalHistory = { surgicalHistory: "", other: "" };
  // Object of checkboxes for medications
  public medications = { medicationsCustom: "", medicationsOptions: "" };
  // Object of checkboxes for allergies
  public allergies = { allergiesCustom: "", allergiesOptions: "" };
  // Object of checkboxes for social history
  public socialHistory = {
    none: false,
    alcohol: false,
    drugs: false,
    tobacco: false,
  };
  // Object of checkboxes for family history
  public familyHistory = {
    none: false,
    unknown: false,
    mi: false,
    stroke: false,
    cancer: false,
    seizure: false,
    other:""
  };
  // Object of checkboxes for tobacco
  public tobacco = {
    none: false,
    chewing: false,
    vapepen: false,
    unknown: false,
    cigarettes: false,
    other: ""
  }
  // Object of checkboxes for drugs
  public drugs = {
    none: false,
    cocaine: false,
    lsd: false,
    marijuana: false,
    heroine: false,
    methamphetamine: false,
    unknown: false,
    other: ""
  }
  // Form details to be sent as array
  // array for reason for consult(checkbox and other)
  reasonForConsultArray: any[] = [];
  // array checkboxes for symptoms(checkbox and other)
  symptomsArray: any[] = [];
  // array of checkboxes for past medical history
  pastMedicalHistoryArray: any[] = [];
  // array of checkboxes for anti platelet therapy
  medicationsForAntiplateletTherapyArray: any[] = [];
  // array of checkboxes for anti coagulation therapy
  medicationsForAnticoagulationTherapyArray : any[] = [];
  // array of checkboxes for past surgical history
  pastSurgicalHistoryArray: any[] = [];
  // array of checkboxes for allergies
  allergiesArray: any[] = [];
  // array of checkboxes for medications
  medicationsArray: any[] = [];
  // array of checkboxes for family history
  familyHistoryArray: any[] = [];
  // array of checkboxes for tobacco
  tobaccoArray: any[] = [];
  // array of checkboxes for drugs
  drugsArray: any[] =[];
  // array of checkboxes for social history
  socialHistoryArray: any[] = [];

  isloading = false; // loader
  // ambulanceSection = false;

  firstHeight;
  lastheight;
  totalheight;
  remainingheight;
  refreshData: Boolean = false;
  sizeOne;
  sizeTwo;
  disableBtns: Boolean = false;
  receivedCallIDFromQuery;
  isCallEndedByDoctor: Boolean = false;
  isAntiCoagulationTherapyMedicationsNoneCheck: boolean;
  @HostListener("window:resize", ["$event"])
  sizeChange(event) {
    this.setmaxHeight();
  }

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public authService: AuthService,
    public dialog: MatDialog,
    private opentokService: OpentokService,
    private ref: ChangeDetectorRef,
    public errorDialog: MatDialog,
    public successDialog: MatDialog,
    public dialogForProfile: MatDialog,
    public loader: LoadingBarService,
    private router: Router,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    public db: AngularFireDatabase,
    public snackBar: MatSnackBar
  ) {
    this.authService
      .getUserFromstatechange()
      .then((val) => (this.loggedInUser = val))
      .then(() => this.getCallDetailsByFirebase());
    this.iagreeDate = this.todayDate.getDate();
    this.iagreePostLetter = this.postLetters[this.iagreeDate];
    this.iagreeMonth = this.monthNames[this.todayDate.getMonth()];
    this.iagreeYear = this.todayDate.getFullYear();
    $(".loader_msg").show();
    if ($(".loader").length) {
      $(".loader").fadeIn("fast");
      // $('.loader_msg').fadeIn('fast');
    } else {
      $('<div class="loader"></div>').appendTo("body").fadeIn("fast");
    }
    this.createForm();
    this.createPatientForm();
    this.changeDetectorRef = ref;
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((routeData: any) => {
        if (routeData.urlAfterRedirects === "/") {
          this.session.disconnect();
        }
        if (routeData.urlAfterRedirects === "/patient-records") {
          this.session.disconnect();
          // alert('stop');
        }
        if (routeData.urlAfterRedirects === "/waiting-room") {
          this.session.disconnect();
          //  alert('stop');
        }
        if (routeData.urlAfterRedirects === "/doctor-profile") {
          this.session.disconnect();
          // alert('stop');
        }
      });

    const url = window.location.search;
    const urlparam = url.split("&");
    const receivedCallId = urlparam[0].split("=");
    const isInitiated = urlparam[1].split("=");
    this.receivedCallIDFromQuery = receivedCallId[1];
    // if (isInitiated[1] === 'false') {
    
  }

  // Open snackbar when data successfully saved by ctrl + S Maulik
  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 2000,
      panelClass: ["green-snackbar"],
    });
  }

  createForm() {
    this.settingsForm = this.fb.group({
      chiefComplaint: [""],
      reviewOfSystems: [""],
      historyOfIllness: [""],
      vitalSigns: [""],
      giveTPA: [""],
      notes: [""],
      doNotgiveTPA: [""],
      physicalExam: [""],
      nihss: [""],
      imaging: [""],
      assessment: [""],
      recommendations: [""],
      levelOfConsiousnessControl: [""],
      responseToLocQueControl: [""],
      responseToLocCommControl: [""],
      bestGazeControl: [""],
      visualControl: [""],
      facialPalsyControl: [""],
      motorLeftArmControl: [""],
      motorRightArmControl: [""],
      motorLeftLegControl: [""],
      motorRightLegControl: [""],
      limbAtaxiaControl: [""],
      sensoryControl: [""],
      bestLanguageControl: [""],
      dysarthriaControl: [""],
      extinctionAndInattentionControl: [""],
      giveOrDoNotGiveTpaControl: [""],
      totalDoseControl: [""],
      bolusDoseControl: [""],
      infusionDoseControl: [""],
      timeToGiveTpaControl: [""],
      agreeControl: [""],
      iagreetncControl: [""],
      embolectomyControl: [""],
      arrivalToEdDateControl: [""],
      arrivalToEdTimeControl: [""],
      unknownControl: [""],
      lastKnownDateControl: [""],
      lastKnownTimeControl: [""],
      timeForConsultRequestControl: [""],
      timePatientAtCtScanControl: [""],
      timePatientReadyForVideoControl: [""],
      timeProviderOnVideoControl: [""],
      timeOfCtReadControl: [""],
      timeOfCtaControl: [""],
      timeOfCtaReadControl: [""],
      timeOfDecisionControl: [""],
      timeOfTpaBolusControl: [""],
      cthControl: [""],
      cthValueControl: [""],
      mriBrainControl: [""],
      mriBrainValueControl: [""],
      ctaHeadAndNeckControl: [""],
      ctaHeadAndNeckValueControl: [""],
      eegControl: [""],
      eegValueControl: [""],
      mraHeadAndNeckControl: [""],
      mraHeadAndNeckValueControl: [""],
      lpControl: [""],
      lpValueControl: [""],
      /* Pradeep Changes */
      ctInterpretationControl: [""],
      ctInterpretationTimeControl: [""],
      ctInterpretationDateFormControl: [""],
      ctValueControl: [""],
      cthFormValueControl: [""],
      ctaInterpretationControl:[""],
      ctaInterpretationhemorrhageControl:[""],
      hemorrhageTypeControl: [""],
      /* Pradeep Changes */
    });
  }
  createPatientForm() {
    this.patientForm = this.fb.group({
      nurse: [""],
      physician: [""],
      familyContactNumber: [""],
      meansOfArrivalOption:[""],
      meansOfArrivalCustom:[""],
      historian: [""],
      hospitalCampus: [""],
      cardiacArrest: [""],
      possibleStroke: [""],
      setLastKnownNormal: [""],
      reasonForConsultOther: [""],
      rightSidedWeakness: [""],
      leftSidedWeakness: [""],
      rightSidedNumbness: [""],
      leftSidedNumbness: [""],
      leftGazePreference: [""],
      rightGazePreference: [""],
      leftFacialDroop: [""],
      rightFacialDroop: [""],
      aphasia: [""],
      slurredSpeech: [""],
      blurryVision: [""],
      doubleVision: [""],
      peripheralVisionLoss: [""],
      alteredMentalStatus: [""],
      headache: [""],
      dizziness: [""],
      unsteadyGait: [""],
      vertigo: [""],
      visionLoss: [""],
      seizure: [""],
      symptomsOther: [""],
      patientOnAntiplateletTherapyOther: [""],
      patientOnAnticoagulationTherapyOther: [""],
      tobaccoOtherControl: [""],
      drugsOtherControl: [""],
      familyHistoryOtherControl: [""],
      bloodPressure: [""],
      weight: [""],
      oxygenSaturation: [""],
      heartRate: [""],
      temperature: [""],
      pastMedicalHistoryOther: [""],
      antiPlateletTherapyOtherMedication: [""],
      antiCoagulationTherapyOtherMedication: [""],
      allergiesCustom: [""],
      medicationsCustom: [""],
      antiplatelettherapy_aspirin: [""],
      antiplatelettherapy_clopidogrel: [""],
      antiplatelettherapy_aspirinAndClopidogrel: [""],
      antiplatelettherapy_ticagrelor: [""],
      antiplatelettherapy_unableToDetermine: [""],
      antiplatelettherapy_none: [""],
      antiplatelettherapy_unknown: [""],
      anticoagulationtherapy_warfarin: [""],
      anticoagulationtherapy_apixaban: [""],
      anticoagulationtherapy_dabigatran: [""],
      anticoagulationtherapy_rivaroxaban: [""],
      anticoagulationtherapy_enoxaparin: [""],
      anticoagulationtherapy_heparin: [""],
      anticoagulationtherapy_unknown: [""],
      medical_unknown: [""],
      medical_none: [""],
      medical_htn: [""],
      medical_hld: [""],
      medical_atrialFibrillation: [""],
      medical_giBleed: [""],
      medical_congestiveHeartFailure: [""],
      medical_mi: [""],
      medical_dm: [""],
      medical_priorStroke: [""],
      medical_dementia: [""],
      medical_ckd: [""],
      medical_cerebralAneurysm: [""],
      medical_brainTumor: [""],
      medical_seizure: [""],
      medical_migraine: [""],
      medical_cancer: [""],
      surgicalHistory: [""],
      pastSurgicalHistoryCustom: [""],
      allergiesOptions: [""],
      medicationsOptions: [""],
      socialHistory_none: [""],
      //socialHistory_unknown: [""],
      socialHistory_alcohol: [""],
      socialHistory_drugs: [""],
      socialHistory_tobacco: [""],
      familyHistory_none: [""],
      familyHistory_unknown: [""],
      familyHistory_mi: [""],
      familyHistory_stroke: [""],
      familyHistory_cancer: [""],
      familyHistory_seizure: [""],
      familyHistory_htn: [""],
      tobacco_none: [""],
      tobacco_chewing: [""],
      tobacco_vapepen: [""],
      tobacco_unknown: [""],
      tobacco_cigarettes: [""],
      drugs_none: [""],
      drugs_cocaine: [""],
      drugs_lsd: [""],
      drugs_marijuana: [""],
      drugs_heroine: [""],
      drugs_methamphetamine: [""],
      drugs_unknown: [""],
      miInPrevious3Weeks: [""],
      strokeInPrevious3Months: [""],
      historyOfIch: [""],
      tookCEPX: [""],
      historyOfNeoplasm: [""],
      isIntracranial: [""],
      tpaEligibilityUnknown: [""],
      familyAtBedside: [""],
      arrivalToEdDate: [""],
      arrivalToEdTime: [""],
      lastKnownNormalDate: [""],
      lastKnownNormalTime: [""],
    });
  }
  ngOnInit() {
   
    this.loggedUser=localStorage.getItem("fullName");
    this.splitInstance = Split(["#one", "#two", "#three"], {
      minSize: [10, 100, 100],
    });
    this.setmaxHeight();
    this.settingsForm.controls["cthValueControl"].disable();
    this.settingsForm.controls["mriBrainValueControl"].disable();
    this.settingsForm.controls["ctaHeadAndNeckValueControl"].disable();
    this.settingsForm.controls["eegValueControl"].disable();
    this.settingsForm.controls["mraHeadAndNeckValueControl"].disable();
    this.settingsForm.controls["lpValueControl"].disable();
    /* Pradeep Changes */
    this.settingsForm.controls["ctValueControl"].enable();
    this.settingsForm.controls["cthFormValueControl"].disable();
    this.settingsForm.controls["ctaInterpretationhemorrhageControl"].enable();
    this.settingsForm.controls["ctaInterpretationControl"].enable();
    /* Pradeep Changes */
  }
  ngAfterViewInit() {
    this.elementRef.nativeElement
      .querySelector("#showBtn1")
      .addEventListener("click", this.collapseOrExpandContainer.bind(this));
    this.elementRef.nativeElement
      .querySelector("#showBtn2")
      .addEventListener("click", this.collapseOrExpandContainer.bind(this));
    this.ref.detectChanges();

    // AfterViewChanges firebase code added 

    firebase
      .database()
      .ref(
        "videoCallActions/stroke/" + this.receivedCallIDFromQuery + "/status"
      )
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (
          snapshot.val() === "endedByNurse" &&
          this.isCallEndedByDoctor === false
        ) {
          this.opendialogForProfile("callEndedByNurse");
          // this.opendialogForProfile('fromEndCallButton');
        }
        if (snapshot.val() === "endedByDoctor") {
          this.isCallEndedByDoctor = true;
          // this.opendialogForProfile('fromEndCallButton');
        }
      });
    // }

        /*============== listner for meansOfArrivalChecked ============*/
        firebase
        .database()
        .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/meansOfArrivalChecked")
        .on("value", (Snapshot) => {
          if (Snapshot.val() !== null) {
            this.meansOfArrivalChecked = Snapshot.val();
            this.ref.detectChanges();
          }
        });

    /*============== listner for meansOfArrival ============*/
    firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/meansOfArrival")
    .on("value", (Snapshot) => {
    
      let a = JSON.parse(Snapshot.val());
        // if(Snapshot.val()!==null ){
      if (Snapshot.val() !== null && this.meansOfArrivalChecked == true) {
        this.meansOfArrivalObj = JSON.parse(Snapshot.val());
        if(this.meansOfArrivalObj["privateVehicle"] !== ""){
          this.ambulanceSection = false;
          this.patientSettings.meansOfArrivalOption = this.meansOfArrivalObj["privateVehicle"];
        }
        if(this.meansOfArrivalObj["inHouseAttack"] !== ""){
          this.ambulanceSection = false;
          this.patientSettings.meansOfArrivalOption = this.meansOfArrivalObj["inHouseAttack"];
        }
        if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == "" && this.meansOfArrivalChecked == true){
          this.ambulanceSection = true;
          this.patientSettings.meansOfArrivalOption = "Ambulance";
          this.patientSettings.meansOfArrivalCustom = this.meansOfArrivalObj["Ambulance"];
        // }
      }
        this.ref.detectChanges();
      
      }
    });

     

    /*============== listner for preArrival ============*/
    firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/preArrival")
    .on("value", (Snapshot) => {
      if (Snapshot.val() !== null) {
        this.patientSettings.preArrival = Snapshot.val();
        this.ref.detectChanges();
      }
    });

     /*============== listner for patient on Anti Platelet Therapy ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/patientOnAntiplatelet")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.patientOnAntiplatelet = Snapshot.val();
         if(this.patientSettings.patientOnAntiplatelet == "Yes"){
          this.antiPlateletMenu = true;
         }
         if(this.patientSettings.patientOnAntiplatelet == "No"){
           this.antiPlateletMenu = false;
        }
        if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
           this.antiPlateletMenu = false;
        }
         this.ref.detectChanges();
       }
     });

     /*============== listner for patient Alcohol Consumption ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/alcoholConsumption")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.patientAlcoholConsumption = Snapshot.val();
        //  if(this.patientSettings.patientOnAntiplatelet == "Yes"){
        //   this.antiPlateletMenu = true;
        //  }
        //  if(this.patientSettings.patientOnAntiplatelet == "No"){
        //    this.antiPlateletMenu = false;
        // }
        // if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
        //    this.antiPlateletMenu = false;
        // }
         this.ref.detectChanges();
       }
     });

     /*============== listner for patient Cigarette Consumption ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/cigarettesPerDay")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.cigarettesPerDay = Snapshot.val();
         if(this.patientSettings.cigarettesPerDay!== null){
           this.cigaretteSection = true;
           
         }
        //  if(this.patientSettings.patientOnAntiplatelet == "Yes"){
        //   this.antiPlateletMenu = true;
        //  }
        //  if(this.patientSettings.patientOnAntiplatelet == "No"){
        //    this.antiPlateletMenu = false;
        // }
        // if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
        //    this.antiPlateletMenu = false;
        // }
         this.ref.detectChanges();
       }
     });

     /*============== listner for patient Cigarette Consumption Quantity ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/cigarettesPerDayQuantity")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.cigarettesPerDayQuantity = Snapshot.val();
        
        //  if(this.patientSettings.patientOnAntiplatelet == "Yes"){
        //   this.antiPlateletMenu = true;
        //  }
        //  if(this.patientSettings.patientOnAntiplatelet == "No"){
        //    this.antiPlateletMenu = false;
        // }
        // if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
        //    this.antiPlateletMenu = false;
        // }
         this.ref.detectChanges();
       }
     });

      /*============== listner for patient smoking cigarettes per day ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/cigarettesPerDay")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.cigarettesPerDay = Snapshot.val();
         if(this.patientSettings.cigarettesPerDay !== null){
           this.cigaretteSection = true;
         }
         
         this.ref.detectChanges();
       }
     });


       /*============== listner for patient on Anti Coagulation Therapy ============*/
       firebase
       .database()
       .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/patientOnAnticoagulation")
       .on("value", (Snapshot) => {
         if (Snapshot.val() !== null) {
           this.patientSettings.patientOnAnticoagulation = Snapshot.val();
           if(this.patientSettings.patientOnAnticoagulation == "Yes"){
            this.antiCoagulationMenu = true;
           }
           if(this.patientSettings.patientOnAnticoagulation == "No"){
             this.antiCoagulationMenu = false;
          }
          if(this.patientSettings.patientOnAnticoagulation == "Unknown"){
             this.antiCoagulationMenu = false;
          }
           this.ref.detectChanges();
         }
       });

    /*============== listner for family contact number ============*/

    firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/familyContactNumber")
    .on("value", (Snapshot) => {
      if (Snapshot.val() !== null) {
        this.patientSettings.familyContactNumber = Snapshot.val();
        this.ref.detectChanges();
      }
    });

    /*============== listner for physician ============*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/physician")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.physician = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Nurse ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/nurse")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.nurse = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Nurse Full Name ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/nurseFullName")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.nurseFullName = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Nurse ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/reason")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          var reasonForConultArr = JSON.parse(Snapshot.val());
          for (let reason of reasonForConultArr) {
            this.reasonForConsult.other = reason;
          }
          this.ref.detectChanges();
        }
      });

    /*============== listner for Historian ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/historian")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historian = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Hospital Campus ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/hospitalCampus"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.hospitalCampus = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Blood Pressure ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/bloodPressure"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.bloodPressure = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Pulse/ heartRate ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/heartRate")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.heartRate = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Oxygen Saturation ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/oxygenSat")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.oxygenSat = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for RR ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/rr")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.rr = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Weight Unit ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/weightUnit")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.weightUnit = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Temp Unit ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/tempUnit")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.tempUnit = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Weight ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/weight")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.weight = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Temp ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/temp")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.temp = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Symptoms ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/symptoms")
      .on("value", (Snapshot) => {
        // reinitialize symptoms
        if (Snapshot.val() !== null) {
          this.symptoms = {
            rightSidedWeakness: "",
            leftSidedWeakness: "",
            rightSidedNumbness: "",
            leftSidedNumbness: "",
            leftGazePreference: "",
            rightGazePreference: "",
            leftFacialDroop: "",
            rightFacialDroop: "",
            aphasia: "",
            slurredSpeech: "",
            blurryVision: "",
            doubleVision: "",
            peripheralVisionLoss: "",
            alteredMentalStatus: "",
            headache: "",
            dizziness: "",
            unsteadyGait: "",
            vertigo: "",
            visionLoss: "",
            seizure: "",
            other: "",
          };

          if (JSON.parse(Snapshot.val()) !== "") {
            for (let symptom of JSON.parse(Snapshot.val())) {
              if (symptom === "Right sided weakness") {
                this.symptoms.rightSidedWeakness = "true";
              }
              if (symptom === "Left sided weakness") {
                this.symptoms.leftSidedWeakness = "true";
              }
              if (symptom === "Right sided numbness") {
                this.symptoms.rightSidedNumbness = "true";
              }
              if (symptom === "Left sided numbness") {
                this.symptoms.leftSidedNumbness = "true";
              }
              if (symptom === "Left gaze preference") {
                this.symptoms.leftGazePreference = "true";
              }
              if (symptom === "Right gaze preference") {
                this.symptoms.rightGazePreference = "true";
              }
              if (symptom === "Left facial droop") {
                this.symptoms.leftFacialDroop = "true";
              }
              if (symptom === "Right facial droop") {
                this.symptoms.rightFacialDroop = "true";
              }
              if (symptom === "Aphasia") {
                this.symptoms.aphasia = "true";
              }
              if (symptom === "Slurred speech") {
                this.symptoms.slurredSpeech = "true";
              }
              if (symptom === "Blurry vision") {
                this.symptoms.blurryVision = "true";
              }
              if (symptom === "Double vision") {
                this.symptoms.doubleVision = "true";
              }
              if (symptom === "Peripheral vision loss") {
                this.symptoms.peripheralVisionLoss = "true";
              }
              if (symptom === "Altered mental status") {
                this.symptoms.alteredMentalStatus = "true";
              }
              if (symptom === "Headache") {
                this.symptoms.headache = "true";
              }
              if (symptom === "Dizziness") {
                this.symptoms.dizziness = "true";
              }
              if (symptom === "Unsteady gait") {
                this.symptoms.unsteadyGait = "true";
              }
              if (symptom === "Vertigo") {
                this.symptoms.vertigo = "true";
              }
              if (symptom === "Vision loss") {
                this.symptoms.visionLoss = "true";
              }
              if (symptom === "Seizure") {
                this.symptoms.seizure = "true";
              }
              if (
                symptom !== "Right sided weakness" &&
                symptom !== "Left sided weakness" &&
                symptom !== "Right sided numbness" &&
                symptom !== "Left sided numbness" &&
                symptom !== "Left gaze preference" &&
                symptom !== "Right gaze preference" &&
                symptom !== "Left facial droop" &&
                symptom !== "Right facial droop" &&
                symptom !== "Aphasia" &&
                symptom !== "Slurred speech" &&
                symptom !== "Blurry vision" &&
                symptom !== "Double vision" &&
                symptom !== "Peripheral vision loss" &&
                symptom !== "Altered mental status" &&
                symptom !== "Headache" &&
                symptom !== "Dizziness" &&
                symptom !== "Unsteady gait" &&
                symptom !== "Vertigo" &&
                symptom !== "Vision loss" &&
                symptom !== "Seizure"
              ) {
                this.symptoms.other = symptom;
              }
            }
          }
          this.ref.detectChanges();
        }
      });

    /*============== listner for Arrival To ED Date ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/arrivalToEdDate"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.arrivalToEdDate = this.reverseDate(
            Snapshot.val()
          );
          this.ref.detectChanges();
        }
      });

    /*============== listner for TPA Eligibility Unkown ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/tpaEligibilityUnknown"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (Snapshot.val() === true || Snapshot.val() === "true") {
            this.patientSettings.tpaEligibilityUnknown = true;
            this.statusLastKnownNormal = true;
            this.timePoints.timeOfLastKnownNormal = "Unknown";
            this.patientSettings.lastKnownNormalDate = this.defaultDate;
            this.patientSettings.lastKnownNormalTime = "";
          } else {
            this.patientSettings.tpaEligibilityUnknown = false;
            this.statusLastKnownNormal = false;
            this.timePoints.timeOfLastKnownNormal = "";
            this.patientSettings.lastKnownNormalDate = null;
          }
          this.ref.detectChanges();
        }
      });

    /*============== listner for Arrival To Time  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/arrivalToEdTime"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.arrivalToEdTime = Snapshot.val();
          this.timePoints.timeOfArrivalToEd = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for LastKnownNormalDate ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/lastKnownNormalDate"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalDate = this.reverseDate(
            Snapshot.val()
          );
          this.ref.detectChanges();
        }
      });

    /*============== listner for LastKnownNormalTime  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/lastKnownNormalTime"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.lastKnownNormalTime = Snapshot.val();
          this.timePoints.timeOfLastKnownNormal = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for MIIn Previous 3 Weeks  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/miInPrevious3Weeks"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.miInPrevious3Weeks = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for Stroke  InPrevious3  Months  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/strokeInPrevious3Months"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.strokeInPrevious3Months = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for history OF Ich  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/isICHHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historyOfIch = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for tookCEPX ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/tookCEPX")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.tookCEPX = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for tookCEPX ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/isNeoplasm")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.historyOfNeoplasm = Snapshot.val();
          this.ref.detectChanges();
        }
      });
    
    /*============== listner for cigarette check box from social history tobacco section  ================*/
      firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/cigaretteCheck")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.cigaretteCheck = Snapshot.val();
          if(this.cigaretteCheck ==true){
            this.cigaretteSection = true;
          }
          if(this.cigaretteCheck ==false){
            this.cigaretteSection = false;
          }
          this.ref.detectChanges();
        }
      });

    /*============== listner for bloodGlucose  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/bloodGlucose"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.patientSettings.bloodGlucose = Snapshot.val();
          this.ref.detectChanges();
        }
      });

    /*============== listner for drugs from hospital portal  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/drugs"
      )
      .on("value", (Snapshot) => {

        if (Snapshot.val() !== null) {
          this.drugsArray = JSON.parse(Snapshot.val());
          this.ref.detectChanges();
        }
      });

      /*============== listner for allergies from hospital portal  ================*/
    firebase
    .database()
    .ref(
      "intakeData/stroke/" + this.receivedCallIDFromQuery + "/allergies"
    )
    .on("value", (Snapshot) => {

      if (Snapshot.val() !== null) {
        this.allergiesArray = JSON.parse(Snapshot.val());
        this.ref.detectChanges();
      }
    });

    /*============== listner for current medications from hospital portal  ================*/
    firebase
    .database()
    .ref(
      "intakeData/stroke/" + this.receivedCallIDFromQuery + "/currentMedications"
    )
    .on("value", (Snapshot) => {

      if (Snapshot.val() !== null) {
        this.medicationsArray = JSON.parse(Snapshot.val());
        this.ref.detectChanges();
      }
    });



      /*============== listner for family history from hospital portal  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/familyHistory"
      )
      .on("value", (Snapshot) => {
  
        if (Snapshot.val() !== null) {
          this.familyHistoryArray = JSON.parse(Snapshot.val());
          this.ref.detectChanges();
        }
      });


         /*============== listner for reason from hospital portal  ================*/
         firebase
         .database()
         .ref(
           "intakeData/stroke/" + this.receivedCallIDFromQuery + "/reason"
         )
         .on("value", (Snapshot) => {
     
           if (Snapshot.val() !== null) {
             this.reasonForConsultArray = JSON.parse(Snapshot.val());
             this.ref.detectChanges();
           }
         });
   

      /*============== listner for tobacco from hospital portal  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/tobacco"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.socialHistoryArray = JSON.parse(Snapshot.val());
          this.ref.detectChanges();
        }
      });

      
      /*============== listner for medications of Antiplatelet therapy from hospital portal  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicationsForAntiplateletTherapy"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val()!== null && Snapshot.val()!=="") {
          this.medicationsForAntiplateletTherapyArray = JSON.parse(Snapshot.val());
          this.ref.detectChanges();
        }
      });

      
      /*============== listner for medications of Anticoagulation therapy from hospital portal  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicationsForAnticoagulationTherapy"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null && Snapshot.val()!=="") {
          this.medicationsForAnticoagulationTherapyArray = JSON.parse(Snapshot.val());
          this.ref.detectChanges();
        }
      });

         /*============== listner for medical History from hospital portal  ================*/
         firebase
         .database()
         .ref(
           "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicalHistory"
         )
         .on("value", (Snapshot) => {
           if (Snapshot.val() !== null) {
             this.pastMedicalHistoryArray = JSON.parse(Snapshot.val());
             this.ref.detectChanges();
           }
         });


        /*============== listner for past surgical History from hospital portal  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" + this.receivedCallIDFromQuery + "/surgicalHistory"
        )
        .on("value", (Snapshot) => {
          if (Snapshot.val() !== null) {
            this.pastSurgicalHistoryArray = JSON.parse(Snapshot.val());
            this.ref.detectChanges();
          }
        });

        /*============== listner for symptoms from hospital portal  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" + this.receivedCallIDFromQuery + "/symptoms"
        )
        .on("value", (Snapshot) => {
          if (Snapshot.val() !== null) {
            this.symptomsArray = JSON.parse(Snapshot.val());
            this.ref.detectChanges();
          }
        });

    /*============== Listner for current medication ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIDFromQuery +
          "/currentMedications"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let currentMedicationsItem of JSON.parse(Snapshot.val())) {
              if (currentMedicationsItem === "Unknown") {
                this.medications.medicationsOptions = "Unknown";
                this.medications.medicationsCustom = "";
              }
              if (currentMedicationsItem === "None") {
                this.medications.medicationsOptions = "None";
                this.medications.medicationsCustom = "";
              }
              if (
                currentMedicationsItem !== "Unknown" &&
                currentMedicationsItem !== "None" &&
                currentMedicationsItem !== "false"
              ) {
                this.medications.medicationsCustom = currentMedicationsItem;
                this.medicationsOptionsControl.setValue("false");
              }
            }
          } else {
            this.medications.medicationsCustom = "";
            this.medicationsOptionsControl.setValue("false");
          }
          this.ref.detectChanges();
        }
      });

    /*============== Listner for  Allergies ============*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery + "/allergies")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let allergiesItem of JSON.parse(Snapshot.val())) {
              if (allergiesItem === "Unknown") {
                this.allergies.allergiesOptions = "Unknown";
                this.allergies.allergiesCustom = "";
              }
              if (allergiesItem === "NKDA") {
                this.allergies.allergiesOptions = "NKDA";
                this.allergies.allergiesCustom = "";
              }
              if (
                allergiesItem !== "Unknown" &&
                allergiesItem !== "NKDA" &&
                allergiesItem !== "false"
              ) {
                this.allergies.allergiesCustom = allergiesItem;
                this.allergiesOptionsControl.setValue("false");
              }
            }
          } else {
            this.allergies.allergiesCustom = "";
            this.allergiesOptionsControl.setValue("false");
          }
          this.ref.detectChanges();
        }
      });

    /*============== Listner for Anti Platelet Therapy Medications  ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicationsForAntiplateletTherapy"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.antiPlateletTherapyMedications = {
            aspirin: false,
            clopidogrel: false,
            aspirinAndClopidogrel: false,
            ticagrelor: false,
            unableToDetermine: false,
            none: false,
            unknown: false,
            other: ""
          }
          if(Snapshot.val()!== undefined && Snapshot.val!==null && Snapshot.val()!== ""){
          if (JSON.parse(Snapshot.val()) !== "" ) {
            for (let antiPlateletTherapyMedicationsItem of JSON.parse(Snapshot.val())) {
              if (antiPlateletTherapyMedicationsItem === "Aspirin") {
                this.antiPlateletTherapyMedications.aspirin = true;
              }
              if (antiPlateletTherapyMedicationsItem === "Clopidogrel") {
                this.antiPlateletTherapyMedications.clopidogrel = true;
              }
              if (antiPlateletTherapyMedicationsItem === "Aspirin & Clopidogrel") {
                this.antiPlateletTherapyMedications.aspirinAndClopidogrel = true;
              }
              if (antiPlateletTherapyMedicationsItem === "Ticagrelor") {
                this.antiPlateletTherapyMedications.ticagrelor = true;
              }
              if (antiPlateletTherapyMedicationsItem === "Unable to determine") {
                this.antiPlateletTherapyMedications.unableToDetermine = true;
              }
              if (antiPlateletTherapyMedicationsItem === "None") {
                this.antiPlateletTherapyMedications.none = true;
              }
              if (antiPlateletTherapyMedicationsItem === "Unknown") {
                this.antiPlateletTherapyMedications.unknown = true;
              }
              
              if (
                antiPlateletTherapyMedicationsItem !== "Aspirin" &&
                antiPlateletTherapyMedicationsItem !== "Clopidogrel" &&
                antiPlateletTherapyMedicationsItem !== "Aspirin & Clopidogrel" &&
                antiPlateletTherapyMedicationsItem !== "Ticagrelor" &&
                antiPlateletTherapyMedicationsItem !== "Unable to determine" &&
                antiPlateletTherapyMedicationsItem !== "None" &&
                antiPlateletTherapyMedicationsItem !== "Unknown"
              ) {
                this.antiPlateletTherapyMedications.other = antiPlateletTherapyMedicationsItem;
              }
            }
          }
        }
          this.ref.detectChanges();
        }
      });

      /*============== Listner for Anti Coagulation Therapy Medications  ============*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicationsForAnticoagulationTherapy"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.antiCoagulationTherapyMedications = {
            warfarin: false,
            apixaban: false,
            dabigatran: false,
            rivaroxaban: false,
            enoxaparin: false,
            heparin: false,
            unknown: false,
            other: ""
          }
          if(Snapshot.val()!== undefined && Snapshot.val()!== null && Snapshot.val()!== ""){
          if (JSON.parse(Snapshot.val()) !== "") {
            for (let antiCoagulationTherapyMedicationsItem of JSON.parse(Snapshot.val())) {
              if (antiCoagulationTherapyMedicationsItem === "Warfarin") {
                this.antiCoagulationTherapyMedications.warfarin = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Apixaban") {
                this.antiCoagulationTherapyMedications.apixaban = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Dabigatran") {
                this.antiCoagulationTherapyMedications.dabigatran = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Rivaroxaban") {
                this.antiCoagulationTherapyMedications.rivaroxaban = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Enoxaparin") {
                this.antiCoagulationTherapyMedications.enoxaparin = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Heparin") {
                this.antiCoagulationTherapyMedications.heparin = true;
              }
              if (antiCoagulationTherapyMedicationsItem === "Unknown") {
                this.antiCoagulationTherapyMedications.unknown = true;
              }
              
              if (
                antiCoagulationTherapyMedicationsItem !== "Warfarin" &&
                antiCoagulationTherapyMedicationsItem !== "Apixaban" &&
                antiCoagulationTherapyMedicationsItem !== "Dabigatran" &&
                antiCoagulationTherapyMedicationsItem !== "Rivaroxaban" &&
                antiCoagulationTherapyMedicationsItem !== "Enoxaparin" &&
                antiCoagulationTherapyMedicationsItem !== "Heparin" &&
                antiCoagulationTherapyMedicationsItem !== "Unknown"
              ) {
                this.antiCoagulationTherapyMedications.other = antiCoagulationTherapyMedicationsItem;
              }
            }
          }
        }
          this.ref.detectChanges();
        }
      });

  
    /*============== Listner for  Past Medical History  ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/medicalHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.pastMedicalHistory = {
            unknown: false,
            none: false,
            htn: false,
            hld: false,
            atrialFibrillation: false,
            giBleed: false,
            congestiveHeartFailure: false,
            mi: false,
            dm: false,
            priorStroke: false,
            dementia: false,
            ckd: false,
            cerebralAneurysm: false,
            brainTumor: false,
            seizure: false,
            migraine: false,
            cancer: false,
            other:""
          };
          if (JSON.parse(Snapshot.val()) !== "") {
            for (let pastMedicalHistoryItem of JSON.parse(Snapshot.val())) {
              if (pastMedicalHistoryItem === "Unknown") {
                this.pastMedicalHistory.unknown = true;
              }
              if (pastMedicalHistoryItem === "None") {
                this.pastMedicalHistory.none = true;
              }
              if (pastMedicalHistoryItem === "HTN") {
                this.pastMedicalHistory.htn = true;
              }
              if (pastMedicalHistoryItem === "HLD") {
                this.pastMedicalHistory.hld = true;
              }
              if (pastMedicalHistoryItem === "Atrial Fibrillation") {
                this.pastMedicalHistory.atrialFibrillation = true;
              }
              if (pastMedicalHistoryItem === "GI Bleed") {
                this.pastMedicalHistory.giBleed = true;
              }
              if (pastMedicalHistoryItem === "Congestive Heart Failure") {
                this.pastMedicalHistory.congestiveHeartFailure = true;
              }
              if (pastMedicalHistoryItem === "MI") {
                this.pastMedicalHistory.mi = true;
              }
              if (pastMedicalHistoryItem === "DM") {
                this.pastMedicalHistory.dm = true;
              }
              if (pastMedicalHistoryItem === "Prior Stroke") {
                this.pastMedicalHistory.priorStroke = true;
              }
              if (pastMedicalHistoryItem === "CKD") {
                this.pastMedicalHistory.ckd = true;
              }
              if (pastMedicalHistoryItem === "Cerebral Aneurysm") {
                this.pastMedicalHistory.cerebralAneurysm = true;
              }
              if (pastMedicalHistoryItem === "Dementia") {
                this.pastMedicalHistory.dementia = true;
              }
              if (pastMedicalHistoryItem === "Brain Tumor") {
                this.pastMedicalHistory.brainTumor = true;
              }
              if (pastMedicalHistoryItem === "Seizure") {
                this.pastMedicalHistory.seizure = true;
              }
              if (pastMedicalHistoryItem === "Migraine") {
                this.pastMedicalHistory.migraine = true;
              }
              if (pastMedicalHistoryItem === "Cancer") {
                this.pastMedicalHistory.cancer = true;
              }
              if (
                pastMedicalHistoryItem !== "Unknown" &&
                pastMedicalHistoryItem !== "None" &&
                pastMedicalHistoryItem !== "HTN" &&
                pastMedicalHistoryItem !== "HLD" &&
                pastMedicalHistoryItem !== "Atrial Fibrillation" &&
                pastMedicalHistoryItem !== "GI Bleed" &&
                pastMedicalHistoryItem !== "Congestive Heart Failure" &&
                pastMedicalHistoryItem !== "MI" &&
                pastMedicalHistoryItem !== "DM" &&
                pastMedicalHistoryItem !== "Prior Stroke" &&
                pastMedicalHistoryItem !== "Dementia" &&
                pastMedicalHistoryItem !== "CKD" &&
                pastMedicalHistoryItem !== "Cerebral Aneurysm" &&
                pastMedicalHistoryItem !== "Brain Tumor" &&
                pastMedicalHistoryItem !== "Seizure" &&
                pastMedicalHistoryItem !== "Migraine" &&
                pastMedicalHistoryItem !== "Cancer"
              ) {
                this.pastMedicalHistory.other = pastMedicalHistoryItem;
              }
            }
          }
          this.ref.detectChanges();
        }
      });

    /*============== Listner for  Surgical History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/surgicalHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let pastSurgicalHistoryItem of JSON.parse(Snapshot.val())) {
              if (pastSurgicalHistoryItem === "Unknown") {
                this.pastSurgicalHistory.surgicalHistory = "Unknown";
                this.pastSurgicalHistory.other = "";
              }
              if (pastSurgicalHistoryItem === "None") {
                this.pastSurgicalHistory.surgicalHistory = "None";
                this.pastSurgicalHistory.other = "";
              }
              if (
                pastSurgicalHistoryItem !== "Unknown" &&
                pastSurgicalHistoryItem !== "None" &&
                pastSurgicalHistoryItem !== "false"
              ) {
                this.pastSurgicalHistory.other = pastSurgicalHistoryItem;
                this.surgicalHistoryControl.setValue("false");
              }
            }
          } else {
            this.pastSurgicalHistory.other = "";
            this.surgicalHistoryControl.setValue("false");
          }
          this.ref.detectChanges();
        }
      });

    /*============== Listner for  Social History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/socialHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.socialHistory = {
            none: false,
            alcohol: false,
            drugs: false,
            tobacco: false,
          };
          if (JSON.parse(Snapshot.val()) !== "") {
            for (let socialHistoryItem of JSON.parse(Snapshot.val())) {
              console.log("social", socialHistoryItem);
              // if (socialHistoryItem === "Unknown") {
              //   this.socialHistory.unknown = true;
              // }
              if (socialHistoryItem === "None") {
                this.socialHistory.none = true;
              }
              if (socialHistoryItem === "Alcohol") {
                this.socialHistory.alcohol = true;
              }
              if (socialHistoryItem === "Drugs") {
                this.socialHistory.drugs = true;
              }
              if (socialHistoryItem === "Tobacco") {
                this.socialHistory.tobacco = true;
              }
            }
          }
          this.ref.detectChanges();
        }
      });

    /*============== Listner for  Family History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIDFromQuery + "/familyHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.familyHistory = {
            none: false,
            unknown: false,
            mi: false,
            stroke: false,
            cancer: false,
            seizure: false,
            other:""
          };
          if (JSON.parse(Snapshot.val()) !== "") {
            for (let familyHistoryItem of JSON.parse(Snapshot.val())) {
              console.log("family", familyHistoryItem);
              if (familyHistoryItem === "Unknown") {
                this.familyHistory.unknown = true;
              }
              if (familyHistoryItem === "None") {
                this.familyHistory.none = true;
              }
              if (familyHistoryItem === "MI") {
                this.familyHistory.mi = true;
              }
              if (familyHistoryItem === "Stroke") {
                this.familyHistory.stroke = true;
              }
              if (familyHistoryItem === "Cancer") {
                this.familyHistory.cancer = true;
              }
              if (familyHistoryItem === "Seizure") {
                this.familyHistory.seizure = true;
              }
              if (
                familyHistoryItem !== "Unknown" &&
                familyHistoryItem !== "None" &&
                familyHistoryItem !== "MI" &&
                familyHistoryItem !== "Stroke" &&
                familyHistoryItem !== "Cancer" &&
                familyHistoryItem !== "Seizure"
                
              ) {
                this.familyHistory.other = familyHistoryItem;
              }
            }
          }
          this.ref.detectChanges();
        }
      });


      /*============== Listner for Tobacco ============*/
    firebase
    .database()
    .ref(
      "intakeData/stroke/" + this.receivedCallIDFromQuery + "/tobacco"
    )
    .on("value", (Snapshot) => {
      if (Snapshot.val() !== null) {
        this.tobacco = {
          none: false,
          chewing: false,
          vapepen: false,
          unknown: false,
          cigarettes: false,
          other:""
        };
        if (JSON.parse(Snapshot.val()) !== "") {
          for (let tobaccoItem of JSON.parse(Snapshot.val())) {
            console.log("tobacco", tobaccoItem);
          
            if (tobaccoItem === "None") {
              this.tobacco.none = true;
            }
            if (tobaccoItem === "Chewing") {
              this.tobacco.chewing = true;
            }
            if (tobaccoItem === "Vape Pen") {
              this.tobacco.vapepen = true;
            }
            if (tobaccoItem === "Unknown") {
              this.tobacco.unknown = true;
            }
            // if(tobaccoItem !=="Cigarettes"){
            //   this.cigaretteSection = false;
            // }
            if (tobaccoItem === "Cigarettes") {
      
              this.cigaretteSection = true;
              this.tobacco.cigarettes = true;
            } 
            
            if (
              tobaccoItem !== "None" &&
              tobaccoItem !== "Chewing" &&
              tobaccoItem !== "Vape Pen" &&
              tobaccoItem !== "Unknown" &&
              tobaccoItem !== "Cigarettes"
            ) {
              this.tobacco.other = tobaccoItem;
            }
          }
        }
        this.ref.detectChanges();
      }
    });

    

          /*============== Listner for Drugs ============*/
          firebase
          .database()
          .ref(
            "intakeData/stroke/" + this.receivedCallIDFromQuery + "/drugs"
          )
          .on("value", (Snapshot) => {
            if (Snapshot.val() !== null) {
              this.drugs = {
                none: false,
                cocaine: false,
                lsd: false,
                marijuana: false,
                heroine: false,
                methamphetamine: false,
                unknown: false,
                other:""
              };
              if (JSON.parse(Snapshot.val()) !== "") {
                for (let drugsItem of JSON.parse(Snapshot.val())) {
                  console.log("drugs", drugsItem);
                  if (drugsItem === "None") {
                    this.drugs.none = true;
                    
                  }
                  if (drugsItem === "Cocaine") {
                    this.drugs.cocaine = true;
                    
                  }
                  if (drugsItem === "LSD") {
                    this.drugs.lsd = true;
                    
                  }
                  if (drugsItem === "Marijuana") {
                    this.drugs.marijuana = true;
                    
                  }
                  if (drugsItem === "Heroine") {
                    this.drugs.heroine = true;
                    
                  }
                  if (drugsItem === "Methamphetamine") {
                    this.drugs.methamphetamine = true;
                    
                  }
                  if (drugsItem === "Unknown") {
                    this.drugs.unknown = true;
                    
                  }
                  if (
                    drugsItem !== "None" &&
                    drugsItem !== "Cocaine" &&
                    drugsItem !== "LSD" &&
                    drugsItem !== "Marijuana" &&
                    drugsItem !== "Heroine" &&
                    drugsItem !== "Methamphetamine" &&
                    drugsItem !== "Unknown"
                  ) {
                    this.drugs.other = drugsItem;
                    
                  }
                }
              }
              this.ref.detectChanges();
            }
          });

    firebase
      .database()
      .ref("doctorAvailability/")
      .on("value", (snapshot) => {
        if (snapshot.hasChild(this.loggedInUser)) {
          // this.availabilityStatus = true;
          this.authService.isDoctorAvail = true;
          this.ref.detectChanges();
        } else {
          // this.availabilityStatus = false;
          this.authService.isDoctorAvail = false;
          this.ref.detectChanges();
        }
      });

    

  }
  ngAfterViewChecked() {}
  @HostListener("window:beforeunload", ["$event"])
  onPopState(event) {
    var confirmationMessage = "o/";
    (event || window.event).returnValue = confirmationMessage;
    return confirmationMessage;
  }
  collapseOrExpandContainer(event) {
    this.allSizes = this.splitInstance.getSizes();
    this.sizeOfDiv1 = this.allSizes[0];
    this.sizeOfDiv2 = this.allSizes[1];
    this.sizeOfDiv3 = this.allSizes[2];
    if (event.target.id === "showBtn1") {
      if (this.expandCollapseDivStatus) {
        localStorage.setItem("size1", this.sizeOfDiv1);
        localStorage.setItem("size2", this.sizeOfDiv2);
        localStorage.setItem("size3", this.sizeOfDiv3);
        this.remainingSize = this.sizeOfDiv1 + this.sizeOfDiv2 - 1;
        this.splitInstance.setSizes([1, this.remainingSize, this.sizeOfDiv3]);
        this.expandCollapseDivStatus = false;
      } else {
        localStorage.setItem("size2", this.sizeOfDiv2);
        localStorage.setItem("size3", this.sizeOfDiv3);
        this.remainingSize =
          Number(100) -
          (Number(localStorage.getItem("size1")) +
            Number(localStorage.getItem("size3")));
        this.splitInstance.setSizes([
          Number(localStorage.getItem("size1")),
          this.remainingSize,
          Number(localStorage.getItem("size3")),
        ]);
        this.expandCollapseDivStatus = true;
      }
    } else {
      if (this.expandCollapseDivStatusForGUtter2) {
        localStorage.setItem("div2size1", this.sizeOfDiv1);
        localStorage.setItem("div2size2", this.sizeOfDiv2);
        localStorage.setItem("div2size3", this.sizeOfDiv3);
        this.remainingSize = this.sizeOfDiv2 + this.sizeOfDiv3 - 1;
        this.splitInstance.setSizes([this.sizeOfDiv1, this.remainingSize, 1]);
        this.expandCollapseDivStatusForGUtter2 = false;
      } else {
        // alert('showBtn2 else');
        localStorage.setItem("div2size1", this.sizeOfDiv1);
        localStorage.setItem("div2size2", this.sizeOfDiv2);
        this.remainingSize =
          100 -
          (Number(localStorage.getItem("div2size1")) +
            Number(localStorage.getItem("div2size3")));
        // alert('setting sizes: first' + Number(localStorage.getItem('div2size1')) + 'middle' + this.remainingSize + 'last' +
        // Number(localStorage.getItem('div2size3')));
        this.splitInstance.setSizes([
          Number(localStorage.getItem("div2size1")),
          this.remainingSize,
          Number(localStorage.getItem("div2size3")),
        ]);
        this.expandCollapseDivStatusForGUtter2 = true;
      }
    }
  }

  setmaxHeight() {
    this.firstHeight = document.getElementById(
      "patientDetailsdiv"
    ).offsetHeight;
    this.lastheight = document.getElementById("stickyDiv").offsetHeight;
    this.totalheight = document.getElementById("totalheight").offsetHeight;
    this.remainingheight =
      this.totalheight - (this.firstHeight + this.lastheight);
    // this.remainingheightForNormal = this.totalheight - this.firstHeight;
    console.log("patientDetailsdiv height is :" + this.firstHeight);
    console.log("Last stickyDiv height is : " + this.lastheight);
    console.log("Total height is : " + this.totalheight);
    console.log("Remianing height is : " + this.remainingheight);
  }
  applyheight() {
    let heightstyle = {
      "max-height": this.remainingheight - 21 + "px",
    };
    return heightstyle;
  }
  applyheightForNormal() {
    let heightstyle = {
      "max-height": this.remainingheight - 30 + "px",
    };
    return heightstyle;
  }

  // on mat-tab changed event hide save & sign and release btn
  public tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log("tabChangeEvent => ", tabChangeEvent);
    var index = tabChangeEvent.index;
    switch (index) {
      case 0: {
        this.showBtns = true;
        break;
      }
      case 1: {
        this.showBtns = false;
        break;
      }
    }
  };

  setPatientDetails(patientId) {
    // return new Promise((resolve, reject) => {
    const params = new HttpParams().set("patientId", patientId);
    this.http
      .get(this.authService.baseURL + "/PatientDataRetrieveTest", {
        params: params,
      })
      .subscribe(
        (data) => {
          if (data["Message"] === "Success") {
            console.log("this is query params data", data);
            this.retrievedData = data["data"];
            this.patientId = this.retrievedData["patientId"]; //
            this.receivedUserID = this.retrievedData["userId"]; // null
            this.receivedCallID = this.retrievedData["callId"]; //
            // this.authService.endCallId = this.getdata['callId'];
            console.log("received user id", this.receivedUserID);
            // this.patientSettings.temp = this.patientSettingsRetrieve.temp;
            // this.patientSettings.tempUnit = this.patientSettingsRetrieve.tempUnit;
            // this.patientSettings.weight = this.patientSettingsRetrieve.weight;
            // this.patientSettings.weightUnit = this.patientSettingsRetrieve.weightUnit;
            this.patientSettings.physician = this.retrievedData.physician;
            this.patientSettings.nurse = this.retrievedData.nurse;
            this.patientSettings.historian = this.retrievedData.historian;
            this.patientSettings.hospitalCampus = this.retrievedData.hospitalCampus;
            this.patientSettings.arrivalToEdDate = this.reverseDate(
              this.retrievedData.arrivalToEdDate
            );
            this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
            this.patientSettings.bloodPressure = this.retrievedData.bloodPressure;
            this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
            this.patientSettings.heartRate = this.retrievedData.heartRate;
            this.patientSettings.rr = this.retrievedData.rr;
            this.patientSettings.bloodGlucose = this.retrievedData.bloodGlucose;
            // this.patientSettingsRetrieve.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
            this.patientSettings.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
            if (this.retrievedData.tpaEligibilityUnknown === true) {
              this.statusLastKnownNormal = true;
              // this.patientSettings.lastKnownNormalDate = this.reverseDate(this.retrievedData.lastKnownNormalDate);
              this.patientSettingsRetrieve.lastKnownNormalDate = String(
                this.defaultDate
              );
              this.patientSettingsRetrieve.lastKnownNormalTime = "";
              this.patientSettings.lastKnownNormalTime = "";
            } else {
              // this.patientSettingsRetrieve.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
              this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
              this.patientSettings.lastKnownNormalDate = this.reverseDate(
                this.retrievedData.lastKnownNormalDate
              );
              // this.patientSettingsRetrieve.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
              this.statusLastKnownNormal = false;
            }
            if (this.retrievedData.tpaEligibilityUnknown === true) {
              this.consultNoteDefaultUnknown = "Unknown";
              this.timePoints.timeOfLastKnownNormal = "Unknown";
              // this.patientSettingsRetrieve.tpaEligibilityUnknown = true;
              this.patientSettings.tpaEligibilityUnknown = true;
            } else {
              // this.patientSettingsRetrieve.tpaEligibilityUnknown = false;
              this.patientSettings.tpaEligibilityUnknown = false;
              // this.consultNoteDefaultUnknown = this.patientSettingsRetrieve.lastKnownNormalDate +
              //  this.patientSettingsRetrieve.lastKnownNormalTime;
              this.timePoints.timeOfLastKnownNormal =
                this.patientSettingsRetrieve.lastKnownNormalDate + " ";
              if (this.patientSettings.lastKnownNormalTime !== "") {
                // this.timePoints.timeOfLastKnownNormal = this.patientSettingsRetrieve.lastKnownNormalDate + ' ' +
                // this.patientSettingsRetrieve.lastKnownNormalTime + ' hrs';
                this.timePoints.timeOfLastKnownNormal =
                  this.patientSettings.lastKnownNormalTime + "hrs";
              }
            }
            if (this.retrievedData.weightUnit === "kg") {
              this.patientSettings.weightUnit = "kg";
              this.patientSettings.weight = this.retrievedData.weight;
            } else {
              this.patientSettings.weightUnit = "lbs";
              this.patientSettings.weight = this.retrievedData.weight;
            }
            if (this.retrievedData.tempUnit === "celsius") {
              this.patientSettings.tempUnit = "celsius";
              this.patientSettings.temp = this.retrievedData.temp;
            } else {
              this.patientSettings.tempUnit = "fahrenheit";
              this.patientSettings.temp = this.retrievedData.temp;
            }
            this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;
            this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
            this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
            this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
            this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
            this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
            this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
            this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
            if (this.retrievedData["reason"] !== "") {
              this.retrieveArrayForReasonForConsult = JSON.parse(
                this.retrievedData["reason"]
              );
            }
            if (this.retrievedData["reason"] !== "") {
              for (let reason of this.retrieveArrayForReasonForConsult) {
                this.reasonForConsult.other = reason;
              }
            }
            // re-initialize so that if we untick checkboxes they are updated
            this.symptoms = {
              rightSidedWeakness: "",
    leftSidedWeakness: "",
    rightSidedNumbness: "",
    leftSidedNumbness: "",
    leftGazePreference: "",
    rightGazePreference: "",
    leftFacialDroop: "",
    rightFacialDroop: "",
    aphasia: "",
    slurredSpeech: "",
    blurryVision: "",
    doubleVision: "",
    peripheralVisionLoss: "",
    alteredMentalStatus: "",
    headache: "",
    dizziness: "",
    unsteadyGait: "",
    vertigo: "",
    visionLoss: "",
    seizure: "",
    other: "",
            };

            this.antiCoagulationTherapyMedications = {
              warfarin: false,
              apixaban: false,
              dabigatran: false,
              rivaroxaban: false,
              enoxaparin: false,
              heparin: false,
              unknown: false,
              other: ""
            }


            this.pastMedicalHistory = {
              unknown: false,
              none: false,
              htn: false,
              hld: false,
              atrialFibrillation: false,
              giBleed: false,
              congestiveHeartFailure: false,
              mi: false,
              dm: false,
              priorStroke: false,
              dementia: false,
              ckd: false,
              cerebralAneurysm: false,
              brainTumor: false,
              seizure: false,
              migraine: false,
              cancer: false,
              other:""
            };
            this.socialHistory = {
              none: false,
              alcohol: false,
              drugs: false,
              tobacco: false,
            };
            this.familyHistory = {
              none:false,
              unknown: false,
              mi: false,
              stroke: false,
              cancer: false,
              seizure: false,
              other:""
            };
            this.tobacco = {
              none: false,
              chewing: false,
              vapepen: false,
              unknown: false,
              cigarettes: false,
              other: ""
            }
            this.drugs = {
              none: false,
              cocaine: false,
              lsd: false,
              marijuana: false,
              heroine: false,
              methamphetamine: false,
              unknown: false,
              other: ""
            }
            if (this.retrievedData["symptoms"] !== "") {
              for (let symptom of JSON.parse(this.retrievedData.symptoms)) {
                console.log(symptom);
                if (symptom === "Right sided weakness") {
                  this.symptoms.rightSidedWeakness = "true";
                }
                if (symptom === "Left sided weakness") {
                  this.symptoms.leftSidedWeakness = "true";
                }
                if (symptom === "Right sided numbness") {
                  this.symptoms.rightSidedNumbness = "true";
                }
                if (symptom === "Left sided numbness") {
                  this.symptoms.leftSidedNumbness = "true";
                }
                if (symptom === "Left gaze preference") {
                  this.symptoms.leftGazePreference = "true";
                }
                if (symptom === "Right gaze preference") {
                  this.symptoms.rightGazePreference = "true";
                }
                if (symptom === "Left facial droop") {
                  this.symptoms.leftFacialDroop = "true";
                }
                if (symptom === "Right facial droop") {
                  this.symptoms.rightFacialDroop = "true";
                }
                if (symptom === "Aphasia") {
                  this.symptoms.aphasia = "true";
                }
                if (symptom === "Slurred speech") {
                  this.symptoms.slurredSpeech = "true";
                }
                if (symptom === "Blurry vision") {
                  this.symptoms.blurryVision = "true";
                }
                if (symptom === "Double vision") {
                  this.symptoms.doubleVision = "true";
                }
                if (symptom === "Peripheral vision loss") {
                  this.symptoms.peripheralVisionLoss = "true";
                }
                if (symptom === "Altered mental status") {
                  this.symptoms.alteredMentalStatus = "true";
                }
                if (symptom === "Headache") {
                  this.symptoms.headache = "true";
                }
                if (symptom === "Dizziness") {
                  this.symptoms.dizziness = "true";
                }
                if (symptom === "Unsteady gait") {
                  this.symptoms.unsteadyGait = "true";
                }
                if (symptom === "Vertigo") {
                  this.symptoms.vertigo = "true";
                }
                if (symptom === "Vision loss") {
                  this.symptoms.visionLoss = "true";
                }
                if (symptom === "Seizure") {
                  this.symptoms.seizure = "true";
                }
                if (
                  symptom !== "Right side weakness" &&
              symptom !== "Left side weakness" &&
              symptom !== "Right sided numbness" &&
              symptom !== "Left sided numbness" &&
              symptom !== "Left gaze preference" &&
              symptom !== "Right gaze preference" &&
              symptom !== "Left facial droop" &&
              symptom !== "Right facial droop" &&
              symptom !== "Aphasia" &&
              symptom !== "Slurred speech" &&
              symptom !== "Blurry vision" &&
              symptom !== "Double vision" &&
              symptom !== "Peripheral vision loss" &&
              symptom !== "Altered mental status" &&
              symptom !== "Headache" &&
              symptom !== "Dizziness" &&
              symptom !== "Unsteady gait" &&
              symptom !== "Vertigo" &&
              symptom !== "Vision loss" &&
              symptom !== "Seizure"
                ) {
                  this.symptoms.other = symptom;
                }
              }
            }

            if (this.retrievedData["medicationForAntiplateletTherapy"] !== "") {
              for (let medicationForAntiplateletTherapyItem of JSON.parse(
                this.retrievedData.medicationForAntiplateletTherapy
              )) {
                if (medicationForAntiplateletTherapyItem === "Aspirin") {
                  this.antiPlateletTherapyMedications.aspirin = true;
                }
                if (medicationForAntiplateletTherapyItem === "Clopidogrel") {
                  this.antiPlateletTherapyMedications.none = true;
                }
                if (medicationForAntiplateletTherapyItem === "Aspirin & Clopidogrel") {
                  this.antiPlateletTherapyMedications.aspirinAndClopidogrel = true;
                }
                if (medicationForAntiplateletTherapyItem === "Ticagrelor") {
                  this.antiPlateletTherapyMedications.ticagrelor = true;
                }
                if (medicationForAntiplateletTherapyItem === "Unable to determine") {
                  this.antiPlateletTherapyMedications.unableToDetermine = true;
                }
                if (medicationForAntiplateletTherapyItem === "None") {
                  this.antiPlateletTherapyMedications.none = true;
                }
                if (medicationForAntiplateletTherapyItem === "Unknown") {
                  this.antiPlateletTherapyMedications.unknown = true;
                }
                if (
                  medicationForAntiplateletTherapyItem !== "Aspirin" &&
                  medicationForAntiplateletTherapyItem !== "Clopidogrel" &&
                  medicationForAntiplateletTherapyItem !== "Aspirin & Clopidogrel" &&
                  medicationForAntiplateletTherapyItem !== "Ticagrelor" &&
                  medicationForAntiplateletTherapyItem !== "Unable to determine" &&
                  medicationForAntiplateletTherapyItem !== "None" &&
                  medicationForAntiplateletTherapyItem !== "Unknown"
                ) {
                  this.antiPlateletTherapyMedications.other = medicationForAntiplateletTherapyItem;
                }
              }
            }

            if (this.retrievedData["medicationForAnticoagulationTherapy"] !== "") {
              for (let medicationForAnticoagulationTherapyItem of JSON.parse(
                this.retrievedData.medicationForAnticoagulationTherapy
              )) {
                if (medicationForAnticoagulationTherapyItem === "Aspirin") {
                  this.antiPlateletTherapyMedications.aspirin = true;
                }
                if (medicationForAnticoagulationTherapyItem === "Clopidogrel") {
                  this.antiPlateletTherapyMedications.none = true;
                }
                if (medicationForAnticoagulationTherapyItem === "Aspirin & Clopidogrel") {
                  this.antiPlateletTherapyMedications.aspirinAndClopidogrel = true;
                }
                if (medicationForAnticoagulationTherapyItem === "Ticagrelor") {
                  this.antiPlateletTherapyMedications.ticagrelor = true;
                }
                if (medicationForAnticoagulationTherapyItem === "Unable to determine") {
                  this.antiPlateletTherapyMedications.unableToDetermine = true;
                }
                if (medicationForAnticoagulationTherapyItem === "None") {
                  this.antiPlateletTherapyMedications.none = true;
                }
                if (medicationForAnticoagulationTherapyItem === "Unknown") {
                  this.antiPlateletTherapyMedications.unknown = true;
                }
                if (
                  medicationForAnticoagulationTherapyItem !== "Aspirin" &&
                  medicationForAnticoagulationTherapyItem !== "Clopidogrel" &&
                  medicationForAnticoagulationTherapyItem !== "Aspirin & Clopidogrel" &&
                  medicationForAnticoagulationTherapyItem !== "Ticagrelor" &&
                  medicationForAnticoagulationTherapyItem !== "Unable to determine" &&
                  medicationForAnticoagulationTherapyItem !== "None" &&
                  medicationForAnticoagulationTherapyItem !== "Unknown"
                ) {
                  this.antiCoagulationTherapyMedications.other = medicationForAnticoagulationTherapyItem;
                }
              }
            }

            if (this.retrievedData["medicalHistory"] !== "") {
              for (let pastMedicalHistoryItem of JSON.parse(
                this.retrievedData.medicalHistory
              )) {
                if (pastMedicalHistoryItem === "Unknown") {
                  this.pastMedicalHistory.unknown = true;
                }
                if (pastMedicalHistoryItem === "None") {
                  this.pastMedicalHistory.none = true;
                }
                if (pastMedicalHistoryItem === "HTN") {
                  this.pastMedicalHistory.htn = true;
                }
                if (pastMedicalHistoryItem === "HLD") {
                  this.pastMedicalHistory.hld = true;
                }
                if (pastMedicalHistoryItem === "Atrial Fibrillation") {
                  this.pastMedicalHistory.atrialFibrillation = true;
                }
                if (pastMedicalHistoryItem === "GI Bleed") {
                  this.pastMedicalHistory.giBleed = true;
                }
                if (pastMedicalHistoryItem === "Congestive Heart Failure") {
                  this.pastMedicalHistory.congestiveHeartFailure = true;
                }
                if (pastMedicalHistoryItem === "MI") {
                  this.pastMedicalHistory.mi = true;
                }
                if (pastMedicalHistoryItem === "DM") {
                  this.pastMedicalHistory.dm = true;
                }
                if (pastMedicalHistoryItem === "Prior Stroke") {
                  this.pastMedicalHistory.priorStroke = true;
                }
                if (pastMedicalHistoryItem === "Dementia") {
                  this.pastMedicalHistory.dementia = true;
                }
                if (pastMedicalHistoryItem === "CKD") {
                  this.pastMedicalHistory.ckd = true;
                }
                if (pastMedicalHistoryItem === "Cerebral Aneurysm") {
                  this.pastMedicalHistory.cerebralAneurysm = true;
                }
                if (pastMedicalHistoryItem === "Brain Tumor") {
                  this.pastMedicalHistory.brainTumor = true;
                }
                if (pastMedicalHistoryItem === "Seizure") {
                  this.pastMedicalHistory.seizure = true;
                }
                if (pastMedicalHistoryItem === "Migraine") {
                  this.pastMedicalHistory.migraine = true;
                }
                if (pastMedicalHistoryItem === "Cancer") {
                  this.pastMedicalHistory.cancer = true;
                }
                if (
                  pastMedicalHistoryItem !== "Unknown" &&
                  pastMedicalHistoryItem !== "None" &&
                  pastMedicalHistoryItem !== "HTN" &&
                  pastMedicalHistoryItem !== "HLD" &&
                  pastMedicalHistoryItem !== "Atrial Fibrillation" &&
                  pastMedicalHistoryItem !== "GI Bleed" &&
                  pastMedicalHistoryItem !== "Cerebral Aneurysm" &&
                  pastMedicalHistoryItem !== "MI" &&
                  pastMedicalHistoryItem !== "DM" &&
                  pastMedicalHistoryItem !== "Prior Stroke" &&
                  pastMedicalHistoryItem !== "Dementia" &&
                  pastMedicalHistoryItem !== "CKD" &&
                  pastMedicalHistoryItem !== "Seizure" &&
                  pastMedicalHistoryItem !== "Migraine" &&
                  pastMedicalHistoryItem !== "Cancer"
                ) {
                  this.pastMedicalHistory.other = pastMedicalHistoryItem;
                }
              }
            }
            if (this.retrievedData["surgicalHistory"] !== "") {
              for (let pastSurgicalHistoryItem of JSON.parse(
                this.retrievedData["surgicalHistory"]
              )) {
                if (pastSurgicalHistoryItem === "Unknown") {
                  this.pastSurgicalHistory.surgicalHistory = "Unknown";
                  this.pastSurgicalHistory.other = "";
                }
                if (pastSurgicalHistoryItem === "None") {
                  this.pastSurgicalHistory.surgicalHistory = "None";
                  this.pastSurgicalHistory.other = "";
                }
                if (
                  pastSurgicalHistoryItem !== "Unknown" &&
                  pastSurgicalHistoryItem !== "None"
                ) {
                  this.pastSurgicalHistory.other = pastSurgicalHistoryItem;
                  this.surgicalHistoryControl.setValue("false");
                }
              }
            }
            if (this.retrievedData["currentMedications"] !== "") {
              for (let currentMedicationsItem of JSON.parse(
                this.retrievedData["currentMedications"]
              )) {
                if (currentMedicationsItem === "Unknown") {
                  this.medications.medicationsOptions = "Unknown";
                  this.medications.medicationsCustom = "";
                }
                if (currentMedicationsItem === "None") {
                  this.medications.medicationsOptions = "None";
                  this.medications.medicationsCustom = "";
                }
                if (
                  currentMedicationsItem !== "Unknown" &&
                  currentMedicationsItem !== "None" &&
                  currentMedicationsItem !== "false"
                ) {
                  this.medications.medicationsCustom = currentMedicationsItem;
                  this.medicationsOptionsControl.setValue("false");
                }
              }
            }
            if (this.retrievedData["allergies"] !== "") {
              for (let allergiesItem of JSON.parse(
                this.retrievedData["allergies"]
              )) {
                if (allergiesItem === "Unknown") {
                  this.allergies.allergiesOptions = "Unknown";
                  this.allergies.allergiesCustom = "";
                }
                if (allergiesItem === "NKDA") {
                  this.allergies.allergiesOptions = "NKDA";
                  this.allergies.allergiesCustom = "";
                }
                if (
                  allergiesItem !== "Unknown" &&
                  allergiesItem !== "NKDA" &&
                  allergiesItem !== "false"
                ) {
                  this.allergies.allergiesCustom = allergiesItem;
                  this.allergiesOptionsControl.setValue("false");
                  this.clearRadioOptionsAllergies();
                }
              }
            }
            if (this.retrievedData["familyHistory"] !== "") {
              for (let familyHistoryItem of JSON.parse(
                this.retrievedData.familyHistory
              )) {
                console.log("family", familyHistoryItem);
                if (familyHistoryItem === "Unknown") {
                  this.familyHistory.unknown = true;
                }
                if (familyHistoryItem === "None") {
                  this.familyHistory.none = true;
                }
                if (familyHistoryItem === "MI") {
                  this.familyHistory.mi = true;
                }
                if (familyHistoryItem === "Stroke") {
                  this.familyHistory.stroke = true;
                }
                if (familyHistoryItem === "Cancer") {
                  this.familyHistory.cancer = true;
                }
                if (familyHistoryItem === "Seizure") {
                  this.familyHistory.seizure = true;
                }
                if (
                  familyHistoryItem !== "Unknown" &&
                  familyHistoryItem !== "None" &&
                  familyHistoryItem !== "MI" &&
                  familyHistoryItem !== "Stroke" &&
                  familyHistoryItem !== "Cancer" &&
                  familyHistoryItem !== "Seizure"
                  
                ) {
                  this.familyHistory.other = familyHistoryItem;
                }
              }
            }

            if (this.retrievedData["patientTobaccoConsumption"] !== "") {
              for (let tobaccoItem of JSON.parse(
                this.retrievedData.tobacco
              )) {
                console.log("tobacco", tobaccoItem);
                if (tobaccoItem === "None") {
                  this.tobacco.none = true;
                }
                if (tobaccoItem === "Chewing") {
                  this.tobacco.chewing = true;
                }
                if (tobaccoItem === "Vape Pen") {
                  this.tobacco.vapepen = true;
                }
                if (tobaccoItem === "Unknown") {
                  this.tobacco.unknown = true;
                }
                // if(tobaccoItem !=="Cigarettes"){
                //   this.cigaretteSection = false;
                // }
                if (tobaccoItem === "Cigarettes") {
                  this.tobacco.cigarettes = true;
                }
                if (
                  tobaccoItem !== "None" &&
                  tobaccoItem !== "Chewing" &&
                  tobaccoItem !== "Vape Pen" &&
                  tobaccoItem !== "Unknown" &&
                  tobaccoItem !== "Cigarettes"
                ) {
                  this.tobacco.other = tobaccoItem;
                }
              }
            }

            if (this.retrievedData["patientDrugsConsumption"] !== "") {
              for (let drugsItem of JSON.parse(
                this.retrievedData.drugs
              )) {
                console.log("drugs", drugsItem);
                if (drugsItem === "None") {
                  this.drugs.none = true;
                }
                if (drugsItem === "Cocaine") {
                  this.drugs.cocaine = true;
                }
                if (drugsItem === "LSD") {
                  this.drugs.lsd = true;
                }
                if (drugsItem === "Marijuana") {
                  this.drugs.marijuana = true;
                }
                if (drugsItem === "Heroine") {
                  this.drugs.heroine = true;
                }
                if (drugsItem === "Methamphetamine") {
                  this.drugs.methamphetamine = true;
                }
                if (drugsItem === "Unknown") {
                  this.drugs.unknown = true;
                }
                if (
                  drugsItem !== "None" &&
                  drugsItem !== "Cocaine" &&
                  drugsItem !== "LSD" &&
                  drugsItem !== "Marijuana" &&
                  drugsItem !== "Heroine" &&
                  drugsItem !== "Methamphetamine" &&
                  drugsItem !== "Unknown"
                ) {
                  this.drugs.other = drugsItem;
                }
              }
            }

            if (this.retrievedData["socialHistory"] !== "") {
              for (let socialHistoryItem of JSON.parse(
                this.retrievedData.socialHistory
              )) {
                console.log("social", socialHistoryItem);
                // if (socialHistoryItem === "Unknown") {
                //   this.socialHistory.unknown = true;
                // }
                if (socialHistoryItem === "None") {
                  this.socialHistory.none = true;
                }
                if (socialHistoryItem === "Alcohol") {
                  this.socialHistory.alcohol = true;
                }
                if (socialHistoryItem === "Drugs") {
                  this.socialHistory.drugs = true;
                }
                if (socialHistoryItem === "Tobacco") {
                  this.socialHistory.tobacco = true;
                }
              }
            }
            $(".loader").fadeOut(2000);
            $(".loader_msg").hide();
            this.loader.complete();
            // } else {
            //     $('.loader').fadeOut(2000);
            //     $('.loader_msg').hide();
            // }
            // resolve();
          } else {
            this.loader.complete();
            $(".loader").fadeOut(2000);
            $(".loader_msg").hide();
          }
        },
        (err) => {
          console.log(err);
          this.loader.complete();
          $(".loader").fadeOut(2000);
          $(".loader_msg").hide();
        }
      );
    // });
  }

  /*====================== Receive Call Function ============*/
  ReceivedCall(callId) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams()
      .set("callId", callId)
      .set("userId", this.loggedInUser)
      .set("doctorFullName",this.loggedUser);
    this.http
      .post(this.authService.baseURL + "/ReceiveCall", params, {
        headers: headers,
      })
      .subscribe((data) => {
        console.log("receive call", data);
        if (data["Message"] === "Success") {
          // this.connectTOVideoSession();
        } else {
          // this.connectTOVideoSession();
        }
      });
  }

  connectTOVideoSession() {
    this.opentokService
      .joinSession(this.video.sessionId, this.video.token)
      .then((session: OT.Session) => {
        this.session = session;
        this.session.on("streamCreated", (event) => {
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
          this.updateCallTimeDetails();
        });
        this.session.on("streamDestroyed", (event) => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
      .then(() => this.opentokService.connect())
      .catch((err) => {
        console.error(err);
        alert(
          "Unable to connect. Make sure you have updated the config.ts file with your OpenTok details."
        );
      });
  }
  updateCallTimeDetails() {
    const url = window.location.search;
    const urlparam = url.split("&");
    const receivedCallId = urlparam[0].split("=");
    const isInitiated = urlparam[1].split("=");
    this.callId = receivedCallId[1];
    var callIdParam = receivedCallId[1];
    this.isCallInitiated = isInitiated[1];
    var updates = {};
    updates[
      "intakesData/stroke/" +
        this.callId +
        "/videoCallDetails" +
        "/connectedTime"
    ] = firebase.database.ServerValue.TIMESTAMP;
    return firebase.database().ref().update(updates);
  }
  /*=================== Call Back to Hospital flow ==================*/
  startVideoSession() {
    return new Promise((resolve, reject) => {
      this.opentokService
        .initSession()
        .then((session: OT.Session) => {
          this.session = session;
          this.video.sessionId = session["sessionId"];
          this.video.token = session["token"];
          this.session.on("streamCreated", (event) => {
            this.streams.push(event.stream);
            this.changeDetectorRef.detectChanges();
          });
          this.session.on("streamDestroyed", (event) => {
            const idx = this.streams.indexOf(event.stream);
            if (idx > -1) {
              this.streams.splice(idx, 1);
              this.changeDetectorRef.detectChanges();
            }
          });
          resolve();
        })
        .then(() => this.opentokService.connect())
        .catch((err) => {
          console.error(err);
          alert(
            "Unable to connect. Make sure you have updated the config.ts file with your OpenTok details."
          );
        });
    });
  }

  callBackToHospital() {
    const callIdRef: firebase.database.Reference = firebase
      .database()
      .ref("intakesData/stroke/" + this.callId + "/videoCallDetails/");
    callIdRef.update({
      sessionId: this.video.sessionId,
      videoToken: this.video.token,
      time: firebase.database.ServerValue.TIMESTAMP,
      endCallStatus: false,
    });
    const videoCallActionsRef: firebase.database.Reference = firebase
      .database()
      .ref("/videoCallActions/stroke/" + this.callId + "/actions/");
    videoCallActionsRef.push({
      action: "callBack",
      info: "Call is initiated by Doctor",
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: this.loggedUser,
    });
    const videoCallActionStatusRef: firebase.database.Reference = firebase
      .database()
      .ref("videoCallActions/stroke/" + this.callId);
    videoCallActionStatusRef.update({
      status: "callBackByDoctor",
    });
    const params = new HttpParams()
      .set("callId", this.callId)
      .set("sessionId", this.video.sessionId)
      .set("videoToken", this.video.token)
      .set("doctorId", this.loggedInUser);
      const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    this.http
      .post(this.authService.baseURL + "/CallbackToHospital", params, {
        headers: headers,
      })
      .subscribe((Response) => {
        console.log("CallBackToHospital", Response["message"]);
      });
  }

  viewImage(fileid, filetype, fileurl) {
    if (filetype === "Normal") {
      console.log("normal", fileurl);
      window.open(fileurl, "_blank");
    } else {
      console.log("fileid to be given to auth SErvice", fileid);
      this.authService.setValue(fileid);
      const dialogRef = this.dialog.open(DicomComponent, {
        height: "400px",
        width: "900px",
      });
    }
  }
  reverseDate(date) {
    const dateArr = date.split("/");
    const modifiedDate = dateArr[0] + "/" + dateArr[1] + "/" + dateArr[2];
    // const d = new date(modifiedDate);
    // const dateConverted = d.toUTCString();
    // return dateConverted;
    var convertedDate = new Date(modifiedDate);
    date = moment(convertedDate);
    return date;
  }

  displayFiles(patientID) {
    var headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    let params = new HttpParams().set("patientId", patientID);
    this.http
      .post(this.authService.baseURL + "/GetPreviousFiles", params, {
        headers: headers,
      })
      .subscribe(
        (data) => {
          if (data["Message"] === "success") {
            console.log("getprevious files", data);
            this.receivedImagesList = data["data"];
            console.log("1", this.receivedImagesList);
            for (let list of this.receivedImagesList) {
              console.log(list);
              this.imagesList.push(list);
              console.log("fileID", list.fileId);
            }
          } else {
            this.imagesList = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  saveNotes() {
    if (this.showErrorForCheckbox) {
      this.inputEl.focus();
      var elmnt = document.getElementById("tpaRecommendationDiv");
      elmnt.scrollIntoView();
      this.xpandStatus = true;
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox("");
    } else {
      var giveOrDoNotGiveTpaObject;
      var giveTpaStatus;
      var doNotGiveTpaStatus;
      var considerOrDoNotConsiderEmbolectomyStatus;
      var considerOrDoNotConsiderEmbolectomyObject;
      if (this.giveOrDoNotGiveTpa === "Give TPA") {
        giveOrDoNotGiveTpaObject = this.giveTpa;
        giveTpaStatus = true;
        doNotGiveTpaStatus = false;
      } else {
        // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj["doNotGiveTpaSelectedList"] = this.selectedReasonList;
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
        // } else {
        //   doNotGiveTpaStatus = false;
        //   giveTpaStatus = false;
        //   giveOrDoNotGiveTpaObject = {};
        // }
      }
      if (this.considerOrDoNotConsiderEmbolectomy === "Consider Embolectomy") {
        var considerOrNotEmbolectomyObj = {};
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
        considerOrDoNotConsiderEmbolectomyStatus = true;
      } else {
        // if (this.considerOrDoNotConsiderEmbolectomy === 'Do not consider Embolectomy') {
        var considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject[
          "considerOrNotSelectedList"
        ] = this.selectedReasonEmbolectomy;
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
        // } else {
        //   considerOrDoNotConsiderEmbolectomyStatus = '';
        //   considerOrDoNotConsiderEmbolectomyObject = {};
        // }
      }
      this.settingsForm.markAsPristine();
      this.settingsForm.updateValueAndValidity();
      this.loader.start();
      this.loader.set(10);
    
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      
      this.totalCigaretteConsumption = this.patientSettings.cigarettesPerDay+" "+this.patientSettings.cigarettesPerDayQuantity;
      const params = new HttpParams()
      .set("allergies", JSON.stringify(this.allergiesArray))
      .set("arrivalToEdDate", JSON.stringify(this.patientSettings.arrivalToEdDate ))
      .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
      .set("offset", JSON.stringify(this.offset))
      .set("bloodGlucose", this.patientSettings.bloodGlucose)
      .set("bloodPressure", this.patientSettings.bloodPressure)
      .set("callId", this.receivedCallIDFromQuery)
      .set("currentMedications", JSON.stringify(this.medicationsArray))
      .set("familyContactNumber", this.patientSettings.familyContactNumber)
      .set("familyHistory", JSON.stringify(this.familyHistoryArray))
      .set("heartRate", this.patientSettings.heartRate)
      .set("historian", this.patientSettings.historian)
      .set("hospitalCampus", this.patientSettings.hospitalCampus)
      .set("isFamilyBedside", this.patientSettings.familyAtBedside)
      .set("isICHHistory", this.patientSettings.historyOfIch)
      .set("isIntracranial", this.patientSettings.isIntracranial)
      .set("isNeoplasm", this.patientSettings.historyOfNeoplasm)
      .set("lastKnownNormalDate", JSON.stringify(this.patientSettings.lastKnownNormalDate))
      .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
      .set("meansOfArrival", JSON.stringify(this.meansOfArrivalObj))
      .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray))
      .set("milnPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
      .set("nurse", this.patientSettings.nurse)
      .set("oxygenSat", this.patientSettings.oxygenSat)
      .set("patientId", this.patientId)
      .set("physician", this.patientSettings.physician)
      .set("preArrivalNotification", this.patientSettings.preArrival)
      .set("isPatientOnAntiplatelet", this.patientSettings.patientOnAntiplatelet)
      .set("wasPatientOnAnticoagulation", this.patientSettings.patientOnAnticoagulation)
      .set("doesPatientConsumeAlcohol", this.patientSettings.patientAlcoholConsumption)
      .set("cigaretteCount", this.totalCigaretteConsumption)
      .set("medicationForAntiplateletTherapy", JSON.stringify(this.medicationsForAntiplateletTherapyArray))
      .set("medicationForAnticoagulationTherapy", JSON.stringify(this.medicationsForAnticoagulationTherapyArray))
      .set("patientDrugsConsumption", JSON.stringify(this.drugsArray))
      .set("reason", JSON.stringify(this.reasonForConsultArray))
      .set("rr", this.patientSettings.rr)
      .set("socialHistory", JSON.stringify(this.socialHistoryArray))
      .set("strokeInPrevious3Months", this.patientSettings.strokeInPrevious3Months)
      .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray))
      .set("symptoms", JSON.stringify(this.symptomsArray))
      .set("temp", this.patientSettings.temp)
      .set("tempUnit", this.patientSettings.tempUnit) 
      .set("tookCEPX", this.patientSettings.tookCEPX)
      .set("tpaEligibilityUnknown", JSON.stringify(this.patientSettings.tpaEligibilityUnknown))
      .set("userId", this.patientSettings.nurse)
      .set("fullName", this.patientSettings.nurseFullName)
      .set("videoSessionId", '')
      .set("videoToken", '')
      .set("weight", this.patientSettings.weight)
      .set("weightUnit", this.patientSettings.weightUnit)
      .set("type", '');
      this.http
        .post(this.authService.baseURL + "/IntiateStrokeNotification", params, {
          headers: headers,
        }).subscribe(
          (data)=>{
            console.log('API called ', data);
          }
        )

      // Convert InterPretation Date into String
      
      // if(this.userSelectedHemorrhage)
      // {
      //   this.hemorrhage.InterpretationDate = this.getStringDate(this.hemorrhage.InterpretationDate);
      // }
      // else
      // {
      //   this.nonHemorrhage.InterpretationDate = this.getStringDate(this.nonHemorrhage.InterpretationDate);
      // }
      
       
      const params_consultNotes = new HttpParams()
        .set("complaint", this.userSettings.chiefComplaint) // new
        .set("presenting_illness", this.userSettings.historyOfIllness) // new
        .set("reviewOfSystems", this.userSettings.reviewOfSystems) // new
        .set("vitalSigns", this.userSettings.vitalSigns) // new
        .set("giveTPA", giveTpaStatus) // new
        .set("notes", this.userSettings.notes) // new
        .set("doNotgiveTPA", doNotGiveTpaStatus) // new
        .set("nihss", JSON.stringify(this.nihss)) // new
        .set("imaging", this.userSettings.imaging) // new
        .set("assessment", this.userSettings.assessment)
        .set("physical_exam", this.userSettings.physicalExam)
        .set("recommendations", JSON.stringify(giveOrDoNotGiveTpaObject)) // new
        .set("userId", this.receivedUserID)
        .set("doctorId", this.loggedInUser)
        .set("patient", this.patientId)
        .set("speciality_id", "123")
        .set("isComplete", "false")
        .set("requested_call", this.receivedCallIDFromQuery)
        .set(
          "considerOrDoNotConsiderEmbolectomy",
          considerOrDoNotConsiderEmbolectomyStatus
        )
        .set(
          "thermobectomyRecommendation",
          JSON.stringify(considerOrDoNotConsiderEmbolectomyObject)
        )
        .set("telestrokeDocumentation", JSON.stringify(this.timePoints))
        .set("additionalWorkup", JSON.stringify(this.addWorkup))
        .set("IsHemorrhage", String(this.userSelectedHemorrhage)) /*Pradeep Changes*/
        .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
        .set("hemorrhage", JSON.stringify(this.hemorrhage)) 
        .set("offset", JSON.stringify(this.offset))/*Pradeep Changes*/
        .set("fullName",this.loggedUser)
        .set("iagreetnc", String(this.iagreetnc));
      var headers_consultNotes = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/ConsultNoteDetails", params_consultNotes, {
          headers: headers_consultNotes,
        })
        .subscribe(
          (data) => {
             // Reset Hemorrage and Non-Hemarrhage Date.
             this.hemorrhage.InterpretationDate = new Date();
             this.nonHemorrhage.InterpretationDate = new Date();
            console.log("save notes", data);
           
            if (data["Message"] === "Success") {
              this.loader.complete();
              this.openSuccessDialog("Consult note saved successfully.");
            } else {
              this.loader.complete();
              this.openErrorDialog("Some error occurred, please try again.");
            }
          },
          (err) => {
            // Reset Hemorrage and Non-Hemarrhage Date.
            this.hemorrhage.InterpretationDate = new Date();
            this.nonHemorrhage.InterpretationDate = new Date();
            this.loader.complete();
            this.openErrorDialog("Some error occurred, please try again.");
          }
        );
      
    }
  }
  signAndRelease() {
    if (this.showErrorForCheckbox) {
      this.inputEl.focus();
      var elmnt = document.getElementById("tpaRecommendationDiv");
      elmnt.scrollIntoView();
      this.xpandStatus = true;
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox("");
    } else {
      var giveOrDoNotGiveTpaObject;
      var giveTpaStatus;
      var doNotGiveTpaStatus;
      var considerOrDoNotConsiderEmbolectomyStatus;
      var considerOrDoNotConsiderEmbolectomyObject;
      if (this.giveOrDoNotGiveTpa === "Give TPA") {
        giveOrDoNotGiveTpaObject = this.giveTpa;
        giveTpaStatus = true;
        doNotGiveTpaStatus = false;
      } else {
        // if (this.giveOrDoNotGiveTpa === 'Do Not Give TPA') {
        var doNotGiveTPAOBj = {};
        doNotGiveTPAOBj["doNotGiveTpaSelectedList"] = this.selectedReasonList;
        giveOrDoNotGiveTpaObject = doNotGiveTPAOBj;
        giveTpaStatus = false;
        doNotGiveTpaStatus = true;
        // } else {
        //   giveTpaStatus = false;
        //   doNotGiveTpaStatus = false;
        //   giveOrDoNotGiveTpaObject = {};
        // }
      }
      if (this.considerOrDoNotConsiderEmbolectomy === "Consider Embolectomy") {
        var considerOrNotEmbolectomyObj = {};
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObj;
        considerOrDoNotConsiderEmbolectomyStatus = true;
      } else {
        // if (this.considerOrDoNotConsiderEmbolectomy === 'Do not consider Embolectomy') {
        var considerOrNotEmbolectomyObject = {};
        considerOrNotEmbolectomyObject[
          "considerOrNotSelectedList"
        ] = this.selectedReasonEmbolectomy;
        considerOrDoNotConsiderEmbolectomyStatus = false;
        considerOrDoNotConsiderEmbolectomyObject = considerOrNotEmbolectomyObject;
        // } else {
        //   considerOrDoNotConsiderEmbolectomyStatus = '';
        //   considerOrDoNotConsiderEmbolectomyObject = {};
        // }
      }
      this.settingsForm.markAsPristine();
      this.settingsForm.updateValueAndValidity();
      this.loader.start();
      this.loader.set(10);
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.totalCigaretteConsumption = this.patientSettings.cigarettesPerDay+" "+this.patientSettings.cigarettesPerDayQuantity;
      const params = new HttpParams()
      .set("allergies", JSON.stringify(this.allergiesArray))
      .set("arrivalToEdDate", JSON.stringify(this.patientSettings.arrivalToEdDate ))
      .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
      .set("offset", JSON.stringify(this.offset))
      .set("bloodGlucose", this.patientSettings.bloodGlucose)
      .set("bloodPressure", this.patientSettings.bloodPressure)
      .set("callId", this.receivedCallIDFromQuery)
      .set("currentMedications", JSON.stringify(this.medicationsArray))
      .set("familyContactNumber", this.patientSettings.familyContactNumber)
      .set("familyHistory", JSON.stringify(this.familyHistoryArray))
      .set("heartRate", this.patientSettings.heartRate)
      .set("historian", this.patientSettings.historian)
      .set("hospitalCampus", this.patientSettings.hospitalCampus)
      .set("isFamilyBedside", this.patientSettings.familyAtBedside)
      .set("isICHHistory", this.patientSettings.historyOfIch)
      .set("isIntracranial", this.patientSettings.isIntracranial)
      .set("isNeoplasm", this.patientSettings.historyOfNeoplasm)
      .set("lastKnownNormalDate", JSON.stringify(this.patientSettings.lastKnownNormalDate))
      .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
      .set("meansOfArrival", JSON.stringify(this.meansOfArrivalObj))
      .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray))
      .set("milnPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
      .set("nurse", this.patientSettings.nurse)
      .set("oxygenSat", this.patientSettings.oxygenSat)
      .set("patientId", this.patientId)
      .set("physician", this.patientSettings.physician)
      .set("preArrivalNotification", this.patientSettings.preArrival)
      .set("isPatientOnAntiplatelet", this.patientSettings.patientOnAntiplatelet)
      .set("wasPatientOnAnticoagulation", this.patientSettings.patientOnAnticoagulation)
      .set("doesPatientConsumeAlcohol", this.patientSettings.patientAlcoholConsumption)
      .set("cigaretteCount", this.totalCigaretteConsumption)
      .set("medicationForAntiplateletTherapy", JSON.stringify(this.medicationsForAntiplateletTherapyArray))
      .set("medicationForAnticoagulationTherapy", JSON.stringify(this.medicationsForAnticoagulationTherapyArray))
      .set("patientDrugsConsumption", JSON.stringify(this.drugsArray))
      .set("reason", JSON.stringify(this.reasonForConsultArray))
      .set("rr", this.patientSettings.rr)
      .set("socialHistory", JSON.stringify(this.socialHistoryArray))
      .set("strokeInPrevious3Months", this.patientSettings.strokeInPrevious3Months)
      .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray))
      .set("symptoms", JSON.stringify(this.symptomsArray))
      .set("temp", this.patientSettings.temp)
      .set("tempUnit", this.patientSettings.tempUnit) 
      .set("tookCEPX", this.patientSettings.tookCEPX)
      .set("tpaEligibilityUnknown", JSON.stringify(this.patientSettings.tpaEligibilityUnknown))
      .set("userId", this.patientSettings.nurse)
      .set("fullName", this.patientSettings.nurseFullName)
      .set("videoSessionId", '')
      .set("videoToken", '')
      .set("weight", this.patientSettings.weight)
      .set("weightUnit", this.patientSettings.weightUnit)
      .set("type", '');
      this.http
        .post(this.authService.baseURL + "/IntiateStrokeNotification", params, {
          headers: headers,
        }).subscribe(
          (data)=>{
            console.log('API called ', data);
          }
        )
       
      // Convert InterPretation Date into String
      
      // if(this.userSelectedHemorrhage)
      // {
      //   this.hemorrhage.InterpretationDate = this.getStringDate(this.hemorrhage.InterpretationDate);
      // }
      // else
      // {
      //   this.nonHemorrhage.InterpretationDate = this.getStringDate(this.nonHemorrhage.InterpretationDate);
      // }  
     
      const params_signAndRelease = new HttpParams()
        .set("complaint", this.userSettings.chiefComplaint) // new
        .set("presenting_illness", this.userSettings.historyOfIllness) // new
        .set("reviewOfSystems", this.userSettings.reviewOfSystems) // new
        .set("vitalSigns", this.userSettings.vitalSigns) // new
        .set("giveTPA", giveTpaStatus) // new
        .set("notes", this.userSettings.notes) // new
        .set("doNotgiveTPA", doNotGiveTpaStatus) // new
        .set("nihss", JSON.stringify(this.nihss)) // new
        .set("imaging", this.userSettings.imaging) // new
        .set("assessment", this.userSettings.assessment)
        .set("physical_exam", this.userSettings.physicalExam)
        .set("recommendations", JSON.stringify(giveOrDoNotGiveTpaObject)) // new
        .set("userId", this.receivedUserID)
        .set("doctorId", this.loggedInUser)
        .set("patient", this.patientId)
        .set("speciality_id", "123")
        .set("isComplete", "true")
        .set("requested_call", this.receivedCallIDFromQuery)
        .set(
          "considerOrDoNotConsiderEmbolectomy",
          considerOrDoNotConsiderEmbolectomyStatus
        )
        .set(
          "thermobectomyRecommendation",
          JSON.stringify(considerOrDoNotConsiderEmbolectomyObject)
        )
        .set("telestrokeDocumentation", JSON.stringify(this.timePoints))
        .set("additionalWorkup", JSON.stringify(this.addWorkup))
        .set("IsHemorrhage", String(this.userSelectedHemorrhage)) /*Pradeep Changes*/
        .set("nonHemorrhage", JSON.stringify(this.nonHemorrhage))
        .set("hemorrhage", JSON.stringify(this.hemorrhage))
        .set("offset", JSON.stringify(this.offset))/*Pradeep Changes*/
        .set("fullName",this.loggedUser)
        .set("iagreetnc", String(this.iagreetnc));
      var headers_SignAndRelease = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/ConsultNoteDetails", params_signAndRelease, {
          headers: headers_SignAndRelease,
        })
        .subscribe(
          (data) => {
            // Reset Hemorrage and Non-Hemarrhage Date.
            this.hemorrhage.InterpretationDate = new Date();
            this.nonHemorrhage.InterpretationDate = new Date();
            console.log("save notes", data);
            // this.isloading = false; // stop loader
            if (data["Message"] === "Success") {
              this.loader.complete();
              this.openSuccessDialog(
                "Consult note signed and released successfully."
              );
            } else {
              this.loader.complete();
              this.openErrorDialog("Some error occurred, please try again.");
            }
          },
          (err) => {
            // Reset Hemorrage and Non-Hemarrhage Date.
            this.hemorrhage.InterpretationDate = new Date();
            this.nonHemorrhage.InterpretationDate = new Date();
            this.loader.complete();
            this.openErrorDialog("Some error occurred, please try again.");
          }
        );
    }
  }
  // show and hide prefilled and disabled intake form
  showHide() {
    this.showEditIcon = !this.showEditIcon;
  }
  /*================== Generate Array for Checkbox values =========*/
  createSymptomsArray() {
    this.symptomsArray = [];
    if (this.symptoms.rightSidedWeakness) {
      this.symptomsArray.push("Right sided weakness");
    }
    if (this.symptoms.leftSidedWeakness) {
      this.symptomsArray.push("Left sided weakness");
    }
    if (this.symptoms.rightSidedNumbness) {
      this.symptomsArray.push("Right sided numbness");
    }
    if (this.symptoms.leftSidedNumbness) {
      this.symptomsArray.push("Left sided numbness");
    }
    if (this.symptoms.leftGazePreference) {
      this.symptomsArray.push("Left gaze preference");
    }
    if (this.symptoms.rightGazePreference) {
      this.symptomsArray.push("Right gaze preference");
    }
    if (this.symptoms.leftFacialDroop) {
      this.symptomsArray.push("Left facial droop");
    }
    if (this.symptoms.rightFacialDroop) {
      this.symptomsArray.push("Right facial droop");
    }
    if (this.symptoms.aphasia) {
      this.symptomsArray.push("Aphasia");
    }
    if (this.symptoms.slurredSpeech) {
      this.symptomsArray.push("Slurred speech");
    }
    if (this.symptoms.blurryVision) {
      this.symptomsArray.push("Blurry vision");
    }
    if (this.symptoms.doubleVision) {
      this.symptomsArray.push("Double vision");
    }
    if (this.symptoms.peripheralVisionLoss) {
      this.symptomsArray.push("Peripheral vision loss");
    }
    if (this.symptoms.alteredMentalStatus) {
      this.symptomsArray.push("Altered mental status");
    }
    if (this.symptoms.headache) {
      this.symptomsArray.push("Headache");
    }
    if (this.symptoms.dizziness) {
      this.symptomsArray.push("Dizziness");
    }
    if (this.symptoms.unsteadyGait) {
      this.symptomsArray.push("Unsteady gait");
    }
    if (this.symptoms.vertigo) {
      this.symptomsArray.push("Vertigo");
    }
    if (this.symptoms.visionLoss) {
      this.symptomsArray.push("Vision loss");
    }
    if (this.symptoms.seizure) {
      this.symptomsArray.push("Seizure");
    }
    if (this.symptoms.other) {
      this.symptomsArray.push(this.symptoms.other);
    }
    console.log(this.symptomsArray);
  }

  createAntiPlateletTherapyMedicationsArray() {
    this.medicationsForAntiplateletTherapyArray = [];
    if (this.antiPlateletTherapyMedications.aspirin) {
      this.medicationsForAntiplateletTherapyArray.push("Aspirin");
    }
    if (this.antiPlateletTherapyMedications.clopidogrel) {
      this.medicationsForAntiplateletTherapyArray.push("Clopidogrel");
    }
    if (this.antiPlateletTherapyMedications.aspirinAndClopidogrel) {
      this.medicationsForAntiplateletTherapyArray.push("Aspirin & Clopidogrel");
    }
    if (this.antiPlateletTherapyMedications.ticagrelor) {
      this.medicationsForAntiplateletTherapyArray.push("Ticagrelor");
    }
    if (this.antiPlateletTherapyMedications.unableToDetermine) {
      this.medicationsForAntiplateletTherapyArray.push("Unable to determine");
    }
    if (this.antiPlateletTherapyMedications.none) {
      this.medicationsForAntiplateletTherapyArray.push("None");
    }
    if (this.antiPlateletTherapyMedications.unknown) {
      this.medicationsForAntiplateletTherapyArray.push("Unknown");
    }
    if (this.antiPlateletTherapyMedications.other) {
      this.medicationsForAntiplateletTherapyArray.push(this.antiPlateletTherapyMedications.other);
    }
    console.log(this.medicationsForAntiplateletTherapyArray);
  }

  createAntiCoagulationTherapyMedicationsArray() {
    this.medicationsForAnticoagulationTherapyArray = [];
    if (this.antiCoagulationTherapyMedications.warfarin) {
      this.medicationsForAnticoagulationTherapyArray.push("Warfarin");
    }
    if (this.antiCoagulationTherapyMedications.apixaban) {
      this.medicationsForAnticoagulationTherapyArray.push("Apixaban");
    }
    if (this.antiCoagulationTherapyMedications.dabigatran) {
      this.medicationsForAnticoagulationTherapyArray.push("Dabigatran");
    }
    if (this.antiCoagulationTherapyMedications.rivaroxaban) {
      this.medicationsForAnticoagulationTherapyArray.push("Rivaroxaban");
    }
    if (this.antiCoagulationTherapyMedications.enoxaparin) {
      this.medicationsForAnticoagulationTherapyArray.push("Enoxaparin");
    }
    if (this.antiCoagulationTherapyMedications.heparin) {
      this.medicationsForAnticoagulationTherapyArray.push("Heparin");
    }
    if (this.antiCoagulationTherapyMedications.unknown) {
      this.medicationsForAnticoagulationTherapyArray.push("Unknown");
    }
    if (this.antiCoagulationTherapyMedications.other) {
      this.medicationsForAnticoagulationTherapyArray.push(this.antiCoagulationTherapyMedications.other);
    }
    console.log(this.medicationsForAnticoagulationTherapyArray);
  }

  createPastMedicalHistoryArray() {
    this.pastMedicalHistoryArray = [];
    if (this.pastMedicalHistory.unknown) {
      this.pastMedicalHistoryArray.push("Unknown");
    }
    if (this.pastMedicalHistory.none) {
      this.pastMedicalHistoryArray.push("None");
    }
    if (this.pastMedicalHistory.htn) {
      this.pastMedicalHistoryArray.push("HTN");
    }
    if (this.pastMedicalHistory.hld) {
      this.pastMedicalHistoryArray.push("HLD");
    }
    if (this.pastMedicalHistory.atrialFibrillation) {
      this.pastMedicalHistoryArray.push("Atrial Fibrillation");
    }
    if (this.pastMedicalHistory.giBleed) {
      this.pastMedicalHistoryArray.push("GI Bleed");
    }
    if (this.pastMedicalHistory.congestiveHeartFailure) {
      this.pastMedicalHistoryArray.push("Congestive Heart Failure");
    }
    if (this.pastMedicalHistory.mi) {
      this.pastMedicalHistoryArray.push("MI");
    }
    if (this.pastMedicalHistory.dm) {
      this.pastMedicalHistoryArray.push("DM");
    }
    if (this.pastMedicalHistory.priorStroke) {
      this.pastMedicalHistoryArray.push("Prior Stroke");
    }
    if (this.pastMedicalHistory.dementia) {
      this.pastMedicalHistoryArray.push("Dementia");
    }
    if (this.pastMedicalHistory.ckd) {
      this.pastMedicalHistoryArray.push("CKD");
    }
    if (this.pastMedicalHistory.cerebralAneurysm) {
      this.pastMedicalHistoryArray.push("Cerebral Aneurysm");
    }
    if (this.pastMedicalHistory.brainTumor) {
      this.pastMedicalHistoryArray.push("Brain Tumor");
    }
    if (this.pastMedicalHistory.seizure) {
      this.pastMedicalHistoryArray.push("Seizure");
    }
    if (this.pastMedicalHistory.migraine) {
      this.pastMedicalHistoryArray.push("Migraine");
    }
    if (this.pastMedicalHistory.cancer) {
      this.pastMedicalHistoryArray.push("Cancer");
    }
    if (this.pastMedicalHistory.other) {
      this.pastMedicalHistoryArray.push(this.pastMedicalHistory.other);
    }
    console.log(this.pastMedicalHistoryArray);
  }
  createPastSurgicalHistoryArray() {
    this.pastSurgicalHistoryArray = [];
    if (this.pastSurgicalHistory.surgicalHistory) {
      if (this.pastSurgicalHistory.surgicalHistory !== "false") {
        this.pastSurgicalHistoryArray.push(
          this.pastSurgicalHistory.surgicalHistory
        );
      }
    }
    if (this.pastSurgicalHistory.other) {
      this.pastSurgicalHistoryArray.push(this.pastSurgicalHistory.other);
    }
    console.log(this.pastSurgicalHistoryArray);
  }
  createAllergiesArray() {
    this.allergiesArray = [];
    if (this.allergies.allergiesCustom) {
      this.allergiesArray.push(this.allergies.allergiesCustom);
    }
    if (this.allergies.allergiesOptions) {
      if (this.allergies.allergiesOptions !== "false") {
        this.allergiesArray.push(this.allergies.allergiesOptions);
      }
    }
    console.log(this.allergiesArray);
  }
  createMedicationsArray() {
    this.medicationsArray = [];
    if (this.medications.medicationsCustom) {
      this.medicationsArray.push(this.medications.medicationsCustom);
    }
    if (this.medications.medicationsOptions) {
      if (this.medications.medicationsOptions !== "false") {
        this.medicationsArray.push(this.medications.medicationsOptions);
      }
    }
    console.log(this.medicationsArray);
  }
  createFamilHistoryArray() {
    this.familyHistoryArray = [];
    if (this.familyHistory.none) {
      this.familyHistoryArray.push("None");
    }
    if (this.familyHistory.unknown) {
      this.familyHistoryArray.push("Unknown");
    }
    if (this.familyHistory.mi) {
      this.familyHistoryArray.push("MI");
    }
    if (this.familyHistory.stroke) {
      this.familyHistoryArray.push("Stroke");
    }
    if (this.familyHistory.cancer) {
      this.familyHistoryArray.push("Cancer");
    }
    if (this.familyHistory.seizure) {
      this.familyHistoryArray.push("Seizure");
    }
    if (this.familyHistory.other) {
      this.familyHistoryArray.push(this.familyHistory.other);
    }
    console.log(this.familyHistoryArray);
  }

  createTobaccoArray() {
    this.tobaccoArray = [];
    if (this.tobacco.none) {
      this.tobaccoArray.push("None");
    }
    if (this.tobacco.chewing) {
      this.tobaccoArray.push("Chewing");
    }
    if (this.tobacco.vapepen) {
      this.tobaccoArray.push("Vape Pen");
    }
    if (this.tobacco.unknown) {
      this.tobaccoArray.push("Unknown");
    }
    if (this.tobacco.cigarettes) {
  
      this.tobaccoArray.push("Cigarettes");
    }
  
    if (this.tobacco.other) {
      this.tobaccoArray.push(this.tobacco.other);
    }
    console.log(this.tobaccoArray);
  }

  createDrugsArray() {
    this.drugsArray = [];
    if (this.drugs.none) {
      this.drugsArray.push("None");
    }
    if (this.drugs.cocaine) {
      this.drugsArray.push("Cocaine");
    }
    if (this.drugs.lsd) {
      this.drugsArray.push("LSD");
    }
    if (this.drugs.marijuana) {
      this.drugsArray.push("Marijuana");
    }
    if (this.drugs.heroine) {
      this.drugsArray.push("Heroine");
    }
    if (this.drugs.methamphetamine) {
      this.drugsArray.push("Methamphetamine");
    }
    if (this.drugs.unknown) {
      this.drugsArray.push("Unknown");
    }
    if (this.drugs.other) {
      this.drugsArray.push(this.drugs.other);
    }

    console.log(this.drugsArray);
  }

  createSocialHistoryArray() {
    this.socialHistoryArray = [];
    if (this.socialHistory.none) {
      this.socialHistoryArray.push("None");
    }
    // if (this.socialHistory.unknown) {
    //   this.socialHistoryArray.push("Unknown");
    // }
    if (this.socialHistory.alcohol) {
      this.socialHistoryArray.push("Alcohol");
    }
    if (this.socialHistory.drugs) {
      this.socialHistoryArray.push("Drugs");
    }
    if (this.socialHistory.tobacco) {
      this.socialHistoryArray.push("Tobacco");
    }
    console.log(this.socialHistoryArray);
  }
  createReasonForConsultArray() {
    this.reasonForConsultArray = [];
    if (this.reasonForConsult.cardiacArrest) {
      this.reasonForConsultArray.push("Cardiac Arrest");
    }
    if (this.reasonForConsult.possibleStroke) {
      this.reasonForConsultArray.push("Possible Stroke");
    }
    if (this.reasonForConsult.other) {
      this.reasonForConsultArray.push(this.reasonForConsult.other);
    }
    console.log(this.reasonForConsultArray);
    console.log(this.patientSettings.nurse);
    console.log(this.patientSettings.physician);
    console.log(this.patientSettings.historian);
    console.log(this.patientSettings.hospitalCampus);
  }
  /*=========== get Patient Info ============*/
  getPatientInfo(patientID) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams().set("mrn", patientID);
    this.http
      .post(this.authService.baseURL + "/GetPatientInfo", params, {
        headers: headers,
      })
      .subscribe((data) => {
        console.log("get info", data);
        if (data["message"] === "success") {
          this.patientInfo.name = data["data"]["name"];
          this.patientInfo.mrn = data["data"]["mrn"];
          this.patientInfo.dob = data["data"]["dob"];
          var receivedDateDetails = this.patientInfo.dob.split("/");
          this.receivedDate = receivedDateDetails[1];
          this.receivedMonth = receivedDateDetails[0];
          this.receivedYear = receivedDateDetails[2];
          this.patientInfo.age = this.CalculateAge(
            this.receivedDate,
            this.receivedMonth,
            this.receivedYear
          );
          this.patientInfo.gender = data["data"]["gender"];
        }
      });
  }
  /*====================== Calculate Age  =============================*/

  CalculateAge(receivedDate, receivedMonth, receivedYear) {
    var yearThen = receivedYear;
    var monthThen = receivedMonth;
    var dayThen = receivedDate;
    var today = new Date();
    var birthday = new Date(yearThen, monthThen - 1, dayThen);
    var differenceInMilisecond = today.valueOf() - birthday.valueOf();
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
    return year_age;
  }

  openErrorDialog(msg) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      height: "88px",
      data: msg,
    });
    setTimeout(() => dialogRef.close(), 1000);
  }

  openSuccessDialog(msg) {
    const dialogRef = this.successDialog.open(SuccessDialogComponent, {
      height: "72px",
      data: msg,
    });
    setTimeout(() => dialogRef.close(), 1000);
  }
  focusOnLastKnownNormal() {
    // this.patientSettings.tpaEligibilityUnknown = '';
    console.log(
      "lastKnownNormalDate",
      new Date(this.patientSettings.lastKnownNormalDate).getTime()
    );
    console.log(
      "lastKnownNormalDate",
      new Date(this.patientSettings.arrivalToEdDate).getTime()
    );
    if (
      new Date(this.patientSettings.lastKnownNormalDate).getTime() >
      new Date(this.patientSettings.arrivalToEdDate).getTime()
    ) {
      // alert('Last Known Normal is greater than Arrival to ED');
      this.lastKnownNormalDateFormControl.setErrors(Validators.requiredTrue);
      this.ref.detectChanges();
      return;
    } else if (
      new Date(this.patientSettings.lastKnownNormalDate).getTime() ===
      new Date(this.patientSettings.arrivalToEdDate).getTime()
    ) {
      // alert('The dates are equal will wait and check for time');
      // this.checkTime();
      if (
        this.patientSettings.lastKnownNormalTime !== "" ||
        this.patientSettings.arrivalToEdTime !== ""
      ) {
        if (
          this.patientSettings.lastKnownNormalTime >
          this.patientSettings.arrivalToEdTime
        ) {
          //  alert('Last Known Normal is greater than Arrival to ED');
          this.lastKnownNormalTimeControl.setErrors(Validators.requiredTrue);
          this.ref.detectChanges();
          return;
        } else if (
          this.patientSettings.lastKnownNormalTime <
          this.patientSettings.arrivalToEdTime
        ) {
          //  alert('Date Format is OK');
          this.lastKnownNormalTimeControl.setErrors({ requiredTrue: null });
          this.lastKnownNormalTimeControl.updateValueAndValidity();
          this.ref.detectChanges();
          return;
        } else {
          // alert('Arrival To ED and Last Known Normal are equal..R u sure u entered the correct data');
          this.ref.detectChanges();
          return;
        }
        // return;
      }
      return;
    } else {
      // alert('Date Format is OK');
      // this.lastKnownNormalDateFormControl.setErrors(null);
      this.lastKnownNormalDateFormControl.setErrors({ requiredTrue: null });
      this.lastKnownNormalDateFormControl.updateValueAndValidity();
      this.lastKnownNormalTimeControl.setErrors({ requiredTrue: null });
      this.lastKnownNormalTimeControl.updateValueAndValidity();
      this.ref.detectChanges();
      return;
    }
  }
  focusOnUnkown() {
    // this.patientSettings.lastKnownNormalDate = '';
    // this.patientSettings.lastKnownNormalTime = '';
    if (
      this.patientSettings.lastKnownNormalTime !== "" &&
      this.patientSettings.arrivalToEdTime !== ""
    ) {
      this.focusOnLastKnownNormal();
    }
  }
  clearRadioOptionsMedications() {
    this.medicationsOptionsControl.setValue("false");
  }
  clearRadioOptionsAllergies() {
    this.allergiesOptionsControl.setValue("false");
  }
  clearRadioOptionsSurgicalHistory() {
    this.surgicalHistoryControl.setValue("false");
  }

  // **************************** Maulik's function to show hide div according to TPA radio button ***************** //
  recommendationChangeEvent(value) {
    if (value === "Give TPA") {
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
    }
    if (value === "Do Not Give TPA") {
      this.showDontTPAdiv = true;
      this.showTPAdiv = false;
    }
  }
  emborecommendationChange(value) {
    if (value === "Consider Embolectomy") {
      this.showDontEmbolectomyDiv = false;
    }
    if (value === "Do Not Consider Embolectomy") {
      this.showDontEmbolectomyDiv = true;
    }
  }
  calculateTotal(event, selectedValue) {
    this.nihss.totalScore = 0;
    if (this.nihss.levelOfConsiousness === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocQue === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.responseToLocComm === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestGaze === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.visual === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.facialPalsy === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftArm === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightArm === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorLeftLeg === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.motorRightLeg === "5") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.limbAtaxia === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.sensory === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.bestLanguage === "4") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.dysarthria === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    if (this.nihss.extinctionAndInattention === "3") {
      this.nihss.totalScore = this.nihss.totalScore - 1;
    }
    this.nihss.totalScore = this.nihss.totalScore +=
      Number(this.nihss.levelOfConsiousness) +
      Number(this.nihss.responseToLocQue) +
      Number(this.nihss.responseToLocComm) +
      Number(this.nihss.bestGaze) +
      Number(this.nihss.visual) +
      Number(this.nihss.facialPalsy) +
      Number(this.nihss.motorLeftArm) +
      Number(this.nihss.motorRightArm) +
      Number(this.nihss.motorLeftLeg) +
      Number(this.nihss.motorRightLeg) +
      Number(this.nihss.limbAtaxia) +
      Number(this.nihss.sensory) +
      Number(this.nihss.bestLanguage) +
      Number(this.nihss.dysarthria) +
      Number(this.nihss.extinctionAndInattention);
  }

  selectDefaultNIHSSValue(value: any) {
    console.log('value12345 :=', value);
    return (Number(value) === 0 || isNullOrUndefined(value));
  }


  // ********************** [ By Maulik ] Unable and Disabled input fields according to select checkbox *******************
  triggerCTHfield(event) {
    let isCTHChecked = event.checked;

    if (isCTHChecked) {
      this.settingsForm.controls["cthValueControl"].enable();
    } else {
      this.settingsForm.controls["cthValueControl"].setValue("");
      this.settingsForm.controls["cthValueControl"].disable();
    }
  }

  // If checked/unchecked MRI brain checkbox
  triggerMRIfield(event) {
    let isMRIChecked = event.checked;
    if (isMRIChecked) {
      this.settingsForm.controls["mriBrainValueControl"].enable();
    } else {
      this.settingsForm.controls["mriBrainValueControl"].setValue("");
      this.settingsForm.controls["mriBrainValueControl"].disable();
    }
  }

  // If checked/unchecked CTA checkbox
  triggerCTAfield(event) {
    let isCTAChecked = event.checked;
    if (isCTAChecked) {
      this.settingsForm.controls["ctaHeadAndNeckValueControl"].enable();
    } else {
      this.settingsForm.controls["ctaHeadAndNeckValueControl"].setValue("");
      this.settingsForm.controls["ctaHeadAndNeckValueControl"].disable();
    }
  }

  // If checked/Unchecked EEG checkbox
  triggerEEGfield(event) {
    let isEEGChecked = event.checked;
    if (isEEGChecked) {
      this.settingsForm.controls["eegValueControl"].enable();
    } else {
      this.settingsForm.controls["eegValueControl"].setValue("");
      this.settingsForm.controls["eegValueControl"].disable();
    }
  }

  // If checked/Unchecked MRA head neck checkbox
  triggerMRAfield(event) {
    let isEEGChecked = event.checked;
    if (isEEGChecked) {
      this.settingsForm.controls["mraHeadAndNeckValueControl"].enable();
    } else {
      this.settingsForm.controls["mraHeadAndNeckValueControl"].setValue("");
      this.settingsForm.controls["mraHeadAndNeckValueControl"].disable();
    }
  }

  // If checked/Unchecked MRA head neck checkbox
  triggerlpfield(event) {
    let islpChecked = event.checked;
    if (islpChecked) {
      this.settingsForm.controls["lpValueControl"].enable();
    } else {
      this.settingsForm.controls["lpValueControl"].setValue("");
      this.settingsForm.controls["lpValueControl"].disable();
    }
  }

  changeStatusConsultNotes(event) {
    let status = event.checked;
    if (status) {
      this.hideA2D = true;
    } else {
      this.hideA2D = false;
    }
  }
  openDialog() {
    this.opendialogForProfile("teleConsent");
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent, {
      // height: '480px',
      data: message,
    });
  }
  validateCheckbox(e) {
    if (this.giveOrDoNotGiveTpa === "Give TPA") {
      if (!this.giveTpa.agree) {
        this.showErrorForCheckbox = true;
      }
    } else {
      this.showErrorForCheckbox = false;
    }
  }
  removeOrSetError(e) {
    if (e.checked) {
      this.showErrorForCheckbox = false;
    } else {
      this.showErrorForCheckbox = true;
    }
  }
  forValidatingCheckbox() {
    if (this.showErrorForCheckbox) {
      // this.inputEl.focus();
      var elmnt = document.getElementById("tpaRecommendationDiv");
      elmnt.scrollIntoView();
      this.xpandStatus = true;
      this.giveOrDoNotGiveTpa = "Give TPA";
      this.showTPAdiv = true;
      this.showDontTPAdiv = false;
      this.validateCheckbox("");
    }
  }
  /*============= Join Call/Start Call Step I ====================*/
  getCallDetailsByFirebase() {
    const url = window.location.search;
    const urlparam = url.split("&");
    const receivedCallId = urlparam[0].split("=");
    const isInitiated = urlparam[1].split("=");
    this.callId = receivedCallId[1];
    var callIdParam = receivedCallId[1];
    this.isCallInitiated = isInitiated[1];
    if (this.isCallInitiated === "true") {
      // alert('is initiated true');
      $(".loader").fadeOut(2000);
      $(".loader_msg").hide();
      this.startVideoSession().then((res) => this.callBackToHospital());
      this.getFirebaseFormDetails(this.callId);
    } else {
      this.getFirebaseFormDetails(this.callId);
      var isFirst = true;
      const dbRef: firebase.database.Reference = firebase
        .database()
        .ref("intakesData/stroke/" + this.callId + "/videoCallDetails");
      dbRef.once("value", (snapshot) => {
        this.updateChangeObservedTime(this.callId);
        this.video.token = snapshot.val()["videoToken"];
        this.video.sessionId = snapshot.val()["sessionId"];
        if (snapshot.hasChild("connectedBy")) {
          if (snapshot.val()["connectedBy"] === this.loggedInUser) {
            const callIdRef: firebase.database.Reference = firebase
              .database()
              .ref(
                "intakesData/stroke/" +
                  this.callId +
                  "/videoCallDetails/" +
                  "sessionId"
              );
            callIdRef.on("value", (snap) => {
              const snapRef: firebase.database.Reference = firebase
                .database()
                .ref("intakesData/stroke/" + this.callId + "/videoCallDetails/");
              snapRef.once("value", (snapFirst) => {
                this.video.token = snapFirst.val()["videoToken"];
                this.video.sessionId = snapFirst.val()["sessionId"];
                if (!isFirst) {
                  window.location.reload();
                } else {
                  this.connectTOVideoSession();
                  isFirst = false;
                }
              });
            });
          } else {
            alert("Call is received by another");
            $(".loader").fadeOut(2000);
            $(".loader_msg").hide();
            this.loader.complete();
          }
        } else {
          this.connectTOVideoSession();
          this.updatePickUpDoctor(this.callId);
          const callIdRef: firebase.database.Reference = firebase
            .database()
            .ref(
              "intakesData/stroke/" +
                this.callId +
                "/videoCallDetails/" +
                "sessionId"
            );
          callIdRef.on("value", (snap) => {
            const snapRef: firebase.database.Reference = firebase
              .database()
              .ref("intakesData/stroke/" + this.callId + "/videoCallDetails/");
            snapRef.once("value", (snapFirst) => {
              this.video.token = snapFirst.val()["videoToken"];
              this.video.sessionId = snapFirst.val()["sessionId"];
              if (!isFirst) {
                window.location.reload();
              } else {
                this.connectTOVideoSession();
                isFirst = false;
              }
            });
          });
        }
      });
    }
  }

  updateChangeObservedTime(callId) {
    var updates = {};
    updates[
      "intakesData/stroke/" +
        callId +
        "/videoCallDetails/" +
        "connectedObservedTime"
    ] = firebase.database.ServerValue.TIMESTAMP;
    return firebase.database().ref().update(updates);
  }
  updatePickUpDoctor(callId) {
    var updates = {};
    updates[
      "intakesData/stroke/" + callId + "/videoCallDetails/" + "connectedBy"
    ] = this.loggedInUser;
    return firebase.database().ref().update(updates);
  }
  /*======== Set Call Call Status to firebase =======*/
  getFirebaseFormDetails(callId) {
    const url = window.location.search;
    const urlparam = url.split("&");
    const receivedCallId = urlparam[0].split("=");
    const isInitiated = urlparam[1].split("=");
    if (isInitiated[1] === "false") {
      this.ReceivedCall(callId);
    }
    const callIdRef: firebase.database.Reference = firebase
      .database()
      .ref("doctorAvailability/" + this.loggedInUser);
    callIdRef.update({
      status: "onCall",
      callId: callId,
    });
    this.getPatientInfoByFirebase(callId);
  }

  getPatientInfoByFirebase(callId) {
    const callIdRef: firebase.database.Reference = firebase
      .database()
      .ref("patientInfo/stroke/" + callId);
    callIdRef.on("value", (snapshot) => {
      if (snapshot.hasChild("PatientId")) {
        this.showBtnsOnDataUpdate = false;
        this.patientInfo.mrn = snapshot.val()["PatientId"];
        this.patientInfo.name = snapshot.val()["name"];
        this.patientInfo.gender = snapshot.val()["gender"];
        this.patientInfo.dob = snapshot.val()["dob"];
        var receivedDateDetails = this.patientInfo.dob.split("/");
        this.receivedMonth = receivedDateDetails[0];
        this.receivedDate = receivedDateDetails[1];
        this.receivedYear = receivedDateDetails[2];
        this.patientInfo.age = this.CalculateAge(
          this.receivedDate,
          this.receivedMonth,
          this.receivedYear
        );
        this.patientId = snapshot.val()["PatientId"];
        this.receivedUserID = snapshot.val()["userId"];
        this.receivedCallID = callId;
        console.log("patientId : ", this.patientId);
        // this.setPatientDetails(this.patientId);
        $(".loader").fadeOut(2000);
        $(".loader_msg").hide();
      } else {
        $(".loader").fadeOut(2000);
        $(".loader_msg").hide();
      }
    });
  }

  /*========== on focusOut Save data to firebase ============*/
  focusOutFunctionForTextField(textField) {
    this.meansOfArrivalObj["privateVehicle"]="";
    this.meansOfArrivalObj["inHouseAttack"]="";
    this.meansOfArrivalObj["Ambulance"]= this.patientSettings.meansOfArrivalCustom;
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    switch (textField) {
      case "physician":
        fbRef.update({
          physician: this.patientSettings.physician,
        });
        break;

      case "familyContactNumber":
      fbRef.update({
        familyContactNumber: this.patientSettings.familyContactNumber,
      });
      break;

      case "ambulance":
        // this.radioChangeMeansOfArrival(event);
        fbRef.update({
          meansOfArrival: JSON.stringify(this.meansOfArrivalObj),
        });
        break;

      case "nurse":
        fbRef.update({
          nurse: this.patientSettings.nurse,
        });
        break;

      case "reasonForConsult":
        var reasonForConsultArr = [];
        reasonForConsultArr.push(this.reasonForConsult.other);
        fbRef.update({
          reason: JSON.stringify(reasonForConsultArr), // need to check
        });
        break;

      case "historian":
        fbRef.update({
          historian: this.patientSettings.historian,
        });
        break;

      case "hospitalCampus":
        fbRef.update({
          hospitalCampus: this.patientSettings.hospitalCampus,
        });
        break;

      // vital Signs
      case "bloodPressure":
        var regex = /^\d{1,3}\/\d{1,3}$/;
        if (
          regex.test(this.patientSettings.bloodPressure) ||
          this.patientSettings.bloodPressure === ""
        ) {
          fbRef.update({
            bloodPressure: this.patientSettings.bloodPressure,
          });
        }
        break;

      case "heartRate":
        var regex = /^\d{1,3}$/;
        if (
          regex.test(this.patientSettings.heartRate) ||
          this.patientSettings.heartRate === ""
        ) {
          fbRef.update({
            heartRate: this.patientSettings.heartRate,
          });
        }
        break;

      case "oxygenSat":
        var regex = /[0-9]+(\.[0-9]{0,2})?%?/;
        if (
          regex.test(this.patientSettings.oxygenSat) ||
          this.patientSettings.oxygenSat === ""
        ) {
          fbRef.update({
            oxygenSat: this.patientSettings.oxygenSat,
          });
        }
        break;

      case "rr":
        var regex = /^(2[0-5]|1[0-9]|[1-9])$/;
        if (
          regex.test(this.patientSettings.rr) ||
          this.patientSettings.rr === ""
        ) {
          fbRef.update({
            rr: this.patientSettings.rr,
          });
        }
        break;

      case "weight":
        if (this.patientSettings.weight === null) {
          this.patientSettings.weight = "";
        }
        fbRef.update({
          weight: this.patientSettings.weight,
        });
        break;

      case "temp":
        if (this.patientSettings.temp === null) {
          this.patientSettings.temp = "";
        }
        fbRef.update({
          temp: this.patientSettings.temp,
        });
        break;

      case "otherSymptoms":
        this.createSymptomsArray();
        fbRef.update({
          symptoms: JSON.stringify(this.symptomsArray),
        });
        break;

      case "otherMedicalHistory":
        this.createPastMedicalHistoryArray();
        if(this.pastMedicalHistory.none === true)
        {
            this.pastMedicalHistory.none = false;
            this.patientForm.patchValue({
             medical_none: false,
           });
          }
        break;

        case "PatientOnAntiplateletTherapyOther":
          this.createAntiPlateletTherapyMedicationsArray();
          if(this.antiPlateletTherapyMedications.none === true){
            this.antiPlateletTherapyMedications.none = false;
            this.patientForm.patchValue({
              antiplatelettherapy_none: false,
            });
          }
          fbRef.update({
            medicationsForAntiplateletTherapy: JSON.stringify(this.medicationsForAntiplateletTherapyArray),
          });
        break;

        case "cigarettesPerDay":
          fbRef.update({
            cigarettesPerDay: this.patientSettings.cigarettesPerDay,
          });
        break;

        case "familyHistoryOther":
          this.createFamilHistoryArray();
          if(this.familyHistory.none === true){
            this.familyHistory.none = false;
            this.patientForm.patchValue({
              familyHistory_none: false,
            });
          }
          fbRef.update({
            familyHistory: JSON.stringify(this.familyHistoryArray),
          });
        break;

        case "tobaccoOther":
          this.createTobaccoArray();
          if(this.tobacco.none === true){
            this.tobacco.none = false;
            this.patientForm.patchValue({
              tobacco_none: false,
            });
          }
          fbRef.update({
            tobacco: JSON.stringify(this.tobaccoArray),
          });
        break;

        case "drugsOther":
          this.createDrugsArray();
          if(this.drugs.none === true){
            this.drugs.none = false;
            this.patientForm.patchValue({
              drugs_none: false,
            });
          }
          fbRef.update({
            drugs: JSON.stringify(this.drugsArray),
          });
        break;

        case "PatientOnAnticoagulationTherapyOther":
          this.createAntiCoagulationTherapyMedicationsArray();
          // if(this.antiCoagulationTherapyMedications.none === true){
          //   this.antiCoagulationTherapyMedications.none = false;
          //   this.patientForm.patchValue({
          //     antiplatelettherapy_none: false,
          //   });
          // }
          fbRef.update({
            medicationsForAnticoagulationTherapy: JSON.stringify(this.medicationsForAnticoagulationTherapyArray),
          });
        break;
      
        // case "otherAntiPlateletTherapyMedication":
        //   this.createAntiPlateletTherapyMedicationsArray();
        //   if(this.antiPlateletTherapyMedications.none === true)
        //   {
        //       this.antiPlateletTherapyMedications.none = false;
        //       this.patientForm.patchValue({
        //        antiplatelettherapy_none: false,
        //      });
        //     }
       
        // fbRef.update({
        //   medicationsForAntiplateletTherapy: JSON.stringify(this.medicationsForAntiplateletTherapyArray),
        // });
        // break;

        // case "otherAntiCoagulationTherapyMedication":
        //   this.createAntiCoagulationTherapyMedicationsArray();
          // if(this.antiCoagulationTherapyMedications.none === true)
          // {
          //     this.antiPlateletTherapyMedications.none = false;
          //     this.patientForm.patchValue({
          //      antiplatelettherapy_none: false,
          //    });
          //   }
       
        // fbRef.update({
        //   medicationsForAnticoagulationTherapy: JSON.stringify(this.medicationsForAnticoagulationTherapyArray),
        // });
        // break;

      case "bloodGlucose":
        if (!this.bloodGlucoseControl.invalid) {
          fbRef.update({
            bloodGlucose: this.patientSettings.bloodGlucose,
          });
        } else if (this.patientSettings.bloodGlucose === "") {
          fbRef.update({
            bloodGlucose: this.patientSettings.bloodGlucose,
          });
        }
        break;

      case "customMedications":
        this.createMedicationsArray();
        fbRef.update({
          currentMedications: JSON.stringify(this.medicationsArray),
        });
        break;

      case "customAllergies":
        this.createAllergiesArray();
        fbRef.update({
          allergies: JSON.stringify(this.allergiesArray),
        });
        break;

      case "customPastSurgicalHistory":
        this.createPastSurgicalHistoryArray();
        fbRef.update({
          surgicalHistory: JSON.stringify(this.pastSurgicalHistoryArray),
        });
        break;

      case "arrivalTOEDTime":
        if (!this.arrivalToEdTimeControl.invalid) {
          fbRef.update({
            arrivalToEdTime: this.patientSettings.arrivalToEdTime,
          });
        } else if (this.patientSettings.arrivalToEdTime === "") {
          fbRef.update({
            arrivalToEdTime: this.patientSettings.arrivalToEdTime,
          });
        }
        break;

      case "lastKnownNormalTime":
        if (!this.lastKnownNormalTimeControl.invalid) {
          this.timePoints.timeOfLastKnownNormal = this.patientSettings.lastKnownNormalTime;
          fbRef.update({
            lastKnownNormalTime: this.patientSettings.lastKnownNormalTime,
          });
        } else if (this.patientSettings.lastKnownNormalTime === "") {
          this.timePoints.timeOfLastKnownNormal = this.patientSettings.lastKnownNormalTime;
          fbRef.update({
            lastKnownNormalTime: this.patientSettings.lastKnownNormalTime,
          });
        }
        if (this.patientSettings.lastKnownNormalDate === null) {
          var lastKnownNormalDateVar = new Date();
          this.date = lastKnownNormalDateVar.getDate();
          this.month = lastKnownNormalDateVar.getMonth() + 1;
          this.year = lastKnownNormalDateVar.getFullYear();
          console.log(String(this.date).length);
          if (String(this.date).length === 1) {
            this.date = "0" + this.date;
          }
          if (String(this.month).length === 1) {
            this.month = "0" + this.month;
          }
          console.log(this.date);
          var completeDateLastKnownNormalDate =
            this.month + "/" + this.date + "/" + this.year;
          fbRef.update({
            lastKnownNormalDate: completeDateLastKnownNormalDate,
          });
        } else {
          let da = new Date(this.patientSettings.lastKnownNormalDate);
          this.date = da.getDate();
          this.month = da.getMonth() + 1;
          this.year = da.getFullYear();
          console.log(String(this.date).length);
          if (String(this.date).length === 1) {
            this.date = "0" + this.date;
          }
          if (String(this.month).length === 1) {
            this.month = "0" + this.month;
          }
          console.log(this.date);
          var completeDateLastKnownNormalDate =
            this.month + "/" + this.date + "/" + this.year;
          if (!this.lastKnownNormalDateFormControl.invalid) {
            fbRef.update({
              lastKnownNormalDate: completeDateLastKnownNormalDate,
            });
          }
        }
    }
  }

  /*=============== Change radio for weight unit =======================*/
  radioChangeWeightUnit(event) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    var assignConvertedWeight = "";
    console.log("Weight", this.patientSettings.weight);
    if (this.patientSettings.weight !== "") {
      if (this.patientSettings.weightUnit === "kg") {
        assignConvertedWeight = (
          Number(this.patientSettings.weight) / 2.2046
        ).toFixed(2);
        this.patientSettings.weight = String(assignConvertedWeight);
      } else {
        assignConvertedWeight = (
          Number(this.patientSettings.weight) * 2.2046
        ).toFixed(2);
        this.patientSettings.weight = String(assignConvertedWeight);
      }
      // set updated data to firebase
      // this.patientSettings.weight = String(assignConvertedWeight);
      // console.log('Weight' , this.patientSettings.weight);
      fbRef
        .update({
          weight: String(assignConvertedWeight),
          weightUnit: this.patientSettings.weightUnit,
        })
        .then(function () {
          this.patientSettings.weight = String(assignConvertedWeight);
        });
    }
  }

  /*=============== Change radio for Temp unit =======================*/
  radioChangeTempUnit(event) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    if (this.patientSettings.temp !== "") {
      if (this.patientSettings.tempUnit === "celsius") {
        var assignConvertedTemp = (
          (Number(this.patientSettings.temp) - 32) /
          1.8
        ).toFixed(2);
        this.patientSettings.temp = String(assignConvertedTemp);
      } else {
        var assignConvertedTemp = (
          Number(this.patientSettings.temp) * 1.8 +
          32
        ).toFixed(2);
        this.patientSettings.temp = String(assignConvertedTemp);
      }
      // set updated data to firebase
      fbRef.update({
        temp: this.patientSettings.temp,
        tempUnit: this.patientSettings.tempUnit,
      });
    }
  }

  /*================== Collect Symptoms Data ================*/
  handleChangeForSymptoms(e) {
    this.createSymptomsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      symptoms: JSON.stringify(this.symptomsArray),
    });
  }

  

    /*================== Collect Anti Platelet Therapy Medications ================*/
    handleChangeForAntiplateletTherapy(e,field) {

    
      if (field === "none" && this.isAntiPlateletTherapyMedicationsNoneCheck===false) {
        this.antiPlateletTherapyMedications = {
          aspirin: false,
          clopidogrel: false,
          aspirinAndClopidogrel: false,
          ticagrelor: false,
          unableToDetermine: false,
          none: false,
          unknown: false,
          other: ""
        }
          this.patientForm.patchValue({
          antiplatelettherapy_aspirin: false,
          antiplatelettherapy_clopidogrel: false,
          antiplatelettherapy_aspirinAndClopidogrel: false,
          antiplatelettherapy_ticagrelor: false,
          antiplatelettherapy_unableToDetermine: false,
          antiplatelettherapy_none: true,
          antiplatelettherapy_unknown: false,
        });
          this.isAspirinCheck = false;
          this.isClopidogrelCheck = false;
          this.isAspirinAndClopidogrelCheck = false
          this.isTicagrelorCheck = false;
          this.isUnableToDetermineCheck = false;
          this.isAntiPlateletTherapyMedicationsNoneCheck = true;
          this.isAntiPlateletTherapyMedicationsUnknownCheck = false;
      }
      else if(field === "none" && this.isAntiPlateletTherapyMedicationsNoneCheck===true){
        
        this.antiPlateletTherapyMedications = {
          aspirin: false,
          clopidogrel: false,
          aspirinAndClopidogrel: false,
          ticagrelor: false,
          unableToDetermine: false,
          none: false,
          unknown: false,
          other: ""
        }
        this.patientForm.patchValue({
          antiplatelettherapy_aspirin: false,
          antiplatelettherapy_clopidogrel: false,
          antiplatelettherapy_aspirinAndClopidogrel: false,
          antiplatelettherapy_ticagrelor: false,
          antiplatelettherapy_unableToDetermine: false,
          antiplatelettherapy_none: false,
          antiplatelettherapy_unknown: false,
        });
      this.isAntiPlateletTherapyMedicationsNoneCheck= false;
      this.isAntiCoagulationMedicationUnknownCheck= true;
    }
  
  
  
    if (field === "unknown" && this.isAntiPlateletTherapyMedicationsUnknownCheck===false) {
      this.antiPlateletTherapyMedications.unknown =true;
      this.antiPlateletTherapyMedications.none=false;
      this.patientForm.patchValue({
        antiplatelettherapy_unknown: true,
        antiplatelettherapy_none: false,
      });
      this.isAntiPlateletTherapyMedicationsUnknownCheck= true;
      this.isAntiPlateletTherapyMedicationsNoneCheck= false;
    }
  
    else if(field === "unknown" && this.isAntiPlateletTherapyMedicationsUnknownCheck===true){
      this.antiPlateletTherapyMedications.unknown =false;
      this.antiPlateletTherapyMedications.none=false;
      this.patientForm.patchValue({
        antiplatelettherapy_unknown: false,
        antiplatelettherapy_none: false,
      });
    this.isAntiPlateletTherapyMedicationsUnknownCheck= false;
  }
  
  if (field === "aspirin" && this.isAspirinCheck===false) {
    this.antiPlateletTherapyMedications.aspirin =true;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_aspirin: true,
      antiplatelettherapy_none: false,
    });
    this.isApixabanCheck= true;
    this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }
  
  else if(field === "aspirin" && this.isAspirinCheck===true){
    this.antiPlateletTherapyMedications.aspirin =false;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_aspirin: false,
      antiplatelettherapy_none: false,
    });
  this.isAspirinCheck= false;
  }
  
  if (field === "clopidogrel" && this.isClopidogrelCheck===false) {
    this.antiPlateletTherapyMedications.clopidogrel =true;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_clopidogrel: true,
      antiplatelettherapy_none: false,
    });
    this.isClopidogrelCheck= true;
    this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }
  
  else if(field === "clopidogrel" && this.isClopidogrelCheck===true){
    this.antiPlateletTherapyMedications.clopidogrel =false;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_clopidogrel: false,
      antiplatelettherapy_none: false,
    });
  this.isClopidogrelCheck= false;
  }
  
  if (field === "aspirin&Clopidogrel" && this.isAspirinAndClopidogrelCheck===false) {
    this.antiPlateletTherapyMedications.aspirinAndClopidogrel =true;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_aspirinAndClopidogrel: true,
      antiplatelettherapy_none: false,
    });
    this.isAspirinAndClopidogrelCheck= true;
    this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }
  
  else if(field === "aspirin&Clopidogrel" && this.isAspirinAndClopidogrelCheck===true){
    this.antiPlateletTherapyMedications.aspirinAndClopidogrel =false;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_aspirinAndClopidogrel: false,
      antiplatelettherapy_none: false,
    });
  this.isAspirinAndClopidogrelCheck= false;
  }
  
  if (field === "ticagrelor" && this.isTicagrelorCheck===false) {
    this.antiPlateletTherapyMedications.ticagrelor =true;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_ticagrelor: true,
      antiplatelettherapy_none: false,
    });
    this.isTicagrelorCheck= true;
    this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }
  
  else if(field === "ticagrelor" && this.isTicagrelorCheck===true){
    this.antiPlateletTherapyMedications.ticagrelor =false;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_ticagrelor: false,
      antiplatelettherapy_none: false,
    });
  this.isTicagrelorCheck= false;
  }
  
  if (field === "unableToDetermine" && this.isUnableToDetermineCheck===false) {
    this.antiPlateletTherapyMedications.unableToDetermine =true;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_unableToDetermine: true,
      antiplatelettherapy_none: false,
    });
    this.isUnableToDetermineCheck= true;
    this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }
  
  else if(field === "unableToDetermine" && this.isUnableToDetermineCheck===true){
    this.antiPlateletTherapyMedications.unableToDetermine =false;
    this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      antiplatelettherapy_unableToDetermine: false,
      antiplatelettherapy_none: false,
    });
  this.isUnableToDetermineCheck= false;
  }
  
      this.createAntiPlateletTherapyMedicationsArray();
      var fbRef = firebase
        .database()
        .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
      fbRef.update({
        medicationsForAntiplateletTherapy: JSON.stringify(this.medicationsForAntiplateletTherapyArray),
      });
    }


  /*================== Collect Anti Platelet Therapy Medications ================*/
  handleChangeForAnticoagulationTherapy(e,field) {

    
  //   if (field === "none" && this.isAntiCoagulationTherapyMedicationsNoneCheck===false) {
  //     this.antiCoagulationTherapyMedications = {
  //       warfarin: false,
  //       apixaban: false,
  //       dabigatran: false,
  //       rivaroxaban: false,
  //       enoxaparin: false,
  //       heparin: false,
  //       unknown: false,
  //       other: ""
  //     }
  //       this.patientForm.patchValue({
  //       anticoagulationtherapy_warfarin: false,
  //       anticoagulationtherapy_apixaban: false,
  //       anticoagulationtherapy_dabigatran: false,
  //       anticoagulationtherapy_rivaroxaban: false,
  //       anticoagulationtherapy_enoxaparin: false,
  //       anticoagulationtherapy_heparin: true,
  //       anticoagulationtherapy_warfarin: false,
  //     });
  //       this.isAspirinCheck = false;
  //       this.isClopidogrelCheck = false;
  //       this.isAspirinAndClopidogrelCheck = false
  //       this.isTicagrelorCheck = false;
  //       this.isUnableToDetermineCheck = false;
  //       this.isAntiPlateletTherapyMedicationsNoneCheck = true;
  //       this.isAntiPlateletTherapyMedicationsUnknownCheck = false;
  //   }
  //   else if(field === "none" && this.isAntiPlateletTherapyMedicationsNoneCheck===true){
      
  //     this.antiPlateletTherapyMedications = {
  //       aspirin: false,
  //       clopidogrel: false,
  //       aspirinAndClopidogrel: false,
  //       ticagrelor: false,
  //       unableToDetermine: false,
  //       none: false,
  //       unknown: false,
  //       other: ""
  //     }
  //     this.patientForm.patchValue({
  //       antiplatelettherapy_aspirin: false,
  //       antiplatelettherapy_clopidogrel: false,
  //       antiplatelettherapy_aspirinAndClopidogrel: false,
  //       antiplatelettherapy_ticagrelor: false,
  //       antiplatelettherapy_unableToDetermine: false,
  //       antiplatelettherapy_none: false,
  //       antiplatelettherapy_unknown: false,
  //     });
  //   this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  //   this.isAntiCoagulationMedicationUnknownCheck= true;
  // }



  if (field === "warfarin" && this.isWarprinCheck===false) {
    this.antiCoagulationTherapyMedications.warfarin =true;
    // this.antiCoagulationTherapyMedications.none=false;
    this.patientForm.patchValue({
      anticoagulationtherapy_warfarin: true,
      // antiplatelettherapy_none: false,
    });
    this.isWarprinCheck= true;
    // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
  }

  else if(field === "warfarin" && this.isWarprinCheck===true){
    this.antiCoagulationTherapyMedications.warfarin =false;
    // this.antiPlateletTherapyMedications.none=false;
    this.patientForm.patchValue({
      anticoagulationtherapy_warfarin: false,
      // antiplatelettherapy_none: false,
    });
  this.isWarprinCheck= false;
}

if (field === "apixaban" && this.isApixabanCheck===false) {
  this.antiCoagulationTherapyMedications.apixaban =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_apixaban: true,
    // antiplatelettherapy_none: false,
  });
  this.isApixabanCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "apixaban" && this.isApixabanCheck===true){
  this.antiCoagulationTherapyMedications.apixaban =false;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_apixaban: false,
    // antiplatelettherapy_none: false,
  });
this.isApixabanCheck= false;
}

if (field === "dabigatran" && this.isDabigatranCheck===false) {
  this.antiCoagulationTherapyMedications.dabigatran =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_dabigatran: true,
    // antiplatelettherapy_none: false,
  });
  this.isDabigatranCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "dabigatran" && this.isDabigatranCheck===true){
  this.antiCoagulationTherapyMedications.dabigatran =false;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_dabigatran: false,
    // antiplatelettherapy_none: false,
  });
this.isDabigatranCheck= false;
}

if (field === "rivaroxaban" && this.isRivaroxabanCheck===false) {
  this.antiCoagulationTherapyMedications.rivaroxaban =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_rivaroxaban: true,
    // antiplatelettherapy_none: false,
  });
  this.isRivaroxabanCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "rivaroxaban" && this.isRivaroxabanCheck===true){
  this.antiCoagulationTherapyMedications.rivaroxaban =false;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_rivaroxaban: false,
    // antiplatelettherapy_none: false,
  });
this.isRivaroxabanCheck= false;
}

if (field === "enoxaparin" && this.isEnoxaparinCheck===false) {
  this.antiCoagulationTherapyMedications.enoxaparin =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_enoxaparin: true,
    // antiplatelettherapy_none: false,
  });
  this.isEnoxaparinCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "enoxaparin" && this.isEnoxaparinCheck===true){
  this.antiCoagulationTherapyMedications.enoxaparin =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_enoxaparin: false,
    // antiplatelettherapy_none: false,
  });
this.isEnoxaparinCheck= false;
}

if (field === "heparin" && this.isHeparinCheck===false) {
  this.antiCoagulationTherapyMedications.heparin =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_heparin: true,
    // antiplatelettherapy_none: false,
  });
  this.isHeparinCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "heparin" && this.isHeparinCheck===true){
  this.antiCoagulationTherapyMedications.heparin =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_heparin: false,
    // antiplatelettherapy_none: false,
  });
this.isHeparinCheck= false;
}

if (field === "unknown" && this.isAntiCoagulationMedicationUnknownCheck===false) {
  this.antiCoagulationTherapyMedications.unknown =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_unknown: true,
    // antiplatelettherapy_none: false,
  });
  this.isAntiCoagulationMedicationUnknownCheck= true;
  // this.isAntiPlateletTherapyMedicationsNoneCheck= false;
}

else if(field === "unknown" && this.isAntiCoagulationMedicationUnknownCheck===true){
  this.antiCoagulationTherapyMedications.unknown =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    anticoagulationtherapy_unknown: false,
    // antiplatelettherapy_none: false,
  });
this.isAntiCoagulationMedicationUnknownCheck= false;
}
    this.createAntiCoagulationTherapyMedicationsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      medicationsForAnticoagulationTherapy: JSON.stringify(this.medicationsForAnticoagulationTherapyArray),
    });
  }


  /*================== Collect Past Medical History ================*/
  handleChangeForPastMedicalHistory(e,field) {

    
    if (field === "none" && this.isMedicaNoneCheck===false) {
      this.pastMedicalHistory = {
        unknown: false,
        none: true,
         htn: false,
        hld: false,
        atrialFibrillation: false,
    giBleed: false,
    congestiveHeartFailure: false,
    mi: false,
    dm: false,
    priorStroke: false,
    dementia: false,
    ckd: false,
    cerebralAneurysm: false,
    brainTumor: false,
    seizure: false,
    migraine: false,
    cancer: false,
     other:"",
      };
        this.patientForm.patchValue({
        Medical_unknown: false,
        Medical_cancer: false,
        Medical_seizure: false,
        Medical_mi: false,
        Medical_htn: false,
        Medical_dm: false,
        medical_brainTumor: false,
        medical_priorStroke: false,
        medical_giBleed: false,
        medical_dementia: false,
        medical_ckd: false,
        medical_cerebralAneurysm: false,
        medical_congestiveHeartFailure: false,
        medical_migraine: false,
        medical_hld: false,
        medical_atrialFibrillation: false,
        Medical_none: true,
      });
        this.isMedicalUnknownCheck = false;
        this.isMedicalCancerCheck = false;
        this.isMedicalSeizureCheck = false;
        this.isMedicalMiCheck = false;
        this.isMedicalhtnCheck = false;
        this.isMedicalDmCheck = false;
        this.isMedicalBrainTumorCheck = false;
        this.isMedicalPriorStrokeCheck = false;
        this.isMedicalgiBleedCheck = false;
        this.isMedicalDementiaCheck = false;
        this.isMedicalCkdCheck = false;
        this.isMedicalCerebralAneurysmCheck = false;
        this.isMedicalCongestiveHeartFailureCheck = false;
        this.isMedicalMigraineCheck = false;
        this.isMedicalHldCheck = false;
        this.isMedicalAtrialFibrillationCheck = false;
        this.isMedicaNoneCheck = true;

    }
    else if(field === "none" && this.isMedicaNoneCheck===true){
      this.pastMedicalHistory = {
        unknown: false,
        none: false,
        htn: false,
        hld: false,
        atrialFibrillation: false,
        giBleed: false,
        congestiveHeartFailure: false,
        mi: false,
        dm: false,
        priorStroke: false,
        dementia: false,
        ckd: false,
        cerebralAneurysm: false,
        brainTumor: false,
        seizure: false,
        migraine: false,
        cancer: false,
        other:""
      };
     
   this.patientForm.patchValue({
    Medical_unknown: false,
    Medical_cancer: false,
    Medical_seizure: false,
    Medical_mi: false,
    Medical_htn: false,
    Medical_dm: false,
    medical_brainTumor: false,
    medical_priorStroke: false,
    medical_giBleed: false,
    medical_dementia: false,
    medical_ckd: false,
    medical_cerebralAneurysm: false,
    medical_congestiveHeartFailure: false,
    medical_migraine: false,
    medical_hld: false,
    medical_atrialFibrillation: false,
    Medical_none: false,
        });
    this.isMedicaNoneCheck= false;
    this.isMedicalUnknownCheck= true;
  }



  if (field === "unknown" && this.isMedicalUnknownCheck===false) {
    this.pastMedicalHistory.unknown =true;
    this.pastMedicalHistory.none=false;
    this.patientForm.patchValue({
      Medical_unknown: true,
      Medical_none: false,
    });
    this.isMedicalUnknownCheck= true;
    this.isMedicaNoneCheck= false;
  }

  else if(field === "unknown" && this.isMedicalUnknownCheck===true){
    this.pastMedicalHistory.unknown =false;
    this.pastMedicalHistory.none=false;
    this.patientForm.patchValue({
      Medical_unknown: false,
      Medical_none: false,
    });
  this.isMedicalUnknownCheck= false;
}

if (field === "cerebralAneurysm" && this.isMedicalCerebralAneurysmCheck===false) {
  this.pastMedicalHistory.cerebralAneurysm =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_cerebralAneurysm: true,
    Medical_none: false,
  });
  this.isMedicalCerebralAneurysmCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "cerebralAneurysm" && this.isMedicalCerebralAneurysmCheck===true){
  this.pastMedicalHistory.cerebralAneurysm =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_cerebralAneurysm: false,
    Medical_none: false,
  });
this.isMedicalCerebralAneurysmCheck= false;
}

if (field === "congestiveHeartFailure" && this.isMedicalCongestiveHeartFailureCheck===false) {
  this.pastMedicalHistory.congestiveHeartFailure =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_congestiveHeartFailure: true,
    Medical_none: false,
  });
  this.isMedicalCongestiveHeartFailureCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "congestiveHeartFailure" && this.isMedicalCongestiveHeartFailureCheck===true){
  this.pastMedicalHistory.congestiveHeartFailure =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_congestiveHeartFailure: false,
    Medical_none: false,
  });
this.isMedicalCongestiveHeartFailureCheck= false;
}

if (field === "seizure" && this.isMedicalSeizureCheck===false) {
  this.pastMedicalHistory.seizure =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_seizure: true,
    Medical_none: false,
  });
  this.isMedicalSeizureCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "seizure" && this.isMedicalSeizureCheck===true){
  this.pastMedicalHistory.seizure =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_seizure: false,
    Medical_none: false,
  });
this.isMedicalSeizureCheck= false;
}

if (field === "htn" && this.isMedicalhtnCheck===false) {
  this.pastMedicalHistory.htn =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_htn: true,
    Medical_none: false,
  });
  this.isMedicalhtnCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "htn" && this.isMedicalhtnCheck===true){
  this.pastMedicalHistory.htn =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_htn: false,
    Medical_none: false,
  });
this.isMedicalhtnCheck= false;
}

if (field === "mi" && this.isMedicalMiCheck===false) {
  this.pastMedicalHistory.mi =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_mi: true,
    Medical_none: false,
  });
  this.isMedicalMiCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "mi" && this.isMedicalMiCheck===true){
  this.pastMedicalHistory.mi =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_mi: false,
    Medical_none: false,
  });
this.isMedicalMiCheck= false;
}

if (field === "migraine" && this.isMedicalMigraineCheck===false) {
  this.pastMedicalHistory.migraine =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_migraine: true,
    Medical_none: false,
  });
  this.isMedicalMigraineCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "migraine" && this.isMedicalMigraineCheck===true){
  this.pastMedicalHistory.migraine =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_migraine: false,
    Medical_none: false,
  });
this.isMedicalMigraineCheck= false;
}

if (field === "cancer" && this.isMedicalCancerCheck===false) {
  this.pastMedicalHistory.cancer =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cancer: true,
    Medical_none: false,
  });
  this.isMedicalCancerCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "cancer" && this.isMedicalCancerCheck===true){
  this.pastMedicalHistory.cancer =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cancer: false,
    Medical_none: false,
  });
this.isMedicalCancerCheck= false;
}

if (field === "hld" && this.isMedicalHldCheck===false) {
  this.pastMedicalHistory.hld =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_hld: true,
    Medical_none: false,
  });
  this.isMedicalHldCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "hld" && this.isMedicalHldCheck===true){
  this.pastMedicalHistory.hld =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_hld: false,
    Medical_none: false,
  });
this.isMedicalHldCheck= false;
}

if (field === "dm" && this.isMedicalDmCheck===false) {
  this.pastMedicalHistory.dm =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dm: true,
    Medical_none: false,
  });
  this.isMedicalDmCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "dm" && this.isMedicalDmCheck===true){
  this.pastMedicalHistory.dm =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dm: false,
    Medical_none: false,
  });
this.isMedicalDmCheck= false;
}

if (field === "ckd" && this.isMedicalCkdCheck===false) {
  this.pastMedicalHistory.ckd =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_ckd: true,
    Medical_none: false,
  });
  this.isMedicalCkdCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "ckd" && this.isMedicalCkdCheck===true){
  this.pastMedicalHistory.ckd =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_ckd: false,
    Medical_none: false,
  });
this.isMedicalCkdCheck= false;
}

if (field === "brainTumor" && this.isMedicalBrainTumorCheck===false) {
  this.pastMedicalHistory.brainTumor =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_brainTumor: true,
    Medical_none: false,
  });
  this.isMedicalBrainTumorCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "brainTumor" && this.isMedicalBrainTumorCheck===true){
  this.pastMedicalHistory.brainTumor =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_brainTumor: false,
    Medical_none: false,
  });
this.isMedicalBrainTumorCheck= false;
}

if (field === "dementia" && this.isMedicalDementiaCheck===false) {
  this.pastMedicalHistory.dementia =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dementia: true,
    Medical_none: false,
  });
  this.isMedicalDementiaCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "dementia" && this.isMedicalDementiaCheck===true){
  this.pastMedicalHistory.dementia =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dementia: false,
    Medical_none: false,
  });
this.isMedicalDementiaCheck= false;
}

if (field === "atrialFibrillation" && this.isMedicalAtrialFibrillationCheck===false) {
  this.pastMedicalHistory.atrialFibrillation =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_atrialFibrillation: true,
    Medical_none: false,
  });
  this.isMedicalAtrialFibrillationCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "atrialFibrillation" && this.isMedicalAtrialFibrillationCheck===true){
  this.pastMedicalHistory.atrialFibrillation =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_atrialFibrillation: false,
    Medical_none: false,
  });
this.isMedicalAtrialFibrillationCheck= false;
}

if (field === "priorStroke" && this.isMedicalPriorStrokeCheck===false) {
  this.pastMedicalHistory.priorStroke =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_priorStroke: true,
    Medical_none: false,
  });
  this.isMedicalPriorStrokeCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "priorStroke" && this.isMedicalPriorStrokeCheck===true){
  this.pastMedicalHistory.priorStroke =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_priorStroke: false,
    Medical_none: false,
  });
this.isMedicalPriorStrokeCheck= false;
}

if (field === "giBleed" && this.isMedicalgiBleedCheck===false) {
  this.pastMedicalHistory.giBleed =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_giBleed: true,
    Medical_none: false,
  });
  this.isMedicalgiBleedCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "giBleed" && this.isMedicalgiBleedCheck===true){
  this.pastMedicalHistory.giBleed =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_giBleed: false,
    Medical_none: false,
  });
this.isMedicalgiBleedCheck= false;
}
    this.createPastMedicalHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      medicalHistory: JSON.stringify(this.pastMedicalHistoryArray),
    });
  }

  /*================== Collect Past Social History ================*/
  handleChangeForSocialHistory(e,field) {

    if (field === "none" && this.isNoneCheck===false) {
      this.socialHistory = {
        alcohol: false,
        drugs: false,
        none: true,
        tobacco: false,
      };
        this.patientForm.patchValue({
        socialHistory_drugs: false,
        socialHistory_tobacco: false,
        socialHistory_alcohol: false,
        socialHistory_none: true,
      });
      this.isNoneCheck= true;
      this.isAlcoholCheck= false;
      this.isTobaccoCheck= false;
      this.isDrugCheck= false;
    }
    else if(field === "none" && this.isNoneCheck===true){
   
       this.socialHistory = {
      alcohol: false,
      drugs: false,
      none: false,
      tobacco: false,
    };
      this.patientForm.patchValue({
      socialHistory_drugs: false,
      socialHistory_tobacco: false,
      socialHistory_alcohol: false,
      socialHistory_none: false,
    });
    this.isNoneCheck= false;
    this.isAlcoholCheck= true;
  }
  if (field === "alcohol" && this.isAlcoholCheck===false) {
    this.socialHistory.alcohol =true;
    this.socialHistory.none=false;
    this.patientForm.patchValue({
      socialHistory_alcohol: true,
      socialHistory_none: false,
    });
    this.isAlcoholCheck= true;
    this.isNoneCheck= false;
  }

  else if(field === "alcohol" && this.isAlcoholCheck===true){
    this.socialHistory.alcohol =false;
    this.socialHistory.none=false;
    this.patientForm.patchValue({
      socialHistory_alcohol: false,
      socialHistory_none: false,
    });
  this.isAlcoholCheck= false;

}

if (field === "tobacco" && this.isTobaccoCheck===false) {
  this.socialHistory.tobacco =true;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_tobacco: true,
    socialHistory_none: false,
  });
  this.isTobaccoCheck= true;
  this.isNoneCheck= false;
}

else if(field === "tobacco" && this.isTobaccoCheck===true){

  this.socialHistory.tobacco =false;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_tobacco: false,
    socialHistory_none: false,
  });
this.isTobaccoCheck= false;
}

if (field === "drugs" && this.isDrugCheck===false) {
  this.socialHistory.drugs =true;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_drugs: true,
    socialHistory_none: false,
  });
  this.isDrugCheck= true;
  this.isNoneCheck= false;
}

else if(field === "drugs" && this.isDrugCheck===true){
  this.socialHistory.drugs =false;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_drugs: false,
    socialHistory_none: false,
  });
this.isDrugCheck= false;
}

    this.createSocialHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      socialHistory: JSON.stringify(this.socialHistoryArray),
    });
  }

  /*================== Collect Past Medical History ================*/
  handleChangeForFamilyHistory(e,field) {
  
    if (field === "none" && this.isFamilyNoneCheck===false) {
      this.familyHistory = {
        stroke: false,
        cancer: false,
        none: true,
        seizure: false,
        mi:false,
        unknown: false,
        other:""
      };
        this.patientForm.patchValue({
        familyHistory_stroke: false,
        familyHistory_cancer: false,
        familyHistory_seizure: false,
        familyHistory_mi: false,
        familyHistory_unknown: false,
        familyHistory_none: true,
      });
      this.isFamilyNoneCheck= true;
      this.isStrokeCheck= false;
      this.isCancerCheck= false;
      this.isSeizureCheck= false;
      this.isMiCheck= false;
      this.isUnknownCheck= false;

    }
    else if(field === "none" && this.isFamilyNoneCheck===true){
      this.familyHistory = {
        stroke: false,
        cancer: false,
        none: false,
        seizure: false,
        mi:false,
        unknown: false,
        other:""
      };
     
   this.patientForm.patchValue({
          familyHistory_stroke: false,
          familyHistory_cancer: false,
          familyHistory_seizure: false,
          familyHistory_mi: false,
          familyHistory_unknown: false,
          familyHistory_none: false,
        });
    this.isFamilyNoneCheck= false;
    this.isStrokeCheck= true;
  }



  if (field === "stroke" && this.isStrokeCheck===false) {
    this.familyHistory.stroke =true;
    this.familyHistory.none=false;
    this.patientForm.patchValue({
      familyHistory_stroke: true,
      familyHistory_none: false,
    });
    this.isStrokeCheck= true;
    this.isFamilyNoneCheck= false;
  }

  else if(field === "stroke" && this.isStrokeCheck===true){
    this.familyHistory.stroke =false;
    this.familyHistory.none=false;
    this.patientForm.patchValue({
      familyHistory_alcohol: false,
      familyHistory_none: false,
    });
  this.isStrokeCheck= false;
}

if (field === "cancer" && this.isCancerCheck===false) {
  this.familyHistory.cancer =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_cancer: true,
    familyHistory_none: false,
  });
  this.isCancerCheck= true;
  this.isFamilyNoneCheck= false;
}
else if(field === "cancer" && this.isCancerCheck===true){
  this.familyHistory.cancer =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_cancer: false,
    familyHistory_none: false,
  });
this.isCancerCheck= false;
}

if (field === "seizure" && this.isSeizureCheck===false) {
  this.familyHistory.seizure =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_seizure: true,
    familyHistory_none: false,
  });
  this.isSeizureCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "seizure" && this.isSeizureCheck===true){
  this.familyHistory.seizure =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_seizure: false,
    familyHistory_none: false,
  });
this.isSeizureCheck= false;
}

if (field === "mi" && this.isMiCheck===false) {
  this.familyHistory.mi =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_mi: true,
    familyHistory_none: false,
  });
  this.isMiCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "mi" && this.isMiCheck===true){
  this.familyHistory.mi =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_mi: false,
    familyHistory_none: false,
  });
this.isMiCheck= false;
}
 
if (field === "unknown" && this.isUnknownCheck===false) {
  this.familyHistory.unknown =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_unknown: true,
    familyHistory_none: false,
  });
  this.isUnknownCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "unknown" && this.isUnknownCheck===true){
  this.familyHistory.unknown =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_unknown: false,
    familyHistory_none: false,
    });
     this.isUnknownCheck= false;
     }

    this.createFamilHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      familyHistory: JSON.stringify(this.familyHistoryArray),
    });
  }

  /*================== Collect Patient Tobacco Consumption Details ================*/
  handleChangeForTobacco(e,field) {
  
    if (field === "none" && this.isTobaccoNoneCheck===false) {
      
      this.cigaretteSection = false;
      this.patientSettings.cigarettesPerDay = "";
      this.patientSettings.cigarettesPerDayQuantity = "";
  localStorage.setItem("patientSetting_cigarettesPerDay", "");
  localStorage.setItem("patientSetting_cigarettesPerDayQuantity","");
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
fbRef.update({
  cigarettesPerDay: "",
  cigarettesPerDayQuantity: "",
});
 
      this.tobacco = {
        none: true,
        chewing: false,
        vapepen: false,
        unknown:false,
        cigarettes: false,
        other:""
      };
        this.patientForm.patchValue({
        tobacco_none: true,
        tobacco_chewing: false,
        tobacco_vapepen: false,
        tobacco_unknown: false,
        tobacco_cigarettes: false,
      });
      this.isTobaccoNoneCheck= true;
      this.isTobaccoChewingCheck= false;
      this.isTobaccoVapepenCheck= false;
      this.isTobaccoUnknownCheck= false;
      this.isTobaccoCigarettesCheck= false;
    }
    else if(field === "none" && this.isTobaccoNoneCheck===true){
     
      this.tobacco = {
        none: false,
        chewing: false,
        vapepen: false,
        unknown:false,
        cigarettes: false,
        other:""
      };
     
   this.patientForm.patchValue({
    tobacco_none: false,
    tobacco_chewing: false,
    tobacco_vapepen: false,
    tobacco_unknown: false,
    tobacco_cigarettes: false,
        });
    this.isTobaccoNoneCheck= false;
    this.isTobaccoChewingCheck= true;
  }



  if (field === "chewing" && this.isTobaccoChewingCheck===false) {
    this.tobacco.chewing =true;
    this.tobacco.none=false;
    this.patientForm.patchValue({
      tobacco_chewing: true,
      tobacco_none: false,
    });
    this.isTobaccoChewingCheck= true;
    this.isTobaccoNoneCheck= false;
  }

  else if(field === "chewing" && this.isTobaccoChewingCheck===true){
    this.tobacco.chewing =false;
    this.tobacco.none=false;
    this.patientForm.patchValue({
      tobacco_chewing: false,
      tobacco_none: false,
    });
  this.isTobaccoChewingCheck= false;
}

if (field === "vapepen" && this.isTobaccoVapepenCheck===false) {
  this.tobacco.vapepen =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_vapepen: true,
    tobacco_none: false,
  });
  this.isTobaccoVapepenCheck= true;
  this.isTobaccoNoneCheck= false;
}
else if(field === "vapepen" && this.isTobaccoVapepenCheck===true){
  this.tobacco.vapepen =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_vapepen: false,
    tobacco_none: false,
  });
this.isTobaccoVapepenCheck= false;
}

if (field === "unknown" && this.isTobaccoUnknownCheck===false) {
  this.tobacco.unknown =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_unknown: true,
    tobacco_none: false,
  });
  this.isTobaccoUnknownCheck= true;
  this.isTobaccoNoneCheck= false;
}

else if(field === "unknown" && this.isTobaccoUnknownCheck===true){

  this.tobacco.unknown =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_unknown: false,
    tobacco_none: false,
  });
this.isTobaccoUnknownCheck= false;
}

if (field === "cigarettes" && this.isTobaccoCigarettesCheck===false) {
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
fbRef.update({
  cigaretteCheck: true,
});
  this.tobacco.cigarettes =true;
  this.cigaretteSection = true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_cigarettes: true,
    tobacco_none: false,
  });
  this.isTobaccoCigarettesCheck= true;
  this.isTobaccoNoneCheck= false;
}

else if(field === "cigarettes" && this.isTobaccoCigarettesCheck===true){
  
   var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      cigaretteCheck: false,
    });
 
  
  this.patientSettings.cigarettesPerDay = "";
  this.patientSettings.cigarettesPerDayQuantity = "";
  localStorage.setItem("patientSetting_cigarettesPerDay", "");
  localStorage.setItem("patientSetting_cigarettesPerDayQuantity","");
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
fbRef.update({
  cigarettesPerDay: "",
  cigarettesPerDayQuantity: "",
});
this.cigaretteSection = false;
  this.tobacco.cigarettes =false;
  this.cigaretteSection = false;
  this.patientSettings.cigarettesPerDay = "";
 
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_cigarettes: false,
    tobacco_none: false,
  });
this.isTobaccoCigarettesCheck= false;
}
 
    this.createTobaccoArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      tobacco: JSON.stringify(this.tobaccoArray),
    });
  }


    /*================== Collect Patient Drugs Consumption Details ================*/
    handleChangeForDrugs(e,field) {
  
      if (field === "none" && this.isDrugsNoneCheck===false) {
        this.drugs = {
          none: true,
          cocaine: false,
          lsd: false,
          marijuana:false,
          heroine: false,
          methamphetamine: false,
          unknown: false,
          other:""
        };
          this.patientForm.patchValue({
          drugs_none: true,
          drugs_cocaine: false,
          drugs_lsd: false,
          drugs_marijuana: false,
          drugs_heroine: false,
          drugs_methamphetamine: false,
          drugs_unknown: false,
        });
        this.isDrugsNoneCheck = true;
        this.isDrugsCocaineCheck = false;
        this.isDrugsLsdCheck = false;
        this.isDrugsMarijuanaCheck = false;
        this.isDrugsHeroineCheck = false;
        this.isDrugsMethamphetamineCheck = false;
        this.isDrugsUnknownCheck = false;
      }
      else if(field === "none" && this.isDrugsNoneCheck===true){
        this.drugs = {
          none: false,
          cocaine: false,
          lsd: false,
          marijuana:false,
          heroine: false,
          methamphetamine: false,
          unknown: false,
          other:""
        };
       
     this.patientForm.patchValue({
      drugs_none: false,
      drugs_cocaine: false,
      drugs_lsd: false,
      drugs_marijuana: false,
      drugs_heroine: false,
      drugs_methamphetamine: false,
      drugs_unknown: false,
          });
      this.isDrugsNoneCheck= false;
      this.isDrugsCocaineCheck= true;
    }
  
  
  
    if (field === "cocaine" && this.isDrugsCocaineCheck===false) {
      this.drugs.cocaine =true;
      this.drugs.none=false;
      this.patientForm.patchValue({
        drugs_cocaine: true,
        drugs_none: false,
      });
      this.isDrugsCocaineCheck= true;
      this.isDrugsNoneCheck= false;
    }
  
    else if(field === "cocaine" && this.isDrugsCocaineCheck===true){
      this.drugs.cocaine =false;
      this.drugs.none=false;
      this.patientForm.patchValue({
        drugs_cocaine: false,
        drugs_none: false,
      });
    this.isDrugsCocaineCheck= false;
  }
  
  if (field === "lsd" && this.isDrugsLsdCheck===false) {
    this.drugs.lsd =true;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_lsd: true,
      drugs_none: false,
    });
    this.isDrugsLsdCheck= true;
    this.isDrugsNoneCheck= false;
  }
  else if(field === "lsd" && this.isDrugsLsdCheck===true){
    this.drugs.lsd =false;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_lsd: false,
      drugs_none: false,
    });
  this.isDrugsLsdCheck= false;
  }
  
  if (field === "marijuana" && this.isDrugsMarijuanaCheck===false) {
    this.drugs.marijuana =true;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_marijuana: true,
      drugs_none: false,
    });
    this.isDrugsMarijuanaCheck= true;
    this.isDrugsNoneCheck= false;
  }
  
  else if(field === "marijuana" && this.isDrugsMarijuanaCheck===true){
    this.drugs.marijuana =false;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_marijuana: false,
      drugs_none: false,
    });
  this.isDrugsMarijuanaCheck= false;
  }
  
  if (field === "heroine" && this.isDrugsHeroineCheck===false) {
    this.drugs.heroine =true;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_heroine: true,
      drugs_none: false,
    });
    this.isDrugsHeroineCheck= true;
    this.isDrugsNoneCheck= false;
  }
  
  else if(field === "heroine" && this.isDrugsHeroineCheck===true){
    this.drugs.heroine =false;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_heroine: false,
      drugs_none: false,
    });
  this.isDrugsHeroineCheck= false;
  }
   
  if (field === "methamphetamine" && this.isDrugsMethamphetamineCheck===false) {
    this.drugs.methamphetamine =true;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_methamphetamine: true,
      drugs_none: false,
    });
    this.isDrugsMethamphetamineCheck= true;
    this.isDrugsNoneCheck= false;
  }
  
  else if(field === "methamphetamine" && this.isDrugsMethamphetamineCheck===true){
    this.drugs.methamphetamine =false;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_methamphetamine: false,
      drugs_none: false,
    });
  this.isDrugsMethamphetamineCheck= false;
  }
  if (field === "unknown" && this.isDrugsUnknownCheck===false) {
    this.drugs.unknown =true;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_unknown: true,
      drugs_none: false,
    });
    this.isDrugsUnknownCheck= true;
    this.isDrugsNoneCheck= false;
  }
  
  else if(field === "unknown" && this.isDrugsUnknownCheck===true){
    this.drugs.unknown =false;
    this.drugs.none=false;
    this.patientForm.patchValue({
      drugs_unknown: false,
      drugs_none: false,
    });
  this.isDrugsUnknownCheck= false;
  }
      this.createDrugsArray();
      var fbRef = firebase
        .database()
        .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
      fbRef.update({
        drugs: JSON.stringify(this.drugsArray),
      });
    }

  /*================== TPA Eligibility Block Radio change ================*/
  handleChangeForMI(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      miInPrevious3Weeks: this.patientSettings.miInPrevious3Weeks,
    });
  }

  handleChangeForStroke(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      strokeInPrevious3Months: this.patientSettings.strokeInPrevious3Months,
    });
  }

  handleChangeForHistoryOfICH(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      isICHHistory: this.patientSettings.historyOfIch,
    });
  }
  handleChangeForTookCEPX(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      tookCEPX: this.patientSettings.tookCEPX,
    });
  }
  radioChangePreArrival(e){
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      preArrival: this.patientSettings.preArrival,
    });
  }
  radioChangePatientAntiplatelet(e){
    if(e.value == "Yes"){
      this.antiPlateletMenu = true;
    }
    if(e.value == "No"){
      this.antiPlateletMenu = false;
      this.antiPlateletTherapyMedications = {
        aspirin: false,
        clopidogrel: false,
        aspirinAndClopidogrel: false,
        ticagrelor: false,
        unableToDetermine: false,
        none: false,
        unknown: false,
        other: ""
      }
      this.patientForm.patchValue({
        antiplatelettherapy_aspirin: false,
      antiplatelettherapy_clopidogrel: false,
      antiplatelettherapy_aspirinAndClopidogrel: false,
      antiplatelettherapy_ticagrelor: false,
      antiplatelettherapy_unableToDetermine: false,
      antiplatelettherapy_none: false,
      antiplatelettherapy_unknown: false,
            });
    }
    if(e.value == "Unknown"){
      this.antiPlateletMenu = false;
      this.antiPlateletTherapyMedications = {
        aspirin: false,
        clopidogrel: false,
        aspirinAndClopidogrel: false,
        ticagrelor: false,
        unableToDetermine: false,
        none: false,
        unknown: false,
        other: ""
      }
      this.patientForm.patchValue({
        antiplatelettherapy_aspirin: false,
      antiplatelettherapy_clopidogrel: false,
      antiplatelettherapy_aspirinAndClopidogrel: false,
      antiplatelettherapy_ticagrelor: false,
      antiplatelettherapy_unableToDetermine: false,
      antiplatelettherapy_none: false,
      antiplatelettherapy_unknown: false,
            });
    }
    var fbRef = firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
  fbRef.update({
    patientOnAntiplatelet: this.patientSettings.patientOnAntiplatelet,
    medicationsForAntiplateletTherapy:"",
  });
  }

  radioChangePatientAlcoholConsumption(e){
    var fbRef = firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
  fbRef.update({
    alcoholConsumption: this.patientSettings.patientAlcoholConsumption,
  });
  }

  radioChangeCigarettesPerDayQuantity(e){
    var fbRef = firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
  fbRef.update({
    cigarettesPerDayQuantity: this.patientSettings.cigarettesPerDayQuantity,
  });
  }

  radioChangePatientAnticoagulation(e){
    if(e.value == "Yes"){
      this.antiCoagulationMenu = true;
    }
    if(e.value == "No"){
      this.antiCoagulationMenu = false;
      this.antiCoagulationTherapyMedications = {
        warfarin: false,
        apixaban: false,
        dabigatran: false,
        rivaroxaban: false,
        enoxaparin: false,
        heparin: false,
        unknown: false,
        other: ""
      }
      
this.patientForm.patchValue({
  anticoagulationtherapy_warfarin: false,
      anticoagulationtherapy_apixaban: false,
      anticoagulationtherapy_dabigatran: false,
      anticoagulationtherapy_rivaroxaban: false,
      anticoagulationtherapy_enoxaparin: false,
      anticoagulationtherapy_heparin: false,
      anticoagulationtherapy_unknown: false,
      });
    }
    if(e.value == "Unknown"){
      this.antiCoagulationMenu = false;
      this.antiCoagulationTherapyMedications = {
        warfarin: false,
        apixaban: false,
        dabigatran: false,
        rivaroxaban: false,
        enoxaparin: false,
        heparin: false,
        unknown: false,
        other: ""
      }
      this.patientForm.patchValue({
        anticoagulationtherapy_warfarin: false,
            anticoagulationtherapy_apixaban: false,
            anticoagulationtherapy_dabigatran: false,
            anticoagulationtherapy_rivaroxaban: false,
            anticoagulationtherapy_enoxaparin: false,
            anticoagulationtherapy_heparin: false,
            anticoagulationtherapy_unknown: false,
            });
    }
    var fbRef = firebase
    .database()
    .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
  fbRef.update({
    patientOnAnticoagulation: this.patientSettings.patientOnAnticoagulation,
    medicationsForAnticoagulationTherapy:"",
  });
  }

  radioChangeMeansOfArrival(e){
    if(e.value == "Ambulance"){
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
      this.meansOfArrivalObj["privateVehicle"]="";
      this.meansOfArrivalObj["inHouseAttack"]="";
      this.meansOfArrivalObj["Ambulance"] = this.patientSettings.meansOfArrivalCustom;
      this.ambulanceSection = true;
    }
    if(e.value == "Private Vehicle"){
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
      this.meansOfArrivalObj["privateVehicle"]= this.patientSettings.meansOfArrivalOption;
      this.meansOfArrivalObj["inHouseAttack"]="";
      this.meansOfArrivalObj["Ambulance"]="";
      this.patientSettings.meansOfArrivalCustom = "";
      this.ambulanceSection = false;
    }
    if(e.value == "In-house Attack"){
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
      this.ambulanceSection = false;
      this.meansOfArrivalObj["privateVehicle"]="";
      this.meansOfArrivalObj["inHouseAttack"]= this.patientSettings.meansOfArrivalOption;
      this.meansOfArrivalObj["Ambulance"]="";
      this.patientSettings.meansOfArrivalCustom = "";
    }
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      meansOfArrival: JSON.stringify(this.meansOfArrivalObj),
    });
  }

  handleChangeForHistoryOfNeoplasm(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      isNeoplasm: this.patientSettings.historyOfNeoplasm,
    });
  }

  /*==================  Current Home Medications Radio changed ==================*/
  radioChangeMedications(event) {
    // alert(event);
    this.medications.medicationsCustom = "";
    this.createMedicationsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      currentMedications: JSON.stringify(this.medicationsArray),
    });
  }

  /*================== Allergies Radio changed ==================*/
  radioChangeAllergies(event) {
    // alert(event);
    this.allergies.allergiesCustom = "";
    this.createAllergiesArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      allergies: JSON.stringify(this.allergiesArray),
    });
  }

  /*================== Past Surggical Radio changed ================*/
  radioChangeSurgicalHistory(event) {
    // alert(event);
    this.pastSurgicalHistory.other = "";
    this.createPastSurgicalHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    fbRef.update({
      surgicalHistory: JSON.stringify(this.pastSurgicalHistoryArray),
    });
  }

  /*================== On Change of Arrival to ED ===================*/
  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.focusOnLastKnownNormal();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    if (this.patientSettings.arrivalToEdDate === null) {
      var arrivalDate = new Date();
      this.date = arrivalDate.getDate();
      this.month = arrivalDate.getMonth() + 1;
      this.year = arrivalDate.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
      fbRef.update({
        arrivalToEdDate: completeDateArrivalDate,
      });
    } else {
      let d = new Date(this.patientSettings.arrivalToEdDate);
      this.date = d.getDate();
      this.month = d.getMonth() + 1;
      this.year = d.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
      if (!this.arrivalToEdDateFormControl.invalid) {
        fbRef.update({
          arrivalToEdDate: completeDateArrivalDate,
        });
      }
    }
  }

  /*=========== on change of Last Known normal Checkbox =======*/

  changeStatus(event) {
    let status = event.checked;
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    if (status) {
      this.statusLastKnownNormal = true;
      this.timePoints.timeOfLastKnownNormal = "Unknown";
      fbRef.update({
        lastKnownNormalDate: "",
        lastKnownNormalTime: "",
        tpaEligibilityUnknown: true,
      });
    } else {
      this.statusLastKnownNormal = false;
      this.timePoints.timeOfLastKnownNormal = "";
      fbRef.update({
        tpaEligibilityUnknown: false,
      });
      this.patientSettings.lastKnownNormalDate = null;
    }
  }

  /*================== On Change of Last Known Normal ===================*/
  addEventlastKnownNormal(event: MatDatepickerInputEvent<Date>) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIDFromQuery);
    if (this.patientSettings.lastKnownNormalDate === null) {
      var lastKnownNormalDateVar = new Date();
      this.date = lastKnownNormalDateVar.getDate();
      this.month = lastKnownNormalDateVar.getMonth() + 1;
      this.year = lastKnownNormalDateVar.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateLastKnownNormalDate =
        this.month + "/" + this.date + "/" + this.year;
      fbRef.update({
        lastKnownNormalDate: completeDateLastKnownNormalDate,
      });
    } else {
      let da = new Date(this.patientSettings.lastKnownNormalDate);
      this.date = da.getDate();
      this.month = da.getMonth() + 1;
      this.year = da.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateLastKnownNormalDate =
        this.month + "/" + this.date + "/" + this.year;
      if (!this.lastKnownNormalDateFormControl.invalid) {
        fbRef.update({
          lastKnownNormalDate: completeDateLastKnownNormalDate,
        });
      }
    }
  }
   /*  ********************** Pradeep Changes******************* */
   validateCTInterPretation(event : any ) {
    if (event.checked) {
      this.settingsForm.controls["ctValueControl"].enable();
      this.nonHemorrhage.CTInterpretation = true;
    } else {
      this.settingsForm.controls["ctValueControl"].setValue("");
      this.settingsForm.controls["ctValueControl"].disable();
      this.nonHemorrhage.CTInterpretation = false;
    }
  }

  validateCTHInterPretation(event : any ) {
    if (event.checked) {
      this.settingsForm.controls["cthFormValueControl"].enable();
      this.hemorrhage.CTH = true;
    } else {
      this.settingsForm.controls["cthFormValueControl"].setValue("");
      this.settingsForm.controls["cthFormValueControl"].disable();
      this.hemorrhage.CTH = false;
    }
  }

  validateCTAInterPretation(event : any ) {
    if (event.checked) {
      this.settingsForm.controls["ctaInterpretationControl"].enable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation = true;
    } else {
      this.settingsForm.controls["ctaInterpretationControl"].setValue("");
      this.settingsForm.controls["ctaInterpretationControl"].disable();
      this.nonHemorrhage.CtaInterpretation.CTAInterpretation  = false;
    }
  }

  enableDisableHemorrhage( value: String ) {
    console.log('this.hemorrhage-',this.hemorrhage);
    console.log('this.nonHemorrhage-',this.nonHemorrhage);
    if( value === "Hemorrhage" ) {
      this.userSelectedHemorrhage = true;
      this.hemorrhage.IsHemorrhage = true;
      if(this.nonHemorrhage.InterpretationTime) {
        this.hemorrhage.InterpretationTime = this.nonHemorrhage.InterpretationTime;
      }
    }
    else {
      this.hemorrhage.IsHemorrhage = false;
    }

    if( value === "Non-Hemorrhage" ) {
      this.userSelectedHemorrhage = false;
      this.nonHemorrhage.IsNonHemorrhage = true;
      if(this.hemorrhage.InterpretationTime) {
        this.nonHemorrhage.InterpretationTime = this.hemorrhage.InterpretationTime;
      }
    }
    else {
      this.nonHemorrhage.IsNonHemorrhage = false;
    }
  }

  calculateAspectScoreTotal() {
    this.nonHemorrhage.TotalAspectScore = 0;
    
    this.nonHemorrhage.TotalAspectScore = this.nonHemorrhage.TotalAspectScore +=
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.FrontalOperaculumM1) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.AnteriorTemporalLobeM2) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) ? 0 : this.nonHemorrhage.AspectSubganglionicNuclei.PosteriorTemporalLobeM1) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.AnteriorMCAM4) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.LateralMCAM5) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) ? 0 : this.nonHemorrhage.AspectSupraganglionicNuclei.FrontalMCAM6) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Caudate) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Caudate) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) ? 0 : this.nonHemorrhage.AspectBasalGanglia.LentiformNucleus) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.Insula) ? 0 : this.nonHemorrhage.AspectBasalGanglia.Insula) +
      Number(isNullOrUndefined(this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ? 0 : this.nonHemorrhage.AspectBasalGanglia.InternalCapsule) ;
  }

  updateHemorrhageType(event : any, hemmorhageType: String) {
    console.log('event.source.checked',event.source.checked);
    if(hemmorhageType === 'IntraparenchymalHemorrhage') {   
      this.hemorrhage.HemorrhageType.IntraparenchymalHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'SubarachnoidHemorrhage') {
      this.hemorrhage.HemorrhageType.SubarachnoidHemorrhage = event.source.checked
    }
    if(hemmorhageType === 'EpiduralHemorrhage') {
      this.hemorrhage.HemorrhageType.EpiduralHemorrhage = !!event.source.checked;
    }
    if(hemmorhageType === 'SubduralHemorrhage') {
      this.hemorrhage.HemorrhageType.SubduralHemorrhage = event.source.checked;
    }
    if(hemmorhageType === 'IntraventricularHemorrhage') {
      this.hemorrhage.HemorrhageType.IntraventricularHemorrhage = event.source.checked;
    }
  }

  updateAspectScoreDoesNotApply(aspectScoreDoesNotApply: String, event : any){
    if(aspectScoreDoesNotApply ==='SuspectPosteriorCirculationStroke') {   
      this.nonHemorrhage.AspectScoreDoesNotApply.SuspectPosteriorCirculationStroke = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MetatasticBrainDisease') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MetatasticBrainDisease = event.checked;
    }
    if(aspectScoreDoesNotApply ==='PrimaryCNSNeoplasm') {
      this.nonHemorrhage.AspectScoreDoesNotApply.PrimaryCNSNeoplasm = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Seizure') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Seizure = event.checked;
    }
    if(aspectScoreDoesNotApply ==='MigraineWithAura') {
      this.nonHemorrhage.AspectScoreDoesNotApply.MigraineWithAura = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ConversionDisorder') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ConversionDisorder = event.checked;
    }
    if(aspectScoreDoesNotApply ==='ToxicMetabolicEncephalopathy') {
      this.nonHemorrhage.AspectScoreDoesNotApply.ToxicMetabolicEncephalopathy = event.checked;
    }
    if(aspectScoreDoesNotApply ==='Other') {
      this.nonHemorrhage.AspectScoreDoesNotApply.Other = event.checked;
    }
  }

  updateSelectType(selectType: String, event : any){
    if(selectType ==='Occlusion') {   
      this.nonHemorrhage.CtaInterpretation.SelectType.Occlusion= !event.source.value;
    }
    if(selectType ==='Dissection') {
      this.nonHemorrhage.CtaInterpretation.SelectType.Dissection = !event.source.value
    }
    if(selectType ==='CervicalIcaMildStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaMildStenosis = !event.source.value;
    }
    if(selectType ==='CervicalIcaModerateStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaModerateStenosis = !event.source.value;
    }
    if(selectType ==='CervicalIcaSevereStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIcaSevereStenosis = !event.source.value;
    }
    if(selectType ==='IntracranialIca') {   
      this.nonHemorrhage.CtaInterpretation.SelectType.IntracranialIca = !event.source.value;
    }
    if(selectType ==='CervicalVertebralOcclusion') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralOcclusion  = !event.source.value
    }
    if(selectType ==='CervicalVertebralArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryStenosis = !event.source.value;
    }
    if(selectType ==='CervicalVertebralArteryDissection') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalVertebralArteryDissection = !event.source.value;
    }
    if(selectType ==='PcaOcclusion') {
      this.nonHemorrhage.CtaInterpretation.SelectType.PcaOcclusion  = !event.source.value;
    }
    if(selectType ==='PcaStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.PcaStenosis  = !event.source.value;
    }
    if(selectType ==='CervicalIntracranialArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.CervicalIntracranialArteryStenosis = !event.source.value;
    }
    if(selectType ==='BasiliarArteryStenosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.BasiliarArteryStenosis = !event.source.value;
    }
    if(selectType ==='DiffuseIntracranialAtherosclerosis') {
      this.nonHemorrhage.CtaInterpretation.SelectType.DiffuseIntracranialAtherosclerosis  = !event.source.value;
    }
  }

  getStringDate(date:Date):String{
    let d = new Date(date);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    if (String(this.date).length === 1) {
      this.date = "0" + this.date;
    }
    if (String(this.month).length === 1) {
      this.month = "0" + this.month;
    }
    var stringDate  =this.month + "/" + this.date + "/" + this.year;
    return stringDate;
  }
  /*  ********************** Pradeep Changes******************* */

}
