import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}



@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  // local variables
  public userData = {userName: '', Password: ''};
  // tslint:disable-next-line:no-inferrable-types
  showLoginValidationErrorMsg: boolean = false;

  constructor(public authService: AuthService) {
   this.authService.currentMessage.subscribe(message => this.showLoginValidationErrorMsg = message);
  }
  // form control validation
  usernameFormControl = new FormControl('', [
    Validators.required
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  matcher = new MyErrorStateMatcher();
  // methods
  ngOnInit() {

  }
  signIn(userName, Password) {
    this.showLoginValidationErrorMsg = true;
    this.authService.login(userName, Password);
  }
}
