import { Component, OnInit, ChangeDetectorRef , HostListener, OnDestroy } from '@angular/core';
import { OpentokService } from '../opentok.service';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import * as OT from '@opentok/client';
import { AuthService } from '../services/auth.service';
// import { CommonFunctionsService } from '../services/common-functions.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import {MatDialog} from '@angular/material';
// import {DialogForEndcallComponent} from '../dialog-for-endcall/dialog-for-endcall.component';
import * as firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';

declare var $: any;

@Component({
  selector: 'app-callfromnotification',
  templateUrl: './callfromnotification.component.html',
  styleUrls: ['./callfromnotification.component.css']
})
export class CallfromnotificationComponent implements OnInit {

  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  video = {'token': '', 'sessionId': ''};
  loggedInUser;
  callId;
  isCallInitiated;
  isCallEndedByDoctor: Boolean = false;

  constructor(private http: HttpClient, public authService: AuthService,
    public dialog: MatDialog, private opentokService: OpentokService, private ref: ChangeDetectorRef,
    public errorDialog: MatDialog , public successDialog: MatDialog , public dialogForProfile: MatDialog,
    public loader: LoadingBarService,  private router: Router, private route: ActivatedRoute,
    public snackBar: MatSnackBar) {

    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.getCallDetailsByFirebase());
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.callId =  receivedCallId[1];
    // if (isInitiated[1] === 'false') {
      firebase.database().ref('videoCallActions/stroke/' + this.callId + '/status').on('value', snapshot => {
        console.log(snapshot.val());
        if (snapshot.val() === 'endedByNurse' && this.callId === false) {
          this.opendialogForProfile('callEndedByNurse');
        }
        if (snapshot.val() === 'endedByDoctor') {
          this.isCallEndedByDoctor = true;
          // this.opendialogForProfile('fromEndCallButton');
        }
      });

  }

    ngOnInit() {

    }

    /*============= Join Call/Start Call Step I ====================*/
    getCallDetailsByFirebase() {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      const isInitiated = (urlparam[1]).split('=');
      this.callId =  receivedCallId[1];
      var callIdParam = receivedCallId[1];
      this.isCallInitiated = isInitiated[1];
      if (this.isCallInitiated === 'false') {
        this.getFirebaseFormDetails(this.callId);
        var isFirst = true;
          const dbRef: firebase.database.Reference = firebase.database().ref('emergencyCall/stroke/' + this.callId + '/videoCallDetails');
          dbRef.once('value', snapshot => {
          this.updateChangeObservedTime(this.callId);
          this.video.token = snapshot.val()['videoToken'];
          this.video.sessionId = snapshot.val()['sessionId'];
            if (snapshot.hasChild('connectedBy')) {
              if (snapshot.val()['connectedBy'] === this.loggedInUser) {
                const callIdRef: firebase.database.Reference =
                firebase.database().ref('emergencyCall/stroke/' + this.callId + '/videoCallDetails/' + 'sessionId');
                callIdRef.on('value', snap => {
                const snapRef: firebase.database.Reference =
                firebase.database().ref('emergencyCall/stroke/' + this.callId + '/videoCallDetails/');
                snapRef.once('value', snapFirst => {
                this.video.token = snapFirst.val()['videoToken'];
                this.video.sessionId = snapFirst.val()['sessionId'];
                if (!isFirst) {
                  window.location.reload();
                } else {
                this.connectTOVideoSession();
                isFirst = false;
                }
              });
              });
              } else {
                alert('Call is received by another');
                $('.loader').fadeOut(2000);
                $('.loader_msg').hide();
                this.loader.complete();
              }
            } else {
              this.connectTOVideoSession();
              this.updatePickUpDoctor(this.callId);
              const callIdRef: firebase.database.Reference =
                firebase.database().ref('emergencyCall/stroke/' + this.callId + '/videoCallDetails/' + 'sessionId');
                callIdRef.on('value', snap => {
                  const snapRef: firebase.database.Reference =
                  firebase.database().ref('emergencyCall/stroke/' + this.callId + '/videoCallDetails/');
                  snapRef.once('value', snapFirst => {
                this.video.token = snapFirst.val()['videoToken'];
                this.video.sessionId = snapFirst.val()['sessionId'];
                if (!isFirst) {
                  window.location.reload();
                } else {
                this.connectTOVideoSession();
                isFirst = false;
                }
              });
              });
            }
          });
      }
    }

    /*======== Set Call Call Status to firebase =======*/
    getFirebaseFormDetails(callId) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      const isInitiated = (urlparam[1]).split('=');
      if (isInitiated[1] === 'false') {
        this.ReceiveEmergencyCall(callId);
      }
      const callIdRef: firebase.database.Reference = firebase.database().ref('doctorAvailability/' + this.loggedInUser);
        callIdRef.update({
          status: 'onCall',
          callId: callId
        });
    }

    /*================== Receive Call ====================*/
    ReceiveEmergencyCall(callId) {
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const params = new HttpParams().set('callId', callId)
      .set('userId', this.loggedInUser);
      this.http.post( this.authService.baseURL + '/ReceiveEmergencyCall', params, {headers: headers}).subscribe(
      data => {
        console.log('receive call', data);
        if (data['Message'] === 'Success' ) {
        } else {
        }
      });
    }

    /*================== update Connected Time ====================*/
    updateChangeObservedTime (callId) {
      var updates = {};
      updates['emergencyCall/stroke/' + callId + '/videoCallDetails/' + 'connectedObservedTime'] = firebase.database.ServerValue.TIMESTAMP;
      return firebase.database().ref().update(updates);
    }

    /*================== Connect to Video Session =================*/
    connectTOVideoSession() {
      this.opentokService.joinSession(this.video.sessionId , this.video.token)
      .then((session: OT.Session) => {
          this.session = session;
          this.session.on('streamCreated', (event) => {
            this.streams.push(event.stream);
            this.changeDetectorRef.detectChanges();
            this.updateCallTimeDetails();
          });
          this.session.on('streamDestroyed', (event) => {
            const idx = this.streams.indexOf(event.stream);
            if (idx > -1) {
              this.streams.splice(idx, 1);
              this.changeDetectorRef.detectChanges();
            }
          });
        })
        .then(() =>
          this.opentokService.connect())
        .catch((err) => {
          console.error(err);
          alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
        });
    }

    /*===================== Update Call Time ================*/
    updateCallTimeDetails() {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      const isInitiated = (urlparam[1]).split('=');
      this.callId =  receivedCallId[1];
      var callIdParam = receivedCallId[1];
      this.isCallInitiated = isInitiated[1];
      var updates = {};
      updates['emergencyCall/stroke/' + this.callId + '/videoCallDetails' + '/connectedTime'] = firebase.database.ServerValue.TIMESTAMP;
      return firebase.database().ref().update(updates);
    }

    /*==================== Update Pick Up Doctor ================*/
    updatePickUpDoctor (callId) {
        var updates = {};
        updates['emergencyCall/stroke/' + callId + '/videoCallDetails/' + 'connectedBy'] = this.loggedInUser;
        return firebase.database().ref().update(updates);
    }

    /*=================== Open Dialog for Profile ===============*/
    opendialogForProfile(message) {
      const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
        // height: '480px',
        data : message
      });
    }
}
